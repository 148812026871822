import React, { FC } from 'react'
import { Button } from 'antd'

import monitorSrc from 'sources/images//content/content-monitor.png'
import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'

import styles from './styles.module.scss'

interface IProps {
  onCreateNewCategory: (isBottomBtn: boolean) => void
}

const WithoutCategories: FC<IProps> = ({ onCreateNewCategory }) => (
  <div className={styles.withoutCategories}>
    <h4 className={styles.text}>
      Here you will start creating your smart tv app ,add videos and playlist
    </h4>
    <img className={styles.monitorPicture} src={monitorSrc} alt="monitor" />
    <Button
      className={styles.createNewCategoryBtn}
      shape="round"
      type="primary"
      icon={<AddIcon />}
      onClick={() => onCreateNewCategory(false)}
    >
      Create New Category
    </Button>
  </div>
)

export default WithoutCategories
