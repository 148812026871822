import React, { FC } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import cn from 'classnames'

import { displayViewsDashboardCount } from 'utils/helper'

import styles from './styles.module.scss'

interface IProps {
  color: string
  value: number
  title: string
  percent: number
  arrow: any
  isLastItem?: boolean | undefined
  isLoading?: boolean
  prevIsLoading?: boolean
  dollarUnit?: string
  percentUnit?: string
}

const antIcon = <LoadingOutlined style={{ fontSize: 42 }} spin />

const InfoFrame: FC<IProps> = ({
  color,
  value,
  title,
  percent,
  arrow,
  isLastItem,
  isLoading,
  prevIsLoading,
  dollarUnit,
  percentUnit,
}) => (
  <div
    className={cn(styles.wrapper, { [styles.isLoading]: isLoading })}
    style={{ marginRight: isLastItem ? '0px' : '16px' }}
  >
    {isLoading ? (
      <Spin
        indicator={antIcon}
        className={styles.monitizationLoader}
        size="large"
      />
    ) : (
      <span className={styles.value} style={{ color }}>
        {dollarUnit || dollarUnit} {displayViewsDashboardCount(value)}{' '}
        {percentUnit || percentUnit}
      </span>
    )}

    <h3 className={styles.title}>{title}</h3>
    <div className={styles.percentWrapper}>
      <span>{`${percent}%`}</span>
      {arrow}
    </div>
  </div>
)

export default InfoFrame
