import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Input,
  message,
  Select,
  Space,
  Typography,
} from 'antd'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'

import { IDistribution } from 'models/distribution'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { useActions } from 'store/hooks/useActions'

import { getPartnerId } from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'
import { ReactComponent as SelectArrowIcon } from 'sources/images/selectApp/select-app-arrow.svg'
import appImgSrc from 'sources/images/selectApp/app.png'

import styles from './styles.module.scss'

interface IProps {
  distributions: IDistribution[] | []
  onChangeDistribution: undefined | ((value: number) => void)
  setAllDistributions: any
  videoProperty: boolean | undefined
}

const SelectDistribution: FC<IProps> = ({
  distributions,
  onChangeDistribution,
  setAllDistributions,
  videoProperty,
}) => {
  const [newName, setNewName] = useState('')
  const [isOpenEnterField, setIsOpenEnterField] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)
  const { partnerInfo } = useTypedSelector(state => state.user)
  const currentDistributionLS = localStorage.getItem('currentDistribution')
  // const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  // const [initDistribution, setInitDistribution] = useState(0)

  // useEffect(() => {
  //   setInitDistribution(0)
  //   setInitDistribution(

  //   )
  // }, [storeCurrentPartnerId])

  const onAddNewDistribution = async (e: any): Promise<void> => {
    try {
      e.preventDefault()
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (!newName) {
        message.info('Please, enter some name!', 3)
        return
      }

      if (partnerId) {
        const response: any = await DistributionAPI.createDistribution(
          partnerId,
          newName
        )

        if (response.status === 'success') {
          setAllDistributions([...distributions, response.distribution])
          setNewName('')
          setIsOpenEnterField(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeNewName = (e: any): void => {
    setNewName(e.target.value)
  }

  const onOpenNewDescription = (): void => {
    setIsOpenEnterField(true)
  }

  const onCloseNewDescription = (): void => {
    setIsOpenEnterField(false)
    setNewName('')
  }

  const sortDistributions = (array: any): IDistribution[] => {
    const sortArray = array.sort((a: any, b: any) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      return 0
    })
    return sortArray
  }

  const filterOption = (input: any, option: any): any =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())

  // console.log(
  //   'storeCurrentDistributionId index',
  //   storeCurrentDistributionId &&
  //     distributions.findIndex(
  //       (distribution: any) => distribution.id === storeCurrentDistributionId
  //     )
  // )

  return (
    <div className={styles.selectApp}>
      <img className={styles.icon} src={appImgSrc} alt="app" />

      {distributions.length > 0 && (
        <Select
          showSearch
          filterOption={filterOption}
          className={styles.select}
          bordered={false}
          suffixIcon={<SelectArrowIcon />}
          placeholder="Distributions name"
          onChange={onChangeDistribution}
          disabled={videoProperty}
          dropdownRender={menu => (
            <>
              {menu}
              {partnerInfo && partnerInfo.isConfirmed && (
                <>
                  <Divider style={{ margin: '2px 0', borderTop: 'none' }} />
                  <Space
                    align="center"
                    className={styles.space}
                    style={{ padding: '0 8px 4px' }}
                  >
                    {!isOpenEnterField && (
                      <Typography.Link
                        style={{ whiteSpace: 'nowrap' }}
                        className={styles.addLink}
                        onClick={onOpenNewDescription}
                      >
                        <Button
                          shape="round"
                          type="primary"
                          className={styles.addBtn}
                          size="small"
                          icon={
                            <PlusOutlined
                              className={styles.addIcon}
                              style={{ color: '#ffffff' }}
                            />
                          }
                        />
                      </Typography.Link>
                    )}

                    {isOpenEnterField && (
                      <>
                        <Input
                          placeholder="Please enter name"
                          value={newName}
                          onChange={onChangeNewName}
                          className={styles.addInputField}
                        />
                        <CloseOutlined
                          className={styles.closeBtn}
                          onClick={onCloseNewDescription}
                        />
                        <CheckOutlined
                          className={styles.doneBtn}
                          onClick={onAddNewDistribution}
                        />
                      </>
                    )}
                  </Space>
                </>
              )}
            </>
          )}
          defaultValue={
            storeCurrentDistributionId &&
            distributions.findIndex(
              (distribution: any) =>
                distribution.id === storeCurrentDistributionId
            ) !== -1
              ? distributions.findIndex(
                  (distribution: any) =>
                    distribution.id === storeCurrentDistributionId
                )
              : 0
          }
          value={
            storeCurrentDistributionId &&
            distributions.findIndex(
              (distribution: any) =>
                distribution.id === storeCurrentDistributionId
            ) !== -1
              ? distributions.findIndex(
                  (distribution: any) =>
                    distribution.id === storeCurrentDistributionId
                )
              : 0
          }
        >
          {sortDistributions(distributions).map(({ name, id }, idx) => (
            <Select.Option key={id} value={idx}>
              {name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  )
}

export default SelectDistribution
