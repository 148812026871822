import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from 'store/actions/user'
import * as ColorActions from 'store/actions/color'
import * as DistributionActions from 'store/actions/distribution'
import * as PathActions from 'store/actions/path'
import * as PartnerActions from 'store/actions/partner'
import * as ProgressBarActions from 'store/actions/progressBar'

export const useActions = (): any => {
  const dispatch = useDispatch()
  return useMemo(
    () =>
      bindActionCreators(
        {
          ...UserActions,
          ...ColorActions,
          ...DistributionActions,
          ...PathActions,
          ...PartnerActions,
          ...ProgressBarActions,
        },
        dispatch
      ),
    [dispatch]
  )
}
