import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import cn from 'classnames'

import { SidebarLinkType } from 'models/sidebar'

import { ReactComponent as DashboardIcon } from 'sources/images/sidebar/dashboard.svg'
import { ReactComponent as ContentIcon } from 'sources/images/sidebar/content.svg'
import { ReactComponent as BrandingIcon } from 'sources/images/sidebar/branding.svg'
import { ReactComponent as LibaryIcon } from 'sources/images/sidebar/libary.svg'
import { ReactComponent as PlatformIcon } from 'sources/images/sidebar/platform.svg'
import { ReactComponent as LiveChannelIcon } from 'sources/images/sidebar/live-channel.svg'
import { ReactComponent as ReportIcon } from 'sources/images/sidebar/report.svg'
import { ReactComponent as SettingIcon } from 'sources/images/sidebar/setting.svg'

import styles from './styles.module.scss'

const SIDEBAR_MENU_ITEMS: SidebarLinkType[] = [
  { path: '/dashboard', icon: <DashboardIcon />, text: 'Dashboard' },
  { path: '/content', icon: <ContentIcon />, text: 'Content' },
  { path: '/branding', icon: <BrandingIcon />, text: 'Branding' },
  { path: '/libary', icon: <LibaryIcon />, text: 'Libary' },
  { path: '/platform', icon: <PlatformIcon />, text: 'Platform' },
  { path: '/liveChannel', icon: <LiveChannelIcon />, text: 'Live Channel' },
  { path: '/report', icon: <ReportIcon />, text: 'Report' },
  { path: '/setting', icon: <SettingIcon />, text: 'Setting' },
]

const MenuSidebar: FC = () => {
  const locationPath = useLocation().pathname

  return (
    <Menu className={styles.menu}>
      {SIDEBAR_MENU_ITEMS.map(({ path, icon, text }: SidebarLinkType) => (
        <Menu.Item
          className={cn(styles.menuItem, {
            [styles.activeLink]: path === locationPath,
          })}
          key={path}
          icon={icon}
        >
          <Link className={styles.menuLink} to={path}>
            {text}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default MenuSidebar
