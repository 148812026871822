import React, { FC, useEffect, useRef, useState } from 'react'
import { message, Spin, Upload } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import { RcFile } from 'antd/lib/upload'

import splashStatusSrc from 'sources/images/branding/splash-status.png'

import viewBgSrc from 'sources/images/branding/branding-view-bg.png'

import styles from './styles.module.scss'

interface IProps {
  uploadSplash: any
  uploadLogo: any
  uploadSplashFile: RcFile | null
  uploadLogoFile: RcFile | null
  isUploadingSplash: boolean
  onBeforeUploadFile: (
    file: RcFile,
    fileList: RcFile[],
    asset: string
  ) => Promise<boolean>
}

const antIcon = <LoadingOutlined style={{ fontSize: 38 }} spin />

const TabAssets: FC<IProps> = ({
  uploadSplash,
  uploadLogo,
  onBeforeUploadFile,
  uploadSplashFile,
  uploadLogoFile,
  isUploadingSplash,
}) => {
  const [isUpdateSplash, setIsUpdateSplash] = useState(false)
  const [splashUrl, setSplashUrl] = useState('')

  const [isUpdateLogo, setIsUpdateLogo] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')

  const canvasSplashRef = useRef<HTMLCanvasElement | null>(null)
  const canvasLogoRef = useRef<HTMLCanvasElement | null>(null)

  const renderSplashImg = (uploadSplashFileData: any): void => {
    const canvas = canvasSplashRef.current
    if (uploadSplashFileData === null) {
      return
    }

    setIsUpdateSplash(true)

    if (canvas) {
      const ctx = canvas.getContext('2d')

      canvas.width = 390
      canvas.height = 216

      // ctx?.drawImage(uploadSplashFileData, 0, 0, canvas.width, canvas.height)

      const imageUrl = canvas.toDataURL()

      setSplashUrl(window.URL.createObjectURL(uploadSplashFileData))

      canvas.width = 0
      canvas.height = 0
    }
  }

  // console.log('uploadSplash', uploadSplash)
  // console.log('uploadLogo', uploadLogo)

  const renderLogoImg = (uploadLogoFileData: any): void => {
    const canvas = canvasLogoRef.current
    if (uploadLogoFileData === null) {
      return
    }

    setIsUpdateLogo(true)

    if (canvas) {
      const ctx = canvas.getContext('2d')

      canvas.width = 86
      canvas.height = 40

      // ctx?.drawImage(uploadLogoFileData, 0, 0, canvas.width, canvas.height)

      const imageUrl = canvas.toDataURL()

      setLogoUrl(window.URL.createObjectURL(uploadLogoFileData))

      canvas.width = 0
      canvas.height = 0
    }
  }

  useEffect(() => {
    renderSplashImg(uploadSplashFile)
  }, [uploadSplash])

  useEffect(() => {
    renderLogoImg(uploadLogoFile)
  }, [uploadLogo])

  return (
    <>
      <Upload.Dragger
        className={styles.splashScreen}
        accept=".png, .jpg, .jpeg"
        beforeUpload={(file, fileList: RcFile[]) =>
          onBeforeUploadFile(file, fileList, 'Splash')
        }
        showUploadList={false}
      >
        <h5 className={styles.uploadTitle}>Splash screen</h5>

        <div className={styles.splashWrapper}>
          <img src={viewBgSrc} alt="view" />
          <div className={styles.viewWrapper}>
            {uploadSplash && (
              <>
                <canvas
                  className={styles.canvasSplash}
                  id="canvassplash"
                  ref={canvasSplashRef}
                />
                <img
                  id={uploadSplash.id || uuidv4()}
                  data-splash=""
                  src={isUpdateSplash ? splashUrl : uploadSplash.url}
                  alt="splash"
                  className={styles.splashBg}
                />
              </>
            )}

            {isUploadingSplash && (
              <Spin
                indicator={antIcon}
                className={styles.spinAsset}
                size="large"
              />
            )}

            {isUploadingSplash && (
              <span className={styles.spinText}>Uploading...</span>
            )}
          </div>
        </div>

        <span className={styles.uploadText}>
          Drag here or <span className={styles.link}>Upload</span>
        </span>
      </Upload.Dragger>

      <Upload.Dragger
        className={styles.channelLogo}
        accept=".png, .jpg, .jpeg"
        beforeUpload={(file, fileList) =>
          onBeforeUploadFile(file, fileList, 'Logo')
        }
        showUploadList={false}
      >
        <h5 className={styles.uploadTitle}>Channel Logo</h5>

        <div className={styles.logoWrapper}>
          <img src={viewBgSrc} alt="view" />
          <div className={styles.viewWrapper}>
            {uploadLogo ? (
              <>
                <canvas
                  className={styles.canvasLogo}
                  id="canvasLogo"
                  ref={canvasLogoRef}
                />
                <img
                  src={isUpdateLogo ? logoUrl : uploadLogo.url}
                  alt="logo"
                  className={styles.logo}
                />
              </>
            ) : (
              <div className={styles.logo}>Logo</div>
            )}

            <div className={styles.description}>
              <span className={styles.descriptionTitle}>
                Title of your channel
              </span>
              <p className={styles.descriptionText}>
                text text text text text text text text text text text text text
                text text text text text text text text text text text text text
                text text
              </p>
            </div>

            <div className={styles.categoriesList}>
              <div className={styles.category}>
                <span className={styles.categoryTitle}>First Category</span>
                <ul className={styles.categoryItems}>
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>

              <div className={styles.category}>
                <span className={styles.categoryTitle}>Second Category</span>
                <ul className={styles.categoryItems}>
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>
            </div>
          </div>
        </div>

        <span className={styles.uploadText}>
          Drag here or <span className={styles.link}>Upload</span>
        </span>
      </Upload.Dragger>
    </>
  )
}

export default TabAssets
