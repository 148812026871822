import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dropdown, Menu, message, Spin, Switch } from 'antd'
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import EmptyCategoryList from 'components/EmptyCategoryList'

import { CategoryAPI } from 'api/VideoCollection/Category'
import { MediaItemAPI } from 'api/VideoCollection/MediaItem'

import { ICategory } from 'models/category'
import { IMediaItem } from 'models/mediaItem'

import {
  displayViewsCount,
  getMediaItemImageUrl,
  getPartnerId,
} from 'utils/helper'

import draggingBgSrc from 'sources/images/content/dragging-bg.png'
import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'
import { ReactComponent as CollapseCategoryIcon } from 'sources/images/content/collapse-arrow.svg'
import { ReactComponent as OptionsCategoryIcon } from 'sources/images/content/category-options.svg'
import { ReactComponent as CategoryDnDIcon } from 'sources/images/content/category-DnD.svg'
import { ReactComponent as HoverMediaItemControlsIcon } from 'sources/images/content/hover-media-item-controls.svg'

import styles from './styles.module.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />

interface IProps {
  category: ICategory
  isLastCategory: boolean
  isDraggingMediaItem: boolean
  newCategoryId: number | null
  setIsDraggingMediaItem: any
  onAddNewVideos: (id: string, DistributionId: number) => void
  onChangeCategories: (categories: ICategory[]) => void
  openMediaItemsSelectedControls: () => void
  changeSelectedMediaItemsId: (
    id: string,
    categoryId: number,
    isRemoved: boolean
  ) => void
  selectedMediaItemsId: any
  onDeleteCategory: (categoryId: number, distributionId: number) => void
  handleDragStartCategory: (e: any) => void
  handleDragEnterCategory: (e: any) => void
  handleDragLeaveCategory: (e: any) => void
  handleDragEndCategory: (e: any) => void
  handleDragOverCategory: (e: any) => void
  handleDragDropCategory: (e: any) => void
  onChangePublishedCategory: any
  currentDistribution: { name: string | undefined; id: number | undefined }
  onDropdownMenuChangePublishedMediaItem: (categoryId: number) => Promise<void>
}

const Category: FC<IProps> = ({
  category,
  isLastCategory,
  newCategoryId,
  isDraggingMediaItem,
  setIsDraggingMediaItem,
  onAddNewVideos,
  openMediaItemsSelectedControls,
  changeSelectedMediaItemsId,
  selectedMediaItemsId,
  onDeleteCategory,
  handleDragStartCategory,
  handleDragEnterCategory,
  handleDragLeaveCategory,
  handleDragEndCategory,
  handleDragOverCategory,
  handleDragDropCategory,
  onChangePublishedCategory,
  currentDistribution,
  onDropdownMenuChangePublishedMediaItem,
}) => {
  const [isCollapseCategory, setIsCollapseCategory] = useState(false)

  const [mediaItems, setMediaItems] = useState<IMediaItem[] | undefined>(
    category.MediaItems?.map((item: any) => {
      const cloneItem = _.cloneDeep(item)
      const smallImgObj =
        cloneItem.Images.length > 0 &&
        cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

      if (smallImgObj) {
        smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
      }

      cloneItem.Images = [
        cloneItem.Images.filter(
          (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
        ),
        smallImgObj,
      ]

      return cloneItem
    }).sort(
      (a: IMediaItem, b: IMediaItem) =>
        a.CategoryMediaItem.orderNumber - b.CategoryMediaItem.orderNumber
    )
  )

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const init = (): void => {
    setIsCollapseCategory(!category.published)
  }

  useEffect(() => {
    init()
  }, [category])

  const [categoryName, setCategoryName] = useState(category.name)
  const [isEditableName, setIsEditableName] = useState(false)

  const inputCategoryNameRef = useRef<HTMLInputElement>(null)

  const draggingStartedIdxRef = useRef<number | null>(null)
  const draggingMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingImgSrcRef = useRef('')

  useEffect(() => {
    setIsEditableName(category.id === newCategoryId)
  }, [newCategoryId])

  const handleDragStartMediaItem = (e: any): void => {
    setIsDraggingMediaItem(true)

    const target = e.target as HTMLImageElement
    const draggingMediaItem = target.closest(
      '[data-media-item-order]'
    ) as HTMLElement

    if (mediaItems) {
      draggingStartedIdxRef.current = mediaItems?.findIndex(
        ({ id }: IMediaItem) => Number(draggingMediaItem.id) === id
      )
    }

    if (draggingMediaItem.firstChild) {
      const imgEl = draggingMediaItem.firstChild as HTMLImageElement
      draggingImgSrcRef.current = imgEl.src

      setTimeout(() => {
        imgEl.src = draggingBgSrc
      }, 0)
    }

    draggingMediaItemRef.current = draggingMediaItem
  }

  const handleDragEnterMediaItem = (e: any): void => {
    try {
      const target = e.target as HTMLImageElement
      const underMediaItem = target.closest(
        '[data-media-item-order]'
      ) as HTMLElement

      if (!underMediaItem.closest('[data-category-order]')) {
        return
      }

      if (underMediaItem.id !== draggingMediaItemRef.current?.id) {
        const draggingIdx = mediaItems?.findIndex(
          ({ id }: IMediaItem) =>
            Number(draggingMediaItemRef.current?.id) === id
        )
        const underIdx = mediaItems?.findIndex(
          ({ id }: IMediaItem) => Number(underMediaItem.id) === id
        )

        if (draggingIdx === -1 || underIdx === -1) {
          return
        }

        const cloneMediaItems = _.cloneDeep(mediaItems)

        if (
          cloneMediaItems &&
          draggingIdx !== undefined &&
          underIdx !== undefined
        ) {
          const tmp = cloneMediaItems[draggingIdx]
          cloneMediaItems[draggingIdx] = cloneMediaItems[underIdx]
          cloneMediaItems[underIdx] = tmp

          setMediaItems(cloneMediaItems)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDropMediaItem = async (e: any): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggingMediaItem(false)

    try {
      const target = e.target as HTMLElement
      const underMediaItem = target.closest(
        '[data-media-item-order]'
      ) as HTMLElement
      const underIdx = mediaItems?.findIndex(
        ({ id }: IMediaItem) => Number(underMediaItem.id) === id
      )

      const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
      img.src = draggingImgSrcRef.current

      if (draggingStartedIdxRef.current !== underIdx) {
        const newOrderNumber = mediaItems?.findIndex(
          ({ id }: IMediaItem) => Number(underMediaItem.id) === id
        )
        const mediaItem = mediaItems?.find(
          ({ id }: IMediaItem) =>
            Number(draggingMediaItemRef.current?.id) === id
        )
        const partnerId = storeCurrentPartnerId || getPartnerId()

        if (mediaItem && newOrderNumber !== undefined && partnerId) {
          const distributionId = Number(mediaItem.DistributionId)
          const mediaItemId = Number(mediaItem.id)
          const categoryId = Number(mediaItem.CategoryMediaItem.CategoryId)

          await MediaItemAPI.setOrderNumberForMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            categoryId,
            newOrderNumber + 1
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDragEndMediaItem = (e: any): void => {
    const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
    img.src = draggingImgSrcRef.current
    setIsDraggingMediaItem(false)
  }

  const onSelectMediaItem = (e: CheckboxChangeEvent): void => {
    const target = e.nativeEvent.target as HTMLInputElement
    const mediaItem = target.closest('[data-media-item-order]')

    openMediaItemsSelectedControls()

    if (target.checked && mediaItem) {
      changeSelectedMediaItemsId(mediaItem.id, category.id, false)
    } else if (mediaItem) {
      changeSelectedMediaItemsId(mediaItem.id, category.id, true)
    }
  }

  const onCloseEditableMode = async (): Promise<void> => {
    try {
      if (inputCategoryNameRef.current) {
        const cloneCategory = _.cloneDeep(category)
        const distributionId = Number(cloneCategory.DistributionId)
        const categoryId = Number(cloneCategory.id)
        const partnerId = storeCurrentPartnerId || getPartnerId()

        cloneCategory.name = inputCategoryNameRef.current.value

        if (partnerId) {
          await CategoryAPI.updateCategory(
            partnerId,
            distributionId,
            categoryId,
            {
              ...cloneCategory,
            }
          )

          setCategoryName(inputCategoryNameRef.current.value)
          setIsEditableName(false)

          message.success('New category name saved!', 3)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onOpenEditableMode = (): void => {
    setIsEditableName(true)

    if (inputCategoryNameRef.current) {
      inputCategoryNameRef.current.focus()
    }
  }

  const onRemoveMediaItem = async (e: React.MouseEvent): Promise<void> => {
    const target = e.target as HTMLElement
    const removeBtn = target.closest('[data-remove-id]')
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (removeBtn && partnerId) {
      const mediaItemId = Number(removeBtn.id)
      const distributionId = category.DistributionId
      const categoryId = String(category.id)

      await MediaItemAPI.deleteCategoryForMediaItem(
        partnerId,
        distributionId,
        mediaItemId,
        categoryId
      )

      const cloneMediaItems = _.cloneDeep(mediaItems)

      setMediaItems(cloneMediaItems?.filter(({ id }) => id !== mediaItemId))
      message.success('Video was deleted from category!', 3)
    }
  }

  return (
    <div
      className={styles.categoryWrapper}
      draggable={!isEditableName}
      id={String(category.id)}
      data-category-id={category.id}
      data-category-order={category.orderNumber}
      onDragStart={handleDragStartCategory}
      onDragEnter={handleDragEnterCategory}
      onDragLeave={handleDragLeaveCategory}
      onDragEnd={handleDragEndCategory}
      onDragOver={handleDragOverCategory}
      onDrop={handleDragDropCategory}
    >
      <CategoryDnDIcon
        className={cn(styles.dndIcon, {
          [styles.isCollapseDndIcon]: isCollapseCategory,
        })}
      />

      <div
        className={cn(styles.categoryTopBar, {
          [styles.collapseCategoryTopBar]: isCollapseCategory,
        })}
      >
        {isEditableName ? (
          <div className={styles.editableCategoryNameWrapper}>
            <input
              type="text"
              data-category-name={category.name}
              defaultValue={categoryName}
              ref={inputCategoryNameRef}
              className={styles.editableCategoryName}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  onCloseEditableMode()
                }
              }}
              onBlur={onCloseEditableMode}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>
        ) : (
          <h3
            className={styles.categoryName}
            onDoubleClick={!isLastCategory ? onOpenEditableMode : undefined}
          >
            {categoryName}
          </h3>
        )}

        {category.MediaItems && category.MediaItems.length > 0 && (
          <>
            <Button shape="round" className={styles.totalVideos}>
              {mediaItems
                ? mediaItems.filter((item: any) => item.published !== false)
                    .length
                : '0'}{' '}
              videos
            </Button>
            <Button shape="round" className={styles.totalViews}>
              {displayViewsCount(
                category.MediaItems.reduce(
                  (sum: number, mediaItem) => sum + mediaItem.played,
                  0
                )
              )}{' '}
              TV views
            </Button>
          </>
        )}
        <div className={styles.categoryControls}>
          <div className={styles.togglePublishedCategory}>
            <span>{category.published ? 'Published' : 'Unpublished'}</span>
            <Switch
              checked={category.published}
              onChange={onChangePublishedCategory}
              className={cn(styles.switchPublished, {
                [styles.disabledPublished]: !category.published,
              })}
            />
          </div>

          <CollapseCategoryIcon
            className={styles.controlsArrow}
            onClick={
              category.published
                ? (e: React.MouseEvent) => {
                    const target = e.target as any
                    setIsCollapseCategory(!isCollapseCategory)

                    target.closest('svg').style.transform = isCollapseCategory
                      ? 'rotate(0)'
                      : 'rotate(90deg)'
                  }
                : undefined
            }
          />

          <Dropdown
            className={styles.controlsOptions}
            placement="bottomRight"
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item key="1" className={styles.controlsOptionLink}>
                  <Link
                    className={styles.controlsOptionBtn}
                    to="/libary"
                    state={{
                      category: category.name,
                    }}
                  >
                    Show in Libary
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" className={styles.controlsOptionLink}>
                  <Button
                    className={styles.controlsOptionBtn}
                    onClick={() =>
                      onDropdownMenuChangePublishedMediaItem(category.id)
                    }
                  >
                    {category.published
                      ? 'Unpublish category'
                      : 'Publish category'}
                  </Button>
                </Menu.Item>
                <Menu.Item key="3" className={styles.controlsOptionLink}>
                  <Button
                    id={String(category.id)}
                    className={styles.controlsOptionBtn}
                    onClick={
                      !isLastCategory
                        ? () =>
                            onDeleteCategory(
                              category.id,
                              category.DistributionId
                            )
                        : undefined
                    }
                  >
                    Delete category
                  </Button>
                </Menu.Item>
                <Menu.Item key="4" className={styles.controlsOptionLink}>
                  <Button className={styles.controlsOptionBtn}>
                    Duplicate
                  </Button>
                </Menu.Item>
                <Menu.Item key="5" className={styles.controlsOptionLink}>
                  <Button className={styles.controlsOptionBtn}>
                    Edit information
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <OptionsCategoryIcon />
          </Dropdown>
        </div>
      </div>

      {!isCollapseCategory && (
        <div className={styles.category}>
          <ul className={cn(styles.categorySlider)}>
            <li className={styles.addVideoSlide}>
              <div className={styles.addVideos}>
                <Button
                  className={styles.addVideosBtn}
                  icon={<AddIcon />}
                  shape="circle"
                  onClick={() =>
                    onAddNewVideos(String(category.id), category.DistributionId)
                  }
                />
                Add videos
              </div>
            </li>

            {mediaItems === undefined ||
            mediaItems.filter((item: any) => item.published !== false)
              .length === 0 ? (
              <EmptyCategoryList count={7} withBorder />
            ) : (
              mediaItems

                ?.filter((item: any) => item.published !== false)
                .map(
                  ({
                    id,
                    Images,
                    CategoryMediaItem,
                    title,
                    status,
                  }: IMediaItem) => (
                    <li
                      key={id}
                      id={String(id)}
                      data-media-item-order={CategoryMediaItem.orderNumber}
                      className={cn(styles.mediaItem, {
                        [styles.selectedMediaItem]: selectedMediaItemsId.find(
                          (item: any) =>
                            String(id) === item.mediaItemId &&
                            category.id === item.categoryId
                        ),
                      })}
                      draggable="true"
                      onDragStart={handleDragStartMediaItem}
                      onDragEnd={handleDragEndMediaItem}
                      onDragEnter={handleDragEnterMediaItem}
                      onDrop={handleDropMediaItem}
                    >
                      <img
                        className={styles.mediaItemImg}
                        src={
                          Images.length > 0
                            ? getMediaItemImageUrl(Images, 'Small', true)
                            : defaultImgBgSrc
                        }
                        alt="video"
                      />

                      {status === 'failed' && (
                        <div className={styles.failedOverlay}>
                          <span className={styles.failedIconWrapper}>
                            <CloseOutlined className={styles.failedIcon} />
                          </span>
                        </div>
                      )}

                      {status !== 'imported' &&
                        status !== 'failed' &&
                        !isDraggingMediaItem && (
                          <Spin
                            indicator={antIcon}
                            className={styles.mediaItemStatus}
                            size="large"
                          />
                        )}

                      {!isDraggingMediaItem && (
                        <>
                          <div className={styles.mediaItemControls}>
                            <Dropdown
                              placement="bottomLeft"
                              className={styles.mediaItemDropdown}
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key="1"
                                    className={styles.mediaItemDropdownLink}
                                  >
                                    <Button
                                      id={String(id)}
                                      data-remove-id={id}
                                      className={styles.controlsOptionBtn}
                                      onClick={onRemoveMediaItem}
                                    >
                                      Remove
                                    </Button>
                                  </Menu.Item>
                                  <Menu.Item
                                    key="2"
                                    className={styles.mediaItemDropdownLink}
                                  >
                                    <Link
                                      className={styles.controlsOptionBtn}
                                      to={`/libary/${category.DistributionId}/video-properties/${id}`}
                                      state={{
                                        distribution: currentDistribution,
                                      }}
                                    >
                                      Video properties
                                    </Link>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <HoverMediaItemControlsIcon />
                            </Dropdown>

                            <Checkbox
                              className={styles.checkboxMediaItem}
                              onChange={onSelectMediaItem}
                              checked={selectedMediaItemsId.find(
                                (item: any) =>
                                  String(id) === item.mediaItemId &&
                                  category.id === item.categoryId
                              )}
                            />
                          </div>

                          <div className={styles.mediaItemTitleWrapper}>
                            <h4 className={styles.title}>{title}</h4>
                          </div>
                        </>
                      )}
                    </li>
                  )
                )
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Category
