import React, { FC } from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { ReactComponent as BackArrowIcon } from 'sources/images/addVideos/back-arrow.svg'

import styles from './styles.module.scss'

interface IProps {
  isConnected: boolean
  allMediaItemsInCategory?: any
  onSaveAddedMediaItemsInCategory?: () => Promise<void>
  isComputer?: boolean

  totalPlaylistsMediaItems?: number
  totalImportedPlaylistsMediaItems?: number

  isChangeOrder?: boolean
  isChangeDescription?: boolean
  isChangeSync?: boolean
  category?: any
}

const AddVideosTopBar: FC<IProps> = ({
  isConnected,
  allMediaItemsInCategory,
  onSaveAddedMediaItemsInCategory,
  isComputer,

  totalPlaylistsMediaItems,
  totalImportedPlaylistsMediaItems,
  isChangeOrder,
  isChangeDescription,
  isChangeSync,
  category,
}) => {
  const navigate = useNavigate()

  const onBack = (): void => {
    navigate('/content')
  }

  return (
    <div className={styles.addVideosTopBar}>
      <Button
        className={styles.backBtn}
        icon={<BackArrowIcon />}
        onClick={onBack}
      />
      <h2 className={styles.title}>Add videos to category</h2>
      {isConnected && (
        <div className={styles.saveVideosWrapper}>
          {!isComputer && (
            <span
              className={styles.saveVideosText}
            >{`${totalImportedPlaylistsMediaItems}/${totalPlaylistsMediaItems} Imported`}</span>
          )}
          <Button
            className={cn(styles.saveVideosBtn, {
              [styles.active]:
                (allMediaItemsInCategory &&
                  allMediaItemsInCategory
                    .filter((item: any) => item !== undefined)
                    .some(
                      (mediaItem: any) =>
                        (mediaItem && mediaItem.uid) ||
                        (mediaItem && mediaItem.uri) ||
                        (mediaItem && mediaItem.snippet)
                    )) ||
                isChangeOrder ||
                isChangeDescription ||
                isChangeSync ||
                (category &&
                  category?.MediaItems.length < allMediaItemsInCategory.length),
            })}
            shape="round"
            onClick={onSaveAddedMediaItemsInCategory}
          >
            Save category
          </Button>
        </div>
      )}
    </div>
  )
}

export default AddVideosTopBar
