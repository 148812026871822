import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import history from 'utils/history'

import SelectDistribution from 'components/SelectDistribution'
import UserInfo from 'components/UserInfo'
import SelectPartner from 'components/SelectPartner'

import { IDistribution, IResponseDistributions } from 'models/distribution'
import { PartnerAPI } from 'api/User/Partner'

import { IPartnerInfo, IUserInfo } from 'store/types/user'
import { IUser } from 'models/user'
import { UserAPI } from 'api/User/User'
import { getPartnerId } from 'utils/helper'
import { useActions } from 'store/hooks/useActions'

import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ReactComponent as BackArrowIcon } from 'sources/images/addVideos/back-arrow.svg'
import logoSrc from 'sources/images/topbar/logo.png'

import styles from './styles.module.scss'

interface IProps {
  isLogged: boolean
  distributions: IDistribution[] | []
  onChangeDistribution: (value: number) => void
  videoProperty?: boolean
  setAllDistributions?: any
  allPartners?: any
  setAllPartners?: any
  setIsSuperAdmin?: any
  isSuperAdmin?: boolean
  onChangePartner?: (value: number) => void

  isHiddenSelectDistribution?: boolean
}

const TopBar: FC<IProps> = ({
  isLogged,
  distributions,
  onChangeDistribution,
  videoProperty,
  setAllDistributions,
  allPartners,
  setAllPartners,
  setIsSuperAdmin,
  isSuperAdmin,
  onChangePartner,

  isHiddenSelectDistribution,
}) => {
  const [userInfoData, setUserInfoData] = useState<IUserInfo | null>(null)
  const [partnerInfoData, setPartnerInfoData] = useState<IPartnerInfo | null>(
    null
  )

  const {
    saveUserInfoAction,
    savePartnerInfoAction,
    saveCurrentPartnerDataAction,
    saveCurrentPartnerIdAction,
    saveAllPartnersDataAction,
  } = useActions()

  const { userInfo, partnerInfo } = useTypedSelector(state => state.user)
  const { storeCurrentPartnerId, storeAllPartnersData } = useTypedSelector(
    state => state.partner
  )

  const init = async (): Promise<void> => {
    if (userInfo) {
      setUserInfoData(userInfo)

      if (storeAllPartnersData && storeCurrentPartnerId) {
        setAllPartners(storeAllPartnersData)
        setIsSuperAdmin(true)

        const findPartner = storeAllPartnersData.find(
          (partner: any) => partner.id === storeCurrentPartnerId
        )

        if (findPartner) {
          saveCurrentPartnerDataAction(findPartner)
          saveCurrentPartnerIdAction(findPartner.id)
        }
      }
    } else {
      const userFromLS = localStorage.getItem('user')
      const partnerId = getPartnerId()

      if (userFromLS && partnerId) {
        const accountFromLS: IUser = JSON.parse(userFromLS)
        const responseUserInfo: any = await UserAPI.getOneUserInfo(
          partnerId,
          accountFromLS.userId,
          accountFromLS.token
        )

        if (responseUserInfo.status === 'success') {
          const userData = responseUserInfo.users[0]
          saveUserInfoAction(userData)
          setUserInfoData(userData)

          if (userData.type === 'super') {
            const responsePartners: any = await PartnerAPI.getAllPartnersInfo(
              accountFromLS.token
            )

            setIsSuperAdmin(true)

            if (responsePartners.status === 'success') {
              const partnerData = responsePartners.partners[0]
              setAllPartners(responsePartners.partners)

              saveCurrentPartnerDataAction(partnerData)

              if (!storeCurrentPartnerId) {
                saveCurrentPartnerIdAction(partnerData.id)
                localStorage.setItem(
                  'currentPartner',
                  JSON.stringify(partnerData.id)
                )
              }

              saveAllPartnersDataAction(responsePartners.partners)
            }
          }

          const responsePartnerInfo: any = await PartnerAPI.getOnePartnerInfo(
            partnerId,
            accountFromLS.token
          )

          if (responsePartnerInfo.status === 'success') {
            savePartnerInfoAction(responsePartnerInfo.partners[0])
          }
        }
      }
    }

    if (userInfo && partnerInfo) {
      setPartnerInfoData(partnerInfo)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div className={styles.topBar}>
      <Link to={isLogged ? '/content' : '/login'}>
        <img className={styles.logo} src={logoSrc} alt="logo" />
      </Link>

      {isLogged && (
        <>
          {isSuperAdmin && !isHiddenSelectDistribution && (
            <SelectPartner
              allPartners={allPartners}
              onChangePartner={onChangePartner}
              setAllDistributions={undefined}
              videoProperty={videoProperty}
            />
          )}

          {!isHiddenSelectDistribution && (
            <SelectDistribution
              distributions={distributions}
              setAllDistributions={setAllDistributions}
              onChangeDistribution={
                videoProperty ? undefined : onChangeDistribution
              }
              videoProperty={videoProperty}
            />
          )}
          <UserInfo userInfoData={userInfoData} />
        </>
      )}
    </div>
  )
}

export default TopBar
