export const StatisticsMethods = [
  'total-views',
  'unique-users',
  'start-apps',
  'views-by-days',
  'video-views',
  'view-by-platform',
  'view-by-country',
]

export interface IPlatformItem {
  deviceType: number
  devices: string
  platform: string
}

export interface IViewItem {
  videoID: number
  distrID: number
  views: string
}

export interface ICountryItem {
  country: string
  devices: string
  part: string
}

export interface IDistributionStatsData {
  name: string
  id: number
  pcidName: string
}

export interface IDataPickerItem {
  startDate: Date | undefined
  endDate: Date | undefined
  key: string
}
