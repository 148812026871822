import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, message, Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { LoadingOutlined } from '@ant-design/icons'
import _ from 'lodash'
import cn from 'classnames'

import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { downloadFile, getPartnerId } from 'utils/helper'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { IImage } from 'models/mediaItem'

import {
  AndroidTags,
  AppleTags,
  PlatformTypes,
  RokuTags,
  rokuAppBuilderTags,
  appleAppBuilderTags,
  androidAppBuilderTags,
} from 'models/platform'

import { ReactComponent as DoneUploadArrowIcon } from 'sources/images/platform/check-upload.svg'

import styles from './styles.module.scss'

interface IProps {
  title: string
  sizeAsset: string
  platform: PlatformTypes
  tag: AppleTags | RokuTags | AndroidTags
  distributionAssets: IImage[]
  accept?: string
  setRokuAssetsTag?: React.Dispatch<React.SetStateAction<string[]>>
  setAndroidAssetsTag?: React.Dispatch<React.SetStateAction<string[]>>
  setAppleAssetsTag?: React.Dispatch<React.SetStateAction<string[]>>
}

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />

const UploadAsset: FC<IProps> = ({
  title,
  sizeAsset,
  platform,
  tag,
  distributionAssets,
  accept,
  setRokuAssetsTag,
  setAndroidAssetsTag,
  setAppleAssetsTag,
}) => {
  const [defaultAsset, setDefaultAsset] = useState<null | string>(null)

  const [isDoneUpload, setIsDoneUpload] = useState(false)
  const [isUploadingAsset, setIsUploadingAsset] = useState(false)
  const [isUpdateAsset, setIsUpdateAsset] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const [uploadFile, setUploadFile] = useState<null | RcFile>(null)
  const [uploadFileSrc, setUploadFileSrc] = useState('')

  const init = (): void => {
    const findDefaultAsset = distributionAssets.find(
      (item: IImage) => item.tag === tag
    )

    if (findDefaultAsset) {
      setDefaultAsset(findDefaultAsset.url)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const createUploadImgSrc = (uploadFileData: RcFile): void => {
    if (uploadFileData === null) {
      return
    }

    setUploadFileSrc(window.URL.createObjectURL(uploadFileData))
  }

  const onBeforeUploadFile = async (
    file: RcFile,
    fileList: RcFile[]
  ): Promise<boolean> => {
    const partnerId = storeCurrentPartnerId || getPartnerId()
    setIsUploadingAsset(true)

    if (file && partnerId && storeCurrentDistributionId) {
      const response: any = await DistributionAPI.addAssetImageToDistribution(
        partnerId,
        storeCurrentDistributionId,
        tag,
        platform,
        file
      )

      if (response.status === 'success') {
        createUploadImgSrc(file)
        const tagImage = response.image.tag

        if (setRokuAssetsTag && rokuAppBuilderTags.includes(tagImage)) {
          setRokuAssetsTag(prev => [...prev, tagImage])
        }

        if (setAppleAssetsTag && appleAppBuilderTags.includes(tagImage)) {
          setAppleAssetsTag(prev => [...prev, tagImage])
        }

        if (setAndroidAssetsTag && androidAppBuilderTags.includes(tagImage)) {
          setAndroidAssetsTag(prev => [...prev, tagImage])
        }

        setIsUpdateAsset(true)
        setIsUploadingAsset(false)
        setIsDoneUpload(true)
        setUploadFile(file)

        message.success(`Asset was uploaded!`, 3)
      }

      if (response.status === 'ERROR') {
        setUploadFile(null)
        setIsUploadingAsset(false)
      }
    }
    return false
  }

  const onDownloadAsset = (): void => {
    downloadFile(sizeAsset, isUpdateAsset ? uploadFileSrc : defaultAsset)
  }

  return (
    <div className={styles.uploadSection}>
      <div className={styles.topUploadWrapper}>
        <div className={styles.info}>
          <h4 className={styles.title}>{title}</h4>
          <span className={styles.text}>{sizeAsset}</span>
        </div>
        <Upload
          showUploadList={false}
          accept={accept || '.png, .jpg, .jpeg'}
          beforeUpload={onBeforeUploadFile}
        >
          <Button
            className={
              isDoneUpload || defaultAsset
                ? styles.replaceBtn
                : styles.uploadBtn
            }
            shape="round"
          >
            {isDoneUpload || defaultAsset ? 'Replace Asset' : 'Upload Asset'}
          </Button>
        </Upload>
      </div>

      {(defaultAsset || isUpdateAsset) && (
        <div className={styles.imgWrapper}>
          <div className={styles.imgInfo}>
            {uploadFile && (
              <span className={styles.fileName}>
                {isDoneUpload && (
                  <DoneUploadArrowIcon className={styles.doneArrow} />
                )}{' '}
                {uploadFile.name}
              </span>
            )}

            <Button className={styles.downloadLink} onClick={onDownloadAsset}>
              Download
            </Button>
          </div>

          <>
            <div className={styles.assetImgWrapper}>
              <img
                data-asset=""
                src={(isUpdateAsset && uploadFileSrc) || defaultAsset || ''}
                alt="splash"
                className={styles.assetImg}
              />
            </div>

            {isUploadingAsset && (
              <Spin
                indicator={antIcon}
                className={styles.spinAsset}
                size="large"
              />
            )}

            {isUploadingAsset && (
              <span className={styles.spinText}>Uploading...</span>
            )}
          </>
        </div>
      )}
    </div>
  )
}

export default UploadAsset
