import React, { FC, useRef, useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import _ from 'lodash'
import cn from 'classnames'

import { getMediaItemImageUrl } from 'utils/helper'

import { ICategory } from 'models/category'
import { IMediaItem } from 'models/mediaItem'

import EmptyCategoryList from 'components/EmptyCategoryList'

import { ReactComponent as ResetIcon } from 'sources/images/reset.svg'
import { ReactComponent as HoverMediaItemControlsIcon } from 'sources/images/content/hover-media-item-controls.svg'

import draggingBgSrc from 'sources/images/content/dragging-bg.png'
import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import styles from './styles.module.scss'

interface IProps {
  draggingUploadMediaItemRef?: any
  handleDragEnterCategory?: any
  handleDragLeaveCategory?: any
  handleDragOverCategory?: any
  handleDropCategory?: any
  category: ICategory
  allMediaItemsInCategory?: []
  setAllMediaItemsInCategory?: any
  selectedUploadMediaItemsId: string[]
  onDeleteAddedMediaItemInCategory?: (id: string) => void
  onCloseControlsSelectedMediaItems?: () => void
  onAddSelectedMediaItemsInCategory?: () => void
  isOpenMediaItemsControls?: boolean
  setIsChangeOrder: any

  inCategory: boolean
  uploadYoutubeMediaItems: any
  setSelectedUploadMediaItemsId: any
  draggingMediaItemRef: any
}

const YoutubeCategory: FC<IProps> = ({
  draggingUploadMediaItemRef,
  handleDragEnterCategory,
  handleDragLeaveCategory,
  handleDragOverCategory,
  handleDropCategory,
  category,
  selectedUploadMediaItemsId,
  onCloseControlsSelectedMediaItems,
  isOpenMediaItemsControls,
  onAddSelectedMediaItemsInCategory,
  onDeleteAddedMediaItemInCategory,
  allMediaItemsInCategory,
  setAllMediaItemsInCategory,
  setIsChangeOrder,

  inCategory,
  uploadYoutubeMediaItems,
  setSelectedUploadMediaItemsId,
  draggingMediaItemRef,
}) => {
  const [isDraggingMediaItem, setIsDraggingMediaItem] = useState(false)

  const draggingStartedIdxRef = useRef<number | null>(null)
  const draggingImgSrcRef = useRef('')

  const handleDragStartMediaItem = (e: any): void => {
    setIsDraggingMediaItem(true)

    const target = e.target as HTMLImageElement
    const draggingMediaItem = target.closest(
      '[data-media-item-order]'
    ) as HTMLElement

    if (allMediaItemsInCategory) {
      draggingStartedIdxRef.current = allMediaItemsInCategory?.findIndex(
        ({ id }: IMediaItem) => Number(draggingMediaItem.id) === id
      )
    }

    if (draggingMediaItem.firstChild) {
      const imgEl = draggingMediaItem.firstChild as HTMLImageElement
      draggingImgSrcRef.current = imgEl.src

      setTimeout(() => {
        imgEl.src = draggingBgSrc
      }, 0)
    }

    // eslint-disable-next-line  no-param-reassign
    draggingMediaItemRef.current = draggingMediaItem
  }

  const handleDragEnterMediaItem = (e: any): void => {
    try {
      setIsChangeOrder(true)
      const target = e.target as HTMLImageElement
      const underMediaItem = target.closest(
        '[data-media-item-order]'
      ) as HTMLElement

      if (draggingMediaItemRef.current?.id === undefined) {
        // eslint-disable-next-line  no-param-reassign
        draggingMediaItemRef.current = draggingUploadMediaItemRef.current
      }

      if (underMediaItem.id !== draggingMediaItemRef.current?.id) {
        const draggingIdx = allMediaItemsInCategory?.findIndex(
          (mediaItem: any) =>
            Number(draggingMediaItemRef.current?.id) === mediaItem.id ||
            (mediaItem.snippet &&
              draggingMediaItemRef.current?.id ===
                mediaItem.snippet.resourceId.videoId)
        )
        const underIdx = allMediaItemsInCategory?.findIndex(
          (mediaItem: any) =>
            Number(underMediaItem.id) === mediaItem.id ||
            (mediaItem.snippet &&
              underMediaItem.id === mediaItem.snippet.resourceId.videoId)
        )

        if (draggingIdx === -1 || underIdx === -1) {
          return
        }

        const cloneMediaItems = _.cloneDeep(allMediaItemsInCategory)

        if (
          cloneMediaItems &&
          draggingIdx !== undefined &&
          underIdx !== undefined
        ) {
          const tmp = cloneMediaItems[draggingIdx]
          cloneMediaItems[draggingIdx] = cloneMediaItems[underIdx]
          cloneMediaItems[underIdx] = tmp

          setAllMediaItemsInCategory(cloneMediaItems)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDropMediaItem = async (e: any): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggingMediaItem(false)

    const tempItem = document.querySelector('[data-temp]')
    tempItem?.remove()

    if (
      allMediaItemsInCategory &&
      allMediaItemsInCategory.find((item: any) =>
        item.snippet
          ? draggingUploadMediaItemRef?.current?.id ===
            item.snippet.resourceId.videoId
          : draggingUploadMediaItemRef?.current?.id === String(item.id)
      )
    ) {
      return
    }

    if (draggingUploadMediaItemRef.current && inCategory) {
      const findMediaItem = uploadYoutubeMediaItems.find(
        (item: any) =>
          item.snippet &&
          draggingUploadMediaItemRef?.current?.id ===
            item.snippet.resourceId.videoId
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadYoutubeMediaItems.filter(
        (item: any) =>
          selectedUploadMediaItemsCurrentId.some(
            (currentId: string) =>
              item.snippet && currentId === item.snippet.resourceId.videoId
          )
      )

      if (findMediaItem && allMediaItemsInCategory) {
        setAllMediaItemsInCategory([
          findMediaItem,
          ...filteredUploadMediaItems,
          ...allMediaItemsInCategory,
        ])
      }

      setSelectedUploadMediaItemsId([])
    }

    try {
      const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
      img.src = draggingImgSrcRef.current
    } catch (error) {
      console.log(error)
    }
  }

  const handleDragEndMediaItem = (e: any): void => {
    const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
    img.src = draggingImgSrcRef.current
    setIsDraggingMediaItem(false)

    draggingStartedIdxRef.current = null
    // eslint-disable-next-line  no-param-reassign
    draggingMediaItemRef.current = null
    draggingImgSrcRef.current = ''
  }

  return (
    <div
      className={styles.categoryWrapper}
      data-category=""
      onDragEnter={handleDragEnterCategory}
      onDragLeave={handleDragLeaveCategory}
      onDragOver={handleDragOverCategory}
      onDrop={handleDropCategory}
    >
      <div className={styles.categoryTopBar} data-drag-zone="">
        <h5 className={styles.categoryName}>{category.name}</h5>

        {selectedUploadMediaItemsId.length > 0 && isOpenMediaItemsControls && (
          <div className={styles.selectedVideos}>
            <span className={styles.selectedVideosText}>
              {
                selectedUploadMediaItemsId.filter((str: string) => str !== '')
                  .length
              }{' '}
              Selected
            </span>
            <Button
              className={cn(styles.selectedVideosAddBtn, {
                [styles.disabledSelected]:
                  selectedUploadMediaItemsId.length === 0,
              })}
              shape="round"
              onClick={onAddSelectedMediaItemsInCategory}
            >
              Add videos
            </Button>
            <Button
              className={styles.selectedVideosResetBtn}
              icon={<ResetIcon />}
              onClick={onCloseControlsSelectedMediaItems}
            />
          </div>
        )}
      </div>

      <div className={styles.category}>
        <ul
          className={styles.categorySlider}
          data-drag-list=""
          // onDragEnter={handleDragEnterCategory}
          // onDragLeave={handleDragLeaveCategory}
        >
          {allMediaItemsInCategory && allMediaItemsInCategory.length === 0 ? (
            <EmptyCategoryList count={7} withBorder />
          ) : (
            <>
              {allMediaItemsInCategory &&
                allMediaItemsInCategory.map((mediaItem: any) => (
                  <li
                    key={
                      (mediaItem.snippet &&
                        mediaItem.snippet.resourceId.videoId) ||
                      mediaItem.id
                    }
                    id={
                      (mediaItem.snippet &&
                        mediaItem.snippet.resourceId.videoId) ||
                      (mediaItem.id && String(mediaItem.id))
                    }
                    className={styles.mediaItem}
                    data-media-item-order=""
                    draggable="true"
                    onDragStart={handleDragStartMediaItem}
                    onDragEnd={handleDragEndMediaItem}
                    onDragEnter={handleDragEnterMediaItem}
                    onDrop={handleDropMediaItem}
                  >
                    <img
                      className={styles.mediaItemImg}
                      src={
                        mediaItem.Images && mediaItem.Images.length > 0
                          ? getMediaItemImageUrl(
                              mediaItem.Images,
                              'Small',
                              true
                            )
                          : (mediaItem.snippet &&
                              mediaItem.snippet.thumbnails.high.url) ||
                            defaultImgBgSrc
                      }
                      alt="video"
                    />

                    {mediaItem.status === 'failed' && (
                      <div className={styles.failedOverlay}>
                        <span className={styles.failedIconWrapper}>
                          <CloseOutlined className={styles.failedIcon} />
                        </span>
                      </div>
                    )}

                    <>
                      {!isDraggingMediaItem && (
                        <>
                          <div className={styles.mediaItemControls}>
                            <Dropdown
                              placement="bottomLeft"
                              className={styles.mediaItemDropdown}
                              trigger={['click']}
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key="1"
                                    className={styles.mediaItemDropdownLink}
                                  >
                                    <Button
                                      className={styles.removeBtn}
                                      onClick={
                                        onDeleteAddedMediaItemInCategory
                                          ? () => {
                                              onDeleteAddedMediaItemInCategory(
                                                (mediaItem.snippet &&
                                                  mediaItem.snippet.resourceId
                                                    .videoId) ||
                                                  ''
                                              )
                                            }
                                          : undefined
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <HoverMediaItemControlsIcon />
                            </Dropdown>
                          </div>
                          <div className={styles.mediaItemTitleWrapper}>
                            <h4 className={styles.title}>
                              {mediaItem.snippet
                                ? mediaItem.snippet.title
                                : mediaItem.name}
                            </h4>
                          </div>
                        </>
                      )}
                    </>
                  </li>
                ))}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default YoutubeCategory
