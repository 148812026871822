import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { message, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import { config } from 'config'

import AddVideosTopBar from 'components/AddVideos/AddVideosTopBar'
import YoutubeSideBar from 'components/AddVideos/YoutubeSideBar'
import { useActions } from 'store/hooks/useActions'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ICategory, IResponseCategories } from 'models/category'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { IMediaItem } from 'models/mediaItem'
import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { CategoryAPI } from 'api/VideoCollection/Category'
import { VideoSyncAPI } from 'api/VideoSync/VideoSync'
import YoutubeCategory from 'components/AddVideos/YoutubeCategory'

import { getPartnerId } from 'utils/helper'

import ConnectAppForm from 'components/ConnectAppForm'
import YoutubeAppVideos from 'components/YoutubeAppVideos'
import ModalEditVideoDescription from 'components/ModalEditVideoDescription'

import { ReactComponent as YoutubeIcon } from 'sources/images/content/youtube-form.svg'

import { MediaPlaylistAPI } from 'api/VideoCollection/MediaPlaylist'

import playlistSrc from 'sources/images/appVideos/playlist.png'

import styles from './styles.module.scss'

const prepareYoutubeVideoLink = (videoId: string): string =>
  `https://www.youtube.com/watch?v=${videoId}`

const prepareYoutubePlaylistLink = (playlistId: string): string =>
  `https://www.youtube.com/playlist?list=${playlistId}`

interface IProps {
  youtubeMediaSources: any
  setYoutubeMediaSources: any
  setsDoneUploadYoutubeMediaItems: React.Dispatch<React.SetStateAction<boolean>>
}

const stepForScrollMediaItems = 50
let inYoutubeCategory = false

const Youtube: FC<IProps> = ({
  youtubeMediaSources,
  setYoutubeMediaSources,
  setsDoneUploadYoutubeMediaItems,
}) => {
  const { id, distributionId } = useParams()
  const navigate = useNavigate()

  const [category, setCategory] = useState<ICategory | null>(null)
  const [allMediaItemsInCategory, setAllMediaItemsInCategory] = useState<any>(
    []
  )

  const [selectedUploadMediaItemsId, setSelectedUploadMediaItemsId] = useState<
    string[]
  >([])

  const [addedMediaItems, setAddedMediaItems] = useState<any>([])
  const [isOpenMediaItemsControls, setIsOpenMediaItemsControls] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingYoutubeData, setIsLoadingYoutubeData] = useState(false)

  const [currentYoutubePlaylists, setCurrentYoutubePlaylists] = useState([])
  const [uploadYoutubeMediaItems, setUploadYoutubeMediaItems] = useState<any>(
    []
  )

  const [filteredMediaItems, setFilteredMediaItems] = useState<any>([])
  const [isFiltered, setIsFiltered] = useState(false)

  const [isVisibleConnectForm, setIsVisibleConnectForm] = useState(true)

  const [currentYoutubeMediaSourceId, setCurrentYoutubeMediaSourceId] =
    useState<null | number>(null)

  const [initCategoryMediaItems, setInitCategoryMediaItems] = useState<
    null | []
  >(null)

  const { storeCurrentDistributionData, storeCurrentDistributionId } =
    useTypedSelector(state => state.distribution)
  const [showcasesData, setShowcasesData] = useState<any>([])
  const [totalPlaylistsMediaItems, setTotalPlaylistsMediaItems] = useState(0)
  const [
    totalImportedPlaylistsMediaItems,
    setTotalImportedPlaylistsMediaItems,
  ] = useState(0)
  const [editCategoryDescription, setEditCategoryDescription] = useState<any>()

  const [isChangeOrder, setIsChangeOrder] = useState(false)
  const [isChangeDescription, setIsChangeDescription] = useState(false)
  const [isChangeSync, setIsChangeSync] = useState(false)

  const [mediaPlaylists, setMediaPlaylists] = useState<any>([])
  const [mediaPlaylistData, setMediaPlaylistData] = useState<any>([])

  const [
    uniqYoutubeChannelVideosWithoutCategory,
    setUniqYoutubeChannelVideosWithoutCategory,
  ] = useState<any>([])

  // edit description
  const [isOpenEditDescription, setIsOpenEditDescription] = useState(false)
  const [editDescriptionMediaItems, setEditDescriptionMediaItems] =
    useState<any>([])
  const [descriptionData, setDescriptionData] = useState<any>([])
  const [currentPlaylistDescription, setCurrentPlaylistDescription] =
    useState('')

  // one playlist
  const [isOnePlaylist, setIsOnePlaylist] = useState(false)
  const [onePlayListData, setOnePlaylistData] = useState<any>([])

  const { userInfo } = useTypedSelector(state => state.user)
  const [
    countAllMediaItemsInDistribution,
    setCountAllMediaItemsInDistribution,
  ] = useState(0)

  // scroll
  const [renderMediaItems, setRenderMediaItems] = useState<any>([])
  const [fetching, setFetching] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  // drag and drop uploaded videos
  const draggingUploadMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingMediaItemRef = useRef<HTMLElement | null>(null)

  const { saveCurrentDistributionDataAction } = useActions()
  const path = useLocation().pathname

  const scrollHandler = (e: any): void => {
    const target = e.target as HTMLElement
    const isVideosTab = target.dataset.tab === 'videos'

    if (
      isVideosTab &&
      target.scrollHeight - (target.scrollTop + target.offsetHeight) < 100 &&
      renderMediaItems.length < filteredMediaItems.length
    ) {
      setFetching(true)
    }
  }

  useEffect(() => {
    if (fetching) {
      try {
        const startIndex = stepForScrollMediaItems * currentPage
        const endIndex = startIndex + stepForScrollMediaItems

        const sliceMediaItems = filteredMediaItems.slice(startIndex, endIndex)
        const sliceForRenderMediaItems = [
          ...renderMediaItems,
          ...sliceMediaItems,
        ]

        setRenderMediaItems(sliceForRenderMediaItems)
        setCurrentPage(prevPage => prevPage + 1)
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    }
  }, [fetching])

  useEffect(() => {
    if (isFiltered || onePlayListData) {
      const sliceMediaItems = filteredMediaItems.slice(
        0,
        stepForScrollMediaItems
      )

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    } else {
      const sliceMediaItems = uploadYoutubeMediaItems.slice(
        0,
        stepForScrollMediaItems
      )

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    }
  }, [filteredMediaItems])

  const countTotalPlaylistsMediaItems = (playlists: any): void => {
    const total: number = playlists.reduce(
      (sum: number, playlist: any) => sum + playlist.totalVideos,
      0
    )

    setTotalPlaylistsMediaItems(total)
  }

  useEffect(() => {
    if (isOnePlaylist) {
      setFilteredMediaItems(onePlayListData.videoList)
      const sliceMediaItems = onePlayListData.videoList.slice(
        0,
        stepForScrollMediaItems
      )
      setRenderMediaItems(sliceMediaItems)
    } else {
      setFilteredMediaItems(uploadYoutubeMediaItems)
      setRenderMediaItems(uploadYoutubeMediaItems)
    }
  }, [isOnePlaylist])

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setsDoneUploadYoutubeMediaItems(false)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)
      let currentDistributionData: any = null

      if (storeCurrentDistributionData) {
        const storeCategory = storeCurrentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }
      } else if (partnerId && storeCurrentDistributionId) {
        const responseCurrentDistributionData: any =
          await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            storeCurrentDistributionId,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

        currentDistributionData =
          responseCurrentDistributionData.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )

        const storeCategory = currentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }

        saveCurrentDistributionDataAction(currentDistributionData)
      }

      if (partnerId) {
        const response: IResponseCategories = await CategoryAPI.getCategory(
          partnerId,
          Number(distributionId),
          Number(id),
          { withMediaItems: 'true', withMediaItemImages: 'true' }
        )
        setCategory(response.categories[0])
        const mediaItems = response.categories[0].MediaItems

        if (partnerId && storeCurrentDistributionId && response.categories[0]) {
          const allDistributionsMediaItemsResponse: any =
            await MediaItemAPI.getAllMediaItemsForDistribution(
              partnerId,
              storeCurrentDistributionId
            )

          if (allDistributionsMediaItemsResponse.status === 'success') {
            setCountAllMediaItemsInDistribution(
              allDistributionsMediaItemsResponse.mediaitems.length
            )
          }

          const mediaPlaylistResponse: any =
            await MediaPlaylistAPI.getAllMediaPlaylistsForCategory(
              partnerId,
              storeCurrentDistributionId,
              response.categories[0].id
            )

          if (mediaPlaylistResponse.status === 'success') {
            setMediaPlaylists(
              mediaPlaylistResponse.mediaPlaylists.filter(
                (mediaPlaylistItem: any) =>
                  mediaPlaylistItem.sourceType === 'youtube'
              )
            )
          }
        }

        if (mediaItems) {
          setAllMediaItemsInCategory(
            mediaItems
              .map((item: any) => {
                const cloneItem = _.cloneDeep(item)
                const smallImgObj =
                  cloneItem.Images.length > 0 &&
                  cloneItem.Images.find(
                    (itemImg: any) => itemImg.tag === 'Small'
                  )

                if (smallImgObj) {
                  smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                }

                cloneItem.Images = [
                  cloneItem.Images.filter(
                    (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                  ),
                  smallImgObj,
                ]

                return cloneItem
              })
              .sort(
                (a: IMediaItem, b: IMediaItem) =>
                  a.CategoryMediaItem.orderNumber -
                  b.CategoryMediaItem.orderNumber
              )
          )
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onAddNewAccount = (): void => {
    setIsVisibleConnectForm(true)
  }

  const onGetYoutubeChannel = async (channelId: string): Promise<void> => {
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (partnerId) {
      // test youtube channelId -> UCWv7vMbMWH4-V0ZXdmDpPBA
      const response: any = await VideoSyncAPI.youtubeAuthByChannelId(
        channelId,
        String(partnerId)
      )

      if (response.status === 'success') {
        setYoutubeMediaSources((prev: any) => [response.mediaSource, ...prev])
        message.success('Youtube channel added!', 3)
      }
    }
  }

  const onGetYoutubeDataAccount = async (channelId: string): Promise<void> => {
    try {
      setIsLoadingYoutubeData(true)
      setTotalPlaylistsMediaItems(0)
      setTotalImportedPlaylistsMediaItems(0)
      setIsOnePlaylist(false)

      if (channelId) {
        const response: any = await VideoSyncAPI.getYoutubePlaylists(channelId)

        const mediaSourceId = youtubeMediaSources.find(
          (mediaSources: any) => mediaSources.accountId === channelId
        )
        setCurrentYoutubeMediaSourceId(mediaSourceId.id)

        if (response.status === 'success') {
          setCurrentYoutubePlaylists(response.playlists)

          const playlistsDataArr: any = []

          if (response.playlists.length > 0) {
            const responseArr = response.playlists.map((playlist: any) => {
              const categoryDescription =
                category?.description === null ? '' : category?.description
              const playlistDescription =
                playlist.snippet.description === null
                  ? ''
                  : playlist.snippet.description
              const showcaseMediaPlaylist = mediaPlaylists.find(
                (item: any) => item.playlistId === playlist.id
              )

              playlistsDataArr.push({
                showcaseLink: prepareYoutubePlaylistLink(playlist.id),
                isAsyncMediaPlaylist: showcaseMediaPlaylist
                  ? showcaseMediaPlaylist.autoSync
                  : false,
                categoryDescription,
                playlistYoutubeId: playlist.id,
                showcasePicture: playlist.snippet.thumbnails.high
                  ? playlist.snippet.thumbnails.high.url
                  : playlistSrc,
                playlistName: playlist.snippet.title,
                playlistDescription,
                checkDescription: false,
              })

              return VideoSyncAPI.getYoutubeVideosByPlaylistId(playlist.id)
            })

            // upload videos
            const responsesVideos: any = await Promise.allSettled(responseArr)
            const allVideos = responsesVideos.map(
              (responseVideo: any) => responseVideo.value.videoList
            )

            if (config.IS_GET_ALL_YOUTUBE_VIDEOS) {
              const responseAllYoutubeChannelVideos: any =
                await VideoSyncAPI.getYoutubeVideosByChannelId(channelId)

              // responseAllYoutubeChannelVideos.videos.filter(
              //   (channelVideo: any) =>
              //     !allVideos
              //       .flat()
              //       .some(
              //         (item: any) =>
              //           item.snippet.resourceId.videoId ===
              //           channelVideo.id.videoId
              //       )
              // )

              const uniqYoutubeChannelVideosWithoutCategoryArray =
                responseAllYoutubeChannelVideos.videos.map((item: any) => {
                  const cloneItem = _.cloneDeep(item)
                  const resourceId = item.id

                  cloneItem.snippet.resourceId = resourceId
                  return cloneItem
                })

              const allMediaItemsFromStore = storeCurrentDistributionData
                .map((item: any) => item.MediaItems)
                .flat()

              setUniqYoutubeChannelVideosWithoutCategory(
                uniqYoutubeChannelVideosWithoutCategoryArray.filter(
                  (item: any) =>
                    !allMediaItemsFromStore.find(
                      (storeItem: any) =>
                        storeItem.externalId === item.snippet.resourceId.videoId
                    )
                )
              )

              const uniqAllVideos = _.uniqBy(
                [
                  ...uniqYoutubeChannelVideosWithoutCategoryArray,
                  ...allVideos.flat(),
                ],
                'snippet.resourceId.videoId'
              )

              const preparePlaylistData: any = playlistsDataArr.map(
                (item: any, idx: number) => ({
                  ...item,
                  totalVideos: allVideos[idx].length,
                  videoList: allVideos[idx],
                })
              )

              countTotalPlaylistsMediaItems(preparePlaylistData)
              setShowcasesData(preparePlaylistData)

              // This is without all account youtube videos uniqAllVideos === allUploadYoutubeMediaItems
              // const allUploadYoutubeMediaItems = _.uniqBy(
              //   allVideos.flat(),
              //   'snippet.resourceId.videoId'
              // )

              const totalImportedMediaItems = _.uniqBy(
                allMediaItemsFromStore.filter((item: any) =>
                  uniqAllVideos.some((uploadItem: any) => {
                    const youtubeId = uploadItem.snippet.resourceId.videoId

                    return youtubeId === item.externalId
                  })
                ),
                'id'
              ).length

              setTotalPlaylistsMediaItems(uniqAllVideos.length)
              setTotalImportedPlaylistsMediaItems(totalImportedMediaItems)

              setUploadYoutubeMediaItems(uniqAllVideos)
              setFilteredMediaItems(uniqAllVideos)

              const sliceMediaItems = uniqAllVideos.slice(
                0,
                stepForScrollMediaItems
              )
              setRenderMediaItems(sliceMediaItems)
            } else {
              //   const responseAllYoutubeChannelVideos: any =
              //   await VideoSyncAPI.getYoutubeVideosByChannelId(channelId)

              // const uniqYoutubeChannelVideosWithoutCategoryArray =
              //   responseAllYoutubeChannelVideos.videos
              //     .filter(
              //       (channelVideo: any) =>
              //         !allVideos
              //           .flat()
              //           .some(
              //             (item: any) =>
              //               item.snippet.resourceId.videoId ===
              //               channelVideo.id.videoId
              //           )
              //     )
              //     .map((item: any) => {
              //       const cloneItem = _.cloneDeep(item)
              //       const resourceId = item.id

              //       cloneItem.snippet.resourceId = resourceId
              //       return cloneItem
              //     })

              const allMediaItemsFromStore = storeCurrentDistributionData
                .map((item: any) => item.MediaItems)
                .flat()

              // setUniqYoutubeChannelVideosWithoutCategory(
              //   uniqYoutubeChannelVideosWithoutCategoryArray.filter(
              //     (item: any) =>
              //       !allMediaItemsFromStore.find(
              //         (storeItem: any) =>
              //           storeItem.externalId === item.snippet.resourceId.videoId
              //       )
              //   )
              // )

              // const uniqAllVideos = _.uniqBy(
              //   [
              //     ...uniqYoutubeChannelVideosWithoutCategoryArray,
              //     ...allVideos.flat(),
              //   ],
              //   'snippet.resourceId.videoId'
              // )

              const preparePlaylistData: any = playlistsDataArr.map(
                (item: any, idx: number) => ({
                  ...item,
                  totalVideos: allVideos[idx].length,
                  videoList: allVideos[idx],
                })
              )

              countTotalPlaylistsMediaItems(preparePlaylistData)
              setShowcasesData(preparePlaylistData)

              // This is without all account youtube videos uniqAllVideos === allUploadYoutubeMediaItems
              const allUploadYoutubeMediaItems = _.uniqBy(
                allVideos.flat(),
                'snippet.resourceId.videoId'
              )

              const totalImportedMediaItems = _.uniqBy(
                allMediaItemsFromStore.filter((item: any) =>
                  allUploadYoutubeMediaItems.some((uploadItem: any) => {
                    const youtubeId = uploadItem.snippet.resourceId.videoId

                    return youtubeId === item.externalId
                  })
                ),
                'id'
              ).length

              setTotalPlaylistsMediaItems(allUploadYoutubeMediaItems.length)
              setTotalImportedPlaylistsMediaItems(totalImportedMediaItems)

              setUploadYoutubeMediaItems(allUploadYoutubeMediaItems)
              setFilteredMediaItems(allUploadYoutubeMediaItems)

              const sliceMediaItems = allUploadYoutubeMediaItems.slice(
                0,
                stepForScrollMediaItems
              )
              setRenderMediaItems(sliceMediaItems)
            }
          } else {
            setUploadYoutubeMediaItems([])
            setFilteredMediaItems([])
            setRenderMediaItems([])
          }
        }
      }

      setIsVisibleConnectForm(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingYoutubeData(false)
    }
  }

  const onCloseControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(false)
  }

  const onOpenControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(true)
  }

  const changeSelectedMediaItemsId = (
    newId: string,
    isRemoved: boolean
  ): void => {
    if (isRemoved) {
      const filteredIds = selectedUploadMediaItemsId.filter(
        (itemId: string) => itemId !== newId
      )
      setSelectedUploadMediaItemsId(filteredIds)
    } else {
      setSelectedUploadMediaItemsId([...selectedUploadMediaItemsId, newId])
    }
  }

  const onSelectUploadMediaItem = (e: CheckboxChangeEvent): void => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItem = target.closest('[data-upload-item]')

    onOpenControlsSelectedMediaItems()

    if (target.checked && uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, false)
    } else if (uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, true)
    }
  }

  const onSelectAllUploadMediaItem = (
    e: CheckboxChangeEvent
  ): void | string => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItemsElements =
      document.querySelectorAll('[data-upload-item]')

    onOpenControlsSelectedMediaItems()

    if (!target.checked) {
      onCloseControlsSelectedMediaItems()
      setSelectedUploadMediaItemsId([])
    } else {
      const ids = Array.from(uploadMediaItemsElements).map((item: Element) => {
        if (
          allMediaItemsInCategory.some(
            (mediaItem: any) =>
              (mediaItem.snippet &&
                item.id === mediaItem.snippet.resourceId.videoId) ||
              initCategoryMediaItems?.some(
                (initItem: any) => initItem.externalId === item.id
              )
          )
        ) {
          return ''
        }

        if (target.checked && item) {
          changeSelectedMediaItemsId(item.id, false)
        } else if (item) {
          changeSelectedMediaItemsId(item.id, true)
        }

        return item.id
      })

      setSelectedUploadMediaItemsId([...ids])
    }
  }

  const onDeleteAddedMediaItemInCategory = (removedId: string): void => {
    if (
      initCategoryMediaItems?.find(
        (initItem: any) => String(initItem.id) === removedId
      )
    ) {
      return
    }

    setDescriptionData(
      descriptionData.filter((item: any) => item.id !== removedId)
    )

    setAllMediaItemsInCategory(
      allMediaItemsInCategory.filter(
        (mediaItem: any) =>
          mediaItem.snippet === undefined ||
          mediaItem.snippet.resourceId.videoId !== removedId
      )
    )
  }

  const onAddSelectedMediaItemsInCategory = (): void => {
    // setIsOpenEditDescription(true)

    const addMediaItems: any = selectedUploadMediaItemsId.map(selectedId =>
      uploadYoutubeMediaItems.filter(
        (mediaItem: any) => mediaItem.snippet.resourceId.videoId === selectedId
      )
    )

    setEditDescriptionMediaItems(addMediaItems.flat())

    setSelectedUploadMediaItemsId([])
    setAllMediaItemsInCategory([
      ...addMediaItems.flat(),
      ...allMediaItemsInCategory,
    ])
  }

  const handleDragStartUploadMediaItems = (e: any): void => {
    // console.log('DRAG START  UPLOAD')
    const target = e.target as HTMLElement
    const uploadItem = target.closest('[data-upload-item]') as HTMLElement

    if (
      uploadItem &&
      !allMediaItemsInCategory.find((item: any) =>
        item.snippet
          ? uploadItem.id === item.snippet.resourceId.videoId
          : uploadItem.id === String(item.id)
      )
    ) {
      draggingUploadMediaItemRef.current = uploadItem
    }
  }

  const handleDragEnterUploadMediaItems = (e: any): void => {
    // console.log('DRAG ENTER UPLOAD')
    const { target } = e
  }

  const handleDragDropUploadMediaItems = (e: any): void => {
    // console.log('DRAG DROP UPLOAD')
    e.preventDefault()
  }

  const handleDragEndUploadMediaItems = (e: any): void => {
    // console.log('DRAG END UPLOAD')
    e.preventDefault()

    if (draggingUploadMediaItemRef.current && inYoutubeCategory) {
      const findMediaItem = uploadYoutubeMediaItems.find(
        (item: any) =>
          item.snippet &&
          draggingUploadMediaItemRef?.current?.id ===
            item.snippet.resourceId.videoId
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadYoutubeMediaItems.filter(
        (item: any) =>
          selectedUploadMediaItemsCurrentId.some(
            (currentId: string) =>
              item.snippet && currentId === item.snippet.resourceId.videoId
          )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        if (findMediaItem) {
          const updateAllMediaItemsInCategory = _.cloneDeep(
            allMediaItemsInCategory
          )

          updateAllMediaItemsInCategory.splice(
            findIndex,
            0,
            findMediaItem,
            ...filteredUploadMediaItems
          )

          setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
        }
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inYoutubeCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const handleDragEnterCategory = (e: any): void => {
    if (draggingUploadMediaItemRef.current) {
      const target = e.target as HTMLElement

      inYoutubeCategory = true

      const li = document.createElement('li')
      li.classList.add(styles.tempMediaItem)
      li.dataset.temp = 'temp'
      li.setAttribute('draggable', 'true')
      li.setAttribute('data-media-item-order', '')
      li.id = draggingUploadMediaItemRef.current.id

      const parentList = document.querySelector('[data-drag-list]')
      parentList?.prepend(li)

      if (inYoutubeCategory) {
        const underLi = target.closest('[data-media-item-order]') as any

        if (parentList && underLi && li) {
          if (!underLi.dataset.temp) {
            if (underLi.nextSibling && underLi.nextSibling.dataset.temp) {
              parentList.insertBefore(underLi.nextSibling, underLi)
            } else {
              parentList.insertBefore(li, underLi.nextSibling)
            }
          }
        }
      }
    }
  }

  const handleDragLeaveCategory = (e: any): void => {
    // console.log('DRAG LEAVE  CATEGORY')

    const tempItem = document.querySelector('[data-temp]')
    tempItem?.remove()
  }

  const handleDragOverCategory = (e: any): void => {
    // console.log('DRAG OVER  CATEGORY')
    e.preventDefault()
  }

  const handleDropCategory = (e: any): void => {
    // console.log('DRAG DROP  CATEGORY')

    if (draggingUploadMediaItemRef.current && inYoutubeCategory) {
      const findMediaItem = uploadYoutubeMediaItems.find(
        (item: any) =>
          item.snippet &&
          draggingUploadMediaItemRef?.current?.id ===
            item.snippet.resourceId.videoId
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadYoutubeMediaItems.filter(
        (item: any) =>
          selectedUploadMediaItemsCurrentId.some(
            (currentId: string) =>
              item.snippet && currentId === item.snippet.resourceId.videoId
          )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        const updateAllMediaItemsInCategory = _.cloneDeep(
          allMediaItemsInCategory
        )

        updateAllMediaItemsInCategory.splice(
          findIndex,
          0,
          findMediaItem,
          ...filteredUploadMediaItems
        )

        setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inYoutubeCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const onSaveAddedMediaItemsInCategory = async (): Promise<void> => {
    try {
      // if (isUserMediaItemsLimit()) {
      //   return
      // }

      // console.log('descriptionData', descriptionData)

      navigate('/content')

      const currentDistributionId = category?.DistributionId
      const categoryId = category?.id
      const partnerId = storeCurrentPartnerId || getPartnerId()
      const saveAddedMediaItems = allMediaItemsInCategory.filter(
        (mediaItem: any) => mediaItem.snippet
      )

      if (currentDistributionId && partnerId && categoryId) {
        const createMediaItemPromiseArr = saveAddedMediaItems.map(
          (mediaItem: any) => {
            const isEditDescription = descriptionData.find(
              (item: any) => item.id === mediaItem.snippet.resourceId.videoId
            )

            const mediaItemDescription =
              mediaItem.snippet.description === null
                ? ''
                : mediaItem.snippet.description

            const descriptionValue = isEditDescription
              ? isEditDescription.description
              : mediaItemDescription

            return VideoSyncAPI.addVideoByURL(
              partnerId,
              currentDistributionId,
              'youtube',
              {
                url: prepareYoutubeVideoLink(
                  mediaItem.snippet.resourceId.videoId
                ),
                mediaSourceId: currentYoutubeMediaSourceId,
                categoryIds: categoryId,
                description: descriptionValue,
              }
            )
          }
        )
        const responses: any = await Promise.allSettled(
          createMediaItemPromiseArr
        )

        // mediaPlaylist sync
        const prepareSyncMediaPlaylist = mediaPlaylistData
          .filter(
            (item: any) =>
              item.isAsyncMediaPlaylist &&
              !mediaPlaylists.find(
                (mediaPlaylistItem: any) =>
                  mediaPlaylistItem.playlistId === item.playlistYoutubeId
              )
          )
          .map((filterItem: any) => {
            const bodyPlaylist: any = {
              name: filterItem.playlistName,
              description: filterItem.playlistDescription,
              sourceType: 'youtube',
              url: filterItem.showcaseLink,
              autoSync: true,
              playlistId: filterItem.playlistYoutubeId,
              mediaSourceId: currentYoutubeMediaSourceId,
            }

            return bodyPlaylist
          })

        const promiseSyncMediaItemsArr = prepareSyncMediaPlaylist.map(
          (body: any) =>
            MediaPlaylistAPI.syncPlaylistToCategory(
              partnerId,
              currentDistributionId,
              category.id,
              body
            )
        )

        const responsesSyncMediaPlaylist: any = await Promise.allSettled(
          promiseSyncMediaItemsArr
        )

        // mediaPlaylist unsync
        const prepareUnSyncMediaPlaylist = mediaPlaylistData.filter(
          (item: any) =>
            !item.isAsyncMediaPlaylist &&
            mediaPlaylists.find(
              (mediaPlaylistItem: any) =>
                mediaPlaylistItem.playlistId === item.playlistYoutubeId
            )
        )

        const unSyncMediaPlaylistIds = mediaPlaylists
          .filter((item: any) =>
            prepareUnSyncMediaPlaylist.find(
              (unSyncItem: any) =>
                item.playlistId === unSyncItem.playlistYoutubeId
            )
          )
          .map((element: any) => element.id)

        const promiseUnSyncMediaItemsArr = unSyncMediaPlaylistIds.map(
          (mediaPlaylistId: string) =>
            MediaPlaylistAPI.unSyncPlaylistToCategory(
              partnerId,
              currentDistributionId,
              category.id,
              mediaPlaylistId
            )
        )

        const responsesMediaPlaylist: any = await Promise.allSettled(
          promiseUnSyncMediaItemsArr
        )

        // category description
        // if (isChangeDescription) {
        //   const cloneCategory = category
        //   cloneCategory.description = editCategoryDescription
        //   CategoryAPI.updateCategory(
        //     partnerId,
        //     currentDistributionId,
        //     cloneCategory.id,
        //     cloneCategory
        //   )
        // }

        if (responses.some((result: any) => result.status === 'rejected')) {
          message.warning('Some videos not added!', 3)
        }

        const createdMediaItems: any = responses
          .filter((result: any) => result.status === 'fulfilled')
          .map((response: any) => response.value.mediaitem)

        const allMediaItems = allMediaItemsInCategory.map((item: any) => {
          if (item.kind === undefined) {
            return item
          }
          const replaceItem = createdMediaItems.find(
            (mediaItem: IMediaItem) =>
              item.snippet.resourceId.videoId === mediaItem.externalId
          )
          return replaceItem
        })

        for (let i = 0; i < allMediaItems.length; i += 1) {
          const mediaItem = allMediaItems[i]
          const response = await MediaItemAPI.setOrderNumberForMediaItem(
            partnerId,
            currentDistributionId,
            mediaItem.id,
            categoryId,
            i + 1
          )
        }

        setsDoneUploadYoutubeMediaItems(true)
        message.success(
          `Videos were added in ${category.name} category from youtube!`,
          3
        )
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data.message)
        }
      }
    }
  }

  const onSearchUploadMediaItems = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setIsFiltered(true)

    if (isOnePlaylist) {
      if (value === '') {
        setIsFiltered(false)
        setFilteredMediaItems(onePlayListData.videoList)
      }

      setFilteredMediaItems(
        onePlayListData.videoList.filter(
          (item: any) =>
            (item.snippet &&
              item.snippet.title.toLowerCase().includes(value.toLowerCase())) ||
            (item.snippet &&
              item.snippet.description
                .toLowerCase()
                .includes(value.toLowerCase()))
        )
      )
    } else {
      if (value === '') {
        setIsFiltered(false)
        setFilteredMediaItems(uploadYoutubeMediaItems)
      }

      setFilteredMediaItems(
        uploadYoutubeMediaItems.filter(
          (item: any) =>
            (item.snippet &&
              item.snippet.title.toLowerCase().includes(value.toLowerCase())) ||
            (item.snippet &&
              item.snippet.description
                .toLowerCase()
                .includes(value.toLowerCase()))
        )
      )
    }
  }

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div
      className={cn(styles.mainWrapper)}
      role="presentation"
      onDragEnter={e => {
        const target = e.target as HTMLElement

        if (!target.closest('[data-category]')) {
          inYoutubeCategory = false
        }
      }}
    >
      <AddVideosTopBar
        isConnected
        allMediaItemsInCategory={allMediaItemsInCategory}
        isChangeOrder={isChangeOrder}
        isChangeDescription={isChangeDescription}
        isChangeSync={isChangeSync}
        onSaveAddedMediaItemsInCategory={onSaveAddedMediaItemsInCategory}
        totalPlaylistsMediaItems={totalPlaylistsMediaItems}
        totalImportedPlaylistsMediaItems={totalImportedPlaylistsMediaItems}
      />

      <div
        className={cn(styles.mainContent, {
          [styles.mainContentWithForm]: isVisibleConnectForm,
        })}
      >
        <YoutubeSideBar
          youtubeSubmenu={youtubeMediaSources}
          onGetYoutubeDataAccount={onGetYoutubeDataAccount}
          onAddNewAccount={onAddNewAccount}
        />

        {isLoadingYoutubeData ? (
          <Spin size="large" className={styles.spinnerYoutubeData} />
        ) : youtubeMediaSources.length === 0 || isVisibleConnectForm ? (
          <div className={styles.section}>
            <ConnectAppForm
              icon={<YoutubeIcon />}
              appName="youtube"
              onGetYoutubeChannel={onGetYoutubeChannel}
            />
          </div>
        ) : (
          <YoutubeAppVideos
            onSearchUploadMediaItems={onSearchUploadMediaItems}
            isFiltered={isFiltered}
            filteredMediaItems={filteredMediaItems}
            youtubePlaylists={currentYoutubePlaylists}
            uploadYoutubeMediaItems={uploadYoutubeMediaItems}
            allMediaItemsInCategory={allMediaItemsInCategory}
            addedMediaItems={addedMediaItems}
            onSelectUploadMediaItem={onSelectUploadMediaItem}
            onSelectAllUploadMediaItem={onSelectAllUploadMediaItem}
            selectedUploadMediaItemsId={selectedUploadMediaItemsId}
            showcasesData={showcasesData}
            setAllMediaItemsInCategory={setAllMediaItemsInCategory}
            setEditCategoryDescription={setEditCategoryDescription}
            initCategoryMediaItems={initCategoryMediaItems}
            setIsChangeDescription={setIsChangeDescription}
            uniqYoutubeChannelVideosWithoutCategory={
              uniqYoutubeChannelVideosWithoutCategory
            }
            mediaPlaylists={mediaPlaylists}
            setIsChangeSync={setIsChangeSync}
            setMediaPlaylistData={setMediaPlaylistData}
            renderMediaItems={renderMediaItems}
            scrollHandler={scrollHandler}
            fetching={fetching}
            setIsOpenEditDescription={setIsOpenEditDescription}
            setEditDescriptionMediaItems={setEditDescriptionMediaItems}
            setCurrentPlaylistDescription={setCurrentPlaylistDescription}
            setOnePlaylistData={setOnePlaylistData}
            onePlayListData={onePlayListData}
            setIsOnePlaylist={setIsOnePlaylist}
            draggingUploadMediaItemRef={draggingUploadMediaItemRef}
            handleDragStartUploadMediaItems={handleDragStartUploadMediaItems}
            handleDragEnterUploadMediaItems={handleDragEnterUploadMediaItems}
            handleDragEndUploadMediaItems={handleDragEndUploadMediaItems}
            handleDragDropUploadMediaItems={handleDragDropUploadMediaItems}
          />
        )}
      </div>

      {category && (
        <YoutubeCategory
          draggingUploadMediaItemRef={draggingUploadMediaItemRef}
          handleDragEnterCategory={handleDragEnterCategory}
          handleDragLeaveCategory={handleDragLeaveCategory}
          handleDragOverCategory={handleDragOverCategory}
          handleDropCategory={handleDropCategory}
          allMediaItemsInCategory={allMediaItemsInCategory}
          setAllMediaItemsInCategory={setAllMediaItemsInCategory}
          category={category}
          onDeleteAddedMediaItemInCategory={onDeleteAddedMediaItemInCategory}
          onAddSelectedMediaItemsInCategory={onAddSelectedMediaItemsInCategory}
          selectedUploadMediaItemsId={selectedUploadMediaItemsId}
          onCloseControlsSelectedMediaItems={onCloseControlsSelectedMediaItems}
          isOpenMediaItemsControls={isOpenMediaItemsControls}
          setIsChangeOrder={setIsChangeOrder}
          inCategory={inYoutubeCategory}
          uploadYoutubeMediaItems={uploadYoutubeMediaItems}
          setSelectedUploadMediaItemsId={setSelectedUploadMediaItemsId}
          draggingMediaItemRef={draggingMediaItemRef}
        />
      )}

      <ModalEditVideoDescription
        isOpenEditDescription={isOpenEditDescription}
        editDescriptionMediaItems={editDescriptionMediaItems}
        setIsOpenEditDescription={setIsOpenEditDescription}
        setEditDescriptionMediaItems={setEditDescriptionMediaItems}
        descriptionData={descriptionData}
        setDescriptionData={setDescriptionData}
        source="youtube"
        playlistDescription={currentPlaylistDescription}
        setCurrentPlaylistDescription={setCurrentPlaylistDescription}
      />
    </div>
  )
}

export default Youtube
