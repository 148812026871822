import {
  DistributionActionsTypes,
  IDistributionAction,
  IDistributionState,
} from 'store/types/distribution'

const initialState: IDistributionState = {
  storeCurrentDistributionId: null,
  storeCurrentDistributionData: null,
}

export const distributionReducer = (
  state = initialState,
  action: IDistributionAction
): IDistributionState => {
  switch (action.type) {
    case DistributionActionsTypes.SAVE_DISTRIBUTION:
      return { ...state, storeCurrentDistributionId: action.payload }
    case DistributionActionsTypes.SAVE_DISTRIBUTION_DATA:
      return { ...state, storeCurrentDistributionData: action.payload }
    case DistributionActionsTypes.CLEAR_DISTRIBUTION:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
