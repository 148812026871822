import React, { FC } from 'react'
import { Button, Checkbox } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import _ from 'lodash'
import cn from 'classnames'

import { getVimeoItemId } from 'utils/helper'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ReactComponent as LeftArrowIcon } from 'sources/images/videoProperties/arrow-left.svg'
import circleStatusSrc from 'sources/images/content/circle-status.png'
import doneSrc from 'sources/images/content/done.png'

import styles from './styles.module.scss'

interface IProps {
  onePlayListData: any
  onCloseOnePlaylistVideos: any
  allMediaItemsInCategory: any
  addedMediaItems: []
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  selectedUploadMediaItemsId: string[]
  initCategoryMediaItems: null | []
  allUnImportedMediaItems: any
  renderMediaItems: any

  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
}

const OnePlaylistMediaItems: FC<IProps> = ({
  onePlayListData,
  onCloseOnePlaylistVideos,
  allMediaItemsInCategory,
  addedMediaItems,
  onSelectUploadMediaItem,
  selectedUploadMediaItemsId,
  initCategoryMediaItems,
  allUnImportedMediaItems,
  renderMediaItems,

  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
}) => {
  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const allMediaItemsFromStore = storeCurrentDistributionData
    .map((item: any) => item.MediaItems)
    .flat()

  return (
    <>
      <div className={styles.onePlaylistTopWrapper}>
        <Button
          icon={<LeftArrowIcon />}
          className={styles.backArrowLeft}
          onClick={onCloseOnePlaylistVideos}
        />
        <h3 className={styles.playlistTitle}>{onePlayListData.showcaseName}</h3>
      </div>

      <ul className={styles.videosWrapper}>
        {renderMediaItems.map((mediaItem: any) => {
          const uid = getVimeoItemId(mediaItem.uri)

          return (
            <li
              key={uuidv4()}
              data-upload-item={uid}
              id={uid}
              draggable
              onDragStart={handleDragStartUploadMediaItems}
              onDragEnter={handleDragEnterUploadMediaItems}
              onDragEnd={handleDragEndUploadMediaItems}
              onDrop={handleDragDropUploadMediaItems}
              className={cn(styles.uploadMediaItem, {
                [styles.hasInCategory]:
                  _.find(allMediaItemsInCategory, {
                    uri: mediaItem.uri,
                  }) ||
                  allMediaItemsInCategory.find((item: any) => {
                    const vimeoId = getVimeoItemId(mediaItem.uri)

                    return vimeoId === item.externalId
                  }),
                [styles.disabled]: _.find(addedMediaItems, {
                  uri: mediaItem.uri,
                }),
                [styles.selectedUploadMediaItem]:
                  selectedUploadMediaItemsId.includes(String(uid)),
              })}
            >
              <img
                className={styles.mediaItemImg}
                src={mediaItem.pictures.base_link}
                alt="video"
              />

              {_.find(allMediaItemsInCategory, { uri: mediaItem.uri }) && (
                <div className={styles.overlay} draggable={false}>
                  <img src={circleStatusSrc} alt="status" draggable={false} />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}

              {!_.find(allUnImportedMediaItems, { link: mediaItem.link }) &&
                !_.find(allMediaItemsInCategory, { uri: mediaItem.uri }) &&
                allMediaItemsFromStore.find(
                  (storeItem: any) => storeItem.externalId === String(uid)
                ) &&
                !initCategoryMediaItems?.find(
                  (initItem: any) => initItem.externalId === String(uid)
                ) && (
                  <div
                    className={styles.importedInAnotherCategory}
                    draggable={false}
                  >
                    <img
                      src={circleStatusSrc}
                      alt="status"
                      draggable={false}
                      className={styles.circle}
                    />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

              {initCategoryMediaItems?.some(
                (initItem: any) => initItem.externalId === String(uid)
              ) && (
                <div className={styles.overlay} draggable={false}>
                  <img src={circleStatusSrc} alt="status" draggable={false} />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}
              <>
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(String(uid))}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.name}</h4>
                  </div>
                </>
              </>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default OnePlaylistMediaItems
