import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Spin,
  Switch,
  Upload,
} from 'antd'
import dayjs from 'dayjs'
import { RcFile } from 'antd/lib/upload'
import _ from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import ReactPlayer from 'react-player'
import cn from 'classnames'

import history from 'utils/history'

import { IDistribution, IDistributionLocationState } from 'models/distribution'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { getMediaItemImageUrl, getPartnerId } from 'utils/helper'
import { IMediaItem } from 'models/mediaItem'
import { MediaItemAPI } from 'api/VideoCollection/MediaItem'

import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { DeviceAPI } from 'api/DeviceFeed/Device'

import { ReactComponent as SourceYoutubeIcon } from 'sources/images/libary/table-source-youtube.svg'
import { ReactComponent as SourceVimeoIcon } from 'sources/images/libary/vimeo.svg'
import { ReactComponent as TVPreviewIcon } from 'sources/images/libary/tv-preview-table.svg'

import { useActions } from 'store/hooks/useActions'

import { ReactComponent as VideoPlayIcon } from 'sources/images/libary/video-info-play.svg'
import { ReactComponent as VideoVolumeIcon } from 'sources/images/libary/video-info-volume.svg'

import { ReactComponent as SelectedCategoryIcon } from 'sources/images/videoProperties/selected-category.svg'
import { ReactComponent as UnSelectedCategoryIcon } from 'sources/images/videoProperties/unselected-category.svg'
import { ReactComponent as LeftArrowIcon } from 'sources/images/videoProperties/arrow-left.svg'

import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'

import styles from './styles.module.scss'

interface IProps {
  isEdit: boolean
}

const VideoProperties: FC<IProps> = ({ isEdit }) => {
  const location = useLocation()
  const pageState = location.state as any
  const [form] = Form.useForm()
  const { id: mediaItemsId, distributionId } = useParams()

  const [mediaItem, setMediaItem] = useState<any>(null)

  const [allCategories, setAllCategories] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)

  const [defaultCategories, setDefaultCategories] = useState([])
  const [editCategories, setEditCategories] = useState<any>([])
  const [editCategoriesIds, setEditCategoriesIds] = useState('')

  const [defaultTags, setDefaultTags] = useState([])
  const [editTags, setEditTags] = useState<any>([])

  const [editTitle, setEditTitle] = useState('')
  const [defaultTitle, setDefaultTitle] = useState('')

  const [editLongDescription, setEditLongDescription] = useState('')
  const [defaultLongDescription, setDefaultLongDescription] = useState('')

  const [editDisplayName, setEditDisplayName] = useState('')
  const [defaultDisplayName, setDefaultDisplayName] = useState('')

  const [uploadImgFile, setUploadImgFile] = useState<null | any>(null)
  const [uploadImgSrc, setUploadImgSrc] = useState<any>()

  const [imageFromVideo, setImageFromVideo] = useState<string | null>(null)

  const [playbackUrl, setPlaybackUrl] = useState('')

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const playerRef = useRef<any>(null)

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false)
  const [play, setPlay] = useState(false)

  const [thumbnailFile, setThumbnailFile] = useState<any>(null)
  const [uploadThumbnailSrc, setUploadThumbnailSrc] = useState<any>()

  const [newThumbnail, setNewThumbnail] = useState<any>(null)

  const [distribution, setDistribution] = useState<IDistributionLocationState>({
    id: 0,
    name: '',
  })

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<[] | null>(null)
  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const navigate = useNavigate()
  const path = useLocation().pathname

  const onUploadThumbnail = (): void => {
    if (!thumbnailFile) {
      message.info('Select thumbnail from video!', 3)
    } else {
      setUploadImgSrc(uploadThumbnailSrc)
      setUploadImgFile(null)

      setNewThumbnail(thumbnailFile)

      // message.success('Thumbnail was uploaded!', 3)
    }
  }

  const onToggleThumbnail = (): void => {
    if (isOpenDropdownMenu) {
      setIsOpenDropdownMenu(false)
      // setPlay(true)
      return
    }

    // setPlay(false)
    setIsOpenDropdownMenu(true)
  }

  const addFrameFromVideo = (): void => {
    try {
      const canvas = canvasRef.current

      if (canvas && playerRef) {
        const ctx = canvas.getContext('2d')

        canvas.width = 206
        canvas.height = 125

        const videoTag =
          playerRef.current?.getInternalPlayer() as HTMLVideoElement
        // videoTag.setAttribute('crossOrigin', 'anonymous')

        ctx?.drawImage(videoTag, 0, 0, canvas.width, canvas.height)

        // console.log('videoFrame', videoTag)

        const imageUrl = canvas.toDataURL()

        fetch(imageUrl)
          .then((res: any) => res.blob())
          .then((blob: any) => {
            setThumbnailFile(blob)
          })

        setImageFromVideo(imageUrl)
        setUploadThumbnailSrc(imageUrl)

        canvas.width = 0
        canvas.height = 0
      }
    } catch (error) {
      console.log(error)
    }
  }

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId && mediaItemsId && distributionId) {
        const responseMediaItem: any = await MediaItemAPI.getMediaItem(
          partnerId,
          Number(distributionId),
          Number(mediaItemsId),
          {
            withCategories: 'true',
            withMediaItemImages: 'true',
            search: '',
          }
        )

        const currentMediaItem = responseMediaItem.mediaitems[0]

        if (currentMediaItem) {
          const responseDevice: any = await DeviceAPI.getPlaybackMediaItemUrl(
            Number(distributionId),
            Number(mediaItemsId)
          )

          const smallImgObj =
            currentMediaItem.Images.length > 0 &&
            currentMediaItem.Images.find(
              (itemImg: any) => itemImg.tag === 'Small'
            )

          if (smallImgObj) {
            smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
          }

          currentMediaItem.Images = [
            currentMediaItem.Images.filter(
              (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
            ),
            smallImgObj,
          ]

          if (responseDevice.error) {
            setPlaybackUrl('')
          } else {
            setPlaybackUrl(responseDevice.d.StreamUrl)
          }
        }

        setDefaultCategories(
          currentMediaItem.Categories.map((item: any) => item.name)
        )
        setEditCategories(
          currentMediaItem.Categories.map((item: any) => item.name)
        )

        const responseDistribution: any =
          await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            Number(distributionId),
            {
              withCategories: 'true',
            }
          )

        if (responseDistribution.status === 'success') {
          setDistribution({
            id: responseDistribution.distributions[0].id,
            name: responseDistribution.distributions[0].name,
          })

          const distributionCategories =
            responseDistribution.distributions[0].Categories.map(
              (category: any) => ({
                name: category.name,
                id: category.id,
              })
            )

          const distributionCategoriesWithoutOrphan =
            distributionCategories.slice(0, -1)

          setAllCategories(distributionCategoriesWithoutOrphan)
        }

        const tags = currentMediaItem.tags
          ? currentMediaItem.tags.split(',')
          : []

        setDefaultTags(tags)
        setEditTags(tags)

        setMediaItem(currentMediaItem)

        setEditTitle(currentMediaItem.title)
        setDefaultTitle(currentMediaItem.title)

        setEditDisplayName(currentMediaItem.name)
        setDefaultDisplayName(currentMediaItem.name)

        setEditLongDescription(currentMediaItem.description)
        setDefaultLongDescription(currentMediaItem.description)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (uploadImgFile) {
      setUploadImgSrc(window.URL.createObjectURL(uploadImgFile))
    }
  }, [uploadImgFile])

  // const getSuperAdminInitDistribution = async (): Promise<void> => {
  //   try {
  //     setIsLoading(true)
  //     const partnerId = storeCurrentPartnerId

  //     if (partnerId) {
  //       const { distributions }: IResponseDistributions =
  //         await DistributionAPI.getDistributionsByPartner(partnerId, {})

  //       setAllDistributions(distributions)

  //       if (distributions.length > 0) {
  //         let response: any = null

  //         response = await DistributionAPI.getOneDistributionByPartner(
  //           partnerId,
  //           distributions[0].id,
  //           {
  //             withCategories: 'true',
  //             withMediaItems: 'true',
  //             withMediaItemImages: 'true',
  //           }
  //         )

  //         saveCurrentDistributionIdAction(distributions[0].id)
  //         saveCurrentDistributionDataAction(
  //           response.distributions[0].Categories.filter(
  //             (item: any) => item.name !== 'Orphan'
  //           )
  //         )

  //         if (response.status === 'success') {
  //           setCurrentDistribution(() => response.distributions[0])
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     getSuperAdminInitDistribution()
  //   }
  // }, [storeCurrentPartnerId])

  const onBeforeUploadFile = (file: RcFile, e: any): boolean => {
    setUploadImgFile(file)
    setNewThumbnail(file)
    return false
  }

  const onChangeCategory = (selectedCategories: any): void => {
    const categoriesIds = allCategories
      .filter((item: any) => selectedCategories.includes(item.name))
      .map((item: any) => item.id)
      .join()

    setEditCategoriesIds(categoriesIds)
    setEditCategories(selectedCategories)
  }

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setEditTitle(value)
  }

  const onChangeDisplayName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target
    setEditDisplayName(value)
  }

  const onChangeDescription = (e: any): void => {
    const { value } = e.target
    setEditLongDescription(value)
  }

  const onChangeTags = (selectedTags: any): void => {
    setEditTags(selectedTags)
  }

  const setUploadImgBg = (): string => {
    if (uploadImgFile) {
      setUploadImgSrc(window.URL.createObjectURL(uploadImgFile))
    }

    return mediaItem && mediaItem.Images[1]
      ? getMediaItemImageUrl(mediaItem.Images, 'Small', true)
      : defaultImgBgSrc
  }

  const onSave = async (): Promise<void> => {
    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId) {
        if (!_.isEqual(_.sortBy(editCategories), _.sortBy(defaultCategories))) {
          const responseSetCategories: any =
            await MediaItemAPI.setCategoriesForMediaItem(
              partnerId,
              Number(distributionId),
              mediaItem.id,
              editCategoriesIds
            )
        }

        const updateMediaItem = _.cloneDeep(mediaItem)
        updateMediaItem.title = editTitle
        updateMediaItem.name = editDisplayName
        updateMediaItem.description = editLongDescription
        updateMediaItem.tags = editTags.join(',')

        let responseThumbnail: any = null

        if (newThumbnail) {
          responseThumbnail = await MediaItemAPI.createThumbnailsForMediaItem(
            partnerId,
            Number(distributionId),
            updateMediaItem.id,
            newThumbnail
          )
        }

        const responseUpdateMediaItem: any = await MediaItemAPI.updateMediaItem(
          partnerId,
          Number(distributionId),
          updateMediaItem.id,
          updateMediaItem
        )

        if (responseUpdateMediaItem.status === 'ERROR') {
          return
        }

        if (newThumbnail && responseThumbnail.status === 'ERROR') {
          return
        }

        message.success('Video was updated!', 3)

        if (!pageState) {
          navigate('/content')
        } else {
          history.back()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onBack = (): void => {
    if (!pageState) {
      navigate('/content')
    } else {
      history.back()
    }
  }

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={[pageState ? pageState.distribution : distribution]}
        onChangeDistribution={(value: number) => {
          console.log(value)
        }}
        videoProperty
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={(value: number) => {
          console.log(value)
        }}
      />

      {isLoading ? (
        <Spin size="large" className={styles.spinner} />
      ) : (
        <div className={styles.mainContent}>
          <SideBar />

          <div className={styles.section}>
            <div className={styles.pageTitleWrapper}>
              <Button
                icon={<LeftArrowIcon />}
                className={styles.backArrowLeft}
                onClick={onBack}
              />
              <h3 className={styles.pageTitle}>Video properties</h3>
            </div>

            <div className={styles.content}>
              <div className={styles.videoProperties}>
                <h3 className={styles.subtitle}> Video properties</h3>

                <Form form={form} className={styles.form} layout="vertical">
                  <Form.Item className={styles.thumnail}>
                    <h5 className={styles.formLabel}>
                      Thumnail (355px X 215px)
                    </h5>

                    <Upload.Dragger
                      className={styles.uploadWrapper}
                      disabled={!isEdit}
                      accept=".png, .jpg, .jpeg"
                      beforeUpload={onBeforeUploadFile}
                      showUploadList={false}
                      style={{
                        backgroundImage: `url(${
                          uploadImgSrc || setUploadImgBg()
                        })`,
                      }}
                    >
                      <h5 className={styles.text}>Drag &amp; Drop videos or</h5>

                      <Button
                        shape="round"
                        className={styles.uploadBtn}
                        disabled={!isEdit}
                      >
                        Upload image
                      </Button>
                    </Upload.Dragger>
                  </Form.Item>

                  <Form.Item
                    className={styles.formItem}
                    name="videoTitle"
                    label="Video title"
                  >
                    <Input
                      className={styles.formItemInput}
                      defaultValue={editTitle}
                      disabled={!isEdit}
                      onChange={onChangeTitle}
                    />
                  </Form.Item>

                  <Form.Item
                    className={styles.formItem}
                    name="categories"
                    label="Published on Categories:"
                  >
                    <Select
                      className={styles.formItemSelect}
                      mode="multiple"
                      placeholder="Please select"
                      defaultValue={editCategories}
                      onChange={onChangeCategory}
                      style={{ width: '100%' }}
                      disabled={!isEdit}
                      menuItemSelectedIcon={
                        <SelectedCategoryIcon
                          className={styles.selectedCategoryIcon}
                        />
                      }
                    >
                      {isEdit &&
                        allCategories.map((category: any) => (
                          <Select.Option
                            key={category.id}
                            value={category.name}
                          >
                            <span>{category.name}</span>
                            <div className={styles.selectedCategoryIcon} />
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className={styles.formItem}
                    name="shortDescription"
                    label="Short Description"
                  >
                    <Input
                      className={styles.formItemInput}
                      disabled={!isEdit}
                      defaultValue={mediaItem ? mediaItem.description : ''}
                      onChange={onChangeDescription}
                    />
                  </Form.Item>

                  <Form.Item
                    className={styles.formItem}
                    name="displayName"
                    label="Display name"
                  >
                    <Input
                      className={styles.formItemInput}
                      defaultValue={editDisplayName}
                      disabled={!isEdit}
                      onChange={onChangeDisplayName}
                    />
                  </Form.Item>

                  <Form.Item
                    className={styles.formItem}
                    name="tags"
                    label="Tags"
                  >
                    <Select
                      mode="multiple"
                      className={styles.formItemSelect}
                      placeholder="Please select"
                      defaultValue={editTags}
                      style={{ width: '100%' }}
                      disabled={!isEdit}
                      onChange={onChangeTags}
                      menuItemSelectedIcon={
                        <SelectedCategoryIcon
                          className={styles.selectedCategoryIcon}
                        />
                      }
                    >
                      {isEdit &&
                        defaultTags.map((tag: any) => (
                          <Select.Option key={uuidv4()} value={tag}>
                            <span>{tag}</span>
                            <div className={styles.selectedCategoryIcon} />
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Form>

                {isEdit && (
                  <div className={styles.controlsBtnWrapper}>
                    <Button
                      shape="round"
                      onClick={onSave}
                      className={styles.controlsBtn}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>

              <div
                className={styles.videoInfo}
                onMouseLeave={() => {
                  setIsOpenDropdownMenu(false)
                }}
              >
                {playbackUrl !== '' && (
                  <>
                    <h3 className={styles.subtitle}>Video info</h3>

                    <div className={styles.videoInfoThumbnail}>
                      <ReactPlayer
                        width="566px"
                        height="377px"
                        id="react-video"
                        ref={playerRef}
                        controls
                        className={styles.videoPlayer}
                        url={playbackUrl}
                        playing={play}
                        // config={{
                        //   file: { attributes: { crossOrigin: 'anonymous' } },
                        // }}
                      />

                      {/* 
                <video
                  width="566px"
                  height="377px"
                  controls
                  id="react-video"
                  ref={playerRef}
                  className={styles.videoPlayer}
                  src={playbackUrl}
                  // crossOrigin="anonymous"
                >
                  <source
                    src={playbackUrl}
                    type="application/x-mpegURL"
                    // type="video/mp4"
                  />

                  <track default kind="captions" srcLang="en" src="" />
                </video> */}

                      {mediaItem && mediaItem.externalType !== 'youtube' && (
                        <Button
                          shape="round"
                          className={styles.selectThumbnailBtn}
                          onClick={onToggleThumbnail}
                        >
                          {isOpenDropdownMenu
                            ? 'Thumbnail'
                            : 'Select frame as a thumbnail'}
                        </Button>
                      )}

                      <canvas
                        className={styles.dropdownUpload}
                        id="canvasId"
                        ref={canvasRef}
                      />

                      <Dropdown
                        placement="bottomLeft"
                        visible={isOpenDropdownMenu}
                        // trigger={['click']}
                        overlay={
                          <Menu className={styles.dropdownWrapper}>
                            <Menu.Item key="1">
                              <div className={styles.dropdownThumbnail}>
                                <span className={styles.dropdownText}>
                                  Select thumbnail
                                </span>

                                <span className={styles.dropdownText}>
                                  1080px X 720px
                                </span>

                                {/* <canvas
                            className={styles.dropdownUpload}
                            id="canvasId"
                            ref={canvasRef}
                          /> */}

                                <img
                                  crossOrigin="anonymous"
                                  className={styles.imageFrame}
                                  src={
                                    imageFromVideo !== null
                                      ? imageFromVideo
                                      : defaultImgBgSrc
                                  }
                                  alt="frame from video"
                                />

                                <Button
                                  shape="round"
                                  onClick={onUploadThumbnail}
                                  className={cn(
                                    styles.dropdownBtn,
                                    styles.firstBtn
                                  )}
                                >
                                  Upload
                                </Button>
                                <Button
                                  shape="round"
                                  onClick={addFrameFromVideo}
                                  className={cn(
                                    styles.dropdownBtn,
                                    styles.secondBtn
                                  )}
                                >
                                  Keyframe from video
                                </Button>
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button
                          shape="round"
                          className={cn(
                            styles.selectThumbnailBtn,
                            styles.hiddenBtn
                          )}
                          // disabled={!isEdit}
                          // onClick={addImgFromVideo}
                        >
                          Select frame as a thumbnail
                        </Button>
                      </Dropdown>
                    </div>
                  </>
                )}

                <h3 className={styles.subtitle}>Video info</h3>

                <div className={styles.info}>
                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>Created date</span>
                    <Button className={styles.infoItemValue} shape="round">
                      {dayjs(mediaItem && mediaItem.createdAt).format(
                        'DD/MM/YYYY'
                      )}
                    </Button>
                  </div>

                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>ID</span>
                    <Button className={styles.infoItemValue} shape="round">
                      {mediaItem && mediaItem.id}
                    </Button>
                  </div>

                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>Source</span>
                    <Button
                      className={cn(styles.infoItemValue, styles.source)}
                      shape="round"
                    >
                      {mediaItem && (
                        <>
                          {mediaItem &&
                            mediaItem.externalType &&
                            mediaItem.externalType.toLowerCase() ===
                              'youtube' && <SourceYoutubeIcon />}

                          {mediaItem &&
                            mediaItem.externalType &&
                            mediaItem.externalType.toLowerCase() ===
                              'vimeo' && <SourceVimeoIcon />}

                          {mediaItem &&
                            mediaItem.externalType &&
                            mediaItem.externalType.toLowerCase() === 'mp4' && (
                              <TVPreviewIcon
                                className={styles.tableTVPreview}
                              />
                            )}

                          {mediaItem && mediaItem.externalType === 'mp4'
                            ? 'computer'
                            : mediaItem.externalType}
                        </>
                      )}
                    </Button>
                  </div>

                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>Status</span>
                    <Button className={styles.infoItemValue} shape="round">
                      {mediaItem && mediaItem.status}
                    </Button>
                  </div>

                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>Updated date</span>
                    <Button className={styles.infoItemValue} shape="round">
                      {dayjs(mediaItem && mediaItem.updatedAt).format(
                        'DD/MM/YYYY'
                      )}
                    </Button>
                  </div>

                  <div className={styles.infoItem}>
                    <span className={styles.infoItemText}>Type</span>
                    <Button className={styles.infoItemValue} shape="round">
                      {mediaItem && mediaItem.type}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoProperties
