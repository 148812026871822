import React, { FC, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, message, Select, Spin, Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Plot from 'react-plotly.js'
import { DateRangePicker } from 'react-date-range'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import dayjs from 'dayjs'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import {
  countAverageValue,
  generateRandomHexColor,
  getMediaItemImageUrl,
  getPartnerId,
  countSumValue,
  displayViewsDashboardCount,
} from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { IDistribution, IResponseDistributions } from 'models/distribution'
import { IImage, IMediaItem } from 'models/mediaItem'
import { IPartner } from 'models/partner'
import { MediaItemAPI } from 'api/VideoCollection/MediaItem'

import { StatisticsAPI } from 'api/Statistics/Statistics'

import { PartnerAPI } from 'api/User/Partner'
import { IUser } from 'models/user'
import { useActions } from 'store/hooks/useActions'
import {
  ICountryItem,
  IDataPickerItem,
  IDistributionStatsData,
  IPlatformItem,
  IViewItem,
  StatisticsMethods,
} from 'models/statistics'

import { ReactComponent as ArrowUp } from 'sources/images/dashboard/arrow-up.svg'
import { ReactComponent as ArrowDown } from 'sources/images/dashboard/arrow-down.svg'
import { ReactComponent as SelectTime } from 'sources/images/dashboard/select-time.svg'
import { ReactComponent as SelectTimeItem } from 'sources/images/dashboard/selected-time-item.svg'
import { ReactComponent as Calendar } from 'sources/images/dashboard/calendar.svg'
import { ReactComponent as SelectDistribution } from 'sources/images/dashboard/select-distribution.svg'
import { ReactComponent as SelectedDistributionItem } from 'sources/images/dashboard/selected-distribution-item.svg'
import { ReactComponent as TableEyeIcon } from 'sources/images/libary/table-eye.svg'
import { ReactComponent as BGColors } from 'sources/images/dashboard/bg-colors.svg'

import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import InfoFrame from './InfoFrame'

import styles from './styles.module.scss'

const PLOT_DEFAULT_DATA = {
  x: [
    '2022-06-29',
    '2022-05-22',
    '2022-05-15',
    '2022-04-08',
    '2022-04-01',
    '2021-12-29',
    '2021-12-02',
    '2021-11-15',
    '2021-11-08',
    '2021-10-01',
  ],
  y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
}

const TEST_PCIDNAME = 'QC_PC_CTV_Arcade Matt,QC_PC_CTV_Car Care - DIY'

let controller: null | AbortController = new AbortController()
const antIcon = <LoadingOutlined style={{ fontSize: 42 }} spin />

const MAX_DAYS_FOR_MONITIZATION_REQUEST = 92
const SELECT_TIME_DATA = {
  Yesterday: {
    fromDate: dayjs().add(-1, 'day'),
    beforeDate: dayjs().add(-1, 'day'),
    prevFromDate: dayjs().add(-2, 'day'),
    prevBeforeDate: dayjs().add(-2, 'day'),
  },

  'Last week': {
    fromDate: dayjs()
      .add(-1, 'day')
      .set('date', dayjs().add(-1, 'day').get('date') - 6),
    beforeDate: dayjs().add(-1, 'day'),
    prevFromDate: dayjs()
      .add(-1, 'day')
      .set('date', dayjs().add(-1, 'day').get('date') - 13),
    prevBeforeDate: dayjs()
      .add(-1, 'day')
      .set('date', dayjs().add(-1, 'day').get('date') - 7),
  },

  'Last month': {
    fromDate: dayjs()
      .add(-1, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 1),
    beforeDate: dayjs().add(-1, 'day'),
    prevFromDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 2),
    prevBeforeDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 1),
  },

  'Last 3 Months': {
    fromDate: dayjs()
      .add(-1, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 3),
    beforeDate: dayjs().add(-1, 'day'),
    prevFromDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 6),
    prevBeforeDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 3),
  },

  'Last year': {
    fromDate: dayjs()
      .add(-1, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 12),
    beforeDate: dayjs().add(-1, 'day'),
    prevFromDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 24),
    prevBeforeDate: dayjs()
      .add(-2, 'day')
      .set('month', dayjs().add(-1, 'day').get('month') - 12),
  },
}

const Dashboard: FC = () => {
  const [selectedDistribution, setSelectedDistribution] = useState<
    number | null
  >(null)

  const [isLoadingData, setIsLoadingData] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )

  const [isHiddenSelectDistribution, setIsHiddenSelectDistribution] =
    useState(true)

  const [allMediaItemsData, setAllMediaItemsData] = useState<any>([])

  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const [selectTimeNameValue, setSelectTimeNameValue] = useState('Last week')
  const [datePeriod, setDatePeriod] = useState<dayjs.Dayjs[]>([])
  const [prevDatePeriod, setPrevDatePeriod] = useState<dayjs.Dayjs[]>([])

  const [selectedDatePickerData, setSelectedDatePickerData] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [allDistributionsName, setAllDistributionsName] = useState<
    IDistributionStatsData[]
  >([])
  const [selectedDistributionName, setSelectedDistributionName] = useState<
    string[]
  >([])

  const [currentMostViewNumber, setCurrentMostViewNumber] = useState(10)
  const [allMostViewMediaItems, setAllMostViewMediaItems] = useState<
    IMediaItem[]
  >([])
  const [renderMostViewMediaItems, setRenderMostViewMediaItems] = useState<
    IMediaItem[]
  >([])

  const [isLoadingMonitization, setIsLoadingMonitization] = useState(true)
  const [impression, setImpression] = useState(0)
  const [cpm, setCpm] = useState(0)
  const [fillRate, setFillRate] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [monitizationDatePeriods, setMonitizationDatePeriods] = useState<
    [Date, Date][]
  >([])

  const [isPrevLoadingMonitization, setIsPrevLoadingMonitization] =
    useState(true)
  const [prevImpression, setPrevImpression] = useState(0)
  const [prevCpm, setPrevCpm] = useState(0)
  const [prevFillRate, setPrevFillRate] = useState(0)
  const [prevRevenue, setPrevRevenue] = useState(0)
  const [prevMonitizationDatePeriods, setPrevMonitizationDatePeriods] =
    useState<[Date, Date][]>([])

  const [totalViews, setTotalViews] = useState(0)
  const [uniqueUsers, setUniqueUsers] = useState(0)
  const [allVisits, setAllVisits] = useState(0)
  const [addedVideos, setAddedVideos] = useState(0)

  const [prevTotalViews, setPrevTotalViews] = useState(0)
  const [prevUniqueUsers, setPrevUniqueUsers] = useState(0)
  const [prevAllVisits, setPrevAllVisits] = useState(0)
  const [prevAddedVideos, setPrevAddedVideos] = useState(0)

  const [viewsLineGraphicData, setViewsLineGraphicData] = useState<any>([])
  const [revenueLineGraphicData, setRevenueLineGraphicData] = useState<{
    x: string[]
    y: number[]
  }>({ x: [''], y: [0] })

  const [geoPieGraphicResponseData, setGeoPieGraphicResponseData] = useState<
    ICountryItem[]
  >([])
  const [renderGeoPieGraphicResponseData, setRenderGeoPieGraphicResponseData] =
    useState<ICountryItem[]>([])

  const [geoCountCountry, setGeoCountCountry] = useState(6)
  const [isShowMoreCountries, setIsShowMoreCountries] = useState(false)

  const [geoPieGraphicData, setGeoPieGraphicData] = useState<any>([])
  const [pieGraphicColors, setPieGraphicColors] = useState<string[]>([])
  const [totalViewsPieGraphic, setTotalViewsPieGraphic] = useState(0)

  const [platformData, setPlatformData] = useState<IPlatformItem[]>([])

  const [isUpdateStatistic, setIsUpdateStatistic] = useState(false)

  // super admin
  const [allPartners, setAllPartners] = useState<IPartner[] | null>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<[] | null>(null)
  const { storeCurrentPartnerId, storeAllPartnersData } = useTypedSelector(
    state => state.partner
  )

  const path = useLocation().pathname

  const { saveCurrentPartnerIdAction, saveCurrentPartnerDataAction } =
    useActions()

  const uploadingMonitizationData = (
    pcidName = '',
    currentPeriod?: [Date, Date][],
    prevPeriod?: [Date, Date][]
  ): void => {
    try {
      setIsLoadingMonitization(true)
      setIsPrevLoadingMonitization(true)

      if (
        selectedDistributionName.length === 1 &&
        selectedDistributionName[0] === 'all'
      ) {
        return
      }

      // console.log('monitizationDatePeriods', monitizationDatePeriods)
      // console.log('prevMonitizationDatePeriods', prevMonitizationDatePeriods)

      const promiseArrMonitizationDatePeriods: any = currentPeriod
        ? [
            StatisticsAPI.getMonitization(
              dayjs(currentPeriod[0][0]).format('YYYY-MM-DD'),
              dayjs(currentPeriod[0][1]).format('YYYY-MM-DD'),
              pcidName,
              controller
            ),
          ]
        : monitizationDatePeriods.map((item: [Date, Date]) =>
            StatisticsAPI.getMonitization(
              dayjs(item[0]).format('YYYY-MM-DD'),
              dayjs(item[1]).format('YYYY-MM-DD'),
              pcidName,
              controller
            )
          )

      const promiseArrPrevMonitizationDatePeriods: any = prevPeriod
        ? [
            StatisticsAPI.getMonitization(
              dayjs(prevPeriod[0][0]).format('YYYY-MM-DD'),
              dayjs(prevPeriod[0][1]).format('YYYY-MM-DD'),
              pcidName,
              controller
            ),
          ]
        : prevMonitizationDatePeriods.map((item: [Date, Date]) =>
            StatisticsAPI.getMonitization(
              dayjs(item[0]).format('YYYY-MM-DD'),
              dayjs(item[1]).format('YYYY-MM-DD'),
              pcidName,
              controller
            )
          )

      Promise.allSettled(promiseArrMonitizationDatePeriods)
        .then(res => {
          const isCancel = res.some((item: any) => item.status === 'rejected')
          return {
            data: res
              .map((responseItem: any) => {
                if (responseItem.status === 'fulfilled') {
                  return responseItem.value.data
                }

                return undefined
              })
              .filter((item: any) => item !== undefined)
              .flat(),
            isCancel,
          }
        })
        .then(result => {
          if (result.isCancel) {
            setRevenueLineGraphicData(PLOT_DEFAULT_DATA)
            setIsLoadingMonitization(true)
            return
          }

          const impressionValues: number[] = []
          const fillRateArrValues: number[] = []
          const revenueValues: number[] = []
          const cpmValues: number[] = []
          const revueGraphicData: {
            x: string[]
            y: number[]
          } = { x: [], y: [] }

          result.data?.forEach((item: any) => {
            impressionValues.push(Number(item.Impression))
            fillRateArrValues.push(Math.floor(item['Fill Rate'].slice(0, -1)))
            revenueValues.push(Number(item.Revenue))
            cpmValues.push(Number(item['Publisher Cpm']))

            revueGraphicData.x.push(item.Date)
            revueGraphicData.y.push(Number(item.Revenue))
          })

          setImpression(Number(countSumValue(impressionValues).toFixed(2)))
          setCpm(Number(countAverageValue(cpmValues).toFixed(2)))
          setFillRate(Number(countAverageValue(fillRateArrValues).toFixed(2)))
          setRevenue(Number(countSumValue(revenueValues).toFixed(2)))
          setRevenueLineGraphicData(
            result.data?.length === 0 ? PLOT_DEFAULT_DATA : revueGraphicData
          )

          setIsLoadingMonitization(false)
        })

      Promise.allSettled(promiseArrPrevMonitizationDatePeriods)
        .then(res =>
          res
            .map((responseItem: any) => {
              if (responseItem.status === 'fulfilled') {
                return responseItem.value.data
              }

              return undefined
            })
            .filter((item: any) => item !== undefined)
            .flat()
        )
        .then((data: any) => {
          const impressionValues: number[] = []
          const fillRateArrValues: number[] = []
          const revenueValues: number[] = []
          const cpmValues: number[] = []

          data?.forEach((item: any) => {
            impressionValues.push(Number(item.Impression))
            fillRateArrValues.push(Math.floor(item['Fill Rate'].slice(0, -1)))
            revenueValues.push(Number(item.Revenue))
            cpmValues.push(Number(item['Publisher Cpm']))
          })

          setPrevImpression(Number(countSumValue(impressionValues).toFixed(2)))
          setPrevCpm(Number(countAverageValue(cpmValues).toFixed(2)))
          setPrevFillRate(
            Number(countAverageValue(fillRateArrValues).toFixed(2))
          )
          setPrevRevenue(Number(countSumValue(revenueValues).toFixed(2)))

          setIsPrevLoadingMonitization(false)
        })
    } catch (error) {
      console.log(error)
      // setIsLoadingMonitization(false)
      // setIsPrevLoadingMonitization(false)
    }
  }

  const init = async (): Promise<void> => {
    try {
      setIsLoadingData(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (storeCurrentPartnerId) {
        const allPartnersDistributionResponse: any =
          await DistributionAPI.getDistributionsByPartner('*', {})

        const distributionsNameData: IDistributionStatsData[] =
          allPartnersDistributionResponse.distributions.map(
            (distribution: IDistribution) => ({
              name: distribution.name,
              id: distribution.id,
              pcidName: distribution.pcidName,
            })
          )

        const defaultDistributionName = distributionsNameData.find(
          (item: IDistributionStatsData) =>
            item.id === storeCurrentDistributionId
        )

        if (defaultDistributionName) {
          setSelectedDistributionName([defaultDistributionName.name])
        }

        setAllDistributionsName(distributionsNameData)
        const responseAllDistribution: any =
          await MediaItemAPI.getAllMediaItemsForDistribution('*', '*')

        const allMediaItemsOInfo: IMediaItem[] = _.uniqBy(
          responseAllDistribution.mediaitems,
          'id'
        )
          .map((item: any) => {
            const cloneItem = _.cloneDeep(item)
            const smallImgObj =
              cloneItem.Images.length > 0 &&
              cloneItem.Images.find(
                (itemImg: IImage) => itemImg.tag === 'Small'
              )

            if (smallImgObj) {
              smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
            }

            cloneItem.Images = [
              cloneItem.Images.filter(
                (cloneItemImg: IImage) => cloneItemImg.tag !== 'Small'
              ),
              smallImgObj,
            ]

            return cloneItem
          })
          .sort((a: IMediaItem, b: IMediaItem) => b.played - a.played)

        setAllMediaItemsData(allMediaItemsOInfo)

        const lastWeekData = SELECT_TIME_DATA['Last week']

        const { fromDate } = lastWeekData
        const { beforeDate } = lastWeekData

        const { prevFromDate } = lastWeekData
        const { prevBeforeDate } = lastWeekData

        const from = new Date(fromDate.toString())
        const before = new Date(beforeDate.toString())

        from.setHours(0, 0, 0, 0)
        before.setHours(23, 59, 59, 999)

        const prevFrom = new Date(prevFromDate.toString())
        const prevBefore = new Date(prevBeforeDate.toString())

        prevFrom.setHours(0, 0, 0, 0)
        prevBefore.setHours(23, 59, 59, 999)

        setMonitizationDatePeriods([[from, before]])
        setPrevMonitizationDatePeriods([[prevFrom, prevBefore]])

        if (defaultDistributionName) {
          // defaultDistributionName.pcidName
          uploadingMonitizationData(
            defaultDistributionName.pcidName,
            [[from, before]],
            [[prevFrom, prevBefore]]
          )
        }

        if (selectTimeNameValue === 'Last week' && storeCurrentDistributionId) {
          setDatePeriod([fromDate, beforeDate])
          setPrevDatePeriod([prevFromDate, prevBeforeDate])

          const allStatisticsResponseData: any =
            await StatisticsAPI.getComboDataByMethods(
              String(storeCurrentDistributionId),
              dayjs(from).format('YYYY-MM-DD'),
              dayjs(before).format('YYYY-MM-DD'),
              StatisticsMethods.join(),
              controller
            )

          const prevAllStatisticsResponseData: any =
            await StatisticsAPI.getComboDataByMethods(
              String(storeCurrentDistributionId),
              dayjs(prevFrom).format('YYYY-MM-DD'),
              dayjs(prevBefore).format('YYYY-MM-DD'),
              'total-views,unique-users,start-apps',
              controller
            )

          setPrevTotalViews(
            prevAllStatisticsResponseData['total-views'].total_views
          )
          setPrevUniqueUsers(
            prevAllStatisticsResponseData['unique-users'].unique_users
          )
          setPrevAllVisits(
            prevAllStatisticsResponseData['start-apps'].start_apps
          )

          setTotalViews(allStatisticsResponseData['total-views'].total_views)
          setUniqueUsers(allStatisticsResponseData['unique-users'].unique_users)
          setAllVisits(allStatisticsResponseData['start-apps'].start_apps)

          setViewsLineGraphicData({
            x: Object.keys(allStatisticsResponseData['views-by-days'].by_days),
            y: Object.values(
              allStatisticsResponseData['views-by-days'].by_days
            ),
          })

          setPlatformData(
            allStatisticsResponseData['view-by-platform'].video_sessions
          )

          let total = 0
          const randomColors: string[] = []

          if (
            allStatisticsResponseData['view-by-country'].video_sessions
              .length === 0
          ) {
            randomColors.push(generateRandomHexColor(0))
            setGeoPieGraphicData([100])
            setPieGraphicColors(randomColors)
            setTotalViewsPieGraphic(total)

            setGeoPieGraphicResponseData([
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ])
            setRenderGeoPieGraphicResponseData(
              [
                ...allStatisticsResponseData[
                  'view-by-country'
                ].video_sessions.sort(
                  (a: ICountryItem, b: ICountryItem) =>
                    Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
                ),
              ].slice(0, geoCountCountry)
            )
          } else {
            setGeoPieGraphicResponseData([
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ])
            setRenderGeoPieGraphicResponseData(
              [
                ...allStatisticsResponseData[
                  'view-by-country'
                ].video_sessions.sort(
                  (a: ICountryItem, b: ICountryItem) =>
                    Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
                ),
              ].slice(0, geoCountCountry)
            )
            setGeoPieGraphicData(
              allStatisticsResponseData['view-by-country'].video_sessions.map(
                (item: ICountryItem) => {
                  total += Number(item.devices)
                  randomColors.push(generateRandomHexColor(0))

                  return Math.round(Number(item.part.slice(0, -1)))
                }
              )
            )
            setPieGraphicColors(randomColors)
            setTotalViewsPieGraphic(total)
          }

          const transformMediaItems: IMediaItem[] = allMediaItemsOInfo
            .filter((mediaItem: IMediaItem) =>
              allStatisticsResponseData['video-views'].video_views.find(
                (item: IViewItem) => item.videoID === mediaItem.id
              )
            )
            .map((mediaItem: IMediaItem) => {
              const cloneMediaItem = _.cloneDeep(mediaItem)
              const findItem = allStatisticsResponseData[
                'video-views'
              ].video_views.find(
                (item: IViewItem) => item.videoID === mediaItem.id
              )

              cloneMediaItem.played = findItem ? Number(findItem.views) : 0

              return cloneMediaItem
            })
            .sort((a: IMediaItem, b: IMediaItem) => b.played - a.played)

          setAllMostViewMediaItems(transformMediaItems)
          setRenderMostViewMediaItems(
            transformMediaItems.slice(0, currentMostViewNumber)
          )
        }

        setAddedVideos(
          allMediaItemsOInfo.filter(
            (mediaItem: IMediaItem) =>
              mediaItem.DistributionId === storeCurrentDistributionId &&
              dayjs(mediaItem.createdAt) >= fromDate
          ).length
        )

        setPrevAddedVideos(
          allMediaItemsOInfo.filter(
            (mediaItem: IMediaItem) =>
              mediaItem.DistributionId === storeCurrentDistributionId &&
              dayjs(mediaItem.createdAt) >= prevFromDate &&
              dayjs(mediaItem.createdAt) <= prevBeforeDate
          ).length
        )
      } else if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        const distributionsNameData: IDistributionStatsData[] =
          distributions.map((distribution: IDistribution) => ({
            name: distribution.name,
            id: distribution.id,
            pcidName: distribution.pcidName,
          }))

        const defaultDistributionName = distributionsNameData.find(
          (item: IDistributionStatsData) =>
            item.id === storeCurrentDistributionId
        )

        if (defaultDistributionName) {
          setSelectedDistributionName([defaultDistributionName.name])
        }

        setAllDistributionsName(distributionsNameData)

        const responseAllDistribution: any =
          await MediaItemAPI.getAllMediaItemsForDistribution(partnerId, '*')

        const allMediaItemsOInfo = _.uniqBy(
          responseAllDistribution.mediaitems,
          'id'
        )
          .map((item: any) => {
            const cloneItem = _.cloneDeep(item)
            const smallImgObj =
              cloneItem.Images.length > 0 &&
              cloneItem.Images.find(
                (itemImg: IImage) => itemImg.tag === 'Small'
              )

            if (smallImgObj) {
              smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
            }

            cloneItem.Images = [
              cloneItem.Images.filter(
                (cloneItemImg: IImage) => cloneItemImg.tag !== 'Small'
              ),
              smallImgObj,
            ]

            return cloneItem
          })
          .sort((a: IMediaItem, b: IMediaItem) => b.played - a.played)

        setAllMediaItemsData(allMediaItemsOInfo)

        const lastWeekData = SELECT_TIME_DATA['Last week']

        const { fromDate } = lastWeekData
        const { beforeDate } = lastWeekData

        const { prevFromDate } = lastWeekData
        const { prevBeforeDate } = lastWeekData

        const from = new Date(fromDate.toString())
        const before = new Date(beforeDate.toString())

        from.setHours(0, 0, 0, 0)
        before.setHours(23, 59, 59, 999)

        const prevFrom = new Date(prevFromDate.toString())
        const prevBefore = new Date(prevBeforeDate.toString())

        prevFrom.setHours(0, 0, 0, 0)
        prevBefore.setHours(23, 59, 59, 999)

        setMonitizationDatePeriods([[from, before]])
        setPrevMonitizationDatePeriods([[prevFrom, prevBefore]])

        if (defaultDistributionName) {
          // defaultDistributionName.pcidName
          uploadingMonitizationData(
            defaultDistributionName.pcidName,
            [[from, before]],
            [[prevFrom, prevBefore]]
          )
        }

        if (selectTimeNameValue === 'Last week' && storeCurrentDistributionId) {
          setDatePeriod([fromDate, beforeDate])
          setPrevDatePeriod([prevFromDate, prevBeforeDate])

          const allStatisticsResponseData: any =
            await StatisticsAPI.getComboDataByMethods(
              String(storeCurrentDistributionId),
              dayjs(from).format('YYYY-MM-DD'),
              dayjs(before).format('YYYY-MM-DD'),
              StatisticsMethods.join(),
              controller
            )

          const prevAllStatisticsResponseData: any =
            await StatisticsAPI.getComboDataByMethods(
              String(storeCurrentDistributionId),
              dayjs(prevFrom).format('YYYY-MM-DD'),
              dayjs(prevBefore).format('YYYY-MM-DD'),
              'total-views,unique-users,start-apps',
              controller
            )

          setPrevTotalViews(
            prevAllStatisticsResponseData['total-views'].total_views
          )
          setPrevUniqueUsers(
            prevAllStatisticsResponseData['unique-users'].unique_users
          )
          setPrevAllVisits(
            prevAllStatisticsResponseData['start-apps'].start_apps
          )

          setTotalViews(allStatisticsResponseData['total-views'].total_views)
          setUniqueUsers(allStatisticsResponseData['unique-users'].unique_users)
          setAllVisits(allStatisticsResponseData['start-apps'].start_apps)

          setViewsLineGraphicData({
            x: Object.keys(allStatisticsResponseData['views-by-days'].by_days),
            y: Object.values(
              allStatisticsResponseData['views-by-days'].by_days
            ),
          })

          setPlatformData(
            allStatisticsResponseData['view-by-platform'].video_sessions
          )

          let total = 0
          const randomColors: string[] = []

          if (
            allStatisticsResponseData['view-by-country'].video_sessions
              .length === 0
          ) {
            randomColors.push(generateRandomHexColor(0))
            setGeoPieGraphicData([100])
            setPieGraphicColors(randomColors)
            setTotalViewsPieGraphic(total)

            setGeoPieGraphicResponseData([
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ])
            setRenderGeoPieGraphicResponseData(
              [
                ...allStatisticsResponseData[
                  'view-by-country'
                ].video_sessions.sort(
                  (a: ICountryItem, b: ICountryItem) =>
                    Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
                ),
              ].slice(0, geoCountCountry)
            )
          } else {
            setGeoPieGraphicResponseData([
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ])
            setRenderGeoPieGraphicResponseData(
              [
                ...allStatisticsResponseData[
                  'view-by-country'
                ].video_sessions.sort(
                  (a: ICountryItem, b: ICountryItem) =>
                    Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
                ),
              ].slice(0, geoCountCountry)
            )
            setGeoPieGraphicData(
              allStatisticsResponseData['view-by-country'].video_sessions.map(
                (item: ICountryItem) => {
                  total += Number(item.devices)
                  randomColors.push(generateRandomHexColor(0))

                  return Math.round(Number(item.part.slice(0, -1)))
                }
              )
            )
            setPieGraphicColors(randomColors)
            setTotalViewsPieGraphic(total)
          }

          const transformMediaItems = allMediaItemsOInfo
            .filter((mediaItem: IMediaItem) =>
              allStatisticsResponseData['video-views'].video_views.find(
                (item: IViewItem) => item.videoID === mediaItem.id
              )
            )
            .map((mediaItem: IMediaItem) => {
              const cloneMediaItem = _.cloneDeep(mediaItem)
              const findItem = allStatisticsResponseData[
                'video-views'
              ].video_views.find(
                (item: IViewItem) => item.videoID === mediaItem.id
              )

              cloneMediaItem.played = findItem ? Number(findItem.views) : 0

              return cloneMediaItem
            })
            .sort((a: IMediaItem, b: IMediaItem) => b.played - a.played)

          setAllMostViewMediaItems(transformMediaItems)
          setRenderMostViewMediaItems(
            transformMediaItems.slice(0, currentMostViewNumber)
          )
        }

        setAddedVideos(
          allMediaItemsOInfo.filter(
            (mediaItem: IMediaItem) =>
              mediaItem.DistributionId === storeCurrentDistributionId &&
              dayjs(mediaItem.createdAt) >= fromDate
          ).length
        )

        setPrevAddedVideos(
          allMediaItemsOInfo.filter(
            (mediaItem: IMediaItem) =>
              mediaItem.DistributionId === storeCurrentDistributionId &&
              dayjs(mediaItem.createdAt) >= prevFromDate &&
              dayjs(mediaItem.createdAt) <= prevBeforeDate
          ).length
        )
      }

      setIsUpdateStatistic(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    init()

    return () => {
      controller?.abort()
      controller = null
    }
  }, [])

  const countPercentValue = (a: number, b: number): number => {
    if (a === 0) {
      return Math.floor(b * 100)
    }

    if (a < b) {
      return Math.floor(((b - a) / a) * 100)
    }

    return Math.floor(((a - b) / a) * 100)
  }

  const resetAllStatistics = (): void => {
    setPrevTotalViews(0)
    setPrevUniqueUsers(0)
    setPrevAllVisits(0)

    setTotalViews(0)
    setUniqueUsers(0)
    setAllVisits(0)

    setImpression(0)
    setCpm(0)
    setFillRate(0)
    setRevenue(0)

    setPrevImpression(0)
    setPrevCpm(0)
    setPrevFillRate(0)
    setPrevRevenue(0)

    setViewsLineGraphicData({
      x: PLOT_DEFAULT_DATA.x,
      y: PLOT_DEFAULT_DATA.y,
    })

    setRevenueLineGraphicData({
      x: PLOT_DEFAULT_DATA.x,
      y: PLOT_DEFAULT_DATA.y,
    })

    setPlatformData([])

    setGeoPieGraphicData([100])
    setPieGraphicColors([])
    setTotalViewsPieGraphic(0)
    setGeoPieGraphicResponseData([])

    setAllMostViewMediaItems([])
    setRenderMostViewMediaItems([])
    setCurrentMostViewNumber(10)

    setGeoPieGraphicResponseData([])
    setRenderGeoPieGraphicResponseData([])
    setGeoCountCountry(6)

    setRenderMostViewMediaItems([])
    setAddedVideos(0)
    setPrevAddedVideos(0)
  }

  const updateStatisticsByDistributions = async (
    distributionsName: string[]
  ): Promise<void> => {
    try {
      setIsLoadingMonitization(true)
      setIsPrevLoadingMonitization(true)

      controller?.abort()

      if (distributionsName.length === 0) {
        resetAllStatistics()
        return
      }

      controller = new AbortController()

      setGeoPieGraphicResponseData([])
      setRenderGeoPieGraphicResponseData([])
      setGeoCountCountry(6)
      setRenderMostViewMediaItems([])
      setCurrentMostViewNumber(10)
      setIsShowMoreCountries(false)

      if (controller) {
        const distributionIds: string[] = allDistributionsName
          .map((item: IDistributionStatsData) => {
            if (distributionsName.find((name: string) => item.name === name)) {
              return String(item.id)
            }

            return ''
          })
          .filter((filterItem: string) => filterItem !== '')

        const distributionPcIdNames: string = allDistributionsName
          .map((item: IDistributionStatsData) => {
            if (distributionsName.find((name: string) => item.name === name)) {
              return String(item.pcidName)
            }

            return undefined
          })
          .filter((filterItem: string | undefined) => filterItem !== undefined)
          .join()

        const fromDate = datePeriod[0]
        const beforeDate = datePeriod[1]

        const prevFromDate = prevDatePeriod[0]
        const prevBeforeDate = prevDatePeriod[1]

        const from = new Date(fromDate.toString())
        const before = new Date(beforeDate.toString())

        from.setHours(0, 0, 0, 0)
        before.setHours(23, 59, 59, 999)

        const prevFrom = new Date(prevFromDate.toString())
        const prevBefore = new Date(prevBeforeDate.toString())

        prevFrom.setHours(0, 0, 0, 0)
        prevBefore.setHours(23, 59, 59, 999)

        // distributionPcIdNames
        uploadingMonitizationData(distributionPcIdNames)

        const allStatisticsResponseData: any =
          await StatisticsAPI.getComboDataByMethods(
            distributionIds.join(),
            dayjs(from).format('YYYY-MM-DD'),
            dayjs(before).format('YYYY-MM-DD'),
            StatisticsMethods.join(),
            controller
          )

        const prevAllStatisticsResponseData: any =
          await StatisticsAPI.getComboDataByMethods(
            distributionIds.join(),
            dayjs(prevFrom).format('YYYY-MM-DD'),
            dayjs(prevBefore).format('YYYY-MM-DD'),
            'total-views,unique-users,start-apps',
            controller
          )

        setPrevTotalViews(
          prevAllStatisticsResponseData['total-views'].total_views
        )
        setPrevUniqueUsers(
          prevAllStatisticsResponseData['unique-users'].unique_users
        )
        setPrevAllVisits(prevAllStatisticsResponseData['start-apps'].start_apps)

        setTotalViews(allStatisticsResponseData['total-views'].total_views)
        setUniqueUsers(allStatisticsResponseData['unique-users'].unique_users)
        setAllVisits(allStatisticsResponseData['start-apps'].start_apps)

        setViewsLineGraphicData({
          x: Object.keys(allStatisticsResponseData['views-by-days'].by_days),
          y: Object.values(allStatisticsResponseData['views-by-days'].by_days),
        })

        setPlatformData(
          allStatisticsResponseData['view-by-platform'].video_sessions
        )

        let total = 0
        const randomColors: string[] = []

        if (
          allStatisticsResponseData['view-by-country'].video_sessions.length ===
          0
        ) {
          randomColors.push(generateRandomHexColor(0))
          setGeoPieGraphicData([100])
          setPieGraphicColors(randomColors)
          setTotalViewsPieGraphic(total)

          setGeoPieGraphicResponseData([
            ...allStatisticsResponseData['view-by-country'].video_sessions.sort(
              (a: ICountryItem, b: ICountryItem) =>
                Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
            ),
          ])
          setRenderGeoPieGraphicResponseData(
            [
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ].slice(0, geoCountCountry)
          )
        } else {
          setGeoPieGraphicResponseData([
            ...allStatisticsResponseData['view-by-country'].video_sessions.sort(
              (a: ICountryItem, b: ICountryItem) =>
                Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
            ),
          ])
          setRenderGeoPieGraphicResponseData(
            [
              ...allStatisticsResponseData[
                'view-by-country'
              ].video_sessions.sort(
                (a: ICountryItem, b: ICountryItem) =>
                  Number(b.part.slice(0, -1)) - Number(a.part.slice(0, -1))
              ),
            ].slice(0, geoCountCountry)
          )
          setGeoPieGraphicData(
            allStatisticsResponseData['view-by-country'].video_sessions.map(
              (item: ICountryItem) => {
                total += Number(item.devices)
                randomColors.push(generateRandomHexColor(0))

                return Math.round(Number(item.part.slice(0, -1)))
              }
            )
          )
          setPieGraphicColors(randomColors)
          setTotalViewsPieGraphic(total)
        }

        const transformMediaItems = allMediaItemsData
          .filter((mediaItem: IMediaItem) =>
            allStatisticsResponseData['video-views'].video_views.find(
              (item: IViewItem) => item.videoID === mediaItem.id
            )
          )
          .map((mediaItem: IMediaItem) => {
            const cloneMediaItem = _.cloneDeep(mediaItem)
            const findItem = allStatisticsResponseData[
              'video-views'
            ].video_views.find(
              (item: IViewItem) => item.videoID === mediaItem.id
            )

            cloneMediaItem.played = findItem ? Number(findItem.views) : 0

            return cloneMediaItem
          })
          .sort((a: IMediaItem, b: IMediaItem) => b.played - a.played)

        setAllMostViewMediaItems(transformMediaItems)
        setRenderMostViewMediaItems(transformMediaItems.slice(0, 10))

        setAddedVideos(
          allMediaItemsData.filter(
            (mediaItem: IMediaItem) =>
              distributionIds.find(
                (id: string) => id === String(mediaItem.DistributionId)
              ) && dayjs(mediaItem.createdAt) >= fromDate
          ).length
        )

        setPrevAddedVideos(
          allMediaItemsData.filter(
            (mediaItem: IMediaItem) =>
              distributionIds.find(
                (id: string) => id === String(mediaItem.DistributionId)
              ) &&
              dayjs(mediaItem.createdAt) >= prevFromDate &&
              dayjs(mediaItem.createdAt) <= prevBeforeDate
          ).length
        )
      }
    } catch (error) {
      console.log(error)
      // setIsLoadingMonitization(false)
      // setIsPrevLoadingMonitization(false)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    if (isUpdateStatistic) {
      setIsLoadingMonitization(true)
      setIsPrevLoadingMonitization(true)

      updateStatisticsByDistributions(selectedDistributionName)
    }
  }, [selectedDistributionName, selectTimeNameValue])

  const onChangeDistribution = (value: number): void => {
    setIsLoadingMonitization(true)
    setIsPrevLoadingMonitization(true)
    setSelectedDistribution(value)
  }

  const onChangeSelectTime = (value: string): void => {
    if (!value) {
      return
    }

    setIsLoadingMonitization(true)
    setIsPrevLoadingMonitization(true)

    const selectTimeData =
      SELECT_TIME_DATA[value as keyof typeof SELECT_TIME_DATA]

    const { fromDate } = selectTimeData
    const { beforeDate } = selectTimeData

    const from = new Date(fromDate.toString())
    from.setHours(0, 0, 0, 0)
    const before = new Date(beforeDate.toString())
    before.setHours(23, 59, 59, 999)

    const { prevFromDate } = selectTimeData
    const { prevBeforeDate } = selectTimeData

    const prevFrom = new Date(prevFromDate.toString())
    prevFrom.setHours(0, 0, 0, 0)
    const prevBefore = new Date(prevBeforeDate.toString())
    prevBefore.setHours(23, 59, 59, 999)

    if (value === 'Last year') {
      const prevOneYearDate = dayjs()
        .add(-1, 'day')
        .set('month', dayjs().add(-1, 'day').get('month') - 12)

      const diffDays = Math.abs(fromDate.diff(beforeDate, 'day'))
      const prevDiffDays = Math.abs(prevFromDate.diff(prevOneYearDate, 'day'))

      if (diffDays > MAX_DAYS_FOR_MONITIZATION_REQUEST) {
        const datePeriods: [Date, Date][] = []
        const prevDatePeriods: [Date, Date][] = []

        for (let i = diffDays; i > 0; i -= MAX_DAYS_FOR_MONITIZATION_REQUEST) {
          const differenceDays = i - MAX_DAYS_FOR_MONITIZATION_REQUEST

          const fromDayjs = dayjs()
            .add(-1, 'day')
            .set('date', dayjs().add(-1, 'day').get('date') - i)

          const beforeDayjs =
            differenceDays < 0
              ? dayjs()
              : dayjs().set(
                  'date',
                  dayjs().add(-1, 'day').get('date') - differenceDays
                )

          const start = new Date(fromDayjs.toString())
          start.setHours(0, 0, 0, 0)

          const end = new Date(beforeDayjs.add(-1, 'day').toString())
          end.setHours(23, 59, 59, 999)

          datePeriods.push([start, end])
        }

        for (
          let i = prevDiffDays;
          i > 0;
          i -= MAX_DAYS_FOR_MONITIZATION_REQUEST
        ) {
          const differenceDays = i - MAX_DAYS_FOR_MONITIZATION_REQUEST

          const fromDayjs = dayjs(prevOneYearDate).set(
            'date',
            dayjs(prevOneYearDate).get('date') - i
          )

          const beforeDayjs =
            differenceDays < 0
              ? prevBeforeDate
              : dayjs(prevOneYearDate).set(
                  'date',
                  dayjs(prevOneYearDate).get('date') - differenceDays
                )

          const start = new Date(fromDayjs.toString())
          start.setHours(0, 0, 0, 0)

          const end = new Date(beforeDayjs.add(-1, 'day').toString())
          end.setHours(23, 59, 59, 999)

          prevDatePeriods.push([start, end])
        }

        setMonitizationDatePeriods(datePeriods)
        setPrevMonitizationDatePeriods(prevDatePeriods)
      }
    } else {
      setMonitizationDatePeriods([[from, before]])
      setPrevMonitizationDatePeriods([[prevFrom, prevBefore]])
    }

    setDatePeriod([fromDate, beforeDate])
    setPrevDatePeriod([prevFromDate, prevBeforeDate])

    setSelectTimeNameValue(value)
  }

  const onChangeSelectDistribution = (value: string[]): void => {
    setIsLoadingMonitization(true)
    setIsPrevLoadingMonitization(true)

    if (
      _.isEqual(
        value,
        allDistributionsName.map(
          (distribution: IDistributionStatsData) => distribution.name
        )
      ) &&
      !selectedDistributionName.find((item: string) => item === 'all')
    ) {
      setSelectedDistributionName([
        'all',
        ...allDistributionsName.map(
          (distribution: IDistributionStatsData) => distribution.name
        ),
      ])
      return
    }

    if (
      value.find((item: string) => item === 'all') &&
      !selectedDistributionName.find((item: string) => item === 'all')
    ) {
      setSelectedDistributionName([
        'all',
        ...allDistributionsName.map(
          (distribution: IDistributionStatsData) => distribution.name
        ),
      ])
      return
    }

    if (
      !value.find((item: string) => item === 'all') &&
      selectedDistributionName.find((item: string) => item === 'all')
    ) {
      setSelectedDistributionName([])
      return
    }

    setSelectedDistributionName(value)
  }

  const onShowMoreVideos = (): void => {
    if (allMostViewMediaItems.length <= renderMostViewMediaItems.length) {
      return
    }

    const step =
      currentMostViewNumber + 10 > allMostViewMediaItems.length
        ? currentMostViewNumber +
          allMediaItemsData.length -
          currentMostViewNumber
        : currentMostViewNumber + 10

    setCurrentMostViewNumber(currentMostViewNumber + 10)
    setRenderMostViewMediaItems(allMostViewMediaItems.slice(0, step))
  }

  const onShowMoreGeoCountry = (): void => {
    if (geoPieGraphicResponseData.length <= geoCountCountry) {
      return
    }

    if (isShowMoreCountries) {
      setRenderGeoPieGraphicResponseData(
        [...geoPieGraphicResponseData].slice(0, geoCountCountry)
      )
      setIsShowMoreCountries(!isShowMoreCountries)
    } else {
      setRenderGeoPieGraphicResponseData(geoPieGraphicResponseData)
      setIsShowMoreCountries(!isShowMoreCountries)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    const changePartner = allPartners && allPartners[currentIndex]
    const userFromLS = localStorage.getItem('user')

    if (changePartner && userFromLS) {
      const accountFromLS: IUser = JSON.parse(userFromLS)

      const response: any = await PartnerAPI.getOnePartnerInfo(
        changePartner.id,
        accountFromLS.token
      )

      saveCurrentPartnerIdAction(allPartners[currentIndex].id)

      if (response.status === 'success') {
        setCurrentPartner(response.partners[0])
        saveCurrentPartnerDataAction(response.partners[0])
      }
    }
  }

  const setCustomPeriodDate = (): void => {
    setIsOpenTimePicker(false)

    setIsLoadingMonitization(true)
    setIsPrevLoadingMonitization(true)

    const customFromDate = dayjs(selectedDatePickerData[0].startDate)
    const customBeforeDate = dayjs(selectedDatePickerData[0].endDate)

    const prevOneYearDate = dayjs(customBeforeDate).set(
      'month',
      dayjs(customBeforeDate).get('month') - 12
    )
    const maxDiffDays = Math.abs(
      dayjs(customBeforeDate).diff(prevOneYearDate, 'day')
    )

    const diffDays = Math.abs(customFromDate.diff(customBeforeDate, 'day'))

    const prevCustomBeforeDate = dayjs(selectedDatePickerData[0].startDate).add(
      -1,
      'day'
    )
    const prevCustomFromDate = dayjs(prevCustomBeforeDate).set(
      'date',
      dayjs(prevCustomBeforeDate).add(-1, 'day').get('date') - diffDays
    )

    // console.log('prevCustomFromDate', prevCustomFromDate)
    // console.log('prevCustomBeforeDate', prevCustomBeforeDate)

    const prevDiffDays =
      Math.abs(prevCustomFromDate.diff(prevCustomBeforeDate, 'day')) - 1

    const from = new Date(customFromDate.toString())
    from.setHours(0, 0, 0, 0)
    const before = new Date(customBeforeDate.toString())
    before.setHours(23, 59, 59, 999)

    const prevFrom = new Date(prevCustomFromDate.toString())
    prevFrom.setHours(0, 0, 0, 0)
    const prevBefore = new Date(prevCustomBeforeDate.toString())
    prevBefore.setHours(23, 59, 59, 999)

    if (diffDays > maxDiffDays) {
      message.error('Date period cannot be more than 1 year', 3)
      return
    }

    // console.log('diffDays', diffDays)
    // console.log('prevDiffDays', prevDiffDays)

    if (diffDays > MAX_DAYS_FOR_MONITIZATION_REQUEST) {
      const datePeriods: [Date, Date][] = []
      const prevDatePeriods: [Date, Date][] = []

      for (let i = diffDays; i > 0; i -= MAX_DAYS_FOR_MONITIZATION_REQUEST) {
        const differenceDays = i - MAX_DAYS_FOR_MONITIZATION_REQUEST

        const fromDayjs = dayjs(customBeforeDate).set(
          'date',
          dayjs(customBeforeDate).get('date') - i
        )

        const beforeDayjs =
          differenceDays < 0
            ? dayjs(customBeforeDate)
            : dayjs(customBeforeDate).set(
                'date',
                dayjs(customBeforeDate).get('date') - differenceDays
              )

        const start = new Date(fromDayjs.toString())
        start.setHours(0, 0, 0, 0)

        const end = new Date(beforeDayjs.add(-1, 'day').toString())
        end.setHours(23, 59, 59, 999)

        datePeriods.push([start, end])
      }

      for (
        let i = prevDiffDays;
        i > 0;
        i -= MAX_DAYS_FOR_MONITIZATION_REQUEST
      ) {
        const differenceDays = i - MAX_DAYS_FOR_MONITIZATION_REQUEST

        const fromDayjs = dayjs(prevCustomBeforeDate).set(
          'date',
          dayjs(prevCustomBeforeDate).get('date') - i
        )

        const beforeDayjs =
          differenceDays < 0
            ? dayjs(prevCustomBeforeDate).add(1, 'day')
            : dayjs(prevCustomBeforeDate).set(
                'date',
                dayjs(prevCustomBeforeDate).get('date') - differenceDays
              )

        const start = new Date(fromDayjs.toString())
        start.setHours(0, 0, 0, 0)

        const end = new Date(beforeDayjs.add(-1, 'day').toString())
        end.setHours(23, 59, 59, 999)

        prevDatePeriods.push([start, end])
      }

      setMonitizationDatePeriods(datePeriods)
      setPrevMonitizationDatePeriods(prevDatePeriods)
    } else {
      setMonitizationDatePeriods([[from, before]])
      setPrevMonitizationDatePeriods([[prevFrom, prevBefore]])
    }

    setSelectTimeNameValue(
      `${customFromDate.format('DD/MM/YY')}-${customBeforeDate.format(
        'DD/MM/YY'
      )}`
    )

    setDatePeriod([customFromDate, customBeforeDate])
    setPrevDatePeriod([prevCustomFromDate, prevCustomBeforeDate])
  }

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={[]}
        onChangeDistribution={onChangeDistribution}
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={onChangePartner}
        isHiddenSelectDistribution={isHiddenSelectDistribution}
      />

      <div className={styles.mainContent}>
        <SideBar />

        <div className={styles.section}>
          <div className={styles.pageTitleWrapper}>
            <h3 className={styles.pageTitle}>Dashboard</h3>

            <div className={styles.selectTimeWrapper}>
              <>
                <Select
                  className={styles.selectTime}
                  suffixIcon={<SelectTime />}
                  defaultValue={selectTimeNameValue}
                  value={selectTimeNameValue}
                  menuItemSelectedIcon={
                    <SelectTimeItem className={styles.selectedTimeImg} />
                  }
                  onChange={onChangeSelectTime}
                >
                  <Select.Option
                    key="Yesterday"
                    className={styles.timeItem}
                    value="Yesterday"
                  >
                    Yesterday
                    <div className={styles.selectedTimeImg} />
                  </Select.Option>

                  <Select.Option
                    key="Last week"
                    className={styles.timeItem}
                    value="Last week"
                  >
                    Last week
                    <div className={styles.selectedTimeImg} />
                  </Select.Option>

                  <Select.Option
                    key="Last month"
                    className={styles.timeItem}
                    value="Last month"
                  >
                    Last month
                    <div className={styles.selectedTimeImg} />
                  </Select.Option>

                  <Select.Option
                    key="Last 3 Months"
                    className={styles.timeItem}
                    value="Last 3 Months"
                  >
                    Last 3 months
                    <div className={styles.selectedTimeImg} />
                  </Select.Option>

                  <Select.Option
                    key="Last year"
                    className={styles.timeItem}
                    value="Last year"
                  >
                    Last year
                    <div className={styles.selectedTimeImg} />
                  </Select.Option>

                  <Select.Option key="custom" value="">
                    <Button
                      icon={<Calendar />}
                      className={styles.customTimeBtn}
                      onClick={() => setIsOpenTimePicker(true)}
                    >
                      Custom
                    </Button>
                  </Select.Option>
                </Select>

                {isOpenTimePicker && (
                  <div
                    className={styles.dateRangePickerWrapper}
                    onMouseLeave={() => setIsOpenTimePicker(false)}
                  >
                    <DateRangePicker
                      className={styles.dateRangePicker}
                      onChange={item =>
                        setSelectedDatePickerData([item.selection])
                      }
                      months={2}
                      ranges={selectedDatePickerData}
                      direction="horizontal"
                    />
                    <div className={styles.datePickerFooter}>
                      <Button
                        className={styles.datePickerFooterBtn}
                        type="primary"
                        shape="round"
                        onClick={setCustomPeriodDate}
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                )}
              </>
            </div>

            <div className={styles.selectDistributionWrapper}>
              <Select
                className={styles.selectDistribution}
                value={selectedDistributionName}
                mode="multiple"
                menuItemSelectedIcon={
                  <SelectedDistributionItem
                    className={styles.selectedDistributionImg}
                  />
                }
                maxTagCount={1}
                tagRender={() => (
                  <span>
                    {selectedDistributionName.length === 1 ? (
                      selectedDistributionName[0]
                    ) : (
                      <span className={styles.multipleApps}>
                        <span>Apps</span>
                        <span>
                          {`${
                            selectedDistributionName.find(
                              (item: string) => item === 'all'
                            )
                              ? selectedDistributionName.length - 1
                              : selectedDistributionName.length
                          } selected`}
                        </span>
                      </span>
                    )}
                  </span>
                )}
                onChange={onChangeSelectDistribution}
              >
                <Select.Option
                  key="all"
                  className={styles.distributionItem}
                  value="all"
                >
                  All
                  <div className={styles.selectedDistributionImg} />
                </Select.Option>
                {allDistributionsName.map((item: IDistributionStatsData) => (
                  <Select.Option
                    key={item.id}
                    className={styles.distributionItem}
                    value={item.name}
                  >
                    {item.name}
                    <div className={styles.selectedDistributionImg} />
                  </Select.Option>
                ))}
              </Select>

              <SelectDistribution className={styles.selectDistributionIcon} />
            </div>
          </div>

          {isLoadingData ? (
            <Spin className={styles.spinner} size="large" />
          ) : (
            <div className={styles.content}>
              <div className={styles.topInfo}>
                <InfoFrame
                  color="#F1557F"
                  title="Total Views"
                  percent={countPercentValue(prevTotalViews, totalViews)}
                  value={totalViews}
                  arrow={
                    prevTotalViews <= totalViews ? <ArrowUp /> : <ArrowDown />
                  }
                />

                <InfoFrame
                  color="#0078FF"
                  title="Unique Users"
                  percent={countPercentValue(prevUniqueUsers, uniqueUsers)}
                  value={uniqueUsers}
                  arrow={
                    prevUniqueUsers <= uniqueUsers ? <ArrowUp /> : <ArrowDown />
                  }
                />

                <InfoFrame
                  color="#F2B02F"
                  title="Visits (open app)"
                  percent={countPercentValue(prevAllVisits, allVisits)}
                  value={allVisits}
                  arrow={
                    prevAllVisits <= allVisits ? <ArrowUp /> : <ArrowDown />
                  }
                />

                <InfoFrame
                  color="#49D299"
                  title="Added Videos"
                  isLastItem
                  percent={countPercentValue(prevAddedVideos, addedVideos)}
                  value={addedVideos}
                  arrow={
                    prevAddedVideos <= addedVideos ? <ArrowUp /> : <ArrowDown />
                  }
                />
              </div>

              <div className={styles.mainInfo}>
                <BGColors className={styles.bgColors} />
                <div className={styles.diagrams}>
                  <div className={styles.lineDiagramWrapper}>
                    <Tabs defaultActiveKey="1" className={styles.tabs}>
                      <Tabs.TabPane
                        tab="Views"
                        key="1"
                        className={styles.tabPanel}
                      >
                        <Plot
                          className={styles.plotViewsGraphic}
                          config={{ staticPlot: true, responsive: true }}
                          data={[
                            {
                              type: 'scatter',
                              mode: 'lines',
                              x: viewsLineGraphicData.x,
                              y: viewsLineGraphicData.y,
                              line: { width: 4, color: '#2e85e8' },
                            },
                          ]}
                          layout={{
                            font: {
                              color: '#AAACB9',
                              family: 'Poppins, sans-serif',
                              size: 12,
                            },
                            annotations: [
                              {
                                xref: 'paper',
                                yref: 'paper',
                                x: 0,
                                xanchor: 'right',
                                y: 1,
                                yanchor: 'bottom',
                                text: 'unit',
                                showarrow: false,
                              },
                            ],
                            showlegend: false,
                            margin: {
                              l: 35,
                              r: 5,
                              b: 30,
                              t: 20,
                              pad: 6,
                            },

                            height: 300,

                            xaxis: {
                              tickformat: '%b-%d',
                              showgrid: false,
                            },
                            yaxis: {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              griddash: 'dash',

                              zeroline: false,
                              gridwidth: 2,
                              gridcolor: '#C6C8D2',
                            },
                          }}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab="Revenue"
                        key="2"
                        className={styles.tabPanel}
                      >
                        {isLoadingMonitization ? (
                          <Spin
                            indicator={antIcon}
                            className={styles.monitizationLoader}
                            size="large"
                          />
                        ) : (
                          <Plot
                            className={styles.plotRevenueGraphic}
                            config={{ staticPlot: true, responsive: true }}
                            data={[
                              {
                                type: 'scatter',
                                mode: 'lines',
                                x: revenueLineGraphicData.x,
                                y: revenueLineGraphicData.y,
                                line: { width: 4, color: '#2e85e8' },
                              },
                            ]}
                            layout={{
                              font: {
                                color: '#AAACB9',
                                family: 'Poppins, sans-serif',
                                size: 12,
                              },

                              showlegend: false,
                              margin: {
                                l: 40,
                                r: 5,
                                b: 30,
                                t: 20,
                                pad: 6,
                              },

                              height: 300,

                              xaxis: {
                                tickformat: '%b-%d',
                                showgrid: false,
                              },
                              yaxis: {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                griddash: 'dash',

                                zeroline: false,
                                gridwidth: 2,
                                gridcolor: '#C6C8D2',
                              },
                            }}
                          />
                        )}
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                  <div className={styles.geoDiagramWrapper}>
                    <div className={styles.topGeoDiagram}>
                      <h3 className={styles.title}>Geo</h3>
                      <Button
                        shape="round"
                        className={styles.geoShowMoreBtn}
                        onClick={onShowMoreGeoCountry}
                      >
                        {`Show ${!isShowMoreCountries ? 'more' : 'less'}`}
                      </Button>
                    </div>

                    <div className={styles.geoDiagram}>
                      <div className={styles.geoDiagramInfoWrapper}>
                        <div className={styles.geoPlotWrapper}>
                          <div className={styles.totalWrapper}>
                            <span className={styles.text}>Total</span>
                            <span className={styles.number}>
                              {displayViewsDashboardCount(totalViewsPieGraphic)}
                            </span>
                          </div>

                          <Plot
                            config={{ staticPlot: true, responsive: true }}
                            data={[
                              {
                                values: geoPieGraphicData,
                                marker: {
                                  colors: pieGraphicColors,
                                },
                                textinfo: 'none',
                                hoverinfo: 'none',

                                hole: 0.8,
                                type: 'pie',
                              },
                            ]}
                            layout={{
                              annotations: [
                                {
                                  font: {
                                    size: 20,
                                  },
                                  showarrow: false,
                                  text: '',
                                },
                              ],
                              margin: {
                                l: 25,
                                r: 25,
                                b: 0,
                                t: 0,
                                pad: 6,
                              },
                              showlegend: false,
                              width: 300,
                              height: 300,
                            }}
                          />
                        </div>

                        {renderGeoPieGraphicResponseData.length > 0 &&
                          renderGeoPieGraphicResponseData.map(
                            (item: ICountryItem, idx: number) => (
                              <div
                                className={styles.geoDiagramInfoItem}
                                key={uuidv4()}
                              >
                                <div
                                  className={styles.verticalLine}
                                  style={{
                                    backgroundColor: `${pieGraphicColors[idx]}`,
                                  }}
                                />

                                <div className={styles.country}>
                                  <p>{item.country}</p>
                                </div>
                                <span className={styles.percent}>
                                  <span className={styles.percentNumber}>
                                    {item.part.slice(0, -1)}
                                  </span>
                                  %
                                </span>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.mostViewed}>
                  <h4
                    className={styles.title}
                  >{`Most Viewed - top ${currentMostViewNumber} videos`}</h4>
                  <ul className={styles.mostViewedList}>
                    {renderMostViewMediaItems.map((mediaItem: IMediaItem) => (
                      <li className={styles.mostViewedItem} key={mediaItem.id}>
                        <img
                          className={styles.mediaItemImg}
                          src={
                            mediaItem.Images.length > 0
                              ? getMediaItemImageUrl(
                                  mediaItem.Images,
                                  'Small',
                                  true
                                )
                              : defaultImgBgSrc
                          }
                          alt="video"
                        />
                        <span className={styles.title}>{mediaItem.name}</span>
                        <span className={styles.views}>
                          {displayViewsDashboardCount(mediaItem.played)}{' '}
                          <TableEyeIcon />
                        </span>
                      </li>
                    ))}
                  </ul>

                  <Button
                    onClick={onShowMoreVideos}
                    shape="round"
                    className={styles.viewMoreBtn}
                  >
                    Show more
                  </Button>
                </div>
              </div>

              <div className={styles.monitiztion}>
                <h4 className={styles.title}>Monitization</h4>
                <div className={styles.frameWrapper}>
                  <InfoFrame
                    color="#F1557F"
                    title="Impressions"
                    percent={
                      !isLoadingMonitization && !isPrevLoadingMonitization
                        ? countPercentValue(prevImpression, impression)
                        : 0
                    }
                    value={impression}
                    arrow={
                      !isLoadingMonitization &&
                      !isPrevLoadingMonitization &&
                      prevImpression <= impression ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    isLoading={isLoadingMonitization}
                    prevIsLoading={isPrevLoadingMonitization}
                  />

                  <InfoFrame
                    color="#0078FF"
                    title="CPM"
                    dollarUnit="$"
                    percent={
                      !isLoadingMonitization && !isPrevLoadingMonitization
                        ? countPercentValue(prevCpm, cpm)
                        : 0
                    }
                    value={cpm}
                    arrow={
                      !isLoadingMonitization &&
                      !isPrevLoadingMonitization &&
                      prevCpm <= cpm ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    isLoading={isLoadingMonitization}
                    prevIsLoading={isPrevLoadingMonitization}
                  />

                  <InfoFrame
                    color="#F2B02F"
                    title="Revenue"
                    percent={
                      !isLoadingMonitization && !isPrevLoadingMonitization
                        ? countPercentValue(prevRevenue, revenue)
                        : 0
                    }
                    value={revenue}
                    arrow={
                      !isLoadingMonitization &&
                      !isPrevLoadingMonitization &&
                      prevRevenue <= revenue ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    isLoading={isLoadingMonitization}
                    prevIsLoading={isPrevLoadingMonitization}
                  />

                  <InfoFrame
                    color="#49D299"
                    title="Fill Rate"
                    percentUnit="%"
                    percent={
                      !isLoadingMonitization && !isPrevLoadingMonitization
                        ? countPercentValue(prevFillRate, fillRate)
                        : 0
                    }
                    value={fillRate}
                    isLastItem
                    arrow={
                      !isLoadingMonitization &&
                      !isPrevLoadingMonitization &&
                      prevFillRate <= fillRate ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )
                    }
                    isLoading={isLoadingMonitization}
                    prevIsLoading={isPrevLoadingMonitization}
                  />
                </div>
              </div>

              <div className={styles.devices}>
                <h4 className={styles.title}>Devices</h4>
                <div className={styles.frameWrapper}>
                  <div className={styles.ios}>
                    <span className={styles.value}>
                      {platformData.length > 0 &&
                      platformData.find(
                        (item: IPlatformItem) => item.platform === 'AppleTV'
                      )
                        ? displayViewsDashboardCount(
                            Number(
                              platformData.find(
                                (item: IPlatformItem) =>
                                  item.platform === 'AppleTV'
                              )?.devices
                            )
                          )
                        : 0}
                    </span>
                    <span className={styles.name}>IOS</span>
                  </div>

                  <div className={styles.roku}>
                    <span className={styles.value}>
                      {' '}
                      {platformData.length > 0 &&
                      platformData.find(
                        (item: IPlatformItem) => item.platform === 'Roku'
                      )
                        ? displayViewsDashboardCount(
                            Number(
                              platformData.find(
                                (item: IPlatformItem) =>
                                  item.platform === 'Roku'
                              )?.devices
                            )
                          )
                        : 0}
                    </span>
                    <span className={styles.name}>Roku</span>
                  </div>

                  <div className={styles.android}>
                    <span className={styles.value}>
                      {platformData.length > 0 &&
                      platformData.find(
                        (item: IPlatformItem) => item.platform === 'AndroidTV'
                      )
                        ? displayViewsDashboardCount(
                            Number(
                              platformData.find(
                                (item: IPlatformItem) =>
                                  item.platform === 'AndroidTV'
                              )?.devices
                            )
                          )
                        : 0}
                    </span>
                    <span className={styles.name}>Android</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
