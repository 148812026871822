import React, { FC, useEffect, useState } from 'react'
import { Input, message, Modal } from 'antd'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'

import { getVimeoItemId } from 'utils/helper'

import styles from './styles.module.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />

interface IProps {
  isOpenEditDescription: boolean
  setIsOpenEditDescription: any
  editDescriptionMediaItems: any
  setEditDescriptionMediaItems: any
  setDescriptionData: any
  descriptionData: any
  source: string
  playlistDescription?: string
  setCurrentPlaylistDescription?: React.Dispatch<string>
}

const ModalEditVideoDescription: FC<IProps> = ({
  isOpenEditDescription,
  setIsOpenEditDescription,
  editDescriptionMediaItems,
  setEditDescriptionMediaItems,
  setDescriptionData,
  descriptionData,
  source,
  playlistDescription,
  setCurrentPlaylistDescription,
}) => {
  const [editDescription, setEditDescription] = useState('')

  useEffect(() => {
    if (playlistDescription) {
      setEditDescription(playlistDescription)
    }
  }, [playlistDescription])

  return (
    <Modal
      centered
      visible={isOpenEditDescription}
      width={600}
      // closable={false}
      title="Enter video description"
      onCancel={() => {
        setIsOpenEditDescription(false)
        setEditDescription('')

        if (setCurrentPlaylistDescription) {
          setCurrentPlaylistDescription('')
        }
      }}
      afterClose={() => {
        // const prepareDescriptionData = editDescriptionMediaItems.map(
        //   (item: any) => ({
        //     id:
        //       source === 'youtube'
        //         ? item.snippet.resourceId.videoId
        //         : getVimeoItemId(item.uri),
        //     description: editDescription,
        //   })
        // )

        // console.log('descriptionData', descriptionData)
        // console.log('prepareDescriptionData', prepareDescriptionData)
        // console.log('[...prepareDescriptionData, ...descriptionData]', [
        //   ...prepareDescriptionData,
        //   ...descriptionData,
        // ])

        // setDescriptionData([...prepareDescriptionData, ...descriptionData])

        setEditDescription('')
        setEditDescriptionMediaItems([])

        if (setCurrentPlaylistDescription) {
          setCurrentPlaylistDescription('')
        }
      }}
      onOk={() => {
        const prepareDescriptionData = editDescriptionMediaItems.map(
          (item: any) => ({
            id:
              source === 'youtube'
                ? item.snippet.resourceId.videoId
                : getVimeoItemId(item.uri),
            description: editDescription,
          })
        )

        // console.log('descriptionData', descriptionData)
        // console.log('prepareDescriptionData', prepareDescriptionData)
        // console.log('[...prepareDescriptionData, ...descriptionData]', [
        //   ...prepareDescriptionData,
        //   ...descriptionData,
        // ])

        setDescriptionData([...prepareDescriptionData, ...descriptionData])

        setEditDescription('')
        setEditDescriptionMediaItems([])

        setIsOpenEditDescription(false)

        if (setCurrentPlaylistDescription) {
          setCurrentPlaylistDescription('')
        }
      }}
      className={styles.editModal}
      closeIcon={<CloseOutlined style={{ color: '#000', display: 'none' }} />}
    >
      <Input.TextArea
        className={styles.textarea}
        value={editDescription}
        onInput={e => {
          const target = e.target as HTMLTextAreaElement
          setEditDescription(target.value)
        }}
      />
    </Modal>
  )
}

export default ModalEditVideoDescription
