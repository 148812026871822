import { ColorActionsTypes, IColorAction } from 'store/types/color'

export const setBackgroundColor = (color: string): IColorAction => ({
  type: ColorActionsTypes.CHANGE_BACKGROUND,
  payload: color,
})

export const setSelectedVideoColor = (color: string): IColorAction => ({
  type: ColorActionsTypes.CHANGE_SELECTED_VIDEO,
  payload: color,
})

export const setTextColor = (color: string): IColorAction => ({
  type: ColorActionsTypes.CHANGE_TEXT,
  payload: color,
})

export const setPopularColors = (color: string[]): IColorAction => ({
  type: ColorActionsTypes.POPULAR_COLORS,
  payload: color,
})
