import React, { FC, ReactElement, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import cn from 'classnames'

import { ReactComponent as HelpIcon } from 'sources/images/content/help-icon.svg'

import styles from './styles.module.scss'

interface IProps {
  icon: ReactElement
  appName: string
  onGenerateVimeoURL?: any
  onGetYoutubeChannel?: any
}

const ConnectAppForm: FC<IProps> = ({
  icon,
  appName,
  onGenerateVimeoURL,
  onGetYoutubeChannel,
}) => {
  const [form] = Form.useForm()

  const [nickName, setNickName] = useState('')
  const [accountId, setAccountId] = useState('')

  const onSubmitForm = (e: any): void => {
    const { channelId } = e

    if (appName !== 'vimeo' && channelId === undefined) {
      message.error('Please input channel ID!', 3)
      return
    }

    if (onGetYoutubeChannel) {
      onGetYoutubeChannel(channelId)
    }

    if (onGenerateVimeoURL) {
      onGenerateVimeoURL('vimeo')
    }
  }

  return (
    <Form form={form} className={styles.form} onFinish={onSubmitForm}>
      <div className={styles.title}>
        <Button className={styles.icon} icon={icon} shape="circle" />
        <span
          className={styles.text}
        >{`Connect to your ${appName} channel and import videos/playlis`}</span>
      </div>

      <Form.Item className={styles.formItem} name="nickName">
        <Input
          className={styles.formInput}
          placeholder="Nick name*"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNickName(e.target.value)
          }
        />
      </Form.Item>

      <Form.Item className={styles.formItem} name="accountId">
        <Input
          className={styles.formInput}
          placeholder="account ID*"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAccountId(e.target.value)
          }
        />
      </Form.Item>

      <Form.Item className={styles.formItem} name="userName">
        <Input className={styles.formInput} placeholder="Username" />
      </Form.Item>

      <Form.Item className={styles.formItem} name="apiKeyOrPassword">
        <Input className={styles.formInput} placeholder="Api key / password" />
      </Form.Item>

      {appName === 'youtube' && (
        <Form.Item className={styles.formItem} name="channelId">
          <Input className={styles.formInput} placeholder="channel ID" />
        </Form.Item>
      )}

      <div className={styles.helpWrapper}>
        <HelpIcon className={styles.helpIcon} />
        <a className={styles.helpLink} href="/">
          How to get this in {appName}
        </a>
      </div>

      <Button
        className={cn(styles.submitBtn, styles.active)}
        htmlType="submit"
        shape="round"
      >
        Connect
      </Button>
    </Form>
  )
}

export default ConnectAppForm
