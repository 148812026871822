import React, { FC, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { IMediaItem } from 'models/mediaItem'

import EmptyCategoryList from 'components/EmptyCategoryList'

import { getMediaItemImageUrl } from 'utils/helper'
import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import styles from './styles.module.scss'

interface IProps {
  name: string
  mediaItems: IMediaItem[] | undefined
  textColor: string
  activeVideo: string
  currentMediaItemActive: any
  allMediaItems: any
  setCurrentMediaItemInfo: React.Dispatch<any>
  setCurrentMediaItemImg: React.Dispatch<any>
  setCurrentMediaItemActive: React.Dispatch<React.SetStateAction<any>>
}

const Category: FC<IProps> = ({
  name,
  mediaItems,
  textColor,
  activeVideo,
  currentMediaItemActive,
  allMediaItems,
  setCurrentMediaItemInfo,
  setCurrentMediaItemImg,
  setCurrentMediaItemActive,
}) => {
  const [renderMediaItems, setRenderMediaItems] = useState<any>([])

  const init = (): void => {
    const sortMediaItems =
      mediaItems &&
      mediaItems
        .map((item: any) => {
          const cloneItem = _.cloneDeep(item)
          const smallImgObj =
            cloneItem.Images.length > 0 &&
            cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

          if (smallImgObj) {
            smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
          }

          cloneItem.Images = [
            cloneItem.Images.filter(
              (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
            ),
            smallImgObj,
          ]

          return cloneItem
        })
        .sort(
          (a: IMediaItem, b: IMediaItem) =>
            a.CategoryMediaItem.orderNumber - b.CategoryMediaItem.orderNumber
        )

    const publishMediaItems = sortMediaItems?.filter(
      (mediaItem: IMediaItem) => mediaItem.published
    )
    const unpublishedMediaItems = sortMediaItems?.filter(
      (mediaItem: IMediaItem) => !mediaItem.published
    )

    if (publishMediaItems && unpublishedMediaItems) {
      setRenderMediaItems([...publishMediaItems, ...unpublishedMediaItems])
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onSelectVideo = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ): void => {
    const target = e.target as HTMLElement
    const currentMediaItem = target.closest('[data-mediaitem]') as HTMLElement
    const allMediaItemsEl = document.querySelectorAll('[data-mediaitem]')

    if (currentMediaItem?.classList.contains(styles.active)) {
      return
    }

    allMediaItemsEl.forEach((item: any) => {
      item.classList.remove(styles.active)
    })

    if (currentMediaItem) {
      const categoryId = currentMediaItem.dataset.mediaitem
      const clickedMediaItem = allMediaItems.find(
        (item: IMediaItem) =>
          item.id === Number(currentMediaItem.id) &&
          item.CategoryMediaItem.id === Number(categoryId)
      )

      setCurrentMediaItemInfo({
        name: clickedMediaItem.name,
        description: clickedMediaItem.description,
      })
      setCurrentMediaItemImg(
        clickedMediaItem.Images.length > 0
          ? getMediaItemImageUrl(clickedMediaItem.Images)
          : defaultImgBgSrc
      )
      setCurrentMediaItemActive({
        id: clickedMediaItem.id,
        categoryId: clickedMediaItem.CategoryMediaItem.id,
      })
    }
  }

  return (
    <div className={styles.category}>
      <h5 style={{ color: textColor }} className={styles.name}>
        {name}
      </h5>

      <ul style={{ color: textColor }} className={styles.list}>
        {renderMediaItems.map((mediaItem: IMediaItem) => (
          <li
            onClick={onSelectVideo}
            data-mediaitem={mediaItem.CategoryMediaItem.id}
            id={String(mediaItem.id)}
            aria-hidden
            className={cn(styles.mediaItem, {
              [styles.active]:
                currentMediaItemActive.id === mediaItem.id &&
                currentMediaItemActive.categoryId ===
                  mediaItem.CategoryMediaItem.id,
            })}
            key={uuidv4()}
            style={{
              outlineColor: activeVideo,
            }}
          >
            <img
              className={styles.mediaItemImg}
              src={
                mediaItem.Images.length > 0
                  ? getMediaItemImageUrl(mediaItem.Images)
                  : defaultImgBgSrc
              }
              alt="video"
            />

            <div className={styles.mediaItemTitleWrapper}>
              <h4 className={styles.title}>{mediaItem.name}</h4>
              <p className={styles.mediaItemDescription}>
                {mediaItem.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Category
