import React, { FC, useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { getMediaItemImageUrl, getPartnerId } from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'

import { ICategory } from 'models/category'
import { IDistribution } from 'models/distribution'
import { IMediaItem } from 'models/mediaItem'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import Category from './Category'

import styles from './styles.module.scss'

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />

interface IProps {
  previewVisible: boolean
  setPreviewVisible: any
}

const Preview: FC<IProps> = ({ previewVisible, setPreviewVisible }) => {
  const [distributionData, setDistributionData] =
    useState<IDistribution | null>(null)
  const [categories, setCategories] = useState<ICategory[] | []>([])

  const [logoSrc, setLogoSrc] = useState<string | null>(null)
  const [splashSrc, setSplashSrc] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const [currentMediaItemImg, setCurrentMediaItemImg] = useState<string | null>(
    null
  )
  const [currentMediaItemInfo, setCurrentMediaItemInfo] = useState<any>({
    title: '',
    description: '',
  })
  const [allMediaItems, setAllMediaItems] = useState<any>([])
  const [currentMediaItemActive, setCurrentMediaItemActive] = useState<any>({
    id: 0,
    categoryId: 0,
  })

  const [defaultColors, setDefaultColors] = useState({
    activeColor: '',
    backgroundColor: '',
    textColor: '',
  })

  const setDistributionAssets = (assets: any): void => {
    const logo = assets.find((item: any) => item.tag.match(/logo/))
    const splash = assets.find((item: any) => item.tag.match(/splash/))

    if (logo) {
      setLogoSrc(logo.url)
    }

    if (splash) {
      setSplashSrc(splash.url)
    }
  }

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const init = async (): Promise<void> => {
    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()
      setIsLoading(true)

      if (partnerId && storeCurrentDistributionId) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          storeCurrentDistributionId,
          {
            withCategories: 'true',
            withMediaItems: 'true',
            withMediaItemImages: 'true',
          }
        )

        if (response.status === 'success') {
          const distribution = response.distributions[0]

          const { branding } = distribution
          const {
            Active_Color: activeColor,
            Background_Color: backgroundColor,
            Text_Color: textColor,
          } = JSON.parse(branding)

          setDefaultColors({ activeColor, backgroundColor, textColor })
          setDistributionData(distribution)
          setDistributionAssets(distribution.Images)

          const filteredCategories =
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
              .filter(
                (category: ICategory) =>
                  category.published === true &&
                  category.MediaItems &&
                  category.MediaItems.length > 2
              )
              .sort(
                (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
              )
              .map((category: any) => {
                category.MediaItems?.sort(
                  (a: IMediaItem, b: IMediaItem) =>
                    a.CategoryMediaItem.orderNumber -
                    b.CategoryMediaItem.orderNumber
                )

                return category
              })

          const firstMediaItem =
            filteredCategories[0] && filteredCategories[0].MediaItems[0]

          setAllMediaItems(
            filteredCategories
              .map((category: ICategory) => category.MediaItems)
              .flat()
          )

          if (firstMediaItem) {
            setCurrentMediaItemInfo({
              name: firstMediaItem.name,
              description: firstMediaItem.description,
            })
            setCurrentMediaItemImg(
              firstMediaItem.Images.length > 0
                ? getMediaItemImageUrl(firstMediaItem.Images)
                : defaultImgBgSrc
            )
            setCurrentMediaItemActive({
              id: firstMediaItem.id,
              categoryId: firstMediaItem.CategoryMediaItem.id,
            })
          }

          setCategories(filteredCategories)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 3000)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Modal
      centered
      visible={previewVisible}
      width={1400}
      onCancel={() => setPreviewVisible(false)}
      footer={[]}
      className={styles.previewPage}
      bodyStyle={{
        backgroundColor: defaultColors.backgroundColor,
        overflowY: isLoading ? 'hidden' : 'auto',
        overflowX: 'hidden',
      }}
      closeIcon={<CloseOutlined style={{ color: defaultColors.textColor }} />}
    >
      {isLoading ? (
        <div className={styles.splashPreview}>
          {splashSrc && <img src={splashSrc} alt="splash" />}
          <Spin indicator={antIcon} className={styles.spin} size="large" />
        </div>
      ) : (
        <div
          className={styles.previewWrapper}
          style={{
            backgroundColor: defaultColors.backgroundColor,
            height: '100%',
          }}
        >
          <div className={styles.top}>
            <div className={styles.info}>
              {logoSrc ? (
                <img src={logoSrc} alt="logo" className={styles.logo} />
              ) : (
                <span
                  className={styles.logoText}
                  style={{ color: defaultColors.textColor }}
                >
                  Logo
                </span>
              )}
              <h2
                className={styles.title}
                style={{ color: defaultColors.textColor }}
              >
                {currentMediaItemInfo.name}
              </h2>
              <p
                className={styles.description}
                style={{ color: defaultColors.textColor }}
              >
                {currentMediaItemInfo.description}
              </p>
            </div>

            {currentMediaItemImg && (
              <div
                className={styles.splashImg}
                style={{
                  background: defaultColors.backgroundColor,
                  backgroundImage: `linear-gradient(to right, ${
                    defaultColors.backgroundColor
                  } 1%, ${
                    currentMediaItemImg
                      ? 'rgba(255, 248, 248, 0.1)'
                      : defaultColors.backgroundColor
                  } 100%), url(${currentMediaItemImg})`,
                }}
              />
            )}
          </div>

          <div className={styles.categories}>
            {categories.map((category: ICategory, idx) => (
              <Category
                key={uuidv4()}
                name={category.name}
                mediaItems={category.MediaItems}
                textColor={defaultColors.textColor}
                activeVideo={defaultColors.activeColor}
                currentMediaItemActive={currentMediaItemActive}
                setCurrentMediaItemInfo={setCurrentMediaItemInfo}
                setCurrentMediaItemImg={setCurrentMediaItemImg}
                allMediaItems={allMediaItems}
                setCurrentMediaItemActive={setCurrentMediaItemActive}
              />
            ))}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default Preview
