import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import { getMediaItemImageUrl, getPartnerId } from 'utils/helper'

import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ICategory } from 'models/category'
import { IMediaItem } from 'models/mediaItem'

import EmptyCategoryList from 'components/EmptyCategoryList'

import { ReactComponent as ResetIcon } from 'sources/images/reset.svg'
import { ReactComponent as HoverMediaItemControlsIcon } from 'sources/images/content/hover-media-item-controls.svg'

import draggingBgSrc from 'sources/images/content/dragging-bg.png'
import defaultImgBgSrc from 'sources/images/content/default-img-bg.png'

import styles from './styles.module.scss'

interface IProps {
  category: ICategory
  allMediaItemsInCategory?: []
  setAllMediaItemsInCategory?: any
  draggingUploadMediaItemRef: any
}

const UrlRssCategory: FC<IProps> = ({
  category,
  allMediaItemsInCategory,
  setAllMediaItemsInCategory,
  draggingUploadMediaItemRef,
}) => {
  const [isDraggingMediaItem, setIsDraggingMediaItem] = useState(false)

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const [mediaItems, setMediaItems] = useState<IMediaItem[] | undefined>(
    category.MediaItems?.map((item: any) => {
      const cloneItem = _.cloneDeep(item)
      const smallImgObj =
        cloneItem.Images.length > 0 &&
        cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

      if (smallImgObj) {
        smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
      }

      cloneItem.Images = [
        cloneItem.Images.filter(
          (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
        ),
        smallImgObj,
      ]

      return cloneItem
    }).sort(
      (a: IMediaItem, b: IMediaItem) =>
        a.CategoryMediaItem.orderNumber - b.CategoryMediaItem.orderNumber
    )
  )

  const draggingStartedIdxRef = useRef<number | null>(null)
  const draggingMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingImgSrcRef = useRef('')

  useEffect(() => {
    setMediaItems(
      category.MediaItems?.map((item: any) => {
        const cloneItem = _.cloneDeep(item)
        const smallImgObj =
          cloneItem.Images.length > 0 &&
          cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

        if (smallImgObj) {
          smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
        }

        cloneItem.Images = [
          cloneItem.Images.filter(
            (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
          ),
          smallImgObj,
        ]

        return cloneItem
      }).sort(
        (a: IMediaItem, b: IMediaItem) =>
          a.CategoryMediaItem.orderNumber - b.CategoryMediaItem.orderNumber
      )
    )
  }, [category])

  const handleDragStartMediaItem = (e: any): void => {
    setIsDraggingMediaItem(true)

    const target = e.target as HTMLImageElement
    const draggingMediaItem = target.closest(
      '[data-media-item-order]'
    ) as HTMLElement

    if (mediaItems) {
      draggingStartedIdxRef.current = mediaItems?.findIndex(
        ({ id }: IMediaItem) => Number(draggingMediaItem.id) === id
      )
    }

    if (draggingMediaItem.firstChild) {
      const imgEl = draggingMediaItem.firstChild as HTMLImageElement
      draggingImgSrcRef.current = imgEl.src

      setTimeout(() => {
        imgEl.src = draggingBgSrc
      }, 0)
    }

    // eslint-disable-next-line  no-param-reassign
    draggingMediaItemRef.current = draggingMediaItem
  }

  const handleDragEnterMediaItem = (e: any): void => {
    try {
      const target = e.target as HTMLImageElement
      const underMediaItem = target.closest(
        '[data-media-item-order]'
      ) as HTMLElement

      if (!underMediaItem.closest('[data-category-order]')) {
        return
      }

      if (underMediaItem.id !== draggingMediaItemRef.current?.id) {
        const draggingIdx = mediaItems?.findIndex(
          ({ id }: IMediaItem) =>
            Number(draggingMediaItemRef.current?.id) === id
        )
        const underIdx = mediaItems?.findIndex(
          ({ id }: IMediaItem) => Number(underMediaItem.id) === id
        )

        if (draggingIdx === -1 || underIdx === -1) {
          return
        }

        const cloneMediaItems = _.cloneDeep(mediaItems)

        if (
          cloneMediaItems &&
          draggingIdx !== undefined &&
          underIdx !== undefined
        ) {
          const tmp = cloneMediaItems[draggingIdx]
          cloneMediaItems[draggingIdx] = cloneMediaItems[underIdx]
          cloneMediaItems[underIdx] = tmp

          setMediaItems(cloneMediaItems)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDropMediaItem = async (e: any): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggingMediaItem(false)

    try {
      const target = e.target as HTMLElement
      const underMediaItem = target.closest(
        '[data-media-item-order]'
      ) as HTMLElement
      const underIdx = mediaItems?.findIndex(
        ({ id }: IMediaItem) => Number(underMediaItem.id) === id
      )

      const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
      img.src = draggingImgSrcRef.current

      if (draggingStartedIdxRef.current !== underIdx) {
        const newOrderNumber = mediaItems?.findIndex(
          ({ id }: IMediaItem) => Number(underMediaItem.id) === id
        )
        const mediaItem = mediaItems?.find(
          ({ id }: IMediaItem) =>
            Number(draggingMediaItemRef.current?.id) === id
        )
        const partnerId = storeCurrentPartnerId || getPartnerId()

        if (mediaItem && newOrderNumber !== undefined && partnerId) {
          const distributionId = Number(mediaItem.DistributionId)
          const mediaItemId = Number(mediaItem.id)
          const categoryId = Number(mediaItem.CategoryMediaItem.CategoryId)

          await MediaItemAPI.setOrderNumberForMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            categoryId,
            newOrderNumber + 1
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDragEndMediaItem = (e: any): void => {
    const img = draggingMediaItemRef.current?.firstChild as HTMLImageElement
    img.src = draggingImgSrcRef.current
    setIsDraggingMediaItem(false)
  }

  return (
    <div
      className={styles.categoryWrapper}
      data-category=""
      data-category-id={category.id}
      data-category-order={category.orderNumber}
      // onDragEnter={handleDragEnterCategory}
      // onDragLeave={handleDragLeaveCategory}
      // onDragOver={handleDragOverCategory}
      // onDrop={handleDropCategory}
    >
      <div className={styles.categoryTopBar} data-drag-zone="">
        <h5 className={styles.categoryName}>{category.name}</h5>
      </div>

      <div className={styles.category}>
        <ul className={styles.categorySlider} data-drag-list="">
          {allMediaItemsInCategory && allMediaItemsInCategory.length === 0 ? (
            <EmptyCategoryList count={7} withBorder />
          ) : (
            <>
              {mediaItems &&
                mediaItems
                  .filter((item: IMediaItem) => item !== undefined)
                  .map((mediaItem: IMediaItem) => (
                    <li
                      key={mediaItem.id}
                      id={String(mediaItem.id)}
                      className={styles.mediaItem}
                      data-media-item-order={
                        mediaItem.CategoryMediaItem.orderNumber
                      }
                      draggable="true"
                      onDragStart={handleDragStartMediaItem}
                      onDragEnd={handleDragEndMediaItem}
                      onDragEnter={handleDragEnterMediaItem}
                      onDrop={handleDropMediaItem}
                    >
                      <img
                        className={styles.mediaItemImg}
                        src={
                          mediaItem.Images && mediaItem.Images.length > 0
                            ? getMediaItemImageUrl(
                                mediaItem.Images,
                                'Small',
                                true
                              )
                            : defaultImgBgSrc
                        }
                        alt="video"
                      />

                      {mediaItem.status === 'failed' && (
                        <div className={styles.failedOverlay}>
                          <span className={styles.failedIconWrapper}>
                            <CloseOutlined className={styles.failedIcon} />
                          </span>
                        </div>
                      )}

                      {!mediaItem.Images && !isDraggingMediaItem && (
                        <h4 className={styles.nameWithoutImg}>
                          {mediaItem.name}
                        </h4>
                      )}

                      <>
                        {!isDraggingMediaItem && (
                          <>
                            <div className={styles.mediaItemControls}>
                              <Dropdown
                                placement="bottomLeft"
                                className={styles.mediaItemDropdown}
                                trigger={['click']}
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key="1"
                                      className={styles.mediaItemDropdownLink}
                                    >
                                      <Button
                                        className={styles.removeBtn}
                                        onClick={undefined}
                                      >
                                        Remove
                                      </Button>
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <HoverMediaItemControlsIcon />
                              </Dropdown>
                            </div>
                            <div className={styles.mediaItemTitleWrapper}>
                              <h4 className={styles.title}>{mediaItem.name}</h4>
                            </div>
                          </>
                        )}
                      </>
                    </li>
                  ))}
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

export default UrlRssCategory
