/* eslint no-bitwise: ["error", { "allow": ["|"] }] */

import { IImage } from 'models/mediaItem'
import { IUser } from 'models/user'
import { v4 as uuidv4 } from 'uuid'

export const rgbToHex = (r: number, g: number, b: number): string =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}` // eslint-disable-line no-bitwise

export const displayViewsCount = (views: number): string => {
  if (views === 0) {
    return '0'
  }

  if (views < 1000) {
    return String(views)
  }
  return `${Math.floor(views / 1000)}k`
}

export const displayViewsDashboardCount = (views: number): string => {
  if (views === 0) {
    return '0'
  }

  if (views < 10000) {
    return String(views)
  }
  return `${Math.floor(views / 1000)}k`
}

export const getPartnerId = (): number | null => {
  const userFromLS = localStorage.getItem('user')

  if (userFromLS) {
    const { partnerId }: IUser = JSON.parse(userFromLS)
    return partnerId
  }

  return null
}

export const redirectToURL = (urlPath: string): void => {
  window.location.assign(urlPath)
}

export const getVimeoItemId = (uri: string): string => {
  // const match = uri.match(/(\/vimeo\.com\/)([0-9]+)/i)
  // return match ? match[2] : ''
  const splitUri = uri.split('/')
  return splitUri[splitUri.length - 1]
}

export const getMediaItemImageUrl = (
  images: IImage[],
  size?: string,
  isDefaultImmSrc?: boolean
): string => {
  const smallImg: IImage | undefined = images.find(({ tag }) =>
    size ? tag === size : tag === 'Small'
  )

  // console.log(isDefaultImmSrc)

  if (isDefaultImmSrc) {
    return smallImg ? `${smallImg.url}` : ''
  }

  return smallImg ? `${smallImg.url}?uuidv4=${uuidv4()}` : ''
}

export const downloadFile = (name: string, url: string | null): void => {
  const a = document.createElement('a')
  a.href = url || ''
  a.download = name
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const generateRandomHexColor = (brightness: number): string => {
  const randomChannel = (brightnessValue: number): string => {
    const r = 255 - brightnessValue
    const n = 0 | (Math.random() * r + brightnessValue)
    const s = n.toString(16)
    return s.length === 1 ? `0${s}` : s
  }

  return `#${randomChannel(brightness)}${randomChannel(
    brightness
  )}${randomChannel(brightness)}`
}

export const countAverageValue = (values: number[]): number => {
  if (values.length === 0) {
    return 0
  }

  return (
    values.reduce(
      (previousValue: number, currentValue: number) =>
        previousValue + currentValue,
      0
    ) / values.length
  )
}

export const countSumValue = (values: number[]): number => {
  if (values.length === 0) {
    return 0
  }

  return values.reduce(
    (previousValue: number, currentValue: number) =>
      previousValue + currentValue,
    0
  )
}

export const countPercent = (
  loaded: number,
  total: number,
  isDone: boolean
): number => {
  const n = isDone ? 0 : 1
  return Math.floor(((loaded - n) / total) * 100)
}
