import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'

import { IPartner } from 'models/partner'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { IDistribution, IResponseDistributions } from 'models/distribution'
import { useActions } from 'store/hooks/useActions'
import { getPartnerId } from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import styles from './styles.module.scss'

const Setting: FC = () => {
  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>()

  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
  } = useActions()

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const { storeCurrentPartnerId, storeCurrentPartnerData } = useTypedSelector(
    state => state.partner
  )

  const path = useLocation().pathname

  const init = async (): Promise<void> => {
    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          if (storeCurrentDistributionId) {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {}
            )

            saveCurrentDistributionIdAction(storeCurrentDistributionId)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          } else {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributions[0].id,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(distributions[0].id)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          }

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
    // } finally {
    //   setIsLoading(false)
    // }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    try {
      // setIsLoading(true)
      const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

      if (partnerId) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          allDistributions[currentIndex].id,
          {}
        )

        saveCurrentDistributionIdAction(allDistributions[currentIndex].id)
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      // setIsLoading(true)
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )

          if (response.status === 'success') {
            setCurrentDistribution(() => response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    try {
      const changePartner = allPartners[currentIndex]

      saveCurrentPartnerIdAction(changePartner.id)
      setCurrentPartner(changePartner)
      saveCurrentPartnerDataAction(changePartner)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }
  }, [storeCurrentPartnerId])

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={allDistributions}
        setAllDistributions={setAllDistributions}
        onChangeDistribution={onChangeDistribution}
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={onChangePartner}
      />

      <div className={styles.mainContent}>
        <SideBar />

        <div className={styles.section}>
          <div className={styles.pageTitleWrapper}>
            <h3 className={styles.pageTitle}>Setting</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting
