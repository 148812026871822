import {
  IProgressBarState,
  IProgressBarAction,
  ProgressBarActionsTypes,
} from 'store/types/progressBar'

const initialState: IProgressBarState = {
  currentUploadSize: 0,
  totalUploadSize: 0,
  totalUploadFiles: 0,
  currentUploadFiles: 0,
  countFunctionCall: 0,
}

export const progressBarReducer = (
  state = initialState,
  action: IProgressBarAction
): IProgressBarState => {
  switch (action.type) {
    case ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_SIZE: {
      // setCurrentUploadSize(prevLoadedSize => {
      //   const newLoadedSize =
      //     prevLoadedSize - loadedSize + progressEvent.loaded
      //   return newLoadedSize
      // })

      const newCurrentUploadSize =
        state.currentUploadSize -
        action.payload.value +
        action.payload.progressLoaded

      // newCurrentUploadSize > state.totalUploadSize ||
      //   Number.isNaN(newCurrentUploadSize)
      //     ? state.totalUploadSize
      //     :

      return {
        ...state,
        currentUploadSize: newCurrentUploadSize,
      }
    }
    case ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_SIZE:
      return {
        ...state,
        totalUploadSize: state.totalUploadSize + action.payload,
      }
    case ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_FILES:
      return {
        ...state,
        totalUploadFiles: state.totalUploadFiles + action.payload,
      }
    case ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_FILES:
      return {
        ...state,
        currentUploadFiles: state.currentUploadFiles + action.payload,
      }

    case ProgressBarActionsTypes.CLEAR_CURRENT_UPLOAD_SIZE:
      return {
        ...state,
        currentUploadSize: action.payload,
      }
    case ProgressBarActionsTypes.CLEAR_TOTAL_UPLOAD_SIZE:
      return {
        ...state,
        totalUploadSize: action.payload,
      }
    case ProgressBarActionsTypes.INCREMENT_FUNCTION_CALL_COUNT:
      return {
        ...state,
        countFunctionCall: state.countFunctionCall + action.payload,
      }
    case ProgressBarActionsTypes.DECREMENT_FUNCTION_CALL_COUNT:
      return {
        ...state,
        countFunctionCall: state.countFunctionCall - action.payload,
      }
    case ProgressBarActionsTypes.RESET_FUNCTION_CALL_COUNT:
      return {
        ...state,
        countFunctionCall: action.payload,
      }

    default:
      return state
  }
}
