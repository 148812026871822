import { axiosInstance } from '.'

export const MediaPlaylistAPI = {
  getAllMediaPlaylistsForCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}/mediaplaylist`
    )

    return data
  },

  syncPlaylistToCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    bodyData: any
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}/mediaplaylist`,
      bodyData
    )

    return data
  },

  unSyncPlaylistToCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    mediaPlaylistId: string
  ): Promise<void> => {
    const { data } = await axiosInstance.delete(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}/mediaplaylist/${mediaPlaylistId}`
    )

    return data
  },
}
