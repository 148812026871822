import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Spin,
  Switch,
  Table,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Key } from 'antd/lib/table/interface'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'

import {
  displayViewsCount,
  getMediaItemImageUrl,
  getPartnerId,
} from 'utils/helper'

import { IDistribution, IResponseDistributions } from 'models/distribution'
import { IMediaItem } from 'models/mediaItem'

import { IPartner } from 'models/partner'

import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { useActions } from 'store/hooks/useActions'

import { ICategory } from 'models/category'

import { ReactComponent as SearchIcon } from 'sources/images/libary/search.svg'
import { ReactComponent as FiltersIcon } from 'sources/images/libary/filters.svg'
import { ReactComponent as ResetFiltersIcon } from 'sources/images/libary/reset-filters.svg'

import defaultImgBgSrc from 'sources/images/libary/default-img-bg.png'

import { ReactComponent as TableEyeIcon } from 'sources/images/libary/table-eye.svg'
import { ReactComponent as TableSourceYoutubeIcon } from 'sources/images/libary/table-source-youtube.svg'
import { ReactComponent as TableSourceVimeoIcon } from 'sources/images/libary/vimeo.svg'
import { ReactComponent as TableTVPreviewIcon } from 'sources/images/libary/tv-preview-table.svg'
import { ReactComponent as TableActionsIcon } from 'sources/images/libary/table-actions.svg'
import { ReactComponent as CloseSelectedIcon } from 'sources/images/libary/close-selected.svg'

import Filters from './Filters'

import styles from './styles.module.scss'

let defaultMediaItems: any = []
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />

// const timerId: NodeJS.Timeout | null = null
// const longDelay = 300000
// const shortDelay = 10000

const Libary: FC = () => {
  const location = useLocation()
  const locationState = location.state as any

  const [visibleFilters, setVisibleFilters] = useState(false)
  const [filters, setFilters] = useState<any>({})

  const [allCategories, setAllCategories] = useState<string[]>([])

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>(null)

  const [allMediaItems, setAllMediaItems] = useState<any>([])
  const [tableLoading, setTableLoading] = useState(false)

  const [filteredMediaItems, setFilteredMediaItems] = useState<any>([])
  const [isFiltered, setIsFiltered] = useState(false)

  const [mediaItemsIds, setMediaItemsIds] = useState<Key[] | []>([])

  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)

  const [isOpenMediaItemsControls, setIsOpenMediaItemsControls] =
    useState(false)

  const [isLongInterval, setIsLongInterval] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
  } = useActions()

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const { storeCurrentPartnerId, storeCurrentPartnerData } = useTypedSelector(
    state => state.partner
  )

  const path = useLocation().pathname

  const isHaveFilters = (): boolean => !!Object.keys(filters).length

  const init = async (): Promise<void> => {
    setTableLoading(true)
    const partnerId = storeCurrentPartnerId || getPartnerId()

    localStorage.setItem('redirectPath', path)

    if (partnerId) {
      const { distributions }: IResponseDistributions =
        await DistributionAPI.getDistributionsByPartner(partnerId, {})

      setAllDistributions(distributions)

      let currentMaxPlayed = 0
      let currentMinPlayed = 0

      if (distributions.length > 0) {
        let response: any = null

        if (storeCurrentDistributionId) {
          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            storeCurrentDistributionId,
            {}
          )

          const filteredCategories =
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )

          saveCurrentDistributionIdAction(storeCurrentDistributionId)
          saveCurrentDistributionDataAction(filteredCategories)

          setAllCategories(
            filteredCategories.map((categoryItem: any) => categoryItem.name)
          )
        } else {
          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          const filteredCategories =
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(filteredCategories)

          setAllCategories(
            filteredCategories.map((categoryItem: any) => categoryItem.name)
          )
        }

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])

          const responseMediaItems: any =
            await MediaItemAPI.getAllMediaItemsForDistribution(
              partnerId,
              response.distributions[0].id
            )

          responseMediaItems.mediaitems.forEach((element: any) => {
            if (element.played >= currentMaxPlayed) {
              currentMaxPlayed = element.played
            }

            if (element.played <= currentMinPlayed) {
              currentMinPlayed = element.played
            }
          })

          setMax(currentMaxPlayed)
          setMin(currentMinPlayed)

          // if (timerId) {
          //   clearInterval(timerId)
          // }

          // createInterval(
          //   responseMediaItems.mediaitems,
          //   response.distributions[0].id
          // )

          const updateAllMediaItemsImg = responseMediaItems.mediaitems.map(
            (item: any) => {
              const cloneItem = _.cloneDeep(item)
              const smallImgObj =
                cloneItem.Images.length > 0 &&
                cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

              if (smallImgObj) {
                smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
              }

              cloneItem.Images = [
                cloneItem.Images.filter(
                  (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                ),
                smallImgObj,
              ]

              return cloneItem
            }
          )

          setAllMediaItems(updateAllMediaItemsImg)
          setFilteredMediaItems(updateAllMediaItemsImg)

          defaultMediaItems = updateAllMediaItemsImg
        }
      }

      if (locationState && locationState.category) {
        const categoryFromContent = locationState.category

        setFilters({
          categories: [categoryFromContent],
          statuses: [],
          published: [],
          sources: [],
          range: [currentMinPlayed, currentMaxPlayed],
        })
      }

      setTableLoading(false)
    }
  }

  useEffect(() => {
    init()

    // setIsDefaultMediaItemImg(true)

    return () => {
      defaultMediaItems = []
    }
  }, [])

  // useEffect(() => {
  //   if (timerId) {
  //     clearInterval(timerId)
  //   }

  //   if (storeCurrentDistributionId) {
  //     createInterval(allMediaItems, storeCurrentDistributionId)
  //   }
  // }, [isLongInterval])

  useEffect(() => {
    if (isHaveFilters()) {
      const filteredMediaItemsByFields = defaultMediaItems.filter(
        (mediaItem: any) => {
          const allCategoriesMediaItemName = mediaItem.Categories.map(
            (item: any) => item.name
          )

          const filterStatus =
            (mediaItem.status === 'imported' && 'imported') ||
            (mediaItem.status === 'failed' && 'failed') ||
            'in progress'
          const source = mediaItem.externalType
          const published =
            mediaItem.published === true ? 'published' : 'unpublished'
          const views = mediaItem.played

          const minRange = filters.range[0]
          const maxRange = filters.range[1]

          // console.log('filters', filters)

          const checkCategory =
            filters.categories.length > 0
              ? _.intersection(allCategoriesMediaItemName, filters.categories)
                  .length > 0
              : undefined
          // console.log('allCategoriesMediaItemName', allCategoriesMediaItemName)
          // console.log('checkCategory', checkCategory)
          const checkStatus =
            filters.statuses.length > 0
              ? filters.statuses.includes(filterStatus)
              : undefined
          const checkSource =
            filters.sources.length > 0
              ? filters.sources.includes(source)
              : undefined
          const checkPublished =
            filters.published.length > 0
              ? filters.published.includes(published)
              : undefined
          const checkRange = views >= minRange && views <= maxRange

          const result = [
            checkCategory,
            checkStatus,
            checkSource,
            checkPublished,
            checkRange,
          ].filter((item: any) => item !== undefined)

          // console.log('result', result)

          return !result.some((item: boolean) => item === false)
        }
      )

      setAllMediaItems(filteredMediaItemsByFields)
    } else {
      setAllMediaItems(defaultMediaItems)
    }
  }, [filters])

  useEffect(() => {
    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()
    setTableLoading(true)

    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (partnerId) {
      const responseDistribution: any =
        await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          allDistributions[currentIndex].id,
          {}
        )

      saveCurrentDistributionIdAction(allDistributions[currentIndex].id)
      saveCurrentDistributionDataAction(
        responseDistribution.distributions[0].Categories.filter(
          (item: any) => item.name !== 'Orphan'
        )
      )

      if (responseDistribution.status === 'success') {
        setCurrentDistribution(responseDistribution.distributions[0])
        saveCurrentDistributionDataAction(
          responseDistribution.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        const responseMediaItems: any =
          await MediaItemAPI.getAllMediaItemsForDistribution(
            partnerId,
            responseDistribution.distributions[0].id
          )

        setAllCategories(
          responseDistribution.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          ).map((categoryItem: any) => categoryItem.name)
        )

        // if (timerId) {
        //   clearInterval(timerId)
        // }

        // createInterval(
        //   responseMediaItems.mediaitems,
        //   allDistributions[currentIndex].id
        // )

        setAllMediaItems(responseMediaItems.mediaitems)
        setFilteredMediaItems(responseMediaItems.mediaitems)
        setIsFiltered(false)
        setFilters({})

        defaultMediaItems = responseMediaItems.mediaitems
      }
    }

    setTableLoading(false)
  }

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      setTableLoading(true)
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )

          const responseMediaItems: any =
            await MediaItemAPI.getAllMediaItemsForDistribution(
              partnerId,
              response.distributions[0].id
            )

          setAllCategories(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            ).map((categoryItem: any) => categoryItem.name)
          )

          // if (timerId) {
          //   clearInterval(timerId)
          // }

          // createInterval(
          //   responseMediaItems.mediaitems,
          //   allDistributions[currentIndex].id
          // )

          setAllMediaItems(responseMediaItems.mediaitems)
          setFilteredMediaItems(responseMediaItems.mediaitems)
          setIsFiltered(false)
          setFilters({})

          defaultMediaItems = responseMediaItems.mediaitems

          if (response.status === 'success') {
            setCurrentDistribution(() => response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    try {
      const changePartner = allPartners[currentIndex]

      saveCurrentPartnerIdAction(changePartner.id)
      setCurrentPartner(changePartner)
      saveCurrentPartnerDataAction(changePartner)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }

    // localStorage.setItem('currentPartner', JSON.stringify(currentPartner?.id))
  }, [storeCurrentPartnerId])

  const onDeleteMediaItem = async (
    mediaItemId: number,
    distributionId: number
  ): Promise<void> => {
    setTableLoading(true)

    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId) {
        await MediaItemAPI.deleteMediaItemFromDistribution(
          partnerId,
          distributionId,
          mediaItemId
        )

        defaultMediaItems = defaultMediaItems.filter(
          (mediaItem: IMediaItem) => mediaItem.id !== mediaItemId
        )

        setAllMediaItems((oldMediaItems: any) =>
          oldMediaItems.filter(
            (mediaItem: IMediaItem) => mediaItem.id !== mediaItemId
          )
        )

        message.success('Video was deleted!', 3)
        setIsFiltered(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onDeleteSelectedMediaItems = async (
    selectedIds: any
  ): Promise<void> => {
    setTableLoading(true)

    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id
        const promiseArr = selectedIds.map((mediaItemId: any) =>
          MediaItemAPI.deleteMediaItemFromDistribution(
            partnerId,
            distributionId,
            mediaItemId
          )
        )

        const responses = await Promise.allSettled(promiseArr)

        defaultMediaItems = defaultMediaItems.filter(
          (mediaItem: IMediaItem) => !selectedIds.includes(mediaItem.id)
        )

        setAllMediaItems((prevMediaItems: any) =>
          prevMediaItems.filter(
            (mediaItem: IMediaItem) => !selectedIds.includes(mediaItem.id)
          )
        )

        message.success('Videos was deleted!', 3)
        setMediaItemsIds([])
        setIsFiltered(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onChangePublishedMediaItem = async (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    setTableLoading(true)

    const target = e.target as HTMLElement
    const row = target.closest('[data-row-key]') as HTMLElement
    const mediaItemId = row && Number(row.dataset.rowKey)

    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id
        const cloneAllMediaItems = _.cloneDeep(allMediaItems)

        const updateMediaItem = cloneAllMediaItems.find(
          (mediaItem: IMediaItem) => mediaItem.id === mediaItemId
        )

        if (checked) {
          updateMediaItem.published = true
          const response: any = MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            updateMediaItem
          )

          const updatedAllMediaItems = cloneAllMediaItems.map(
            (mediaItem: IMediaItem) => {
              if (mediaItem.id === mediaItemId) {
                const currentMediaItem = _.cloneDeep(mediaItem)
                currentMediaItem.published = true
                return currentMediaItem
              }
              return mediaItem
            }
          )

          defaultMediaItems = defaultMediaItems.map((mediaItem: IMediaItem) => {
            if (mediaItem.id === mediaItemId) {
              const currentMediaItem = _.cloneDeep(mediaItem)
              currentMediaItem.published = true
              return currentMediaItem
            }
            return mediaItem
          })

          setAllMediaItems(updatedAllMediaItems)

          message.success(`Videos ${updateMediaItem.name} was published!`, 3)
        } else {
          updateMediaItem.published = false
          const response: any = MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            updateMediaItem
          )

          const updatedAllMediaItems = cloneAllMediaItems.map(
            (mediaItem: IMediaItem) => {
              if (mediaItem.id === mediaItemId) {
                const currentMediaItem = _.cloneDeep(mediaItem)
                currentMediaItem.published = false
                return currentMediaItem
              }
              return mediaItem
            }
          )

          defaultMediaItems = defaultMediaItems.map((mediaItem: IMediaItem) => {
            if (mediaItem.id === mediaItemId) {
              const currentMediaItem = _.cloneDeep(mediaItem)
              currentMediaItem.published = false
              return currentMediaItem
            }
            return mediaItem
          })

          setAllMediaItems(updatedAllMediaItems)

          message.success(`Videos ${updateMediaItem.name} was unpublished!`, 3)
        }

        setTimeout(() => {
          setTableLoading(false)
        }, 400)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onDropdownMenuChangePublishedMediaItem = async (
    clickedDropdownMenuMediaItem: IMediaItem
  ): Promise<void> => {
    setTableLoading(true)
    const mediaItemId = Number(clickedDropdownMenuMediaItem.id)

    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id
        const cloneAllMediaItems = _.cloneDeep(allMediaItems)

        const updateMediaItem = cloneAllMediaItems.find(
          (mediaItem: IMediaItem) => mediaItem.id === mediaItemId
        )

        if (!clickedDropdownMenuMediaItem.published) {
          updateMediaItem.published = true
          const response: any = MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            updateMediaItem
          )

          defaultMediaItems = defaultMediaItems.map((mediaItem: IMediaItem) => {
            if (mediaItem.id === mediaItemId) {
              const currentMediaItem = _.cloneDeep(mediaItem)
              currentMediaItem.published = true
              return currentMediaItem
            }
            return mediaItem
          })

          setAllMediaItems(defaultMediaItems)

          message.success(`Videos ${updateMediaItem.name} was published!`, 3)
        } else {
          updateMediaItem.published = false
          const response: any = MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItemId,
            updateMediaItem
          )

          defaultMediaItems = defaultMediaItems.map((mediaItem: IMediaItem) => {
            if (mediaItem.id === mediaItemId) {
              const currentMediaItem = _.cloneDeep(mediaItem)
              currentMediaItem.published = false
              return currentMediaItem
            }
            return mediaItem
          })

          setAllMediaItems(defaultMediaItems)

          message.success(`Videos ${updateMediaItem.name} was unpublished!`, 3)
        }

        setTimeout(() => {
          setTableLoading(false)
        }, 400)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onUnpublishedSelectedMediaItems = async (
    selectedIds: any
  ): Promise<void> => {
    setTableLoading(true)

    try {
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id
        const filteredMediaItemsFromCategory = allMediaItems.filter(
          (mediaItem: IMediaItem) => selectedIds.includes(mediaItem.id)
        )

        const updatedMediaItems = filteredMediaItemsFromCategory.map(
          (mediaItem: IMediaItem) => {
            const cloneMediaItem = _.cloneDeep(mediaItem)
            cloneMediaItem.published = false
            return cloneMediaItem
          }
        )

        const promiseArr = updatedMediaItems.map((mediaItem: IMediaItem) =>
          MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItem.id,
            mediaItem
          )
        )

        const responses = await Promise.allSettled(promiseArr)

        defaultMediaItems = defaultMediaItems.map((mediaItem: IMediaItem) => {
          if (selectedIds.includes(mediaItem.id)) {
            const cloneMediaItem = _.cloneDeep(mediaItem)
            cloneMediaItem.published = false
            return cloneMediaItem
          }

          return mediaItem
        })

        setAllMediaItems(
          allMediaItems.map((mediaItem: IMediaItem) => {
            if (selectedIds.includes(mediaItem.id)) {
              const cloneMediaItem = _.cloneDeep(mediaItem)
              cloneMediaItem.published = false
              return cloneMediaItem
            }

            return mediaItem
          })
        )

        message.success('Videos was unpublished!', 3)

        setMediaItemsIds([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTableLoading(false)
    }
  }

  const onShowFilters = (): void => {
    setVisibleFilters(true)
  }

  const onCloseFilters = (): void => {
    setVisibleFilters(false)
  }

  const onResetAllFilters = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setFilters({})
  }

  const onSetFilters = (applyFilters: any): void => {
    setFilters({ ...applyFilters })
    onCloseFilters()
  }

  const onSelectMediaItemsIds = (id: Key[]): void => {
    setMediaItemsIds([...id])
    setIsOpenMediaItemsControls(true)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'img',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <img
          data-image=""
          src={
            data.Images.length > 0
              ? getMediaItemImageUrl(data.Images, 'Small', true)
              : defaultImgBgSrc
          }
          alt="table img"
          className={styles.tableImg}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: uuidv4(),
    },
    {
      title: 'Categories',
      dataIndex: 'category',
      key: uuidv4(),
      sorted: true,
      render: (record: any, data: any) => (
        <span>
          {data.Categories.map(
            (item: any, idx: number) =>
              `${item.name}${idx !== data.Categories.length - 1 ? ', ' : ''} `
          )}
        </span>
      ),
    },
    {
      title: 'Views',
      dataIndex: 'played',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <span className={styles.views}>
          {displayViewsCount(data.played)} <TableEyeIcon />
        </span>
      ),
    },
    {
      title: 'Create Date',
      dataIndex: 'createdAt',
      key: uuidv4(),
      render: (record: any, data: any) =>
        dayjs(data.createdAt).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: uuidv4(),
      render: (record: any, data: any) =>
        data.status === 'imported' || data.status === 'failed' ? (
          data.status
        ) : (
          <Spin
            indicator={antIcon}
            className={styles.mediaItemStatus}
            size="large"
          />
        ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <span className={styles.source}>
          {data.externalType &&
            data.externalType.toLowerCase() === 'youtube' && (
              <TableSourceYoutubeIcon />
            )}

          {data.externalType && data.externalType.toLowerCase() === 'vimeo' && (
            <TableSourceVimeoIcon />
          )}

          {data.externalType && data.externalType.toLowerCase() === 'mp4' && (
            <TableTVPreviewIcon className={styles.tableTVPreview} />
          )}

          {data.externalType === 'mp4' ? 'computer' : data.externalType}
        </span>
      ),
    },
    {
      title: 'Published',
      dataIndex: 'published',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <Switch
          id={data.id}
          className={styles.tableSwitch}
          defaultChecked={record}
          onChange={onChangePublishedMediaItem}
        />
      ),
    },
    {
      title: 'Actions',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <Dropdown
          className={styles.controlsActionsDropdown}
          placement="bottomRight"
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key="1" className={styles.controlsActionsDropdownLink}>
                <Link
                  className={styles.controlsActionsBtn}
                  to={`/libary/${data.DistributionId}/video-properties/${data.id}`}
                  state={{
                    distribution: {
                      name: currentDistribution?.name,
                      id: currentDistribution?.id,
                    },
                  }}
                >
                  Video properties
                </Link>
              </Menu.Item>
              {/* <Menu.Item key="2" className={styles.controlsActionsDropdownLink}>
                <Link
                  className={styles.controlsActionsBtn}
                  to={`/libary/${data.DistributionId}/edit-video/${data.id}`}
                >
                  Edit video
                </Link>
              </Menu.Item> */}
              <Menu.Item key="3" className={styles.controlsActionsDropdownLink}>
                <Button
                  className={styles.controlsActionsBtn}
                  onClick={() =>
                    onDeleteMediaItem(data.id, data.DistributionId)
                  }
                >
                  Delete
                </Button>
              </Menu.Item>
              <Menu.Item key="4" className={styles.controlsActionsDropdownLink}>
                <a className={styles.controlsActionsBtn} href="/content">
                  Play video
                </a>
              </Menu.Item>
              <Menu.Item key="5" className={styles.controlsActionsDropdownLink}>
                <Button
                  className={styles.controlsActionsBtn}
                  onClick={() => {
                    onDropdownMenuChangePublishedMediaItem(data)
                  }}
                >
                  {data.published ? 'Unpublished' : 'Published'}
                </Button>
              </Menu.Item>
              <Menu.Item key="6" className={styles.controlsActionsDropdownLink}>
                <a className={styles.controlsActionsBtn} href="/content">
                  Analistics
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <TableActionsIcon />
        </Dropdown>
      ),
    },
  ]

  const onSearchMediaItems = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setIsFiltered(true)

    if (value === '') {
      setIsFiltered(false)
      setFilteredMediaItems(allMediaItems)
    }

    setFilteredMediaItems(
      allMediaItems.filter(
        ({ name, description }: IMediaItem) =>
          (name && name.toLowerCase().includes(value.toLowerCase())) ||
          (description &&
            description.toLowerCase().includes(value.toLowerCase()))
      )
    )
  }

  const onCloseControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(false)
  }

  return (
    <>
      <div className={styles.mainWrapper}>
        <TopBar
          isLogged
          distributions={allDistributions}
          setAllDistributions={setAllDistributions}
          onChangeDistribution={onChangeDistribution}
          allPartners={allPartners}
          setAllPartners={setAllPartners}
          setIsSuperAdmin={setIsSuperAdmin}
          isSuperAdmin={isSuperAdmin}
          onChangePartner={onChangePartner}
        />

        <div className={styles.mainContent}>
          <SideBar />

          <div className={styles.section}>
            <div className={styles.pageTitleWrapper}>
              <h3 className={styles.pageTitle}>Libary</h3>

              <Button
                className={styles.titleBtn}
                shape="round"
                icon={<SearchIcon />}
              >
                <Input
                  placeholder="Search"
                  className={styles.searchField}
                  onChange={onSearchMediaItems}
                />
              </Button>
              <Button
                className={cn(styles.titleBtn, {
                  [styles.haveFilters]: isHaveFilters(),
                })}
                shape="round"
                icon={<FiltersIcon />}
                onClick={onShowFilters}
              >
                Filter
                {isHaveFilters() && (
                  <Button
                    className={styles.resetFiltersBtn}
                    icon={<ResetFiltersIcon />}
                    shape="circle"
                    type="primary"
                    size="small"
                    onClick={onResetAllFilters}
                  />
                )}
              </Button>
            </div>
            <Table
              rowKey="id"
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: mediaItemsIds,
                onChange: onSelectMediaItemsIds,
              }}
              className={styles.table}
              dataSource={isFiltered ? filteredMediaItems : allMediaItems}
              columns={columns}
              pagination={false}
              loading={tableLoading}
            />
          </div>
        </div>

        <Filters
          onCloseFilters={onCloseFilters}
          visibleFilters={visibleFilters}
          onSetFilters={onSetFilters}
          allCategories={allCategories}
          min={min}
          max={max}
          allFilters={filters}
          categoryFromContent={
            locationState ? locationState.category : undefined
          }
        />
      </div>
      {isOpenMediaItemsControls && mediaItemsIds.length > 0 && (
        <div className={styles.bottomSelectedControls}>
          <Button
            className={styles.selectedCloseBtn}
            icon={<CloseSelectedIcon />}
            onClick={onCloseControlsSelectedMediaItems}
          />
          <span className={styles.selectedCount}>
            {mediaItemsIds.length} Selected
          </span>
          <Button
            shape="round"
            className={styles.selectedBtn}
            onClick={() => onDeleteSelectedMediaItems(mediaItemsIds)}
          >
            Delete
          </Button>
          <Button
            shape="round"
            className={styles.selectedBtn}
            onClick={() => onUnpublishedSelectedMediaItems(mediaItemsIds)}
          >
            Unpublish
          </Button>
        </div>
      )}
    </>
  )
}

export default Libary
