import React, { FC, useEffect, useState } from 'react'

import { Checkbox, Pagination } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import circleStatusSrc from 'sources/images/content/circle-status.png'
import doneSrc from 'sources/images/content/done.png'

import { getVimeoItemId } from 'utils/helper'

import styles from './styles.module.scss'

interface IProps {
  uploadVimeoMediaItems: any
  allMediaItemsInCategory: any
  addedMediaItems: []
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  onSelectAllUploadMediaItem: (e: CheckboxChangeEvent) => void
  selectedUploadMediaItemsId: string[]
  isFiltered: boolean
  filteredMediaItems: any
  initCategoryMediaItems: null | []
  allUnImportedMediaItems: any
  renderMediaItems: any
  scrollHandler: any
  fetching: boolean
  uniqVimeoVideosWithoutShowcase: any

  draggingUploadMediaItemRef: any
  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
}

const Videos: FC<IProps> = ({
  uploadVimeoMediaItems,
  allMediaItemsInCategory,
  addedMediaItems,
  onSelectUploadMediaItem,
  onSelectAllUploadMediaItem,
  selectedUploadMediaItemsId,
  isFiltered,
  filteredMediaItems,
  initCategoryMediaItems,
  allUnImportedMediaItems,
  renderMediaItems,
  scrollHandler,
  fetching,
  uniqVimeoVideosWithoutShowcase,

  draggingUploadMediaItemRef,
  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
}) => {
  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const allMediaItemsFromStore = storeCurrentDistributionData
    .map((item: any) => item.MediaItems)
    .flat()

  const importedInThisCategoryMediaItems = uploadVimeoMediaItems.filter(
    (uploadItem: any) =>
      allMediaItemsInCategory.find(
        (categoryItem: any) => uploadItem.link === categoryItem.sourceUrl
      )
  )

  // const [total, setTotal] = useState(0)
  // const [page, setPage] = useState(1)
  // const [mediaItemPerPage, setMediaItemPerPage] = useState(15)

  // const indexOfLastPage = page * mediaItemPerPage
  // const indexOfFirstPage = indexOfLastPage - mediaItemPerPage
  // const currentMediaItems = isFiltered
  //   ? filteredMediaItems.slice(indexOfFirstPage, indexOfLastPage)
  //   : uploadVimeoMediaItems.slice(indexOfFirstPage, indexOfLastPage)

  // const init = (): void => {
  //   setTotal(
  //     isFiltered ? filteredMediaItems.length : uploadVimeoMediaItems.length
  //   )
  //   setPage(1)
  // }

  // useEffect(() => {
  //   init()
  // }, [filteredMediaItems])

  return (
    <>
      <ul className={styles.videosWrapper}>
        {renderMediaItems.map((mediaItem: any) => {
          const uid = getVimeoItemId(mediaItem.uri)

          return (
            <li
              key={uuidv4()}
              data-upload-item={uid}
              id={uid}
              draggable
              onDragStart={handleDragStartUploadMediaItems}
              onDragEnter={handleDragEnterUploadMediaItems}
              onDragEnd={handleDragEndUploadMediaItems}
              onDrop={handleDragDropUploadMediaItems}
              className={cn(styles.uploadMediaItem, {
                [styles.hasInCategory]:
                  _.find(allMediaItemsInCategory, {
                    uri: mediaItem.uri,
                  }) ||
                  allMediaItemsInCategory.find((item: any) => {
                    const vimeoId = getVimeoItemId(mediaItem.uri)

                    return vimeoId === item.externalId
                  }),
                [styles.disabled]: _.find(addedMediaItems, {
                  uri: mediaItem.uri,
                }),
                [styles.selectedUploadMediaItem]:
                  selectedUploadMediaItemsId.includes(String(uid)),
              })}
            >
              <img
                className={styles.mediaItemImg}
                src={mediaItem.pictures.base_link}
                alt="video"
              />

              {_.find(allMediaItemsInCategory, { uri: mediaItem.uri }) && (
                <div className={styles.overlay} draggable={false}>
                  <img src={circleStatusSrc} alt="status" draggable={false} />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}

              {!_.find(allUnImportedMediaItems, { link: mediaItem.link }) &&
                !_.find(allMediaItemsInCategory, { uri: mediaItem.uri }) &&
                allMediaItemsFromStore.find(
                  (storeItem: any) => storeItem.externalId === String(uid)
                ) &&
                !initCategoryMediaItems?.find(
                  (initItem: any) => initItem.externalId === String(uid)
                ) && (
                  <div
                    className={styles.importedInAnotherCategory}
                    draggable={false}
                  >
                    <img
                      src={circleStatusSrc}
                      alt="status"
                      draggable={false}
                      className={styles.circle}
                    />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

              {initCategoryMediaItems?.some(
                (initItem: any) => initItem.externalId === String(uid)
              ) && (
                <div className={styles.overlay} draggable={false}>
                  <img src={circleStatusSrc} alt="status" draggable={false} />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}
              <>
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(String(uid))}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.name}</h4>
                  </div>
                </>
              </>
            </li>
          )
        })}

        {/* {fetching && <Spin size="large" />} */}
      </ul>
      {/* <Pagination
        onChange={value => setPage(value)}
        pageSize={mediaItemPerPage}
        total={total}
        current={page}
        className={styles.pagination}
        showSizeChanger={false}
        showQuickJumper={false}
      /> */}
    </>
  )
}

export default Videos
