import {
  IPartnerAction,
  IPartnerState,
  PartnerActionsTypes,
} from 'store/types/partner'

const initialState: IPartnerState = {
  storeCurrentPartnerId: null,
  storeCurrentPartnerData: null,
  storeAllPartnersData: null,
}

export const partnerReducer = (
  state = initialState,
  action: IPartnerAction
): IPartnerState => {
  switch (action.type) {
    case PartnerActionsTypes.SAVE_SUPER_PARTNER_ID:
      return { ...state, storeCurrentPartnerId: action.payload }
    case PartnerActionsTypes.SAVE_SUPER_PARTNER_DATA:
      return { ...state, storeCurrentPartnerData: action.payload }
    case PartnerActionsTypes.SAVE_SUPER_ALL_PARTNERS_DATA:
      return { ...state, storeAllPartnersData: action.payload }
    case PartnerActionsTypes.CLEAR_SUPER_PARTNER:
      return { ...state, ...action.payload }

    default:
      return state
  }
}
