import { axiosInstance } from '.'

export const DeviceAPI = {
  getPlaybackMediaItemUrl: async (
    distributionId: number,
    mediaItemId: number
  ): Promise<void> => {
    const bodyData = {
      context: {
        AuthToken: '',
        ClientIP: '',
        CultureName: '',
        DeviceType: '',
        DistrID: distributionId,
        SerialNumber: '',
        TextDir: '',
      },
      request: {
        ContentID: mediaItemId,
        IsPreview: false,
        Delivery: 'Streaming',
      },
    }

    const { data } = await axiosInstance.post(
      '/API/2.3/DeviceAPI.asmx/GetPlaybackInfo',
      bodyData
    )
    return data
  },
}
