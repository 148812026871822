import React, { createContext, FC, useEffect, useState } from 'react'
import {
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
  useNavigate,
} from 'react-router-dom'

import history from 'utils/history'
import { RequireAuth } from 'utils/hoc/RequireAuth'
import { AuthProvider } from 'utils/hoc/AuthProvider'

import Login from 'containers/Login'
import Register from 'containers/Register'
import Dashboard from 'containers/Dashboard'
import Content from 'containers/Content'
import Branding from 'containers/Branding'
import Libary from 'containers/Libary'
import Platform from 'containers/Platform'
import LiveChannel from 'containers/LiveChannel'
import Report from 'containers/Report'
import Setting from 'containers/Setting'
import AddVideos from 'containers/AddVideos'
import VideoProperties from 'containers/VideoProperties'
import RedirectVimeo from 'components/RedirectVimeo'
import ProgressBar from 'components/ProgressBar'
import LogOutModal from 'components/LogOutModal'

import { useActions } from 'store/hooks/useActions'

export const ProgressBarContext = createContext({
  totalUploadFiles: 0,
  currentUploadFiles: 0,
} as any)

const App: FC = () => {
  const { saveCurrentDistributionIdAction, saveCurrentPartnerIdAction } =
    useActions()

  const [isDoneUploadComputerMediaItems, setIsDoneUploadComputerMediaItems] =
    useState(false)

  const [totalUploadFiles, setTotalUploadFiles] = useState(0)
  const [currentUploadFiles, setCurrentUploadFiles] = useState(0)

  const [isDoneUploadVimeoMediaItems, setIsDoneUploadVimeoMediaItems] =
    useState(false)
  const [isDoneUploadYoutubeMediaItems, setsDoneUploadYoutubeMediaItems] =
    useState(false)

  const [isActiveProgressBar, setIsActiveProgressBar] = useState(false)
  const [isOpenLogOutModal, setIsOpenLogOutModal] = useState(false)
  const [abortControllers, setAbortControllers] = useState<
    [] | AbortController[]
  >([])

  useEffect(() => {
    const currentDistributionLS = localStorage.getItem('currentDistribution')
    const currentPartnerLS = localStorage.getItem('currentPartner')

    if (currentDistributionLS && currentDistributionLS !== 'undefined') {
      saveCurrentDistributionIdAction(Number(currentDistributionLS))
    }

    if (currentPartnerLS && currentPartnerLS !== 'undefined') {
      saveCurrentPartnerIdAction(Number(currentPartnerLS))
    }
  }, [])

  return (
    <>
      <AuthProvider>
        <HistoryRouter history={history}>
          <ProgressBarContext.Provider
            value={{
              totalUploadFiles,
              currentUploadFiles,
              setTotalUploadFiles,
              setCurrentUploadFiles,
              isActiveProgressBar,
              setIsOpenLogOutModal,
              setAbortControllers,
              abortControllers,
            }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              <Route
                path="/dashboard"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/content"
                element={
                  <RequireAuth>
                    <Content
                      isDoneUploadComputerMediaItems={
                        isDoneUploadComputerMediaItems
                      }
                      isDoneUploadVimeoMediaItems={isDoneUploadVimeoMediaItems}
                      isDoneUploadYoutubeMediaItems={
                        isDoneUploadYoutubeMediaItems
                      }
                      setIsDoneUploadComputerMediaItems={
                        setIsDoneUploadComputerMediaItems
                      }
                      setIsDoneUploadVimeoMediaItems={
                        setIsDoneUploadVimeoMediaItems
                      }
                      setsDoneUploadYoutubeMediaItems={
                        setsDoneUploadYoutubeMediaItems
                      }
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/content/:distributionId/add-videos/:id/*"
                element={
                  <RequireAuth>
                    <AddVideos
                      setIsDoneUploadComputerMediaItems={
                        setIsDoneUploadComputerMediaItems
                      }
                      setIsDoneUploadVimeoMediaItems={
                        setIsDoneUploadVimeoMediaItems
                      }
                      setsDoneUploadYoutubeMediaItems={
                        setsDoneUploadYoutubeMediaItems
                      }
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/branding"
                element={
                  <RequireAuth>
                    <Branding />
                  </RequireAuth>
                }
              />
              <Route
                path="/libary"
                element={
                  <RequireAuth>
                    <Libary />
                  </RequireAuth>
                }
              />

              <Route
                path="/libary/:distributionId/video-properties/:id"
                element={
                  <RequireAuth>
                    <VideoProperties isEdit />
                  </RequireAuth>
                }
              />

              <Route
                path="/platform"
                element={
                  <RequireAuth>
                    <Platform />
                  </RequireAuth>
                }
              />
              <Route
                path="/liveChannel"
                element={
                  <RequireAuth>
                    <LiveChannel />
                  </RequireAuth>
                }
              />
              <Route
                path="/report"
                element={
                  <RequireAuth>
                    <Report />
                  </RequireAuth>
                }
              />
              <Route
                path="/setting"
                element={
                  <RequireAuth>
                    <Setting />
                  </RequireAuth>
                }
              />

              <Route path="/redirect/vimeo" element={<RedirectVimeo />} />

              <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
          </ProgressBarContext.Provider>
        </HistoryRouter>
      </AuthProvider>

      {totalUploadFiles > 0 && (
        <ProgressBar
          currentUploadFiles={currentUploadFiles}
          totalUploadFiles={totalUploadFiles}
          setIsActiveProgressBar={setIsActiveProgressBar}
          setCurrentUploadFiles={setCurrentUploadFiles}
          setTotalUploadFiles={setTotalUploadFiles}
          isActiveProgressBar={isActiveProgressBar}
          abortControllers={abortControllers}
          setAbortControllers={setAbortControllers}
        />
      )}

      <LogOutModal
        isOpenLogOutModal={isOpenLogOutModal}
        setIsOpenLogOutModal={setIsOpenLogOutModal}
        setTotalUploadFiles={setTotalUploadFiles}
        setCurrentUploadFiles={setCurrentUploadFiles}
        abortControllers={abortControllers}
        setAbortControllers={setAbortControllers}
      />
    </>
  )
}

export default App
