import axios from 'axios'
import { message } from 'antd'

import { config } from 'config'
import history from 'utils/history'

export const axiosInstance = axios.create({
  baseURL: `${config.HOST}:${config.VIDEO_COLLECTION_PORT}`,
  validateStatus: status => {
    if (status === 401) {
      localStorage.clear()
      history.push('/login')
      return false
    }

    return true
  },
})

axiosInstance.interceptors.request.use(request => {
  const userFromLS = localStorage.getItem('user')

  if (userFromLS) {
    const { token } = JSON.parse(userFromLS)

    if (request.headers) {
      request.headers.Authorization = `Bearer ${token}`
    }
  }

  return request
})

axiosInstance.interceptors.response.use(
  response => {
    if (response.data.status !== 'ERROR') {
      if (
        response.data.name === 'JsonWebTokenError' ||
        response.data.name === 'TokenExpiredError'
      ) {
        message.error(response.data.message, 3)
        localStorage.clear()
        history.push('/login')
      }
    } else {
      message.error(response.data.message, 3)
    }

    return response
  },
  error => Promise.reject(error)
)
