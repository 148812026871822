import React, { FC, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { message, Spin } from 'antd'

import { VideoSyncAPI } from 'api/VideoSync/VideoSync'

const RedirectVimeo: FC = () => {
  const location: any = useLocation()
  const [params, setParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)
  const code = params.get('code')
  const state = params.get('state')
  const error = params.get('error')

  const init = async (): Promise<void> => {
    setIsLoading(true)

    const response: any = await VideoSyncAPI.vimeoAuthCallback(location.search)

    if (response.status === 'success') {
      setIsLoading(false)
      message.success('Connection was successful!', 3)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return <Spin size="large" />
}

export default RedirectVimeo
