import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button, message, Spin, Upload } from 'antd'
import axios from 'axios'
import { RcFile } from 'antd/lib/upload'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import { ProgressBarContext } from 'containers/App'

import { ICategory, IResponseCategories } from 'models/category'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { CategoryAPI } from 'api/VideoCollection/Category'
import { IMediaItem } from 'models/mediaItem'

import { useActions } from 'store/hooks/useActions'

import { getPartnerId } from 'utils/helper'

import ComputerCategory from 'components/AddVideos/ComputerCategory'
import AddVideosTopBar from 'components/AddVideos/AddVideosTopBar'
import DefaultSideBar from 'components/AddVideos/DefaultSideBar'

import UploadMediaItems from './UploadMediaItems'

import styles from './styles.module.scss'

const stepForScrollMediaItems = 50

interface IProps {
  setIsDoneUploadComputerMediaItems: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

let inComputerCategory = false
let uploadFilesTimerId: NodeJS.Timeout | null = null
let countSaveFunctionCall = 0

export const resetCountSaveFunctionCall = (): void => {
  countSaveFunctionCall = 0
}

const Computer: FC<IProps> = ({ setIsDoneUploadComputerMediaItems }) => {
  const {
    setCurrentUploadFiles,
    setTotalUploadFiles,
    totalUploadFiles,
    currentUploadFiles,
    setAbortControllers,
  } = useContext(ProgressBarContext)

  const navigate = useNavigate()

  const {
    changeProgressBarUploadSizeAction,
    changeProgressBarUploadTotalSizeAction,
  } = useActions()

  const { id, distributionId } = useParams()
  const [category, setCategory] = useState<ICategory | null>(null)
  const [allMediaItemsInCategory, setAllMediaItemsInCategory] = useState<any>(
    []
  )

  const [uploadMediaItems, setUploadMediaItems] = useState<any>([])
  const [selectedUploadMediaItemsId, setSelectedUploadMediaItemsId] = useState<
    string[]
  >([])
  const [addedMediaItemIds, setAddedMediaItemIds] = useState<string[]>([])

  const [addedMediaItems, setAddedMediaItems] = useState<RcFile[] | []>([])
  const [isOpenMediaItemsControls, setIsOpenMediaItemsControls] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [filteredMediaItems, setFilteredMediaItems] = useState<any>([])
  const [isFiltered, setIsFiltered] = useState(false)

  const [failedMediaItems, setFailedMediaItems] = useState<any>([])

  const [allMediaItemsFromComputer, setAllMediaItemsFromComputer] =
    useState<any>([])

  const [initCategoryMediaItems, setInitCategoryMediaItems] = useState<
    null | []
  >(null)

  const [isChangeOrder, setIsChangeOrder] = useState(false)

  const { storeCurrentDistributionData, storeCurrentDistributionId } =
    useTypedSelector(state => state.distribution)
  const { userInfo } = useTypedSelector(state => state.user)
  const [
    countAllMediaItemsInDistribution,
    setCountAllMediaItemsInDistribution,
  ] = useState(0)

  // scroll
  const [renderMediaItems, setRenderMediaItems] = useState<any>([])
  const [fetching, setFetching] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  // drag and drop uploaded videos
  const draggingUploadMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingMediaItemRef = useRef<HTMLElement | null>(null)

  const { saveCurrentDistributionDataAction } = useActions()

  const path = useLocation().pathname

  const scrollHandler = (e: any): void => {
    const target = e.target as HTMLElement
    const isVideosTab = target.dataset.tab === 'videos'

    if (
      isVideosTab &&
      target.scrollHeight - (target.scrollTop + target.offsetHeight) < 100 &&
      renderMediaItems.length < filteredMediaItems.length
    ) {
      setFetching(true)
    }
  }

  useEffect(() => {
    if (fetching) {
      try {
        const startIndex = stepForScrollMediaItems * currentPage
        const endIndex = startIndex + stepForScrollMediaItems

        const sliceMediaItems = filteredMediaItems.slice(startIndex, endIndex)
        const sliceForRenderMediaItems = [
          ...renderMediaItems,
          ...sliceMediaItems,
        ]

        setRenderMediaItems(sliceForRenderMediaItems)
        setCurrentPage(prevPage => prevPage + 1)
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    }
  }, [fetching])

  useEffect(() => {
    if (isFiltered) {
      const sliceMediaItems = filteredMediaItems.slice(
        0,
        stepForScrollMediaItems
      )

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    } else {
      const sliceMediaItems = uploadMediaItems.slice(0, stepForScrollMediaItems)

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    }
  }, [filteredMediaItems])

  const init = async (): Promise<void> => {
    try {
      setIsDoneUploadComputerMediaItems(false)
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)
      let currentDistributionData: any = null

      if (storeCurrentDistributionData) {
        const storeCategory = storeCurrentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }
      } else if (partnerId && storeCurrentDistributionId) {
        const responseCurrentDistributionData: any =
          await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            storeCurrentDistributionId,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

        currentDistributionData =
          responseCurrentDistributionData.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )

        const storeCategory = currentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }

        saveCurrentDistributionDataAction(currentDistributionData)
      }

      if (partnerId && storeCurrentDistributionId) {
        const allDistributionsMediaItemsResponse: any =
          await MediaItemAPI.getAllMediaItemsForDistribution(
            partnerId,
            storeCurrentDistributionId
          )

        if (allDistributionsMediaItemsResponse.status === 'success') {
          setCountAllMediaItemsInDistribution(
            allDistributionsMediaItemsResponse.mediaitems.length
          )
          const allMP4MediaItems =
            allDistributionsMediaItemsResponse.mediaitems.filter(
              (mediaItem: any) => mediaItem.externalType === 'mp4'
            )

          const distributionData =
            currentDistributionData || storeCurrentDistributionData

          const filteredComputerMediaItems = _.uniqBy(
            [
              ...distributionData
                .map((item: any) =>
                  item.MediaItems.filter(
                    (element: any) => element.externalType === 'mp4'
                  )
                )
                .flat(),
              ...allMP4MediaItems,
            ],
            'id'
          )

          setAllMediaItemsFromComputer(filteredComputerMediaItems)
          setUploadMediaItems(filteredComputerMediaItems)
          setFilteredMediaItems(filteredComputerMediaItems)

          setFailedMediaItems(
            filteredComputerMediaItems.filter(
              (item: any) => item.status === 'failed'
            )
          )

          const sliceMediaItems = filteredComputerMediaItems.slice(
            0,
            stepForScrollMediaItems
          )

          setRenderMediaItems(sliceMediaItems)
        }
      }

      if (partnerId) {
        const response: IResponseCategories = await CategoryAPI.getCategory(
          partnerId,
          Number(distributionId),
          Number(id),
          { withMediaItems: 'true', withMediaItemImages: 'true' }
        )

        setCategory(response.categories[0])
        const mediaItems = response.categories[0].MediaItems

        if (mediaItems) {
          setAllMediaItemsInCategory(
            mediaItems
              .map((item: any) => {
                const cloneItem = _.cloneDeep(item)
                const smallImgObj =
                  cloneItem.Images.length > 0 &&
                  cloneItem.Images.find(
                    (itemImg: any) => itemImg.tag === 'Small'
                  )

                if (smallImgObj) {
                  smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                }

                cloneItem.Images = [
                  cloneItem.Images.filter(
                    (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                  ),
                  smallImgObj,
                ]

                return cloneItem
              })
              .sort(
                (a: IMediaItem, b: IMediaItem) =>
                  a.CategoryMediaItem.orderNumber -
                  b.CategoryMediaItem.orderNumber
              )
          )
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 400)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (category?.MediaItems) {
      setAllMediaItemsInCategory([...addedMediaItems, ...category.MediaItems])
    } else {
      setAllMediaItemsInCategory([...addedMediaItems])
    }
  }, [addedMediaItems])

  const onBeforeUploadFile = (file: RcFile, fileList: RcFile[]): boolean => {
    setUploadMediaItems([...fileList, ...uploadMediaItems])
    setFilteredMediaItems([...fileList, ...filteredMediaItems])
    return false
  }

  const onCloseControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(false)
  }

  const onOpenControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(true)
  }

  const changeSelectedMediaItemsId = (
    newId: string,
    isRemoved: boolean
  ): void => {
    if (isRemoved) {
      const filteredIds = selectedUploadMediaItemsId.filter(
        (itemId: string) => itemId !== newId
      )
      setSelectedUploadMediaItemsId(filteredIds)
    } else {
      setSelectedUploadMediaItemsId([...selectedUploadMediaItemsId, newId])
    }
  }

  const onSelectUploadMediaItem = (e: CheckboxChangeEvent): void => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItem = target.closest('[data-upload-item]')

    if (
      uploadMediaItem &&
      failedMediaItems.find(
        (item: any) => uploadMediaItem.id === String(item.id)
      )
    ) {
      return
    }

    onOpenControlsSelectedMediaItems()

    if (target.checked && uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, false)
    } else if (uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, true)
    }
  }

  const onSelectAllUploadMediaItem = (
    e: CheckboxChangeEvent
  ): void | string => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItemsElements =
      document.querySelectorAll('[data-upload-item]')

    onOpenControlsSelectedMediaItems()

    if (!target.checked) {
      onCloseControlsSelectedMediaItems()
      setSelectedUploadMediaItemsId([])
    } else {
      const ids = Array.from(uploadMediaItemsElements)
        .filter(
          (itemElement: any) =>
            !failedMediaItems.find(
              (item: any) => itemElement.id === String(item.id)
            )
        )
        .map((item: Element) => {
          if (
            allMediaItemsInCategory
              .filter((filterItem: any) => filterItem !== undefined)
              .some((mediaItem: any) => item.id === mediaItem.uid)
          ) {
            return ''
          }

          if (
            allMediaItemsInCategory
              .filter((filterItem: any) => filterItem !== undefined)
              .some((mediaItem: any) => item.id === String(mediaItem.id))
          ) {
            return ''
          }

          if (target.checked && item) {
            changeSelectedMediaItemsId(item.id, false)
          } else if (item) {
            changeSelectedMediaItemsId(item.id, true)
          }

          return item.id
        })

      setSelectedUploadMediaItemsId(ids.filter(filterId => filterId !== ''))
    }
  }

  const onDeleteUploadMediaItem = (removedId: string): void => {
    if (removedId === undefined) {
      return
    }

    setUploadMediaItems(
      uploadMediaItems.filter((mediaItem: any) => mediaItem.uid !== removedId)
    )
    setFilteredMediaItems(
      filteredMediaItems.filter((mediaItem: any) => mediaItem.uid !== removedId)
    )

    setAddedMediaItemIds(
      addedMediaItemIds.filter(filterId => filterId !== removedId)
    )
  }

  const onDeleteAddedMediaItemInCategory = (removedId: string): void => {
    if (
      initCategoryMediaItems?.find(
        (initItem: any) => String(initItem.id) === removedId
      )
    ) {
      return
    }

    setAllMediaItemsInCategory(
      allMediaItemsInCategory
        .filter((item: any) => item !== undefined)
        .filter((mediaItem: any) =>
          mediaItem.uid
            ? mediaItem.uid !== removedId
            : String(mediaItem.id) !== removedId
        )
    )
  }

  const onAddSelectedMediaItemsInCategory = (): void => {
    const addMediaItems: any = selectedUploadMediaItemsId.map(selectedId =>
      uploadMediaItems.filter(
        (item: any) => item.uid === selectedId || String(item.id) === selectedId
      )
    )

    setSelectedUploadMediaItemsId([])
    setAllMediaItemsInCategory([
      ...addMediaItems.flat().filter((item: any) => item !== undefined),
      ...allMediaItemsInCategory,
    ])
  }

  const onSaveAddedMediaItemsInCategory = async (): Promise<void> => {
    try {
      // countSaveFunctionCall += 1

      const controller: AbortController = new AbortController()
      setAbortControllers((prev: [] | AbortController[]) => [
        ...prev,
        controller,
      ])
      navigate('/content')

      const currentDistributionId = category?.DistributionId
      const categoryId = category?.id
      const partnerId = storeCurrentPartnerId || getPartnerId()

      const addedFiles = allMediaItemsInCategory.filter(
        (mediaItem: any) => mediaItem.uid
      )

      if (currentDistributionId && partnerId && categoryId) {
        const createMediaItemPromiseArr = addedFiles.map((mediaItem: RcFile) =>
          MediaItemAPI.createMediaItemWithUpload(
            partnerId,
            currentDistributionId,
            mediaItem,
            categoryId,
            changeProgressBarUploadTotalSizeAction,
            changeProgressBarUploadSizeAction,
            setCurrentUploadFiles,
            controller
          )
        )

        setTotalUploadFiles((prev: number) => {
          const newCount = prev + addedFiles.length
          return newCount
        })

        const responses: any = await Promise.allSettled(
          createMediaItemPromiseArr
        )

        // if (responses.some((result: any) => result.status === 'rejected')) {
        //   message.warning('Some videos not added!', 3)
        // }

        const createdMediaItems: any = responses
          .filter((result: any) => result.status === 'fulfilled')
          .map((response: any) => response.value.mediaitem)

        const allMediaItems = allMediaItemsInCategory
          .filter((item: any) => item !== undefined)
          .map((item: any) => {
            if (item.id !== undefined) {
              return item
            }
            const replaceItem = createdMediaItems.find(
              (mediaItem: IMediaItem) => item.uid === mediaItem.description
            )
            return replaceItem
          })

        for (let i = 0; i < allMediaItems.length; i += 1) {
          const mediaItem = allMediaItems[i]

          if (
            category &&
            !category.MediaItems?.find(
              (categoryMediaItem: any) => categoryMediaItem.id === mediaItem.id
            )
          ) {
            const setCategoryResponse =
              await MediaItemAPI.addCategoriesForMediaItem(
                partnerId,
                Number(distributionId),
                mediaItem.id,
                String(category.id)
              )
          }

          const response: any = await MediaItemAPI.setOrderNumberForMediaItem(
            partnerId,
            currentDistributionId,
            mediaItem.id,
            categoryId,
            i + 1
          )

          if (response.status === 'ERROR') {
            message.error(response.message, 3)
            break
          }
        }

        setIsDoneUploadComputerMediaItems(true)

        if (uploadFilesTimerId) {
          clearTimeout(uploadFilesTimerId)
        }

        //  if (countSaveFunctionCall === 1) {
        uploadFilesTimerId = setTimeout(() => {
          // message.success(`Videos were added in category from computer!`, 3)

          setCurrentUploadFiles(0)
          setTotalUploadFiles(0)

          if (uploadFilesTimerId) {
            clearTimeout(uploadFilesTimerId)
          }
        }, 2000)
        // }

        // countSaveFunctionCall -= 1
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data.message)
        }
      }

      setCurrentUploadFiles(0)
      setTotalUploadFiles(0)
    }
  }

  const handleDragStartUploadMediaItems = (e: any): void => {
    // console.log('DRAG START  UPLOAD')
    const target = e.target as HTMLElement
    const uploadItem = target.closest('[data-upload-item]') as HTMLElement

    if (
      uploadItem &&
      !allMediaItemsInCategory.find((item: any) =>
        item.uid
          ? uploadItem.id === item.uid
          : uploadItem.id === String(item.id)
      )
    ) {
      draggingUploadMediaItemRef.current = uploadItem
    }
  }

  const handleDragEnterUploadMediaItems = (e: any): void => {
    // console.log('DRAG ENTER UPLOAD')
    const { target } = e
  }

  const handleDragDropUploadMediaItems = (e: any): void => {
    // console.log('DRAG DROP UPLOAD')
    e.preventDefault()
  }

  const handleDragEndUploadMediaItems = (e: any): void => {
    // console.log('DRAG END UPLOAD')
    e.preventDefault()

    if (draggingUploadMediaItemRef.current && inComputerCategory) {
      const findMediaItem = uploadMediaItems.find((item: any) =>
        item.uid
          ? draggingUploadMediaItemRef?.current?.id === item.uid
          : draggingUploadMediaItemRef?.current?.id === String(item.id)
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadMediaItems.filter((item: any) =>
        selectedUploadMediaItemsCurrentId.some((currentId: string) =>
          item.uid ? currentId === item.uid : currentId === String(item.id)
        )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        if (findMediaItem) {
          const updateAllMediaItemsInCategory = _.cloneDeep(
            allMediaItemsInCategory
          )

          updateAllMediaItemsInCategory.splice(
            findIndex,
            0,
            findMediaItem,
            ...filteredUploadMediaItems
          )

          setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
        }
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inComputerCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const handleDragEnterCategory = (e: any): void => {
    // console.log('DRAG ENTER  CATEGORY')

    if (draggingUploadMediaItemRef.current) {
      const target = e.target as HTMLElement

      inComputerCategory = true

      const li = document.createElement('li')
      li.classList.add(styles.tempMediaItem)
      li.dataset.temp = 'temp'
      li.setAttribute('draggable', 'true')
      li.setAttribute('data-media-item-order', '')
      li.id = draggingUploadMediaItemRef.current.id

      const parentList = document.querySelector('[data-drag-list]')
      parentList?.prepend(li)

      if (inComputerCategory) {
        const underLi = target.closest('[data-media-item-order]') as any

        if (parentList && underLi && li) {
          if (!underLi.dataset.temp) {
            if (underLi.nextSibling && underLi.nextSibling.dataset.temp) {
              parentList.insertBefore(underLi.nextSibling, underLi)
            } else {
              parentList.insertBefore(li, underLi.nextSibling)
            }
          }
        }
      }
    }
  }

  const handleDragLeaveCategory = (e: any): void => {
    // console.log('DRAG LEAVE  CATEGORY')

    const tempItem = document.querySelector('[data-temp]')
    tempItem?.remove()
  }

  const handleDragOverCategory = (e: any): void => {
    // console.log('DRAG OVER  CATEGORY')
    e.preventDefault()
  }

  const handleDropCategory = (e: any): void => {
    // console.log('DRAG DROP  CATEGORY')

    if (draggingUploadMediaItemRef.current && inComputerCategory) {
      const findMediaItem = uploadMediaItems.find((item: any) =>
        item.uid
          ? draggingUploadMediaItemRef?.current?.id === item.uid
          : draggingUploadMediaItemRef?.current?.id === String(item.id)
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadMediaItems.filter((item: any) =>
        selectedUploadMediaItemsCurrentId.some((currentId: string) =>
          item.uid ? currentId === item.uid : currentId === String(item.id)
        )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        const updateAllMediaItemsInCategory = _.cloneDeep(
          allMediaItemsInCategory
        )

        updateAllMediaItemsInCategory.splice(
          findIndex,
          0,
          findMediaItem,
          ...filteredUploadMediaItems
        )

        setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inComputerCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const onSearchUploadMediaItems = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setIsFiltered(true)

    if (value === '') {
      setIsFiltered(false)
      setFilteredMediaItems(uploadMediaItems)
    }

    setFilteredMediaItems(
      uploadMediaItems.filter((item: RcFile) => {
        const name = item.name.toLowerCase()
        return name.includes(value.toLowerCase())
      })
    )
  }

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div
      className={styles.mainWrapper}
      role="presentation"
      onDragEnter={e => {
        const target = e.target as HTMLElement

        if (!target.closest('[data-category]')) {
          inComputerCategory = false
        }
      }}
    >
      <AddVideosTopBar
        isConnected
        isComputer
        isChangeOrder={isChangeOrder}
        category={category}
        allMediaItemsInCategory={allMediaItemsInCategory}
        onSaveAddedMediaItemsInCategory={onSaveAddedMediaItemsInCategory}
      />

      <div className={styles.mainContent}>
        <DefaultSideBar />

        <div className={styles.section}>
          {uploadMediaItems.length !== 0 ? (
            <UploadMediaItems
              filteredMediaItems={filteredMediaItems}
              allMediaItemsInCategory={allMediaItemsInCategory}
              onSearchUploadMediaItems={onSearchUploadMediaItems}
              isFiltered={isFiltered}
              uploadMediaItems={uploadMediaItems}
              selectedUploadMediaItemsId={selectedUploadMediaItemsId}
              addedMediaItems={addedMediaItems}
              onBeforeUploadFile={onBeforeUploadFile}
              onSelectUploadMediaItem={onSelectUploadMediaItem}
              onSelectAllUploadMediaItem={onSelectAllUploadMediaItem}
              onDeleteUploadMediaItem={onDeleteUploadMediaItem}
              handleDragStartUploadMediaItems={handleDragStartUploadMediaItems}
              handleDragEnterUploadMediaItems={handleDragEnterUploadMediaItems}
              handleDragEndUploadMediaItems={handleDragEndUploadMediaItems}
              handleDragDropUploadMediaItems={handleDragDropUploadMediaItems}
              initCategoryMediaItems={initCategoryMediaItems}
              renderMediaItems={renderMediaItems}
              scrollHandler={scrollHandler}
              fetching={fetching}
              draggingUploadMediaItemRef={draggingUploadMediaItemRef}
              failedMediaItems={failedMediaItems}
            />
          ) : (
            <Upload.Dragger
              className={styles.uploadWrapper}
              accept=".mp4"
              multiple
              beforeUpload={onBeforeUploadFile}
              showUploadList={false}
              method={undefined}
            >
              <h5 className={styles.text}>
                Drag &amp; Drop videos <span>or</span>
              </h5>

              <Button shape="round" className={styles.uploadBtn}>
                Upload from computer
              </Button>
            </Upload.Dragger>
          )}
        </div>
      </div>
      {category && (
        <ComputerCategory
          draggingUploadMediaItemRef={draggingUploadMediaItemRef}
          handleDragEnterCategory={handleDragEnterCategory}
          handleDragLeaveCategory={handleDragLeaveCategory}
          handleDragOverCategory={handleDragOverCategory}
          handleDropCategory={handleDropCategory}
          allMediaItemsInCategory={allMediaItemsInCategory}
          setAllMediaItemsInCategory={setAllMediaItemsInCategory}
          category={category}
          onDeleteAddedMediaItemInCategory={onDeleteAddedMediaItemInCategory}
          onAddSelectedMediaItemsInCategory={onAddSelectedMediaItemsInCategory}
          selectedUploadMediaItemsId={selectedUploadMediaItemsId}
          onCloseControlsSelectedMediaItems={onCloseControlsSelectedMediaItems}
          isOpenMediaItemsControls={isOpenMediaItemsControls}
          setIsChangeOrder={setIsChangeOrder}
          inCategory={inComputerCategory}
          uploadMediaItems={uploadMediaItems}
          setSelectedUploadMediaItemsId={setSelectedUploadMediaItemsId}
          draggingMediaItemRef={draggingMediaItemRef}
        />
      )}
    </div>
  )
}

export default Computer
