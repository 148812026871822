import { IPathAction, IPathState, PathActionsTypes } from 'store/types/path'

const initialState: IPathState = {
  currentPath: '',
}

export const pathReducer = (
  state = initialState,
  action: IPathAction
): IPathState => {
  switch (action.type) {
    case PathActionsTypes.SAVE_PATH:
      return { ...state, currentPath: action.payload }

    default:
      return state
  }
}
