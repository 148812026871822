import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Spin, Tabs, Upload } from 'antd'
import _ from 'lodash'
import cn from 'classnames'

import { config } from 'config'
import {
  rokuAppBuilderTags,
  appleAppBuilderTags,
  androidAppBuilderTags,
} from 'models/platform'
import { IImage } from 'models/mediaItem'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'

import { IPartner } from 'models/partner'

import { IDistribution, IResponseDistributions } from 'models/distribution'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { getPartnerId } from 'utils/helper'

import { PartnerAPI } from 'api/User/Partner'
import { IUser } from 'models/user'

import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { useActions } from 'store/hooks/useActions'
import Preview from 'components/Preview'

import { ReactComponent as PreviewIcon } from 'sources/images/tv-preview-icon.svg'

import UploadAsset from './UploadAsset'

import styles from './styles.module.scss'

const Platform: FC = () => {
  const [selectedDistribution, setSelectedDistribution] = useState<
    number | null
  >(null)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>()

  const [isLoading, setIsLoading] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)

  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
  } = useActions()

  const [rokuAssetsTags, setRokuAssetsTag] = useState<string[]>([])
  const [appleAssetsTags, setAppleAssetsTag] = useState<string[]>([])
  const [androidAssetsTags, setAndroidAssetsTag] = useState<string[]>([])

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const { storeCurrentPartnerId, storeCurrentPartnerData } = useTypedSelector(
    state => state.partner
  )

  const path = useLocation().pathname

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          if (storeCurrentDistributionId) {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {}
            )

            saveCurrentDistributionIdAction(storeCurrentDistributionId)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )

            const images = response.distributions[0].Images

            if (images.length > 0) {
              const rokuTags = images
                .map((item: IImage) => {
                  if (rokuAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const appleTags = images
                .map((item: IImage) => {
                  if (appleAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const androidTags = images
                .map((item: IImage) => {
                  if (androidAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              setAppleAssetsTag(appleTags)
              setAndroidAssetsTag(androidTags)
              setRokuAssetsTag(rokuTags)
            }
          } else {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributions[0].id,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(distributions[0].id)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          }

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])

            const images = response.distributions[0].Images

            if (images.length > 0) {
              const rokuTags = images
                .map((item: IImage) => {
                  if (rokuAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const appleTags = images
                .map((item: IImage) => {
                  if (appleAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const androidTags = images
                .map((item: IImage) => {
                  if (androidAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              setAppleAssetsTag(appleTags)
              setAndroidAssetsTag(androidTags)
              setRokuAssetsTag(rokuTags)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

      if (partnerId) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          allDistributions[currentIndex].id,
          {}
        )

        saveCurrentDistributionIdAction(allDistributions[currentIndex].id)
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])

          const images = response.distributions[0].Images

          if (images.length > 0) {
            const rokuTags = images
              .map((item: IImage) => {
                if (rokuAppBuilderTags.includes(item.tag)) {
                  return item.tag
                }

                return undefined
              })
              .filter((tag: string | undefined) => tag !== undefined)

            const appleTags = images
              .map((item: IImage) => {
                if (appleAppBuilderTags.includes(item.tag)) {
                  return item.tag
                }

                return undefined
              })
              .filter((tag: string | undefined) => tag !== undefined)

            const androidTags = images
              .map((item: IImage) => {
                if (androidAppBuilderTags.includes(item.tag)) {
                  return item.tag
                }

                return undefined
              })
              .filter((tag: string | undefined) => tag !== undefined)

            setAppleAssetsTag(appleTags)
            setAndroidAssetsTag(androidTags)
            setRokuAssetsTag(rokuTags)
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )

          if (response.status === 'success') {
            setCurrentDistribution(() => response.distributions[0])

            const images = response.distributions[0].Images

            if (images.length > 0) {
              const rokuTags = images
                .map((item: IImage) => {
                  if (rokuAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const appleTags = images
                .map((item: IImage) => {
                  if (appleAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              const androidTags = images
                .map((item: IImage) => {
                  if (androidAppBuilderTags.includes(item.tag)) {
                    return item.tag
                  }

                  return undefined
                })
                .filter((tag: string | undefined) => tag !== undefined)

              setAppleAssetsTag(appleTags)
              setAndroidAssetsTag(androidTags)
              setRokuAssetsTag(rokuTags)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    try {
      const changePartner = allPartners[currentIndex]

      saveCurrentPartnerIdAction(changePartner.id)
      setCurrentPartner(changePartner)
      saveCurrentPartnerDataAction(changePartner)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }
  }, [storeCurrentPartnerId])

  const checkAppTags = (a: string[], b: string[]): boolean =>
    _.difference(a, b).length !== 0

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={allDistributions}
        setAllDistributions={setAllDistributions}
        onChangeDistribution={onChangeDistribution}
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={onChangePartner}
      />

      <div className={styles.mainContent}>
        <SideBar />

        <div className={styles.section}>
          <div className={styles.pageTitleWrapper}>
            <h3 className={styles.pageTitle}>Platform</h3>
            <Button
              className={styles.titleBtn}
              shape="round"
              onClick={() => setPreviewVisible(true)}
              icon={<PreviewIcon />}
            >
              Preview
            </Button>

            {previewVisible && (
              <Preview
                previewVisible={previewVisible}
                setPreviewVisible={setPreviewVisible}
              />
            )}
          </div>

          <div className={styles.content}>
            {isLoading ? (
              <Spin size="large" className={styles.spinner} />
            ) : (
              <Tabs
                defaultActiveKey="1"
                className={styles.tabs}
                destroyInactiveTabPane
              >
                <Tabs.TabPane tab="Roku" key="1">
                  <div className={styles.tabPaneGuid}>
                    <div className={styles.guidesWrapper}>
                      <h3 className={styles.guidTitle}>
                        Assets guides for Roku
                      </h3>
                      <a
                        className={styles.guidLink}
                        href="https://developer.roku.com/en-gb/docs/specs/graphics.md"
                      >
                        https://developer.roku.com/en-gb/docs/specs/graphics.md
                      </a>
                      <p className={styles.guidText}>
                        Roku Image Demand (ALL MUST BE JPEG, each image should
                        be not bigger than 200 Kb, and 500Kb for FHD splash ) -
                        save by image name!{' '}
                      </p>
                      <p className={styles.guidText}>
                        The Channel Poster is the image shown on the Roku OS
                        home screen when your channel is installed and is also
                        used in your Channel Store listing displayed alongside
                        the channel details.
                      </p>

                      {isSuperAdmin && config.ROKU_BUILDER_URL !== '' && (
                        <Button
                          shape="round"
                          className={cn(styles.builderBtn, {
                            [styles.disabled]: checkAppTags(
                              rokuAppBuilderTags,
                              rokuAssetsTags
                            ),
                          })}
                          target="_blank"
                          disabled={checkAppTags(
                            rokuAppBuilderTags,
                            rokuAssetsTags
                          )}
                          href={`${config.ROKU_BUILDER_URL}?distributionId=${storeCurrentDistributionId}`}
                        >
                          Open App Builder
                        </Button>
                      )}
                    </div>

                    <div className={styles.uploadSectionWrapper}>
                      <UploadAsset
                        title="Channel Poster"
                        sizeAsset="mm_focus_sd (246 x 140)"
                        platform="Roku"
                        tag="Roku/mm_focus_sd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Channel Poster"
                        sizeAsset="mm_focus_hd (336 x 210)"
                        platform="Roku"
                        tag="Roku/mm_focus_hd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Channel Poster"
                        sizeAsset="mm_focus_fhd (540 X 405)"
                        platform="Roku"
                        tag="Roku/mm_focus_fhd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Splash"
                        sizeAsset="splash_sd (720 x 480)"
                        platform="Roku"
                        tag="Roku/splash_sd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Splash"
                        sizeAsset="splash_hd (1280 x 720)"
                        platform="Roku"
                        tag="Roku/splash_hd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Splash"
                        sizeAsset="splash_fhd (1920 x 1080)"
                        platform="Roku"
                        tag="Roku/splash_fhd"
                        accept=".jpg"
                        setRokuAssetsTag={setRokuAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane destroyInactiveTabPane tab="Android" key="2">
                  <div className={styles.tabPaneGuid}>
                    <div className={styles.guidesWrapper}>
                      <h3 className={styles.guidTitle}>
                        Assets guides for Android
                      </h3>

                      {isSuperAdmin && config.ANDROIDTV_BUILDER_URL !== '' && (
                        <Button
                          shape="round"
                          className={cn(styles.builderBtn, {
                            [styles.disabled]: checkAppTags(
                              androidAppBuilderTags,
                              androidAssetsTags
                            ),
                          })}
                          target="_blank"
                          disabled={checkAppTags(
                            androidAppBuilderTags,
                            androidAssetsTags
                          )}
                          href={`${config.ANDROIDTV_BUILDER_URL}?distributionId=${storeCurrentDistributionId}`}
                        >
                          Open App Builder
                        </Button>
                      )}
                    </div>

                    <div className={styles.uploadSectionWrapper}>
                      <UploadAsset
                        title="Home Screen Banner"
                        sizeAsset="asset_image (320 x 180)"
                        platform="Android"
                        tag="Android/asset_image_320x180"
                        setAndroidAssetsTag={setAndroidAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="App Icon"
                        sizeAsset="asset_image (512 x 512)"
                        platform="Android"
                        tag="Android/asset_image_512x512"
                        setAndroidAssetsTag={setAndroidAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Feature Graphic"
                        sizeAsset="asset_image (1024 x 500)"
                        platform="Android"
                        tag="Android/asset_image_1024x500"
                        setAndroidAssetsTag={setAndroidAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="TV Banner"
                        sizeAsset="asset_image (1280 x 720)"
                        platform="Android"
                        tag="Android/asset_image_1280x720"
                        setAndroidAssetsTag={setAndroidAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />
                    </div>
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane destroyInactiveTabPane tab="Apple TV" key="3">
                  <div className={styles.tabPaneGuid}>
                    <div className={styles.guidesWrapper}>
                      <h3 className={styles.guidTitle}>
                        Assets guides for Apple TV
                      </h3>

                      <a
                        className={styles.guidLink}
                        href="https://developer.apple.com/design/human-interface-guidelines/tvos/icons-and-images/a
                        pp-icon/
                        "
                      >
                        https://developer.apple.com/design/human-interface-guidelines/tvos/icons-and-images/a
                        pp-icon/
                      </a>

                      {isSuperAdmin && config.APPLETV_BUILDER_URL !== '' && (
                        <Button
                          shape="round"
                          className={cn(styles.builderBtn, {
                            [styles.disabled]: checkAppTags(
                              appleAppBuilderTags,
                              appleAssetsTags
                            ),
                          })}
                          target="_blank"
                          disabled={checkAppTags(
                            appleAppBuilderTags,
                            appleAssetsTags
                          )}
                          href={`${config.APPLETV_BUILDER_URL}?distributionId=${storeCurrentDistributionId}`}
                        >
                          Open App Builder
                        </Button>
                      )}
                    </div>

                    <div className={styles.uploadSectionWrapper}>
                      <UploadAsset
                        title="Top Shelf Image Wide"
                        sizeAsset="TopShelfImageWide (2320 x 720)"
                        platform="Apple"
                        tag="Apple/TopShelfImageWide_2320x720"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Top Shelf Image"
                        sizeAsset="TopShelfImage (1920 x 720)"
                        platform="Apple"
                        tag="Apple/TopShelfImage_1920x720"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Top Shelf Image"
                        sizeAsset="TopShelfImageWide2x (4640 x 1440)"
                        platform="Apple"
                        tag="Apple/TopShelfImageWide2x_4640x1440"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Top Shelf Image"
                        sizeAsset="TopShelfImage2x (3840 x 1440)"
                        platform="Apple"
                        tag="Apple/TopShelfImage2x_3840x1440"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="App Store Icon"
                        sizeAsset="AppStoreIcon (1280 x 768)"
                        platform="Apple"
                        tag="Apple/AppStoreIcon_1280x768"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Splash"
                        sizeAsset="MainSplash (1920 x 1080)"
                        platform="Apple"
                        tag="Apple/MainSplash_1920x1080"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Splash"
                        sizeAsset="MainSplash2x (3840 x 2160)"
                        platform="Apple"
                        tag="Apple/MainSplash2x_3840x2160"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Home Screen"
                        sizeAsset="MainHomeScreen (800 x 480)"
                        platform="Apple"
                        tag="Apple/MainHomeScreen_800x480"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Home Screen"
                        sizeAsset="MainHomeScreen (740 x 444)"
                        platform="Apple"
                        tag="Apple/MainHomeScreen_740x444"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main Home Screen"
                        sizeAsset="MainHomeScreen (600 x 360)"
                        platform="Apple"
                        tag="Apple/MainHomeScreen_600x360"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main App Image"
                        sizeAsset="MainAppImage (400 x 240)"
                        platform="Apple"
                        tag="Apple/MainAppImage_400x240"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main App Image"
                        sizeAsset="MainAppImage (370 x 222)"
                        platform="Apple"
                        tag="Apple/MainAppImage_370x222"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />

                      <UploadAsset
                        title="Main App Image"
                        sizeAsset="MainAppImage (300 x 180)"
                        platform="Apple"
                        tag="Apple/MainAppImage_300x180"
                        setAppleAssetsTag={setAppleAssetsTag}
                        distributionAssets={
                          currentDistribution ? currentDistribution?.Images : []
                        }
                      />
                    </div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Platform
