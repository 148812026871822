import { IPartnerAction, PartnerActionsTypes } from 'store/types/partner'

export const saveCurrentPartnerIdAction = (id: number): IPartnerAction => ({
  type: PartnerActionsTypes.SAVE_SUPER_PARTNER_ID,
  payload: id,
})

export const saveCurrentPartnerDataAction = (data: any): IPartnerAction => ({
  type: PartnerActionsTypes.SAVE_SUPER_PARTNER_DATA,
  payload: data,
})

export const saveAllPartnersDataAction = (data: any): IPartnerAction => ({
  type: PartnerActionsTypes.SAVE_SUPER_ALL_PARTNERS_DATA,
  payload: data,
})

export const clearPartnerAction = (data: any): IPartnerAction => ({
  type: PartnerActionsTypes.CLEAR_SUPER_PARTNER,
  payload: data,
})
