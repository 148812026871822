import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message, Spin, Tabs, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import AddVideosTopBar from 'components/AddVideos/AddVideosTopBar'
import DefaultSideBar from 'components/AddVideos/DefaultSideBar'
import UrlRssCategory from 'components/AddVideos/UrlRssCategory'

import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { CategoryAPI } from 'api/VideoCollection/Category'
import { VideoSyncAPI } from 'api/VideoSync/VideoSync'

import { useActions } from 'store/hooks/useActions'

import { ICategory, IResponseCategories } from 'models/category'
import { IMediaItem } from 'models/mediaItem'
import { getPartnerId } from 'utils/helper'

import { ReactComponent as LinkUrlRssIcon } from 'sources/images/addVideos/linkUrlRss.svg'
import { ReactComponent as HelpIcon } from 'sources/images/content/help-icon.svg'

import styles from './styles.module.scss'

const UrlRss: FC = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { id, distributionId } = useParams()
  const [category, setCategory] = useState<ICategory | null>(null)
  const [allMediaItemsInCategory, setAllMediaItemsInCategory] = useState<any>(
    []
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCsvFile, setIsLoadingCsvFile] = useState(false)
  const [isLoadingRssUrl, setIsLoadingRssUrl] = useState(false)

  const [rssUrl, setRssUrl] = useState('')

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)
  const { storeCurrentDistributionData, storeCurrentDistributionId } =
    useTypedSelector(state => state.distribution)

  const { saveCurrentDistributionDataAction } = useActions()
  const path = useLocation().pathname

  // const draggingMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingUploadMediaItemRef = useRef<HTMLElement | null>(null)

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)
      let currentDistributionData: any = null

      if (partnerId && storeCurrentDistributionId) {
        const responseCurrentDistributionData: any =
          await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            storeCurrentDistributionId,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

        currentDistributionData =
          responseCurrentDistributionData.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )

        saveCurrentDistributionDataAction(currentDistributionData)
      }

      if (partnerId) {
        const response: IResponseCategories = await CategoryAPI.getCategory(
          partnerId,
          Number(distributionId),
          Number(id),
          { withMediaItems: 'true', withMediaItemImages: 'true' }
        )
        setCategory(response.categories[0])
        const mediaItems = response.categories[0].MediaItems

        if (mediaItems) {
          setAllMediaItemsInCategory(
            mediaItems
              .map((item: any) => {
                const cloneItem = _.cloneDeep(item)
                const smallImgObj =
                  cloneItem.Images.length > 0 &&
                  cloneItem.Images.find(
                    (itemImg: any) => itemImg.tag === 'Small'
                  )

                if (smallImgObj) {
                  smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                }

                cloneItem.Images = [
                  cloneItem.Images.filter(
                    (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                  ),
                  smallImgObj,
                ]

                return cloneItem
              })
              .sort(
                (a: IMediaItem, b: IMediaItem) =>
                  a.CategoryMediaItem.orderNumber -
                  b.CategoryMediaItem.orderNumber
              )
          )
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 400)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onBeforeUploadCsvFile = async (file: RcFile): Promise<false> => {
    try {
      setIsLoadingCsvFile(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (file && partnerId && storeCurrentDistributionId && category) {
        const response: any = await VideoSyncAPI.addVideoFromCSV(
          partnerId,
          storeCurrentDistributionId,
          category.id,
          file
        )

        if (response.status === 'success') {
          const responseCategory: IResponseCategories =
            await CategoryAPI.getCategory(
              partnerId,
              Number(distributionId),
              Number(id),
              { withMediaItems: 'true', withMediaItemImages: 'true' }
            )

          message.success('Videos were added from CSV file!', 3)
          navigate('/content')

          setCategory(responseCategory.categories[0])
          const mediaItems = responseCategory.categories[0].MediaItems

          if (mediaItems) {
            setAllMediaItemsInCategory(
              mediaItems
                .map((item: any) => {
                  const cloneItem = _.cloneDeep(item)
                  const smallImgObj =
                    cloneItem.Images.length > 0 &&
                    cloneItem.Images.find(
                      (itemImg: any) => itemImg.tag === 'Small'
                    )

                  if (smallImgObj) {
                    smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                  }

                  cloneItem.Images = [
                    cloneItem.Images.filter(
                      (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                    ),
                    smallImgObj,
                  ]

                  return cloneItem
                })
                .sort(
                  (a: IMediaItem, b: IMediaItem) =>
                    a.CategoryMediaItem.orderNumber -
                    b.CategoryMediaItem.orderNumber
                )
            )
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingCsvFile(false)
    }

    return false
  }

  const onImportVideosFromRssUrl = async (): Promise<void> => {
    try {
      if (rssUrl === '') {
        return
      }

      setIsLoadingRssUrl(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && storeCurrentDistributionId && category) {
        const response: any = await VideoSyncAPI.addVideoFromRSS(
          partnerId,
          storeCurrentDistributionId,
          category.id,
          rssUrl
        )

        if (response.status === 'success') {
          const responseCategory: IResponseCategories =
            await CategoryAPI.getCategory(
              partnerId,
              Number(distributionId),
              Number(id),
              { withMediaItems: 'true', withMediaItemImages: 'true' }
            )

          message.success('Videos were added from RSS feed!', 3)
          navigate('/content')

          setCategory(responseCategory.categories[0])
          const mediaItems = responseCategory.categories[0].MediaItems

          if (mediaItems) {
            setAllMediaItemsInCategory(
              mediaItems
                .map((item: any) => {
                  const cloneItem = _.cloneDeep(item)
                  const smallImgObj =
                    cloneItem.Images.length > 0 &&
                    cloneItem.Images.find(
                      (itemImg: any) => itemImg.tag === 'Small'
                    )

                  if (smallImgObj) {
                    smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                  }

                  cloneItem.Images = [
                    cloneItem.Images.filter(
                      (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                    ),
                    smallImgObj,
                  ]

                  return cloneItem
                })
                .sort(
                  (a: IMediaItem, b: IMediaItem) =>
                    a.CategoryMediaItem.orderNumber -
                    b.CategoryMediaItem.orderNumber
                )
            )
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingRssUrl(false)
    }
  }

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div className={styles.mainWrapper}>
      <AddVideosTopBar isConnected={false} />
      <div className={styles.mainContent}>
        <DefaultSideBar />

        <div className={styles.section}>
          <Tabs
            defaultActiveKey="1"
            className={styles.tabs}
            destroyInactiveTabPane
          >
            <Tabs.TabPane tab="CSV" key="1" className={styles.urlTab}>
              <Form form={form} className={styles.formRss}>
                <div className={styles.title}>
                  <Button
                    className={styles.icon}
                    icon={<LinkUrlRssIcon />}
                    shape="circle"
                  />
                  <span className={styles.text}>
                    {' '}
                    Import Files through CSV file using{' '}
                    <a href="/template.csv" download>
                      template
                    </a>
                  </span>
                </div>

                <Upload
                  showUploadList={false}
                  accept=".csv"
                  multiple={false}
                  beforeUpload={onBeforeUploadCsvFile}
                >
                  <Button
                    className={styles.addFileBtn}
                    shape="round"
                    disabled={isLoadingCsvFile}
                    loading={isLoadingCsvFile}
                  >
                    Add file
                  </Button>
                </Upload>
              </Form>
            </Tabs.TabPane>

            <Tabs.TabPane tab="RSS" className={styles.tabVideos} key="2">
              <Form form={form} className={styles.formUrl}>
                <div className={styles.title}>
                  <Button
                    className={styles.icon}
                    icon={<LinkUrlRssIcon />}
                    shape="circle"
                  />
                  <span className={styles.text}>
                    Import Files through RSS feed
                  </span>
                </div>
                <Form.Item
                  className={styles.formItem}
                  rules={[{ required: true }]}
                  name="url"
                >
                  <Input
                    className={styles.formInput}
                    placeholder="Past your link here..."
                    value={rssUrl}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRssUrl(e.target.value)
                    }
                  />
                </Form.Item>
                {/* 
                <div className={styles.helpWrapper}>
                  <HelpIcon className={styles.helpIcon} />
                  <a className={styles.helpLink} href="/">
                    How to get this in youtube
                  </a>
                </div> */}

                <Button
                  className={styles.submitBtn}
                  htmlType="submit"
                  shape="round"
                  onClick={onImportVideosFromRssUrl}
                  disabled={isLoadingRssUrl}
                  loading={isLoadingRssUrl}
                >
                  Save
                </Button>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>

      {category && (
        <UrlRssCategory
          category={category}
          allMediaItemsInCategory={allMediaItemsInCategory}
          draggingUploadMediaItemRef={draggingUploadMediaItemRef}
        />
      )}
    </div>
  )
}

export default UrlRss
