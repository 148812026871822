import React, { FC, useEffect, useState } from 'react'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { MediaSourceAPI } from 'api/VideoCollection/MediaSource'
import { Route, Routes } from 'react-router-dom'
import { getPartnerId } from 'utils/helper'

import Computer from './Computer'
import UrlRss from './UrlRss'
import Vimeo from './Vimeo'
import Youtube from './Youtube'

interface IProps {
  setIsDoneUploadComputerMediaItems: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsDoneUploadVimeoMediaItems: React.Dispatch<React.SetStateAction<boolean>>
  setsDoneUploadYoutubeMediaItems: React.Dispatch<React.SetStateAction<boolean>>
}

const AddVideos: FC<IProps> = ({
  setIsDoneUploadComputerMediaItems,
  setIsDoneUploadVimeoMediaItems,
  setsDoneUploadYoutubeMediaItems,
}) => {
  const [vimeoMediaSources, setVimeoMediaSources] = useState([])
  const [youtubeMediaSources, setYoutubeMediaSources] = useState([])

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const init = async (): Promise<void> => {
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (partnerId) {
      const response: any = await MediaSourceAPI.getMediaSources(partnerId)
      const allMediaSources = response.mediaSources ? response.mediaSources : []

      const filterVimeoMediaSources = allMediaSources.filter(
        ({ sourceType }: any) => sourceType === 'vimeo'
      )
      const filterYoutubeMediaSources = allMediaSources.filter(
        ({ sourceType }: any) => sourceType === 'youtube'
      )

      setVimeoMediaSources(filterVimeoMediaSources)
      setYoutubeMediaSources(filterYoutubeMediaSources)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Routes>
      <Route
        path="/computer"
        element={
          <Computer
            setIsDoneUploadComputerMediaItems={
              setIsDoneUploadComputerMediaItems
            }
          />
        }
      />
      <Route
        path="/youtube"
        element={
          <Youtube
            youtubeMediaSources={youtubeMediaSources}
            setYoutubeMediaSources={setYoutubeMediaSources}
            setsDoneUploadYoutubeMediaItems={setsDoneUploadYoutubeMediaItems}
          />
        }
      />
      <Route
        path="/vimeo"
        element={
          <Vimeo
            vimeoMediaSources={vimeoMediaSources}
            setIsDoneUploadVimeoMediaItems={setIsDoneUploadVimeoMediaItems}
          />
        }
      />
      <Route path="/urlRss" element={<UrlRss />} />
    </Routes>
  )
}

export default AddVideos
