import React, { FC, useState } from 'react'
import { message, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { resetCountSaveFunctionCall } from 'containers/AddVideos/Computer'

import history from 'utils/history'

import { useActions } from 'store/hooks/useActions'

import styles from './styles.module.scss'

interface IProps {
  isOpenLogOutModal: boolean
  setIsOpenLogOutModal: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentUploadFiles: React.Dispatch<React.SetStateAction<number>>
  setTotalUploadFiles: React.Dispatch<React.SetStateAction<number>>
  abortControllers: [] | AbortController[]
  setAbortControllers: React.Dispatch<
    React.SetStateAction<[] | AbortController[]>
  >
}

const LogOutModal: FC<IProps> = ({
  isOpenLogOutModal,
  setIsOpenLogOutModal,
  setCurrentUploadFiles,
  setTotalUploadFiles,
  abortControllers,
  setAbortControllers,
}) => {
  const {
    clearDistributionAction,
    clearPartnerAction,
    removeUserInfoAction,
    removePartnerInfoAction,
  } = useActions()

  return (
    <Modal
      centered
      visible={isOpenLogOutModal}
      className={styles.logOutModal}
      closeIcon={<CloseOutlined style={{ color: '#000', display: 'none' }} />}
      onCancel={() => {
        setIsOpenLogOutModal(false)
      }}
      onOk={() => {
        setIsOpenLogOutModal(false)

        abortControllers.forEach((controller: AbortController) =>
          controller.abort()
        )
        setAbortControllers([])

        localStorage.clear()
        message.success('You logout!', 3)

        clearDistributionAction({
          storeCurrentDistributionId: null,
          storeCurrentDistributionData: null,
        })
        clearPartnerAction({
          storeCurrentPartnerId: null,
          storeCurrentPartnerData: null,
          storeAllPartnersData: null,
        })
        removeUserInfoAction()
        removePartnerInfoAction()

        setCurrentUploadFiles(0)
        setTotalUploadFiles(0)

        // resetCountSaveFunctionCall()

        history.push('/login')
      }}
    >
      <p className={styles.text}>
        If you still decide to log out - we will stop the uploading
      </p>
    </Modal>
  )
}

export default LogOutModal
