import axios, { Axios } from 'axios'
import { axiosInstance } from '.'

export const StatisticsAPI = {
  getTotalViews: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/total-views?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getUniqueUsers: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/unique-users?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getAllVisits: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/start-apps?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getViewsByDays: async (
    distributionId: number,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/views-by-days?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getVideoViews: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/video-views?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getViewsByPlatform: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/view-by-platform?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getViewsByCountry: async (
    distributionId: string,
    from: string,
    before: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/view-by-country?distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getComboDataByMethods: async (
    distributionId: string,
    from: Date | string,
    before: Date | string,
    methods: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/combo?methods=${methods}&distributionId=${distributionId}&from=${from}&before=${before}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },

  getMonitization: async (
    from: Date | string,
    before: Date | string,
    pcidNameDistribution: string,
    controller: null | AbortController | undefined
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/stats/monitization?from=${from}&before=${before}&pcidName=${pcidNameDistribution}`,
      {
        signal: controller ? controller.signal : undefined,
      }
    )

    return data
  },
}
