export enum ProgressBarActionsTypes {
  CHANGE_CURRENT_UPLOAD_SIZE = 'CHANGE_CURRENT_UPLOAD_SIZE',
  CHANGE_TOTAL_UPLOAD_SIZE = 'CHANGE_TOTAL_UPLOAD_SIZE',
  CLEAR_CURRENT_UPLOAD_SIZE = 'CLEAR_CURRENT_UPLOAD_SIZE',
  CLEAR_TOTAL_UPLOAD_SIZE = 'CLEAR_TOTAL_UPLOAD_SIZE',
  CHANGE_TOTAL_UPLOAD_FILES = 'CHANGE_TOTAL_UPLOAD_FILES',
  CHANGE_CURRENT_UPLOAD_FILES = 'CHANGE_CURRENT_UPLOAD_FILES',
  INCREMENT_FUNCTION_CALL_COUNT = 'INCREMENT_FUNCTION_CALL_COUNT',
  DECREMENT_FUNCTION_CALL_COUNT = ' DECREMENT_FUNCTION_CALL_COUNT',
  RESET_FUNCTION_CALL_COUNT = 'RESET_FUNCTION_CALL_COUNT',
}

interface ProgressBarCurrentUploadSizeAction {
  type: ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_SIZE
  payload: { value: number; progressLoaded: number }
}

interface ProgressBarTotalUploadSizeAction {
  type: ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_SIZE
  payload: number
}

interface ProgressBarClearTotalUploadSizeAction {
  type: ProgressBarActionsTypes.CLEAR_TOTAL_UPLOAD_SIZE
  payload: number
}

interface ProgressBarClearCurrentUploadSizeAction {
  type: ProgressBarActionsTypes.CLEAR_CURRENT_UPLOAD_SIZE
  payload: number
}

interface ProgressBarCurrentUploadFilesAction {
  type: ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_FILES
  payload: number
}

interface ProgressBarTotalUploadFilesAction {
  type: ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_FILES
  payload: number
}

interface IncrementFunctionCallAction {
  type: ProgressBarActionsTypes.INCREMENT_FUNCTION_CALL_COUNT
  payload: number
}

interface DecrementFunctionCallAction {
  type: ProgressBarActionsTypes.DECREMENT_FUNCTION_CALL_COUNT
  payload: number
}

interface ResetFunctionCallAction {
  type: ProgressBarActionsTypes.RESET_FUNCTION_CALL_COUNT
  payload: number
}

export type IProgressBarAction =
  | ProgressBarCurrentUploadSizeAction
  | ProgressBarTotalUploadSizeAction
  | ProgressBarCurrentUploadFilesAction
  | ProgressBarTotalUploadFilesAction
  | ProgressBarClearCurrentUploadSizeAction
  | ProgressBarClearTotalUploadSizeAction
  | IncrementFunctionCallAction
  | DecrementFunctionCallAction
  | ResetFunctionCallAction

export interface IProgressBarState {
  currentUploadSize: number
  totalUploadSize: number
  totalUploadFiles: number
  currentUploadFiles: number
  countFunctionCall: number
}
