import { loadavg } from 'os'
import { IUserAction, IUserState, UserActionsTypes } from 'store/types/user'

const initialState: IUserState = {
  user: null,
  userInfo: null,
  partnerInfo: null,
  loading: false,
  error: undefined,
}

export const userReducer = (
  state = initialState,
  action: IUserAction
): IUserState => {
  switch (action.type) {
    case UserActionsTypes.START_LOGIN:
      return { ...state, loading: true }

    case UserActionsTypes.SUCCESS_LOGIN:
      return { ...state, loading: false }

    case UserActionsTypes.SAVE_USER:
      return {
        ...state,
        user: action.payload,
        error: undefined,
        loading: false,
      }

    case UserActionsTypes.SAVE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      }

    case UserActionsTypes.REMOVE_USER_INFO:
      return {
        ...state,
        userInfo: null,
      }

    case UserActionsTypes.SAVE_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: action.payload,
      }

    case UserActionsTypes.REMOVE_PARTNER_INFO:
      return {
        ...state,
        partnerInfo: null,
      }

    case UserActionsTypes.REMOVE_USER:
      return {
        ...state,
        user: null,
      }

    case UserActionsTypes.ERROR_USER:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
