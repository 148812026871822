import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { message, Spin } from 'antd'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import AddVideosTopBar from 'components/AddVideos/AddVideosTopBar'
import VimeoSideBar from 'components/AddVideos/VimeoSideBar'
import ModalEditVideoDescription from 'components/ModalEditVideoDescription'

import { useActions } from 'store/hooks/useActions'

import { ICategory, IResponseCategories } from 'models/category'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { CategoryAPI } from 'api/VideoCollection/Category'
import { VideoSyncAPI } from 'api/VideoSync/VideoSync'

import { getPartnerId, getVimeoItemId, redirectToURL } from 'utils/helper'
import { IMediaItem } from 'models/mediaItem'

import { MediaPlaylistAPI } from 'api/VideoCollection/MediaPlaylist'

import VimeoCategory from 'components/AddVideos/VimeoCategory'
import ConnectAppForm from 'components/ConnectAppForm'
import VimeoAppVideos from 'components/VimeoAppVideos'

import playlistSrc from 'sources/images/appVideos/playlist.png'

import { ReactComponent as VimeoIcon } from 'sources/images/content/vimeo-form.svg'

import styles from './styles.module.scss'

interface IProps {
  vimeoMediaSources: any
  setIsDoneUploadVimeoMediaItems: React.Dispatch<React.SetStateAction<boolean>>
}

const stepForScrollMediaItems = 50
let inVimeoCategory = false

const Vimeo: FC<IProps> = ({
  vimeoMediaSources,
  setIsDoneUploadVimeoMediaItems,
}) => {
  const { id, distributionId } = useParams()
  const navigate = useNavigate()

  const [url, setUrl] = useState('')
  const [category, setCategory] = useState<ICategory | null>(null)
  const [allMediaItemsInCategory, setAllMediaItemsInCategory] = useState<any>(
    []
  )

  const [selectedUploadMediaItemsId, setSelectedUploadMediaItemsId] = useState<
    string[]
  >([])

  const [addedMediaItems, setAddedMediaItems] = useState<any>([])
  const [isOpenMediaItemsControls, setIsOpenMediaItemsControls] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingVimeoData, setIsLoadingVimeoData] = useState(false)

  const [currentVimeoPlaylists, setCurrentVimeoPlaylists] = useState([])
  const [uploadVimeoMediaItems, setUploadVimeoMediaItems] = useState<any>([])

  const [filteredMediaItems, setFilteredMediaItems] = useState<any>([])
  const [isFiltered, setIsFiltered] = useState(false)

  const [isVisibleConnectForm, setIsVisibleConnectForm] = useState(true)

  const [currentVimeoMediaSourceId, setCurrentVimeoMediaSourceId] = useState<
    null | number
  >(null)

  const [initCategoryMediaItems, setInitCategoryMediaItems] = useState<
    null | []
  >(null)

  const { storeCurrentDistributionData, storeCurrentDistributionId } =
    useTypedSelector(state => state.distribution)
  const [showcasesData, setShowcasesData] = useState<any>([])
  const [totalPlaylistsMediaItems, setTotalPlaylistsMediaItems] = useState(0)
  const [
    totalImportedPlaylistsMediaItems,
    setTotalImportedPlaylistsMediaItems,
  ] = useState(0)
  const [editCategoryDescription, setEditCategoryDescription] = useState<any>()

  const [isChangeOrder, setIsChangeOrder] = useState(false)
  const [isChangeDescription, setIsChangeDescription] = useState(false)
  const [isChangeSync, setIsChangeSync] = useState(false)

  const [mediaPlaylists, setMediaPlaylists] = useState<any>([])
  const [mediaPlaylistData, setMediaPlaylistData] = useState<any>([])

  // edit description
  const [isOpenEditDescription, setIsOpenEditDescription] = useState(false)
  const [editDescriptionMediaItems, setEditDescriptionMediaItems] =
    useState<any>([])
  const [descriptionData, setDescriptionData] = useState<any>([])
  const [currentPlaylistDescription, setCurrentPlaylistDescription] =
    useState('')

  const { userInfo } = useTypedSelector(state => state.user)
  const [
    countAllMediaItemsInDistribution,
    setCountAllMediaItemsInDistribution,
  ] = useState(0)

  // one playlist
  const [isOnePlaylist, setIsOnePlaylist] = useState(false)
  const [onePlayListData, setOnePlaylistData] = useState<any>([])

  // scroll
  const [renderMediaItems, setRenderMediaItems] = useState<any>([])
  const [fetching, setFetching] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const { saveCurrentDistributionDataAction } = useActions()
  const path = useLocation().pathname

  // drag and drop uploaded videos
  const draggingUploadMediaItemRef = useRef<HTMLElement | null>(null)
  const draggingMediaItemRef = useRef<HTMLElement | null>(null)

  const [uniqVimeoVideosWithoutShowcase, setUniqVimeoVideosWithoutShowcase] =
    useState<any>([])

  const scrollHandler = (e: any): void => {
    const target = e.target as HTMLElement
    const isVideosTab = target.dataset.tab === 'videos'

    if (
      isVideosTab &&
      target.scrollHeight - (target.scrollTop + target.offsetHeight) < 100 &&
      renderMediaItems.length < filteredMediaItems.length
    ) {
      setFetching(true)
    }
  }

  useEffect(() => {
    if (fetching) {
      try {
        const startIndex = stepForScrollMediaItems * currentPage
        const endIndex = startIndex + stepForScrollMediaItems

        const sliceMediaItems = filteredMediaItems.slice(startIndex, endIndex)
        const sliceForRenderMediaItems = [
          ...renderMediaItems,
          ...sliceMediaItems,
        ]

        setRenderMediaItems(sliceForRenderMediaItems)
        setCurrentPage(prevPage => prevPage + 1)
      } catch (error) {
        console.log(error)
      } finally {
        setFetching(false)
      }
    }
  }, [fetching])

  useEffect(() => {
    if (isFiltered || onePlayListData) {
      const sliceMediaItems = filteredMediaItems.slice(
        0,
        stepForScrollMediaItems
      )

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    } else {
      const sliceMediaItems = uploadVimeoMediaItems.slice(
        0,
        stepForScrollMediaItems
      )

      setRenderMediaItems(sliceMediaItems)
      setCurrentPage(1)
    }
  }, [filteredMediaItems])

  useEffect(() => {
    if (isOnePlaylist) {
      setFilteredMediaItems(onePlayListData.videoList)
      const sliceMediaItems = onePlayListData.videoList.slice(
        0,
        stepForScrollMediaItems
      )
      setRenderMediaItems(sliceMediaItems)
    } else {
      setFilteredMediaItems(uploadVimeoMediaItems)
      setRenderMediaItems(uploadVimeoMediaItems)
    }

    // console.log('onePlayListData.videoList', onePlayListData.videoList)
    // console.log('isOnePlaylist', isOnePlaylist)
  }, [isOnePlaylist])

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setIsDoneUploadVimeoMediaItems(false)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)
      let currentDistributionData: any = null

      if (storeCurrentDistributionData) {
        const storeCategory = storeCurrentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }
      } else if (partnerId && storeCurrentDistributionId) {
        const responseCurrentDistributionData: any =
          await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            storeCurrentDistributionId,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

        currentDistributionData =
          responseCurrentDistributionData.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )

        const storeCategory = currentDistributionData.find(
          (item: any) => item.id === Number(id)
        )

        if (storeCategory.MediaItems) {
          setInitCategoryMediaItems(storeCategory.MediaItems)
        }

        saveCurrentDistributionDataAction(currentDistributionData)
      }

      if (partnerId) {
        const response: IResponseCategories = await CategoryAPI.getCategory(
          partnerId,
          Number(distributionId),
          Number(id),
          { withMediaItems: 'true', withMediaItemImages: 'true' }
        )
        setCategory(response.categories[0])
        const mediaItems = response.categories[0].MediaItems

        if (partnerId && storeCurrentDistributionId && response.categories[0]) {
          const allDistributionsMediaItemsResponse: any =
            await MediaItemAPI.getAllMediaItemsForDistribution(
              partnerId,
              storeCurrentDistributionId
            )

          if (allDistributionsMediaItemsResponse.status === 'success') {
            setCountAllMediaItemsInDistribution(
              allDistributionsMediaItemsResponse.mediaitems.length
            )
          }

          const mediaPlaylistResponse: any =
            await MediaPlaylistAPI.getAllMediaPlaylistsForCategory(
              partnerId,
              storeCurrentDistributionId,
              response.categories[0].id
            )

          if (mediaPlaylistResponse.status === 'success') {
            setMediaPlaylists(
              mediaPlaylistResponse.mediaPlaylists.filter(
                (mediaPlaylistItem: any) =>
                  mediaPlaylistItem.sourceType === 'vimeo'
              )
            )
          }
        }

        if (mediaItems) {
          setAllMediaItemsInCategory(
            mediaItems
              .map((item: any) => {
                const cloneItem = _.cloneDeep(item)
                const smallImgObj =
                  cloneItem.Images.length > 0 &&
                  cloneItem.Images.find(
                    (itemImg: any) => itemImg.tag === 'Small'
                  )

                if (smallImgObj) {
                  smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
                }

                cloneItem.Images = [
                  cloneItem.Images.filter(
                    (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
                  ),
                  smallImgObj,
                ]

                return cloneItem
              })
              .sort(
                (a: IMediaItem, b: IMediaItem) =>
                  a.CategoryMediaItem.orderNumber -
                  b.CategoryMediaItem.orderNumber
              )
          )
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onGetVimeoDataAccount = async (
    accountId: number,
    selectedMediaSourceId: number
  ): Promise<void> => {
    try {
      setIsLoadingVimeoData(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()
      setShowcasesData([])
      setTotalPlaylistsMediaItems(0)
      setTotalImportedPlaylistsMediaItems(0)
      setIsOnePlaylist(false)

      if (accountId && partnerId) {
        // all vimeo videos without showcases uniqAllVideos === allUploadVimeoMediaItems
        const responseAllVimeoVideos: any =
          await VideoSyncAPI.getVimeoVideosByVimeoUserId(accountId, partnerId)

        const response: any = await VideoSyncAPI.getVimeoShowcases(
          accountId,
          partnerId
        )
        setCurrentVimeoPlaylists(response.showcases)

        const mediaSourceId = vimeoMediaSources.find(
          (mediaSources: any) => mediaSources.id === selectedMediaSourceId
        )

        // console.log('accountId', accountId)
        // console.log('selectedMediaSourceId', selectedMediaSourceId)
        // console.log('mediaSourceId.id', mediaSourceId.id)
        setCurrentVimeoMediaSourceId(mediaSourceId.id)

        const showcasesDataArr: any = []

        if (response.showcases.length > 0) {
          const responseArr = response.showcases.map((showcase: any) => {
            const showcaseId = getVimeoItemId(showcase.uri)
            const categoryDescription =
              category?.description === null ? '' : category?.description
            const showcaseDescription =
              showcase.description === null ? '' : showcase.description
            const showcaseMediaPlaylist = mediaPlaylists.find(
              (item: any) => item.playlistId === showcaseId
            )

            showcasesDataArr.push({
              isAsyncMediaPlaylist: showcaseMediaPlaylist
                ? showcaseMediaPlaylist.autoSync
                : false,
              showcaseLink: showcase.link,
              categoryDescription,
              showcaseVimeoId: showcaseId,
              showcasePicture: showcase.pictures.base_link
                ? showcase.pictures.base_link
                : playlistSrc,
              showcaseName: showcase.name,
              showcaseDescription,
              checkDescription: false,
            })

            return VideoSyncAPI.getVimeoVideosByShowcaseId(
              accountId,
              showcaseId
            )
          })

          const responsesMediaItems: any = await Promise.allSettled(responseArr)
          const allMediaItems = responsesMediaItems.map(
            (responseMediaItem: any) => {
              const { videoList } = responseMediaItem.value

              return videoList
            }
          )

          const prepareShowcasesData: any = showcasesDataArr.map(
            (item: any, idx: number) => ({
              ...item,
              totalVideos: allMediaItems[idx].length,
              videoList: allMediaItems[idx],
            })
          )

          setShowcasesData(prepareShowcasesData)

          const allUploadVimeoMediaItems = _.uniqBy(allMediaItems.flat(), 'uri')
          const allMediaItemsFromStore = storeCurrentDistributionData
            .map((item: any) => item.MediaItems)
            .flat()

          // filter all vimeo videos without showcases uniqAllVideos === allUploadVimeoMediaItems
          const uniqVimeoVideosWithoutShowcaseItems =
            responseAllVimeoVideos.videoList.filter((mediaItem: any) => {
              const vimeoMediaItemId =
                mediaItem && getVimeoItemId(mediaItem.uri)

              return !allUploadVimeoMediaItems.find(
                (uploadItem: any) =>
                  vimeoMediaItemId === getVimeoItemId(uploadItem.uri)
              )
            })

          const uniqAllVideos = [
            ...uniqVimeoVideosWithoutShowcaseItems,
            ...allUploadVimeoMediaItems,
          ]

          // console.log('uniqAllVideos', uniqAllVideos)

          setUniqVimeoVideosWithoutShowcase(uniqVimeoVideosWithoutShowcaseItems)

          const totalImportedMediaItems = _.uniqBy(
            allMediaItemsFromStore.filter((item: any) =>
              uniqAllVideos.some((uploadItem: any) => {
                const vimeoId = getVimeoItemId(uploadItem.uri)

                return vimeoId === item.externalId
              })
            ),
            'id'
          ).length

          setTotalPlaylistsMediaItems(uniqAllVideos.length)
          setTotalImportedPlaylistsMediaItems(totalImportedMediaItems)

          setUploadVimeoMediaItems(uniqAllVideos)
          setFilteredMediaItems(uniqAllVideos)

          const sliceMediaItems = uniqAllVideos.slice(
            0,
            stepForScrollMediaItems
          )
          setRenderMediaItems(sliceMediaItems)
        } else {
          setUploadVimeoMediaItems([])
          setFilteredMediaItems([])
          setRenderMediaItems([])
        }
      }

      setIsVisibleConnectForm(false)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingVimeoData(false)
    }
  }

  const onGenerateVimeoURL = async (sourceType: string): Promise<void> => {
    const response: any = await VideoSyncAPI.generatedOAuth2URL(sourceType)
    setUrl(response.url)

    redirectToURL(response.url)
  }

  const onAddNewAccount = (): void => {
    setIsVisibleConnectForm(true)
  }

  const onCloseControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(false)
  }

  const onOpenControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(true)
  }

  const changeSelectedMediaItemsId = (
    newId: string,
    isRemoved: boolean
  ): void => {
    if (isRemoved) {
      const filteredIds = selectedUploadMediaItemsId.filter(
        (itemId: string) => itemId !== newId
      )
      setSelectedUploadMediaItemsId(filteredIds)
    } else {
      setSelectedUploadMediaItemsId([...selectedUploadMediaItemsId, newId])
    }
  }

  const onSelectUploadMediaItem = (e: CheckboxChangeEvent): void => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItem = target.closest('[data-upload-item]')

    onOpenControlsSelectedMediaItems()

    if (target.checked && uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, false)
    } else if (uploadMediaItem) {
      changeSelectedMediaItemsId(uploadMediaItem.id, true)
    }
  }

  const onSelectAllUploadMediaItem = (
    e: CheckboxChangeEvent
  ): void | string => {
    const target = e.nativeEvent.target as HTMLInputElement
    const uploadMediaItemsElements =
      document.querySelectorAll('[data-upload-item]')

    onOpenControlsSelectedMediaItems()

    if (!target.checked) {
      onCloseControlsSelectedMediaItems()
      setSelectedUploadMediaItemsId([])
    } else {
      const ids = Array.from(uploadMediaItemsElements).map((item: Element) => {
        if (
          allMediaItemsInCategory.some(
            (mediaItem: any) =>
              (mediaItem.uri && item.id === getVimeoItemId(mediaItem.uri)) ||
              initCategoryMediaItems?.some(
                (initItem: any) => initItem.externalId === item.id
              )
          )
        ) {
          return ''
        }

        if (target.checked && item) {
          changeSelectedMediaItemsId(item.id, false)
        } else if (item) {
          changeSelectedMediaItemsId(item.id, true)
        }

        return item.id
      })

      setSelectedUploadMediaItemsId([...ids])
    }
  }

  const onDeleteAddedMediaItemInCategory = (removedId: string): void => {
    if (
      initCategoryMediaItems?.find(
        (initItem: any) => String(initItem.id) === removedId
      )
    ) {
      return
    }

    setDescriptionData(
      descriptionData.filter((item: any) => item.id !== removedId)
    )

    setAllMediaItemsInCategory(
      allMediaItemsInCategory.filter(
        (mediaItem: any) =>
          mediaItem.uri === undefined ||
          getVimeoItemId(mediaItem.uri) !== removedId
      )
    )
  }

  const onAddSelectedMediaItemsInCategory = (): void => {
    const addMediaItems: any = selectedUploadMediaItemsId.map(selectedId =>
      uploadVimeoMediaItems.filter(
        (mediaItem: any) => getVimeoItemId(mediaItem.uri) === selectedId
      )
    )

    setEditDescriptionMediaItems(addMediaItems.flat())

    setSelectedUploadMediaItemsId([])
    setAllMediaItemsInCategory([
      ...addMediaItems.flat(),
      ...allMediaItemsInCategory,
    ])
  }

  const handleDragStartUploadMediaItems = (e: any): void => {
    // console.log('DRAG START  UPLOAD')
    const target = e.target as HTMLElement
    const uploadItem = target.closest('[data-upload-item]') as HTMLElement

    if (
      uploadItem &&
      !allMediaItemsInCategory.find((item: any) =>
        item.uri
          ? uploadItem.id === getVimeoItemId(item.uri)
          : uploadItem.id === String(item.id)
      )
    ) {
      draggingUploadMediaItemRef.current = uploadItem
    }
  }

  const handleDragEnterUploadMediaItems = (e: any): void => {
    // console.log('DRAG ENTER UPLOAD')
    const { target } = e
  }

  const handleDragDropUploadMediaItems = (e: any): void => {
    // console.log('DRAG DROP UPLOAD')
    e.preventDefault()
  }

  const handleDragEndUploadMediaItems = (e: any): void => {
    // console.log('DRAG END UPLOAD')
    e.preventDefault()

    if (draggingUploadMediaItemRef.current && inVimeoCategory) {
      const findMediaItem = uploadVimeoMediaItems.find(
        (item: any) =>
          item.uri &&
          draggingUploadMediaItemRef?.current?.id === getVimeoItemId(item.uri)
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadVimeoMediaItems.filter(
        (item: any) =>
          selectedUploadMediaItemsCurrentId.some(
            (currentId: string) =>
              item.uri && currentId === getVimeoItemId(item.uri)
          )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        if (findMediaItem) {
          const updateAllMediaItemsInCategory = _.cloneDeep(
            allMediaItemsInCategory
          )

          updateAllMediaItemsInCategory.splice(
            findIndex,
            0,
            findMediaItem,
            ...filteredUploadMediaItems
          )

          setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
        }
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inVimeoCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const handleDragEnterCategory = (e: any): void => {
    if (draggingUploadMediaItemRef.current) {
      const target = e.target as HTMLElement

      inVimeoCategory = true

      const li = document.createElement('li')
      li.classList.add(styles.tempMediaItem)
      li.dataset.temp = 'temp'
      li.setAttribute('draggable', 'true')
      li.setAttribute('data-media-item-order', '')
      li.id = draggingUploadMediaItemRef.current.id

      const parentList = document.querySelector('[data-drag-list]')
      parentList?.prepend(li)

      if (inVimeoCategory) {
        const underLi = target.closest('[data-media-item-order]') as any

        if (parentList && underLi && li) {
          if (!underLi.dataset.temp) {
            if (underLi.nextSibling && underLi.nextSibling.dataset.temp) {
              parentList.insertBefore(underLi.nextSibling, underLi)
            } else {
              parentList.insertBefore(li, underLi.nextSibling)
            }
          }
        }
      }
    }
  }

  const handleDragLeaveCategory = (e: any): void => {
    // console.log('DRAG LEAVE  CATEGORY')

    const tempItem = document.querySelector('[data-temp]')
    tempItem?.remove()
  }

  const handleDragOverCategory = (e: any): void => {
    // console.log('DRAG OVER  CATEGORY')
    e.preventDefault()
  }

  const handleDropCategory = (e: any): void => {
    // console.log('DRAG DROP  CATEGORY')

    if (draggingUploadMediaItemRef.current && inVimeoCategory) {
      const findMediaItem = uploadVimeoMediaItems.find(
        (item: any) =>
          item.uri &&
          draggingUploadMediaItemRef?.current?.id === getVimeoItemId(item.uri)
      )

      const selectedUploadMediaItemsCurrentId =
        selectedUploadMediaItemsId.filter(
          (currentId: string) =>
            draggingUploadMediaItemRef?.current?.id !== currentId ||
            draggingUploadMediaItemRef?.current?.id !== currentId
        )

      const filteredUploadMediaItems = uploadVimeoMediaItems.filter(
        (item: any) =>
          selectedUploadMediaItemsCurrentId.some(
            (currentId: string) =>
              item.uri && currentId === getVimeoItemId(item.uri)
          )
      )

      const mediaItemsLi = document.querySelectorAll('[data-media-item-order]')
      const findIndex = Array.from(mediaItemsLi).findIndex(
        (li: any) => li && li.dataset.temp
      )

      if (findMediaItem) {
        const updateAllMediaItemsInCategory = _.cloneDeep(
          allMediaItemsInCategory
        )

        updateAllMediaItemsInCategory.splice(
          findIndex,
          0,
          findMediaItem,
          ...filteredUploadMediaItems
        )

        setAllMediaItemsInCategory(updateAllMediaItemsInCategory)
      }

      draggingUploadMediaItemRef.current = null
      draggingMediaItemRef.current = null
      inVimeoCategory = false

      setSelectedUploadMediaItemsId([])

      const tempItem = document.querySelector('[data-temp]')
      tempItem?.remove()
    }
  }

  const onSaveAddedMediaItemsInCategory = async (): Promise<void> => {
    try {
      // if (isUserMediaItemsLimit()) {
      //   return
      // }

      // console.log('descriptionData', descriptionData)

      navigate('/content')

      const currentDistributionId = category?.DistributionId
      const categoryId = category?.id
      const partnerId = storeCurrentPartnerId || getPartnerId()
      const saveAddedMediaItems = allMediaItemsInCategory.filter(
        (mediaItem: any) => mediaItem.uri
      )

      if (currentDistributionId && partnerId && categoryId) {
        const createMediaItemPromiseArr = saveAddedMediaItems.map(
          (mediaItem: any) => {
            const isEditDescription = descriptionData.find(
              (item: any) => item.id === getVimeoItemId(mediaItem.uri)
            )
            const mediaItemDescription =
              mediaItem.description === null ? '' : mediaItem.description

            const descriptionValue = isEditDescription
              ? isEditDescription.description
              : mediaItemDescription

            return VideoSyncAPI.addVideoByURL(
              partnerId,
              currentDistributionId,
              'vimeo',
              {
                url: mediaItem.link,
                mediaSourceId: currentVimeoMediaSourceId,
                categoryIds: categoryId,
                description: descriptionValue,
              }
            )
          }
        )
        const responses: any = await Promise.allSettled(
          createMediaItemPromiseArr
        )

        // mediaPlaylist sync
        const prepareSyncMediaPlaylist = mediaPlaylistData
          .filter(
            (item: any) =>
              item.isAsyncMediaPlaylist &&
              !mediaPlaylists.find(
                (mediaPlaylistItem: any) =>
                  mediaPlaylistItem.playlistId === item.showcaseVimeoId
              )
          )
          .map((filterItem: any) => {
            const bodyPlaylist: any = {
              name: filterItem.showcaseName,
              description: filterItem.showcaseDescription,
              sourceType: 'vimeo',
              url: filterItem.showcaseLink,
              autoSync: true,
              playlistId: filterItem.showcaseVimeoId,
              mediaSourceId: currentVimeoMediaSourceId,
            }

            return bodyPlaylist
          })

        const promiseSyncMediaItemsArr = prepareSyncMediaPlaylist.map(
          (body: any) =>
            MediaPlaylistAPI.syncPlaylistToCategory(
              partnerId,
              currentDistributionId,
              category.id,
              body
            )
        )

        const responsesSyncMediaPlaylist: any = await Promise.allSettled(
          promiseSyncMediaItemsArr
        )

        // mediaPlaylist unsync
        const prepareUnSyncMediaPlaylist = mediaPlaylistData.filter(
          (item: any) =>
            !item.isAsyncMediaPlaylist &&
            mediaPlaylists.find(
              (mediaPlaylistItem: any) =>
                mediaPlaylistItem.playlistId === item.showcaseVimeoId
            )
        )

        const unSyncMediaPlaylistIds = mediaPlaylists
          .filter((item: any) =>
            prepareUnSyncMediaPlaylist.find(
              (unSyncItem: any) =>
                item.playlistId === unSyncItem.showcaseVimeoId
            )
          )
          .map((element: any) => element.id)

        const promiseUnSyncMediaItemsArr = unSyncMediaPlaylistIds.map(
          (mediaPlaylistId: string) =>
            MediaPlaylistAPI.unSyncPlaylistToCategory(
              partnerId,
              currentDistributionId,
              category.id,
              mediaPlaylistId
            )
        )

        const responsesMediaPlaylist: any = await Promise.allSettled(
          promiseUnSyncMediaItemsArr
        )

        // category description
        // if (isChangeDescription) {
        //   const cloneCategory = category
        //   cloneCategory.description = editCategoryDescription
        //   CategoryAPI.updateCategory(
        //     partnerId,
        //     currentDistributionId,
        //     cloneCategory.id,
        //     cloneCategory
        //   )
        // }

        if (responses.some((result: any) => result.status === 'rejected')) {
          message.warning('Some videos not added!', 3)
        }

        const createdMediaItems: any = responses
          .filter((result: any) => result.status === 'fulfilled')
          .map((response: any) => response.value.mediaitem)

        const allMediaItems = allMediaItemsInCategory.map((item: any) => {
          if (item.id !== undefined) {
            return item
          }
          const replaceItem = createdMediaItems.find(
            (mediaItem: IMediaItem) => item.link === mediaItem.sourceUrl
          )
          return replaceItem
        })

        for (let i = 0; i < allMediaItems.length; i += 1) {
          const mediaItem = allMediaItems[i]
          const response = await MediaItemAPI.setOrderNumberForMediaItem(
            partnerId,
            currentDistributionId,
            mediaItem.id,
            categoryId,
            i + 1
          )
        }

        setIsDoneUploadVimeoMediaItems(true)
        message.success(
          `Videos were added in ${category.name} category from vimeo!`,
          3
        )
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.log(error.response.data.message)
        }
      }
    }
  }

  const onSearchUploadMediaItems = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setIsFiltered(true)

    if (isOnePlaylist) {
      if (value === '') {
        setIsFiltered(false)
        setFilteredMediaItems(onePlayListData.videoList)
      }

      setFilteredMediaItems(
        onePlayListData.videoList.filter(
          (item: any) =>
            (item.name &&
              item.name.toLowerCase().includes(value.toLowerCase())) ||
            (item.description &&
              item.description.toLowerCase().includes(value.toLowerCase()))
        )
      )
    } else {
      if (value === '') {
        setIsFiltered(false)
        setFilteredMediaItems(uploadVimeoMediaItems)
      }

      setFilteredMediaItems(
        uploadVimeoMediaItems.filter(
          (item: any) =>
            (item.name &&
              item.name.toLowerCase().includes(value.toLowerCase())) ||
            (item.description &&
              item.description.toLowerCase().includes(value.toLowerCase()))
        )
      )
    }
  }

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div
      className={styles.mainWrapper}
      role="presentation"
      onDragEnter={e => {
        const target = e.target as HTMLElement

        if (!target.closest('[data-category]')) {
          inVimeoCategory = false
        }
      }}
    >
      <AddVideosTopBar
        isConnected
        isChangeOrder={isChangeOrder}
        isChangeDescription={isChangeDescription}
        isChangeSync={isChangeSync}
        allMediaItemsInCategory={allMediaItemsInCategory}
        onSaveAddedMediaItemsInCategory={onSaveAddedMediaItemsInCategory}
        totalPlaylistsMediaItems={totalPlaylistsMediaItems}
        totalImportedPlaylistsMediaItems={totalImportedPlaylistsMediaItems}
      />

      <div
        className={cn(styles.mainContent, {
          [styles.mainContentWithForm]: isVisibleConnectForm,
        })}
      >
        <VimeoSideBar
          vimeoSubmenu={vimeoMediaSources}
          onGetVimeoDataAccount={onGetVimeoDataAccount}
          onAddNewAccount={onAddNewAccount}
        />

        {isLoadingVimeoData ? (
          <Spin size="large" className={styles.spinnerVimeoData} />
        ) : vimeoMediaSources.length === 0 || isVisibleConnectForm ? (
          <div className={styles.section}>
            <ConnectAppForm
              icon={<VimeoIcon />}
              appName="vimeo"
              onGenerateVimeoURL={onGenerateVimeoURL}
            />
          </div>
        ) : (
          <VimeoAppVideos
            initCategoryMediaItems={initCategoryMediaItems}
            onSearchUploadMediaItems={onSearchUploadMediaItems}
            isFiltered={isFiltered}
            filteredMediaItems={filteredMediaItems}
            vimeoPlaylists={currentVimeoPlaylists}
            uploadVimeoMediaItems={uploadVimeoMediaItems}
            allMediaItemsInCategory={allMediaItemsInCategory}
            addedMediaItems={addedMediaItems}
            onSelectUploadMediaItem={onSelectUploadMediaItem}
            onSelectAllUploadMediaItem={onSelectAllUploadMediaItem}
            selectedUploadMediaItemsId={selectedUploadMediaItemsId}
            showcasesData={showcasesData}
            setAllMediaItemsInCategory={setAllMediaItemsInCategory}
            setEditCategoryDescription={setEditCategoryDescription}
            setIsChangeDescription={setIsChangeDescription}
            mediaPlaylists={mediaPlaylists}
            setIsChangeSync={setIsChangeSync}
            setMediaPlaylistData={setMediaPlaylistData}
            renderMediaItems={renderMediaItems}
            scrollHandler={scrollHandler}
            fetching={fetching}
            setIsOpenEditDescription={setIsOpenEditDescription}
            setEditDescriptionMediaItems={setEditDescriptionMediaItems}
            uniqVimeoVideosWithoutShowcase={uniqVimeoVideosWithoutShowcase}
            setCurrentPlaylistDescription={setCurrentPlaylistDescription}
            setOnePlaylistData={setOnePlaylistData}
            onePlayListData={onePlayListData}
            setIsOnePlaylist={setIsOnePlaylist}
            draggingUploadMediaItemRef={draggingUploadMediaItemRef}
            handleDragStartUploadMediaItems={handleDragStartUploadMediaItems}
            handleDragEnterUploadMediaItems={handleDragEnterUploadMediaItems}
            handleDragEndUploadMediaItems={handleDragEndUploadMediaItems}
            handleDragDropUploadMediaItems={handleDragDropUploadMediaItems}
          />
        )}
      </div>

      {category && (
        <VimeoCategory
          draggingUploadMediaItemRef={draggingUploadMediaItemRef}
          handleDragEnterCategory={handleDragEnterCategory}
          handleDragLeaveCategory={handleDragLeaveCategory}
          handleDragOverCategory={handleDragOverCategory}
          handleDropCategory={handleDropCategory}
          allMediaItemsInCategory={allMediaItemsInCategory}
          setAllMediaItemsInCategory={setAllMediaItemsInCategory}
          category={category}
          onDeleteAddedMediaItemInCategory={onDeleteAddedMediaItemInCategory}
          onAddSelectedMediaItemsInCategory={onAddSelectedMediaItemsInCategory}
          selectedUploadMediaItemsId={selectedUploadMediaItemsId}
          onCloseControlsSelectedMediaItems={onCloseControlsSelectedMediaItems}
          isOpenMediaItemsControls={isOpenMediaItemsControls}
          setIsChangeOrder={setIsChangeOrder}
          inCategory={inVimeoCategory}
          uploadVimeoMediaItems={uploadVimeoMediaItems}
          setSelectedUploadMediaItemsId={setSelectedUploadMediaItemsId}
          draggingMediaItemRef={draggingMediaItemRef}
        />
      )}

      <ModalEditVideoDescription
        isOpenEditDescription={isOpenEditDescription}
        editDescriptionMediaItems={editDescriptionMediaItems}
        setIsOpenEditDescription={setIsOpenEditDescription}
        setEditDescriptionMediaItems={setEditDescriptionMediaItems}
        descriptionData={descriptionData}
        setDescriptionData={setDescriptionData}
        source="vimeo"
        playlistDescription={currentPlaylistDescription}
        setCurrentPlaylistDescription={setCurrentPlaylistDescription}
      />
    </div>
  )
}

export default Vimeo
