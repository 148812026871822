export enum PartnerActionsTypes {
  SAVE_SUPER_PARTNER_ID = 'SAVE_SUPER_PARTNER_ID',
  SAVE_SUPER_PARTNER_DATA = 'SAVE_SUPER_PARTNER_DATA',
  SAVE_SUPER_ALL_PARTNERS_DATA = 'SAVE_SUPER_ALL_PARTNERS_DATA',
  CLEAR_SUPER_PARTNER = 'CLEAR_SUPER_PARTNER',
}

interface SaveAllPartnersDataAction {
  type: PartnerActionsTypes.SAVE_SUPER_ALL_PARTNERS_DATA
  payload: number | null
}

interface SavePartnerIdAction {
  type: PartnerActionsTypes.SAVE_SUPER_PARTNER_ID
  payload: any | null
}

interface SavePartnerDataAction {
  type: PartnerActionsTypes.SAVE_SUPER_PARTNER_DATA
  payload: any | null
}

interface ClearPartnerAction {
  type: PartnerActionsTypes.CLEAR_SUPER_PARTNER
  payload: any
}

export type IPartnerAction =
  | SavePartnerIdAction
  | SavePartnerDataAction
  | ClearPartnerAction
  | SaveAllPartnersDataAction

export interface IPartnerState {
  storeCurrentPartnerId: number | null
  storeCurrentPartnerData: null | any
  storeAllPartnersData: null | any
}
