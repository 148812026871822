import React, { FC } from 'react'
import { Button } from 'antd'

import styles from './styles.module.scss'

const Upgrade: FC = () => (
  <div className={styles.upgrade}>
    <span className={styles.upgradeText}>
      Upgrade plan for unlimited videos and categories
    </span>
    <Button shape="round" className={styles.upgradeBtn}>
      Upgrade
    </Button>
  </div>
)

export default Upgrade
