import { ColorActionsTypes, IColorAction, IColorState } from 'store/types/color'

const initialState: IColorState = {
  background: '',
  selectedVideo: '',
  text: '',
  popularColors: [
    '#111111',
    '#50E3C2',
    '#34C635',
    '#F8E71C',
    '#896CFC',
    '#2F1D60',
  ],
}

export const colorReducer = (
  state = initialState,
  action: IColorAction
): IColorState => {
  switch (action.type) {
    case ColorActionsTypes.CHANGE_BACKGROUND:
      return { ...state, background: action.payload }

    case ColorActionsTypes.CHANGE_SELECTED_VIDEO:
      return { ...state, selectedVideo: action.payload }

    case ColorActionsTypes.CHANGE_TEXT:
      return { ...state, text: action.payload }

    case ColorActionsTypes.POPULAR_COLORS:
      return { ...state, popularColors: action.payload }

    default:
      return state
  }
}
