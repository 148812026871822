import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, message, Spin } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { useAuth } from 'utils/hooks/useAuth'
import TopBar from 'components/TopBar'

import { ReactComponent as UserIcon } from 'sources/images/login/user.svg'
import { ReactComponent as PasswordIcon } from 'sources/images/login/password.svg'

import styles from './styles.module.scss'

const Login: FC = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const { signIn } = useAuth()

  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const onSubmitForm = (formData: React.FormEvent): void => {
    signIn(formData, () => {
      message.success('Perfect! You signed in', 3)
      navigate('/content', { replace: true })
    })
  }

  useEffect(() => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [])

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div className={styles.loginWrapper}>
      <TopBar
        isLogged={false}
        distributions={[]}
        onChangeDistribution={(value: number) => {
          console.log(value)
        }}
      />

      <div className={styles.content}>
        <div className={styles.formWrapper}>
          <Form className={styles.form} form={form} onFinish={onSubmitForm}>
            <h2 className={styles.title}>Sign in to your account</h2>

            <Form.Item
              rules={[{ required: true }]}
              name="login"
              className={cn(styles.formItem, styles.userName)}
            >
              <Input
                className={styles.formInput}
                prefix={<UserIcon />}
                placeholder="User name"
                autoComplete="false"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUserName(e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              className={styles.formItem}
              rules={[{ required: true }]}
              name="password"
            >
              <Input.Password
                className={styles.formInput}
                prefix={<PasswordIcon />}
                placeholder="Password"
                visibilityToggle={false}
                autoComplete="false"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </Form.Item>

            <a className={styles.forgotLink} href="/">
              Forgot my password
            </a>

            <Button
              className={cn(styles.submitBtn, {
                [styles.active]: !!userName && !!password,
              })}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Login
            </Button>

            <span className={styles.formText}>
              Don’t have an account yet?{' '}
              <Link className={styles.registerLink} to="/register">
                Register
              </Link>
            </span>
          </Form>
        </div>
        <div className={styles.picture} />
      </div>
    </div>
  )
}

export default Login
