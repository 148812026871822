import { Dispatch } from 'redux'

import { IPathAction, PathActionsTypes } from 'store/types/path'

export const savePathAction =
  (path: string) =>
  async (dispatch: Dispatch<IPathAction>): Promise<void> => {
    try {
      dispatch({
        type: PathActionsTypes.SAVE_PATH,
        payload: path,
      })
    } catch (error) {
      console.log(error)
    }
  }
