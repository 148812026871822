import React, { FC, useEffect, useState } from 'react'
import { Button, message, Switch } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { getVimeoItemId } from 'utils/helper'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import styles from './styles.module.scss'

interface IProps {
  vimeoPlaylists: any

  showcasesData: any
  initCategoryMediaItems: any
  allMediaItemsInCategory: any
  setAllMediaItemsInCategory: any
  setEditCategoryDescription: any
  setIsChangeDescription: any
  setAllUnImportedMediaItems: any
  mediaPlaylists: any
  setIsChangeSync: any
  setMediaPlaylistData: any
  setIsOpenEditDescription: any
  setEditDescriptionMediaItems: any
  setOnePlaylistData: React.Dispatch<any>
  setIsOpenPlaylistMediaItems: React.Dispatch<boolean>
  setCurrentPlaylistDescription: React.Dispatch<string>
  setIsVideosTab: React.Dispatch<boolean>

  setIsOnePlaylist: React.Dispatch<boolean>
}

const Playlists: FC<IProps> = ({
  vimeoPlaylists,
  showcasesData,
  initCategoryMediaItems,
  allMediaItemsInCategory,
  setAllMediaItemsInCategory,
  setEditCategoryDescription,
  setIsChangeDescription,
  setAllUnImportedMediaItems,
  mediaPlaylists,
  setIsChangeSync,
  setMediaPlaylistData,
  setIsOpenEditDescription,
  setEditDescriptionMediaItems,
  setOnePlaylistData,
  setIsOpenPlaylistMediaItems,
  setCurrentPlaylistDescription,
  setIsVideosTab,

  setIsOnePlaylist,
}) => {
  const [vimeoShowcases, setVimeoShowcases] = useState<any>([])
  const [
    firstShowcaseWithEqualDescription,
    setFirstShowcaseWithEqualDescription,
  ] = useState<any | undefined>(undefined)

  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const init = (): void => {
    const allDistributionMediaItems = storeCurrentDistributionData
      .map((item: any) => item.MediaItems)
      .flat()

    setFirstShowcaseWithEqualDescription(
      showcasesData.find((item: any) => item.checkDescription)
    )

    const filteredShowcases = showcasesData.map((showcaseData: any) => {
      const updateVideoList = showcaseData.videoList.filter((video: any) => {
        const vimeoMediaItemId = video.uri && getVimeoItemId(video.uri)

        return !allDistributionMediaItems.some(
          (distributionMediaItem: any) =>
            distributionMediaItem.externalId === vimeoMediaItemId
        )
      })

      return {
        ...showcaseData,
        importedVideos: showcaseData.videoList.length - updateVideoList.length,
        unImportedVideoList: updateVideoList,
      }
    })

    setAllUnImportedMediaItems(
      _.uniqBy(
        filteredShowcases.map((item: any) => item.unImportedVideoList).flat(),
        'uri'
      )
    )

    setMediaPlaylistData(filteredShowcases)
    setVimeoShowcases(filteredShowcases)
  }

  useEffect(() => {
    init()
  }, [])

  const onAddAllShowcaseMediaItems = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const currentShowcase = vimeoShowcases.find(
      (item: any) => item.showcaseVimeoId === showcaseId
    )

    if (currentShowcase.checkDescription) {
      setIsOpenEditDescription(true)
      setCurrentPlaylistDescription(currentShowcase.showcaseDescription)
    } else {
      setCurrentPlaylistDescription('')
    }

    const uniqVideoList = currentShowcase.videoList.filter((vimeoItem: any) => {
      const idMediaItemInVimeo = getVimeoItemId(vimeoItem.uri)

      return !allMediaItemsInCategory.find((item: any) => {
        const idMediaItemInCategory = item.uri
          ? getVimeoItemId(item.uri)
          : item.externalId

        return idMediaItemInVimeo === idMediaItemInCategory
      })
    })

    setEditDescriptionMediaItems(uniqVideoList)
    setAllMediaItemsInCategory([...uniqVideoList, ...allMediaItemsInCategory])
  }

  const onAddUnImportedShowcaseMediaItems = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const currentShowcase = vimeoShowcases.find(
      (item: any) => item.showcaseVimeoId === showcaseId
    )

    if (currentShowcase.checkDescription) {
      setIsOpenEditDescription(true)
      setCurrentPlaylistDescription(currentShowcase.showcaseDescription)
    } else {
      setCurrentPlaylistDescription('')
    }

    const uniqVideoList = currentShowcase.unImportedVideoList.filter(
      (vimeoItem: any) => {
        const idMediaItemInVimeo = getVimeoItemId(vimeoItem.uri)

        return !allMediaItemsInCategory.find((item: any) => {
          const idMediaItemInCategory = item.uri
            ? getVimeoItemId(item.uri)
            : item.externalId

          return idMediaItemInVimeo === idMediaItemInCategory
        })
      }
    )

    setEditDescriptionMediaItems(uniqVideoList)
    setAllMediaItemsInCategory([...uniqVideoList, ...allMediaItemsInCategory])
  }

  const onUseShowcaseDescription = (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId
    const cloneShowcases = _.cloneDeep(vimeoShowcases)

    const findShowcase = cloneShowcases.find(
      (item: any) => item.showcaseVimeoId === showcaseId
    )

    setIsChangeDescription(true)
    setFirstShowcaseWithEqualDescription(undefined)

    if (checked) {
      setEditCategoryDescription(findShowcase.showcaseDescription)

      setVimeoShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = true
            return cloneShowcase
          }

          return showcase
        })
      )
      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = true
            return cloneShowcase
          }

          return showcase
        })
      )
    } else {
      setEditCategoryDescription(findShowcase.categoryDescription)

      setVimeoShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = false
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = false
            return cloneShowcase
          }

          return showcase
        })
      )
    }

    // const updateShowcases = cloneShowcases.map((item: any) => {
    //   const showcase = _.cloneDeep(item)

    //   if (checked && showcase.showcaseVimeoId === showcaseId) {
    //     showcase.checkDescription = true
    //   } else {
    //     showcase.checkDescription = false
    //   }

    //   return showcase
    // })

    // setVimeoShowcases(updateShowcases)
  }

  const onSyncPlaylist = (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId
    const cloneShowcases = _.cloneDeep(vimeoShowcases)

    setIsChangeSync(true)

    if (checked) {
      setVimeoShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = true
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = true
            return cloneShowcase
          }

          return showcase
        })
      )

      message.success('Playlist will be sync!', 3)
    } else {
      setVimeoShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = false
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.showcaseVimeoId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = false
            return cloneShowcase
          }

          return showcase
        })
      )

      message.error('Playlist will be not sync!', 3)
    }
  }

  const onShowPlaylistMediaItems = (e: any): void => {
    setIsOpenPlaylistMediaItems(true)
    setIsVideosTab(true)
    setIsOnePlaylist(true)

    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const findShowcase = showcasesData.find(
      (item: any) => item.showcaseVimeoId === showcaseId
    )

    if (findShowcase) {
      setOnePlaylistData(findShowcase)
    }
  }

  return (
    <div className={styles.playlistWrapper}>
      {vimeoShowcases.map((showcase: any) => (
        <div
          className={styles.playlist}
          key={uuidv4()}
          data-showcase-id={showcase.showcaseVimeoId}
        >
          <img
            className={styles.img}
            src={showcase.showcasePicture}
            alt="playlist"
            role="presentation"
            onClick={onShowPlaylistMediaItems}
          />

          <div className={styles.description}>
            <div
              className={styles.imported}
            >{`${showcase.importedVideos} / ${showcase.totalVideos} imported`}</div>
            <div
              className={styles.title}
              role="presentation"
              onClick={onShowPlaylistMediaItems}
            >
              {showcase.showcaseName}
            </div>
            <p className={styles.text}>{showcase.showcaseDescription}</p>
          </div>

          <div className={styles.controls}>
            <div className={styles.syncPlaylist}>
              <span
                className={cn(styles.textSyncPlaylist, {
                  [styles.disabledText]: !showcase.isAsyncMediaPlaylist,
                })}
              >
                Automaticly sync this playlist to category
              </span>
              <Switch
                className={styles.switch}
                onChange={onSyncPlaylist}
                defaultChecked={showcase.isAsyncMediaPlaylist}
              />
            </div>

            <div className={styles.useDescription}>
              <span
                className={cn(styles.textUseDescription, {
                  [styles.disabledText]: !showcase.checkDescription,
                })}
              >
                Use this Description for your Category
              </span>

              <span
              // aria-hidden="true"
              // onClick={
              //   showcase.showcaseDescription === ''
              //     ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              //         message.warning(
              //           'This playlist not have description!',
              //           3
              //         )
              //     : undefined
              // }
              >
                <Switch
                  className={cn(styles.switch, styles.description)}
                  checked={showcase.checkDescription}
                  // disabled={showcase.showcaseDescription === ''}
                  onChange={onUseShowcaseDescription}
                />
              </span>
            </div>

            <div className={styles.buttons}>
              <Button
                className={cn(styles.addUnimportedBtn, {
                  [styles.disabledBtn]:
                    showcase.unImportedVideoList.length === 0,
                })}
                shape="round"
                onClick={onAddUnImportedShowcaseMediaItems}
                disabled={showcase.unImportedVideoList.length === 0}
              >
                Add Only unimported
              </Button>
              <Button
                className={cn(styles.addAllVideosBtn, {
                  [styles.disabledBtn]: showcase.videoList.length === 0,
                })}
                shape="round"
                onClick={onAddAllShowcaseMediaItems}
                disabled={showcase.videoList.length === 0}
              >
                Add all Videos
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Playlists
