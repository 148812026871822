import React, { FC, useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'antd'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { ChromePicker } from 'react-color'
import cn from 'classnames'

import colorPaletteSrc from 'sources/images/branding/color-palette.png'

import { rgbToHex } from 'utils/helper'

import styles from './styles.module.scss'

interface IProps {
  popularColors: string[]
  id: string
  colorPickHandler: (color: string) => void
}

const ColorPicker: FC<IProps> = ({ popularColors, id, colorPickHandler }) => {
  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false)
  const [currentColor, setCurrentColor] = useState('')

  const onCirclePickColor = (e: React.MouseEvent): void => {
    const target = e.target as HTMLElement
    const circles = document.querySelectorAll(
      '[data-id]'
    ) as NodeListOf<HTMLLIElement>

    setIsOpenColorPicker(false)

    Array.from(circles)
      .filter((circle: HTMLLIElement) => circle.dataset.id === id)
      .forEach((circle: HTMLLIElement) => {
        circle.classList.remove(styles.active)
      })

    target.classList.add(styles.active)

    const { color } = target.dataset

    if (color) {
      colorPickHandler(color)
    }
  }

  const init = (): void => {
    const canvas = document.getElementById(id) as HTMLCanvasElement
    const ctx = canvas.getContext('2d')
    const image = new Image()

    image.src = colorPaletteSrc
    image.width = canvas.width
    image.height = canvas.height

    image.addEventListener('load', () => {
      ctx?.drawImage(image, 0, 0)
    })

    canvas.addEventListener('click', (e: MouseEvent) => {
      const canvasLeftGap = canvas.offsetLeft
      const canvasTopGap = canvas.offsetTop

      const actualX = Math.floor(e.pageX - canvasLeftGap)
      const actualY = Math.floor(e.pageY - canvasTopGap)

      const pixelData = ctx?.getImageData(actualX, actualY, 1, 1)
      const data = pixelData?.data

      const pixelColor = data && rgbToHex(data[0], data[1], data[2])

      if (pixelColor) {
        setCurrentColor(pixelColor)
      }
    })
  }

  const onToggleColorPicker = (): void => {
    if (isOpenColorPicker) {
      setIsOpenColorPicker(false)
    } else {
      setIsOpenColorPicker(true)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onChangeComplete = (color: any): void => {
    setCurrentColor(color.hex)
    colorPickHandler(color.hex)
  }

  const onChange = (color: any): void => {
    setCurrentColor(color.hex)
    colorPickHandler(color.hex)
  }

  return (
    <div
      onMouseLeave={() => {
        setIsOpenColorPicker(false)
      }}
    >
      <ul className={styles.popularColors}>
        {popularColors.map((color: string, idx: number) => (
          <li
            onClick={onCirclePickColor}
            key={color}
            className={cn(styles.circle, {
              [styles.active]: idx === 0,
            })}
            style={{ backgroundColor: color }}
            data-color={color}
            data-id={id}
            role="presentation"
          />
        ))}
      </ul>

      <Dropdown
        visible={isOpenColorPicker}
        overlay={
          <ChromePicker
            className={styles.colorPicker}
            color={currentColor}
            onChangeComplete={onChangeComplete}
            onChange={onChange}
            disableAlpha
          />
        }
      >
        <canvas
          id={id}
          className={styles.canvas}
          width={284}
          height={111}
          onClick={onToggleColorPicker}
        />
      </Dropdown>
    </div>
  )
}

export default ColorPicker
