import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Select, Table } from 'antd'
import { DateRangePicker } from 'react-date-range'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'
import ModalReportStats from 'components/ModalReportStats'

import { IPartner } from 'models/partner'

import { MediaItemAPI } from 'api/VideoCollection/MediaItem'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { IDistribution, IResponseDistributions } from 'models/distribution'
import { useActions } from 'store/hooks/useActions'
import {
  displayViewsCount,
  getMediaItemImageUrl,
  getPartnerId,
} from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { ReactComponent as FiltersIcon } from 'sources/images/libary/filters.svg'
import { ReactComponent as ArrowUp } from 'sources/images/dashboard/arrow-up.svg'
import { ReactComponent as ArrowDown } from 'sources/images/dashboard/arrow-down.svg'
import { ReactComponent as SelectTime } from 'sources/images/dashboard/select-time.svg'
import { ReactComponent as SelectTimeItem } from 'sources/images/dashboard/selected-time-item.svg'
import { ReactComponent as Calendar } from 'sources/images/dashboard/calendar.svg'
import { ReactComponent as SelectDistribution } from 'sources/images/dashboard/select-distribution.svg'
import { ReactComponent as SelectedDistributionItem } from 'sources/images/dashboard/selected-distribution-item.svg'
import { ReactComponent as TableEyeIcon } from 'sources/images/libary/table-eye.svg'

import defaultImgBgSrc from 'sources/images/libary/default-img-bg.png'

import Filters from './Filters'

import styles from './styles.module.scss'

const Report: FC = () => {
  const [isHiddenSelectDistribution, setIsHiddenSelectDistribution] =
    useState(true)

  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const [selectTimeNameValue, setSelectTimeNameValue] = useState('Last week')

  const [selectedDatePickerData, setSelectedDatePickerData] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [allDistributionsName, setAllDistributionsName] = useState<any>([])
  const [selectedDistributionName, setSelectedDistributionName] = useState<any>(
    []
  )

  const [allCategories, setAllCategories] = useState<string[]>([])

  const [visibleFilters, setVisibleFilters] = useState(false)
  const [filters, setFilters] = useState<any>({})

  const [tableLoading, setTableLoading] = useState(false)
  const [allMediaItemsData, setAllMediaItemsData] = useState<any>([])

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
  } = useActions()

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const { storeCurrentPartnerId, storeCurrentPartnerData } = useTypedSelector(
    state => state.partner
  )

  const path = useLocation().pathname

  const init = async (): Promise<void> => {
    try {
      setTableLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        const responseAllDistribution: any =
          await MediaItemAPI.getAllMediaItemsForDistribution(partnerId, '*')

        const allMediaItemsOInfo = _.uniqBy(
          responseAllDistribution.mediaitems,
          'id'
        ).map((item: any) => {
          const cloneItem = _.cloneDeep(item)
          const smallImgObj =
            cloneItem.Images.length > 0 &&
            cloneItem.Images.find((itemImg: any) => itemImg.tag === 'Small')

          if (smallImgObj) {
            smallImgObj.url = `${smallImgObj.url}?uuidv4=${uuidv4()}`
          }

          cloneItem.Images = [
            cloneItem.Images.filter(
              (cloneItemImg: any) => cloneItemImg.tag !== 'Small'
            ),
            smallImgObj,
          ]

          return cloneItem
        })

        setAllMediaItemsData(allMediaItemsOInfo)

        if (distributions.length > 0) {
          let response: any = null

          if (storeCurrentDistributionId) {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {}
            )

            saveCurrentDistributionIdAction(storeCurrentDistributionId)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          } else {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributions[0].id,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(distributions[0].id)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          }

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])
          }
        }
      }

      setTableLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const onOpenModal = (): void => {
    setIsOpenModal(true)
  }

  const onCloseModal = (): void => {
    setIsOpenModal(false)
  }

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    try {
      // setIsLoading(true)
      const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

      if (partnerId) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          allDistributions[currentIndex].id,
          {}
        )

        saveCurrentDistributionIdAction(allDistributions[currentIndex].id)
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      // setIsLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      // setIsLoading(true)
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )

          if (response.status === 'success') {
            setCurrentDistribution(() => response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    try {
      const changePartner = allPartners[currentIndex]

      saveCurrentPartnerIdAction(changePartner.id)
      setCurrentPartner(changePartner)
      saveCurrentPartnerDataAction(changePartner)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }
  }, [storeCurrentPartnerId])

  const onCloseFilters = (): void => {
    setVisibleFilters(false)
  }

  const onSetFilters = (applyFilters: any): void => {
    setFilters({ ...applyFilters })
    onCloseFilters()
  }

  const onShowFilters = (): void => {
    setVisibleFilters(true)
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'img',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <img
          data-image=""
          src={
            data.Images.length > 0
              ? getMediaItemImageUrl(data.Images, 'Small', true)
              : defaultImgBgSrc
          }
          alt="table img"
          className={styles.tableImg}
        />
      ),
    },

    {
      title: '',
      dataIndex: 'name',
      key: uuidv4(),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: uuidv4(),
    },
    {
      title: 'Views',
      dataIndex: 'played',
      key: uuidv4(),
      render: (record: any, data: any) => (
        <span className={styles.views}>
          {displayViewsCount(data.played)} <TableEyeIcon />
        </span>
      ),
    },
    {
      title: 'Categories',
      dataIndex: 'category',
      key: uuidv4(),
      sorted: true,
      render: (record: any, data: any) => (
        <span className={styles.tableCategory}>
          {data.Categories.map(
            (item: any, idx: number) =>
              `${item.name}${idx !== data.Categories.length - 1 ? ', ' : ''} `
          )}
        </span>
      ),
    },

    {
      title: 'Duration',
      dataIndex: '',
      key: uuidv4(),
    },
    {
      title: 'Average Minutes per users',
      dataIndex: '',
      key: uuidv4(),
    },
    {
      title: 'Video Completion rate',
      dataIndex: '',
      key: uuidv4(),
    },
  ]

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={allDistributions}
        setAllDistributions={setAllDistributions}
        onChangeDistribution={onChangeDistribution}
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={onChangePartner}
        // isHiddenSelectDistribution={isHiddenSelectDistribution}
      />

      <div className={styles.mainContent}>
        <SideBar />

        <div className={styles.section}>
          <div className={styles.pageTitleWrapper}>
            <h3 className={styles.pageTitle}>Reports</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report
