import React, { ChangeEvent, FC, useState } from 'react'
import { Button, Checkbox, Input, Tabs } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import cn from 'classnames'

import { ReactComponent as SearchIcon } from 'sources/images/appVideos/tab-search-icon.svg'
import { ReactComponent as DownArrowIcon } from 'sources/images/appVideos/tab-down-arrow.svg'

import Videos from './Videos'
import Playlists from './Playlists'

import styles from './styles.module.scss'
import OnePlaylistMediaItems from './OnePlaylistMediaItems'

interface IProps {
  vimeoPlaylists: any
  uploadVimeoMediaItems: any
  allMediaItemsInCategory: any
  addedMediaItems: []
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  onSelectAllUploadMediaItem: (e: CheckboxChangeEvent) => void
  selectedUploadMediaItemsId: string[]
  onSearchUploadMediaItems: (e: ChangeEvent<HTMLInputElement>) => void
  isFiltered: boolean
  filteredMediaItems: any
  initCategoryMediaItems: null | []
  showcasesData: any
  setAllMediaItemsInCategory: any
  setEditCategoryDescription: any
  setIsChangeDescription: any
  mediaPlaylists: any
  setIsChangeSync: any
  setMediaPlaylistData: any
  renderMediaItems: any
  scrollHandler: any
  fetching: boolean
  setIsOpenEditDescription: any
  setEditDescriptionMediaItems: any
  uniqVimeoVideosWithoutShowcase: any
  setCurrentPlaylistDescription: React.Dispatch<string>
  setOnePlaylistData: any
  onePlayListData: any
  setIsOnePlaylist: React.Dispatch<boolean>

  draggingUploadMediaItemRef: any
  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
}

const VimeoAppVideos: FC<IProps> = ({
  vimeoPlaylists,
  uploadVimeoMediaItems,
  allMediaItemsInCategory,
  addedMediaItems,
  onSelectUploadMediaItem,
  onSelectAllUploadMediaItem,
  selectedUploadMediaItemsId,
  onSearchUploadMediaItems,
  isFiltered,
  filteredMediaItems,
  initCategoryMediaItems,
  showcasesData,
  setAllMediaItemsInCategory,
  setEditCategoryDescription,
  setIsChangeDescription,
  mediaPlaylists,
  setIsChangeSync,
  setMediaPlaylistData,
  renderMediaItems,
  scrollHandler,
  fetching,
  setIsOpenEditDescription,
  setEditDescriptionMediaItems,
  uniqVimeoVideosWithoutShowcase,
  setCurrentPlaylistDescription,
  setOnePlaylistData,
  onePlayListData,
  setIsOnePlaylist,

  draggingUploadMediaItemRef,
  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
}) => {
  const [isVideosTab, setIsVideosTab] = useState(false)
  const [allUnImportedMediaItems, setAllUnImportedMediaItems] = useState<any>(
    []
  )

  const [isOpenPlaylistMediaItems, setIsOpenPlaylistMediaItems] =
    useState(false)

  const operations = {
    right: (
      <div className={styles.tabOperations}>
        <Button className={styles.tabOperationsBtn} shape="round">
          <SearchIcon />{' '}
          <Input
            placeholder="Search"
            className={styles.searchField}
            onChange={onSearchUploadMediaItems}
          />
        </Button>
        <Button className={styles.tabOperationsBtn} shape="round">
          Recently added <DownArrowIcon />
        </Button>
        <Button className={styles.tabOperationsBtn} shape="round">
          <Checkbox
            className={styles.checkbox}
            onChange={onSelectAllUploadMediaItem}
            checked={
              renderMediaItems.length === selectedUploadMediaItemsId.length
            }
            // disabled={uploadVimeoMediaItems.length === addedMediaItems.length}
          >
            Select all
          </Checkbox>
        </Button>
      </div>
    ),
  }

  const onCloseOnePlaylistVideos = (): void => {
    setIsOpenPlaylistMediaItems(false)
    setIsVideosTab(false)
    setIsOnePlaylist(false)
  }

  return (
    <Tabs
      defaultActiveKey="1"
      className={cn(styles.tabs)}
      destroyInactiveTabPane
      onChange={key => {
        if (key === '1') {
          setIsVideosTab(false)
          setIsOpenPlaylistMediaItems(false)
        } else {
          setIsVideosTab(true)
        }
      }}
      onScroll={scrollHandler}
      tabBarExtraContent={isVideosTab ? operations : null}
      data-tab={`${isVideosTab ? 'videos' : 'playlists'}`}
    >
      <Tabs.TabPane
        className={styles.tabPlaylists}
        tab={`Playlists (${vimeoPlaylists.length})`}
        key="1"
      >
        {isOpenPlaylistMediaItems ? (
          <OnePlaylistMediaItems
            onePlayListData={onePlayListData}
            onCloseOnePlaylistVideos={onCloseOnePlaylistVideos}
            allMediaItemsInCategory={allMediaItemsInCategory}
            addedMediaItems={addedMediaItems}
            onSelectUploadMediaItem={onSelectUploadMediaItem}
            selectedUploadMediaItemsId={selectedUploadMediaItemsId}
            initCategoryMediaItems={initCategoryMediaItems}
            allUnImportedMediaItems={allUnImportedMediaItems}
            renderMediaItems={renderMediaItems}
            handleDragStartUploadMediaItems={handleDragStartUploadMediaItems}
            handleDragEnterUploadMediaItems={handleDragEnterUploadMediaItems}
            handleDragEndUploadMediaItems={handleDragEndUploadMediaItems}
            handleDragDropUploadMediaItems={handleDragDropUploadMediaItems}
          />
        ) : (
          <Playlists
            vimeoPlaylists={vimeoPlaylists}
            showcasesData={showcasesData}
            initCategoryMediaItems={initCategoryMediaItems}
            allMediaItemsInCategory={allMediaItemsInCategory}
            setAllMediaItemsInCategory={setAllMediaItemsInCategory}
            setEditCategoryDescription={setEditCategoryDescription}
            setIsChangeDescription={setIsChangeDescription}
            setAllUnImportedMediaItems={setAllUnImportedMediaItems}
            mediaPlaylists={mediaPlaylists}
            setIsChangeSync={setIsChangeSync}
            setMediaPlaylistData={setMediaPlaylistData}
            setIsOpenEditDescription={setIsOpenEditDescription}
            setEditDescriptionMediaItems={setEditDescriptionMediaItems}
            setIsOpenPlaylistMediaItems={setIsOpenPlaylistMediaItems}
            setOnePlaylistData={setOnePlaylistData}
            setCurrentPlaylistDescription={setCurrentPlaylistDescription}
            setIsVideosTab={setIsVideosTab}
            setIsOnePlaylist={setIsOnePlaylist}
          />
        )}
      </Tabs.TabPane>
      {!isOpenPlaylistMediaItems && (
        <Tabs.TabPane
          className={styles.tabVideos}
          tab={`Videos (${uploadVimeoMediaItems.length})`}
          key="2"
        >
          <Videos
            initCategoryMediaItems={initCategoryMediaItems}
            isFiltered={isFiltered}
            filteredMediaItems={filteredMediaItems}
            uploadVimeoMediaItems={uploadVimeoMediaItems}
            addedMediaItems={addedMediaItems}
            selectedUploadMediaItemsId={selectedUploadMediaItemsId}
            onSelectUploadMediaItem={onSelectUploadMediaItem}
            onSelectAllUploadMediaItem={onSelectAllUploadMediaItem}
            allMediaItemsInCategory={allMediaItemsInCategory}
            allUnImportedMediaItems={allUnImportedMediaItems}
            renderMediaItems={renderMediaItems}
            scrollHandler={scrollHandler}
            fetching={fetching}
            uniqVimeoVideosWithoutShowcase={uniqVimeoVideosWithoutShowcase}
            draggingUploadMediaItemRef={draggingUploadMediaItemRef}
            handleDragStartUploadMediaItems={handleDragStartUploadMediaItems}
            handleDragEnterUploadMediaItems={handleDragEnterUploadMediaItems}
            handleDragEndUploadMediaItems={handleDragEndUploadMediaItems}
            handleDragDropUploadMediaItems={handleDragDropUploadMediaItems}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

export default VimeoAppVideos
