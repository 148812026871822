import { ICategory, IResponseCategories } from 'models/category'
import { axiosInstance } from '.'

export const CategoryAPI = {
  getCategories: async (
    partnerId: number,
    distributionId: number,
    queryParams: any
  ): Promise<ICategory[] | []> => {
    const { data } = await axiosInstance.get<ICategory[] | []>(
      `/partner/${partnerId}/distribution/${distributionId}/category`,
      {
        params: { ...queryParams },
      }
    )

    return data
  },

  getCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    queryParams: any
  ): Promise<IResponseCategories> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}`,
      {
        params: { ...queryParams },
      }
    )

    return data
  },

  updateCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    updateCategory: any
  ): Promise<any> => {
    const { data } = await axiosInstance.put(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}`,
      updateCategory
    )

    return data
  },

  deleteCategory: async (
    partnerId: number,
    distributionId: number,
    categoryId: number
  ): Promise<void> => {
    await axiosInstance.delete(
      `/partner/${partnerId}/distribution/${distributionId}/category/${categoryId}`
    )
  },
}
