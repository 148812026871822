import { Dispatch } from 'redux'

import {
  IProgressBarAction,
  ProgressBarActionsTypes,
} from 'store/types/progressBar'

export const changeProgressBarUploadSizeAction =
  (value: number, progressLoaded: number) =>
  async (dispatch: Dispatch<IProgressBarAction>): Promise<void> => {
    try {
      dispatch({
        type: ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_SIZE,
        payload: { value, progressLoaded },
      })
    } catch (error) {
      console.log(error)
    }
  }

export const changeProgressBarUploadTotalSizeAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_SIZE,
  payload: value,
})

export const changeProgressBarUploadTotalFilesAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.CHANGE_TOTAL_UPLOAD_FILES,
  payload: value,
})

export const changeProgressBarUploadCurrentFilesAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.CHANGE_CURRENT_UPLOAD_FILES,
  payload: value,
})

export const clearProgressBarUploadTotalSizeAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.CLEAR_TOTAL_UPLOAD_SIZE,
  payload: value,
})

export const clearProgressBarCurrentUploadSizeAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.CLEAR_CURRENT_UPLOAD_SIZE,
  payload: value,
})

export const incrementCountFunctionCallAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.INCREMENT_FUNCTION_CALL_COUNT,
  payload: value,
})

export const decrementCountFunctionCallAction = (
  value: number
): IProgressBarAction => ({
  type: ProgressBarActionsTypes.DECREMENT_FUNCTION_CALL_COUNT,
  payload: value,
})

export const resetFunctionCallAction = (value: number): IProgressBarAction => ({
  type: ProgressBarActionsTypes.RESET_FUNCTION_CALL_COUNT,
  payload: value,
})
