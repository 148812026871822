import { Dispatch } from 'redux'

import {
  DistributionActionsTypes,
  IDistributionAction,
} from 'store/types/distribution'

export const saveCurrentDistributionIdAction = (
  id: number
): IDistributionAction => ({
  type: DistributionActionsTypes.SAVE_DISTRIBUTION,
  payload: id,
})

export const saveCurrentDistributionDataAction = (
  data: any
): IDistributionAction => ({
  type: DistributionActionsTypes.SAVE_DISTRIBUTION_DATA,
  payload: data,
})

export const clearDistributionAction = (data: any): IDistributionAction => ({
  type: DistributionActionsTypes.CLEAR_DISTRIBUTION,
  payload: data,
})
