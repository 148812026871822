import { axiosInstance } from '.'

export const UserAPI = {
  getUsersInfoByPartnerId: async (
    partnerId: number,
    token: string
  ): Promise<void> => {
    const { data } = await axiosInstance.get(`/partner/${partnerId}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  getOneUserInfo: async (
    partnerId: number,
    userId: number,
    token: string
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return data
  },
}
