export type PlatformTypes = 'Common' | 'Android' | 'Roku' | 'Apple'

export type AppleTags =
  | 'Apple/TopShelfImageWide_2320x720'
  | 'Apple/TopShelfImage_1920x720'
  | 'Apple/TopShelfImageWide2x_4640x1440'
  | 'Apple/TopShelfImage2x_3840x1440'
  | 'Apple/AppStoreIcon_1280x768'
  | 'Apple/MainSplash_1920x1080'
  | 'Apple/MainSplash2x_3840x2160'
  | 'Apple/MainHomeScreen_800x480'
  | 'Apple/MainHomeScreen_740x444'
  | 'Apple/MainHomeScreen_600x360'
  | 'Apple/MainAppImage_400x240'
  | 'Apple/MainAppImage_370x222'
  | 'Apple/MainAppImage_300x180'

export type RokuTags =
  | 'Roku/mm_focus_sd'
  | 'Roku/mm_focus_hd'
  | 'Roku/mm_focus_fhd'
  | 'Roku/splash_sd'
  | 'Roku/splash_hd'
  | 'Roku/splash_fhd'

export type AndroidTags =
  | 'Android/asset_image_320x180'
  | 'Android/asset_image_512x512'
  | 'Android/asset_image_1024x500'
  | 'Android/asset_image_1280x720'

export type CommonTags =
  | 'Common/main_splash_1920x1080'
  | 'Common/app_logo_200x100'

export const rokuAppBuilderTags = [
  'Common/main_splash_1920x1080',
  'Common/app_logo_200x100',
  'Roku/mm_focus_sd',
  'Roku/mm_focus_hd',
  'Roku/mm_focus_fhd',
  'Roku/splash_sd',
  'Roku/splash_hd',
  'Roku/splash_fhd',
]

export const appleAppBuilderTags = [
  'Common/main_splash_1920x1080',
  'Common/app_logo_200x100',
  'Apple/TopShelfImageWide_2320x720',
  'Apple/TopShelfImage_1920x720',
  'Apple/TopShelfImageWide2x_4640x1440',
  'Apple/TopShelfImage2x_3840x1440',
  'Apple/AppStoreIcon_1280x768',
  'Apple/MainSplash_1920x1080',
  'Apple/MainSplash2x_3840x2160',
  'Apple/MainHomeScreen_800x480',
  'Apple/MainHomeScreen_740x444',
  'Apple/MainHomeScreen_600x360',
  'Apple/MainAppImage_400x240',
  'Apple/MainAppImage_370x222',
  'Apple/MainAppImage_300x180',
]

export const androidAppBuilderTags = [
  'Common/main_splash_1920x1080',
  'Common/app_logo_200x100',
  'Android/asset_image_320x180',
  'Android/asset_image_512x512',
  'Android/asset_image_1024x500',
  'Android/asset_image_1280x720',
]
