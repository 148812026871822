import React, { FC, useState } from 'react'
import {
  Button,
  Divider,
  Input,
  message,
  Select,
  Space,
  Typography,
} from 'antd'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'

import { IDistribution } from 'models/distribution'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { useActions } from 'store/hooks/useActions'

import { getPartnerId } from 'utils/helper'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'
import { ReactComponent as SelectArrowIcon } from 'sources/images/selectApp/select-app-arrow.svg'
import appImgSrc from 'sources/images/selectApp/app.png'
import { ReactComponent as PartnerIcon } from 'sources/images/topbar/partner.svg'

import styles from './styles.module.scss'

interface IProps {
  allPartners: any
  onChangePartner: undefined | ((value: number) => void)
  setAllDistributions: any
  videoProperty: boolean | undefined
}

const SelectPartner: FC<IProps> = ({
  allPartners,
  onChangePartner,
  setAllDistributions,
  videoProperty,
}) => {
  const [newName, setNewName] = useState('')
  const [isOpenEnterField, setIsOpenEnterField] = useState(false)

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const { partnerInfo } = useTypedSelector(state => state.user)

  const sortPartners = (partners: any): any => {
    const sortArray = partners.sort((a: any, b: any) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      return 0
    })
    return sortArray
  }

  const filterOption = (input: any, option: any): any =>
    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <div className={styles.selectApp}>
      <div className={styles.icon}>P</div>

      {allPartners && allPartners.length > 0 && (
        <Select
          showSearch
          className={styles.select}
          bordered={false}
          suffixIcon={<SelectArrowIcon />}
          placeholder="Partner name"
          onChange={onChangePartner}
          dropdownRender={menu => menu}
          disabled={videoProperty}
          defaultValue={
            storeCurrentPartnerId
              ? allPartners.findIndex(
                  (partner: any) => partner.id === storeCurrentPartnerId
                )
              : 0
          }
          filterOption={filterOption}
          // value={
          //   storeCurrentPartnerId
          //     ? allPartners.findIndex(
          //         (partner: any) => partner.id === storeCurrentPartnerId
          //       )
          //     : 0
          // }
        >
          {sortPartners(allPartners).map((partner: any, idx: number) => (
            <Select.Option key={partner.id} value={idx}>
              {partner.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  )
}

export default SelectPartner
