import React, { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, Menu, message } from 'antd'

import { ProgressBarContext } from 'containers/App'

import avatarSrc from 'sources/images/topbar/avatar.png'
import { useAuth } from 'utils/hooks/useAuth'

import { IUserInfo } from 'store/types/user'

import { useActions } from 'store/hooks/useActions'

import styles from './styles.module.scss'

interface IProps {
  userInfoData: IUserInfo | null
}

const UserInfo: FC<IProps> = ({ userInfoData }) => {
  const { signOut } = useAuth()
  const navigate = useNavigate()

  const {
    clearDistributionAction,
    clearPartnerAction,
    removeUserInfoAction,
    removePartnerInfoAction,
  } = useActions()

  const { isActiveProgressBar, setIsOpenLogOutModal } =
    useContext(ProgressBarContext)

  const resetAccount = (
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>
  ): void => {
    if (isActiveProgressBar) {
      setIsOpenLogOutModal(true)
    } else {
      setIsAuth(false)

      localStorage.clear()
      message.success('You logout!', 3)

      clearDistributionAction({
        storeCurrentDistributionId: null,
        storeCurrentDistributionData: null,
      })
      clearPartnerAction({
        storeCurrentPartnerId: null,
        storeCurrentPartnerData: null,
        storeAllPartnersData: null,
      })
      removeUserInfoAction()
      removePartnerInfoAction()
      navigate('/login', { replace: true })
    }
  }

  return (
    <Dropdown
      className={styles.controlsOptions}
      placement="bottomRight"
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item key="1" className={styles.controlsOptionLink}>
            <Button className={styles.controlsOptionBtn}>Settings</Button>
          </Menu.Item>
          <Menu.Item key="2" className={styles.controlsOptionLink}>
            <Button
              className={styles.controlsOptionBtn}
              onClick={() => signOut(resetAccount)}
            >
              Logout
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <div className={styles.userInfoWrapper}>
        <span className={styles.name}>
          {userInfoData &&
            `${
              userInfoData.name !== '' ? userInfoData.name : userInfoData.email
            }`}
        </span>
        <img className={styles.avatar} src={avatarSrc} alt="avatar" />
      </div>
    </Dropdown>
  )
}

export default UserInfo
