import React, { FC } from 'react'
import { Button, Menu } from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

import { AddVideosSidebarLinkType } from 'models/sidebar'

import { ReactComponent as ComputerIcon } from 'sources/images/addVideos/computer.svg'
import { ReactComponent as YoutubeIcon } from 'sources/images/addVideos/youtube.svg'
import { ReactComponent as VimeoIcon } from 'sources/images/addVideos/vimeo.svg'
import { ReactComponent as LinkUrlRssIcon } from 'sources/images/addVideos/linkUrlRss.svg'

import avatarSrc from 'sources/images/addVideos/account-avatar.png'
import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'

import styles from './styles.module.scss'

const SIDEBAR_MENU_ITEMS: AddVideosSidebarLinkType[] = [
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/computer`,
    icon: <ComputerIcon />,
    text: 'Computers',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/youtube`,
    icon: <YoutubeIcon />,
    text: 'Youtube',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/vimeo`,
    icon: <VimeoIcon />,
    text: 'Vimeo',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/urlRss`,
    icon: <LinkUrlRssIcon />,
    text: 'CSV/RSS',
  },
]

interface IProps {
  vimeoSubmenu?: any
  onAddNewAccount?: any
  onGetVimeoDataAccount?: (
    accountId: number,
    mediaSourceId: number
  ) => Promise<void>
}

const VimeoSideBar: FC<IProps> = ({
  vimeoSubmenu,
  onAddNewAccount,
  onGetVimeoDataAccount,
}) => {
  const locationPath = useLocation().pathname
  const { id, distributionId } = useParams()

  return (
    <div className={styles.sideBarWrapper}>
      <div className={styles.topText}>Upload content from your sources</div>

      <Menu className={styles.menu} mode="inline" defaultOpenKeys={['sub1']}>
        {SIDEBAR_MENU_ITEMS.map(
          ({ path, icon, text }: AddVideosSidebarLinkType) => {
            const appName = text.toLowerCase()

            return appName === 'vimeo' ? (
              <Menu.SubMenu
                className={cn(styles.subMenuItem, {
                  [styles.activeLink]:
                    path(String(id), String(distributionId)) === locationPath,
                  [styles.computer]: path(
                    String(id),
                    String(distributionId)
                  ).includes('computer'),
                  [styles.vimeo]: path(
                    String(id),
                    String(distributionId)
                  ).includes('vimeo'),
                  [styles.youtube]: path(
                    String(id),
                    String(distributionId)
                  ).includes('youtube'),
                })}
                icon={icon}
                key="sub1"
                title={text}
              >
                {path(String(id), String(distributionId)) === locationPath &&
                  vimeoSubmenu &&
                  vimeoSubmenu.length > 0 &&
                  vimeoSubmenu.map((item: any) => (
                    <Menu.Item
                      key={uuidv4()}
                      className={styles.account}
                      id={item.id}
                      onClick={() =>
                        onGetVimeoDataAccount
                          ? onGetVimeoDataAccount(item.accountId, item.id)
                          : undefined
                      }
                    >
                      <img
                        className={styles.accountAvatar}
                        src={
                          item?.description &&
                          item?.description.includes('picture')
                            ? JSON.parse(item?.description).picture
                            : avatarSrc
                        }
                        alt="avatar"
                      />
                      <span className={styles.accountEmail}>
                        {item?.name ? item.name : 'Account name'}
                      </span>
                    </Menu.Item>
                  ))}

                <Menu.Item key={Math.random()} className={styles.addAccount}>
                  <Button
                    className={styles.titleBtn}
                    shape="round"
                    icon={<AddIcon />}
                    onClick={onAddNewAccount}
                  >
                    ADD ACCOUNT
                  </Button>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                className={cn(styles.menuItem, {
                  [styles.activeLink]:
                    path(String(id), String(distributionId)) === locationPath,
                  [styles.computer]: path(
                    String(id),
                    String(distributionId)
                  ).includes('computer'),
                  [styles.vimeo]: path(
                    String(id),
                    String(distributionId)
                  ).includes('vimeo'),
                  [styles.youtube]: path(
                    String(id),
                    String(distributionId)
                  ).includes('youtube'),
                })}
                key={path(String(id), String(distributionId))}
                icon={icon}
              >
                <Link
                  className={styles.menuLink}
                  to={path(String(id), String(distributionId))}
                >
                  {text}
                </Link>
              </Menu.Item>
            )
          }
        )}
      </Menu>
    </div>
  )
}

export default VimeoSideBar
