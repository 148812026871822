import React, { FC, useEffect } from 'react'
import { Progress } from 'antd'

import MenuSidebar from './MenuSidebar'
import Upgrade from './Upgrade'

import styles from './styles.module.scss'

const SideBar: FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className={styles.sideBarWrapper}>
      <div className={styles.appProgressWrapper}>
        <h5 className={styles.appProgressText}>App progress</h5>
        <Progress
          className={styles.appProgressBar}
          showInfo={false}
          percent={50} // 100% / 6 steps = 16.66% one step
          size="small"
        />
        <span className={styles.appStepsText}>3/6 Steps left</span>
      </div>

      <MenuSidebar />
      <Upgrade />
    </div>
  )
}

export default SideBar
