export enum DistributionActionsTypes {
  SAVE_DISTRIBUTION = 'SAVE_DISTRIBUTION',
  SAVE_DISTRIBUTION_DATA = 'SAVE_DISTRIBUTION_DATA',
  CLEAR_DISTRIBUTION = 'CLEAR_DISTRIBUTION',
}

interface SaveDistributionAction {
  type: DistributionActionsTypes.SAVE_DISTRIBUTION
  payload: number | null
}

interface SaveDistributionDataAction {
  type: DistributionActionsTypes.SAVE_DISTRIBUTION_DATA
  payload: any | null
}

interface ClearDistributionAction {
  type: DistributionActionsTypes.CLEAR_DISTRIBUTION
  payload: any
}

export type IDistributionAction =
  | SaveDistributionAction
  | SaveDistributionDataAction
  | ClearDistributionAction

export interface IDistributionState {
  storeCurrentDistributionId: number | null
  storeCurrentDistributionData: null | any
}
