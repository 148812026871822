import { combineReducers } from 'redux'

import { colorReducer } from './color'
import { distributionReducer } from './distribution'
import { userReducer } from './user'
import { pathReducer } from './path'
import { partnerReducer } from './partner'
import { progressBarReducer } from './progressBar'

export const rootReducer = combineReducers({
  user: userReducer,
  color: colorReducer,
  distribution: distributionReducer,
  path: pathReducer,
  partner: partnerReducer,
  progressBar: progressBarReducer,
})

export type RootState = ReturnType<typeof rootReducer>
