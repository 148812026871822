import { axiosInstance } from '.'

export const VideoSyncAPI = {
  generatedOAuth2URL: async (sourceType: string): Promise<void> => {
    const { data } = await axiosInstance.get(`/sync/${sourceType}/getUrl`)
    return data
  },
  vimeoAuthCallback: async (query: string): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/vimeo/oauth/redirect${query}`
    )
    return data
  },
  getVimeoShowcases: async (
    vimeoUserId: number,
    partnerId: number
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/vimeo/${vimeoUserId}/showcases?partnerId=${partnerId}`
    )
    return data
  },
  getVimeoVideosByShowcaseId: async (
    vimeoUserId: number,
    showcaseId: string
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/vimeo/${vimeoUserId}/showcases/${showcaseId}/list`
    )
    return data
  },
  addVideoByURL: async (
    partnerId: number,
    distributionId: number,
    sourceType: string,
    bodyData: any
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/sync/partner/${partnerId}/distribution/${distributionId}/addVideo/${sourceType}`,

      bodyData
    )
    return data
  },
  youtubeAuthByChannelId: async (
    channelId: string,
    partnerId: string
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/sync/youtube/channel/${channelId}?partnerId=${partnerId}`,
      {}
    )
    return data
  },
  getYoutubePlaylists: async (channelId: string): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/youtube/channel/${channelId}/playlists`
    )

    return data
  },
  getYoutubeVideosByPlaylistId: async (playlistsId: string): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/youtube/playlist/${playlistsId}/list`
    )

    return data
  },
  getYoutubeVideosByChannelId: async (channelId: string): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/youtube/channel/${channelId}/videos`
    )

    return data
  },
  getVimeoVideosByVimeoUserId: async (
    vimeoUserId: number,
    partnerId: number
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/sync/vimeo/${vimeoUserId}/uploadvideos?partnerId=${partnerId}`
    )

    return data
  },
  addVideoFromCSV: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    fileData: any
  ): Promise<void> => {
    const formData = new FormData()

    formData.append('categoryId', String(categoryId))
    formData.append('playlist', fileData)

    const { data } = await axiosInstance.post(
      `/sync/partner/${partnerId}/distribution/${distributionId}/importPlaylist/csv`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  },
  addVideoFromRSS: async (
    partnerId: number,
    distributionId: number,
    categoryId: number,
    rssUrl: string
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/sync/partner/${partnerId}/distribution/${distributionId}/importPlaylist/rss`,
      { playlist: rssUrl, categoryId }
    )

    return data
  },
}
