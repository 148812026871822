import React, { FC } from 'react'
import { Menu } from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'

import { AddVideosSidebarLinkType } from 'models/sidebar'

import { ReactComponent as ComputerIcon } from 'sources/images/addVideos/computer.svg'
import { ReactComponent as YoutubeIcon } from 'sources/images/addVideos/youtube.svg'
import { ReactComponent as VimeoIcon } from 'sources/images/addVideos/vimeo.svg'
import { ReactComponent as LinkUrlRssIcon } from 'sources/images/addVideos/linkUrlRss.svg'

import styles from './styles.module.scss'

const SIDEBAR_MENU_ITEMS: AddVideosSidebarLinkType[] = [
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/computer`,
    icon: <ComputerIcon />,
    text: 'Computers',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/youtube`,
    icon: <YoutubeIcon />,
    text: 'Youtube',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/vimeo`,
    icon: <VimeoIcon />,
    text: 'Vimeo',
  },
  {
    path: (id: string, distributionId: string) =>
      `/content/${distributionId}/add-videos/${id}/urlRss`,
    icon: <LinkUrlRssIcon />,
    text: 'CSV/RSS',
  },
]

const DefaultSideBar: FC = () => {
  const locationPath = useLocation().pathname
  const { id, distributionId } = useParams()

  return (
    <div className={styles.sideBarWrapper}>
      <div className={styles.topText}>Upload content from your sources</div>

      <Menu className={styles.menu} mode="inline">
        {SIDEBAR_MENU_ITEMS.map(
          ({ path, icon, text }: AddVideosSidebarLinkType) => (
            <Menu.Item
              className={cn(styles.menuItem, {
                [styles.activeLink]:
                  path(String(id), String(distributionId)) === locationPath,
                [styles.computer]: path(
                  String(id),
                  String(distributionId)
                ).includes('computer'),
                [styles.vimeo]: path(
                  String(id),
                  String(distributionId)
                ).includes('vimeo'),
                [styles.youtube]: path(
                  String(id),
                  String(distributionId)
                ).includes('youtube'),
              })}
              key={path(String(id), String(distributionId))}
              icon={icon}
            >
              <Link
                className={styles.menuLink}
                to={path(String(id), String(distributionId))}
              >
                {text}
              </Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </div>
  )
}

export default DefaultSideBar
