import { IRegisterBody, IRegisterFormData } from 'models/register'
import { axiosInstance } from '.'

export const PartnerAPI = {
  registerNewPartner: async ({
    email,
    password,
    appName,
  }: IRegisterFormData): Promise<void> => {
    const prepareBodyData: IRegisterBody = {
      password,
      email,
      login: email,
      username: email,
      partnername: email,
      description: email,
      distributionname: appName,
    }

    const { data } = await axiosInstance.post(
      '/partner/register',
      prepareBodyData
    )

    return data
  },

  getOnePartnerInfo: async (
    partnerId: number,
    token: string
  ): Promise<void> => {
    const { data } = await axiosInstance.get(`/partner/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  },

  getAllPartnersInfo: async (token: string): Promise<void> => {
    const { data } = await axiosInstance.get(`/partner`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  },
}
