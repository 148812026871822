import React, { FC } from 'react'
import { Button, Checkbox } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import _ from 'lodash'
import cn from 'classnames'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import { ReactComponent as LeftArrowIcon } from 'sources/images/videoProperties/arrow-left.svg'
import circleStatusSrc from 'sources/images/content/circle-status.png'
import doneSrc from 'sources/images/content/done.png'

import styles from './styles.module.scss'

interface IProps {
  onePlayListData: any
  onCloseOnePlaylistVideos: any
  allMediaItemsInCategory: any
  addedMediaItems: []
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  selectedUploadMediaItemsId: string[]
  initCategoryMediaItems: null | []
  allUnImportedMediaItems: any
  renderMediaItems: any

  draggingUploadMediaItemRef: any
  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
}

const OnePlaylistMediaItems: FC<IProps> = ({
  onePlayListData,
  onCloseOnePlaylistVideos,
  allMediaItemsInCategory,
  addedMediaItems,
  onSelectUploadMediaItem,
  selectedUploadMediaItemsId,
  initCategoryMediaItems,
  allUnImportedMediaItems,
  renderMediaItems,

  draggingUploadMediaItemRef,
  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
}) => {
  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const allMediaItemsFromStore = storeCurrentDistributionData
    .map((item: any) => item.MediaItems)
    .flat()

  return (
    <>
      <div className={styles.onePlaylistTopWrapper}>
        <Button
          icon={<LeftArrowIcon />}
          className={styles.backArrowLeft}
          onClick={onCloseOnePlaylistVideos}
        />
        <h3 className={styles.playlistTitle}>{onePlayListData.playlistName}</h3>
      </div>

      <ul className={styles.videosWrapper}>
        {renderMediaItems.map((mediaItem: any) => (
          <li
            key={uuidv4()}
            data-upload-item={mediaItem.snippet.resourceId.videoId}
            id={mediaItem.snippet.resourceId.videoId}
            draggable
            onDragStart={handleDragStartUploadMediaItems}
            onDragEnter={handleDragEnterUploadMediaItems}
            onDragEnd={handleDragEndUploadMediaItems}
            onDrop={handleDragDropUploadMediaItems}
            className={cn(styles.uploadMediaItem, {
              [styles.hasInCategory]:
                allMediaItemsInCategory.find(
                  (item: any) =>
                    item.snippet &&
                    item.snippet.resourceId.videoId ===
                      mediaItem.snippet.resourceId.videoId
                ) ||
                allMediaItemsInCategory.find(
                  (item: any) =>
                    item.externalId === mediaItem.snippet.resourceId.videoId
                ),
              [styles.disabled]: _.find(addedMediaItems, {
                videoId: mediaItem.snippet.resourceId.videoId,
              }),
              [styles.selectedUploadMediaItem]:
                selectedUploadMediaItemsId.includes(
                  String(mediaItem.snippet.resourceId.videoId)
                ),
            })}
          >
            <img
              className={styles.mediaItemImg}
              src={
                mediaItem.snippet.thumbnails.high &&
                mediaItem.snippet.thumbnails.high.url
              }
              alt="video"
            />
            {allMediaItemsInCategory.find((item: any) => {
              const videoIdInCategory = item.snippet
                ? item.snippet.resourceId.videoId
                : 'videoIdInCategory'
              const videoIdInRenderMediaItem = mediaItem.snippet
                ? mediaItem.snippet.resourceId.videoId
                : 'videoIdInCategory'

              if (videoIdInCategory === videoIdInRenderMediaItem) {
                return true
              }

              if (item.id === mediaItem.id) {
                return true
              }

              return false
            }) && (
              <div className={styles.overlay} draggable={false}>
                <img src={circleStatusSrc} alt="status" draggable={false} />
                <img
                  src={doneSrc}
                  alt="done"
                  className={styles.doneImg}
                  draggable={false}
                />
              </div>
            )}

            {!allUnImportedMediaItems.find(
              (item: any) =>
                item.snippet.resourceId.videoId ===
                mediaItem.snippet.resourceId.videoId
            ) &&
              allMediaItemsFromStore.find(
                (storeItem: any) =>
                  storeItem.externalId === mediaItem.snippet.resourceId.videoId
              ) &&
              !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
              !initCategoryMediaItems?.some(
                (initItem: any) =>
                  initItem.externalId ===
                  String(mediaItem.snippet.resourceId.videoId)
              ) &&
              !allMediaItemsInCategory.find((item: any) => {
                const videoIdInCategory = item.snippet
                  ? item.snippet.resourceId.videoId
                  : 'videoIdInCategory'
                const videoIdInRenderMediaItem = mediaItem.snippet
                  ? mediaItem.snippet.resourceId.videoId
                  : 'videoIdInCategory'

                if (videoIdInCategory === videoIdInRenderMediaItem) {
                  return true
                }

                if (item.id === mediaItem.id) {
                  return true
                }

                return false
              }) && (
                <div
                  className={styles.importedInAnotherCategory}
                  draggable={false}
                >
                  <img
                    src={circleStatusSrc}
                    alt="status"
                    draggable={false}
                    className={styles.circle}
                  />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}

            {initCategoryMediaItems?.some(
              (initItem: any) =>
                initItem.externalId ===
                String(mediaItem.snippet.resourceId.videoId)
            ) && (
              <div className={styles.overlay} draggable={false}>
                <img src={circleStatusSrc} alt="status" draggable={false} />
                <img
                  src={doneSrc}
                  alt="done"
                  className={styles.doneImg}
                  draggable={false}
                />
              </div>
            )}
            <>
              <>
                <div className={styles.mediaItemControls}>
                  <Checkbox
                    className={styles.checkboxMediaItem}
                    onChange={onSelectUploadMediaItem}
                    checked={selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    )}
                  />
                </div>

                <div className={styles.mediaItemTitleWrapper}>
                  <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                </div>
              </>
            </>
          </li>
        ))}

        {/* {fetching && <Spin size="large" />} */}

        {/* {isFiltered
          ? filteredMediaItems.map((mediaItem: any) => (
              <li
                key={uuidv4()}
                data-upload-item={mediaItem.snippet.resourceId.videoId}
                id={mediaItem.snippet.resourceId.videoId}
                draggable={false}
                className={cn(styles.uploadMediaItem, {
                  [styles.disabled]: _.find(addedMediaItems, {
                    videoId: mediaItem.snippet.resourceId.videoId,
                  }),
                  [styles.selectedUploadMediaItem]:
                    selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    ),
                })}
              >
                <img
                  className={styles.mediaItemImg}
                  src={
                    mediaItem.snippet.thumbnails.high &&
                    mediaItem.snippet.thumbnails.high.url
                  }
                  alt="video"
                />
                {_.find(allMediaItemsInCategory, {
                  id: mediaItem.id,
                }) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

                {!allUnImportedMediaItems.find(
                  (item: any) =>
                    item.snippet.resourceId.videoId ===
                    mediaItem.snippet.resourceId.videoId
                ) &&
                  allMediaItemsFromStore.find(
                    (storeItem: any) =>
                      storeItem.externalId ===
                      mediaItem.snippet.resourceId.videoId
                  ) &&
                  !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !initCategoryMediaItems?.some(
                    (initItem: any) =>
                      initItem.externalId ===
                      String(mediaItem.snippet.resourceId.videoId)
                  ) && (
                    <div
                      className={styles.importedInAnotherCategory}
                      draggable={false}
                    >
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                        className={styles.circle}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {initCategoryMediaItems?.some(
                  (initItem: any) =>
                    initItem.externalId ===
                    String(mediaItem.snippet.resourceId.videoId)
                ) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(
                        String(mediaItem.snippet.resourceId.videoId)
                      )}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                  </div>
                </>
              </li>
            ))
          : uploadYoutubeMediaItems.map((mediaItem: any) => (
              <li
                key={uuidv4()}
                data-upload-item={mediaItem.snippet.resourceId.videoId}
                id={mediaItem.snippet.resourceId.videoId}
                draggable={false}
                className={cn(styles.uploadMediaItem, {
                  [styles.disabled]: _.find(addedMediaItems, {
                    videoId: mediaItem.snippet.resourceId.videoId,
                  }),
                  [styles.selectedUploadMediaItem]:
                    selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    ),
                })}
              >
                <img
                  className={styles.mediaItemImg}
                  src={
                    mediaItem.snippet.thumbnails.high &&
                    mediaItem.snippet.thumbnails.high.url
                  }
                  alt="video"
                />
                {_.find(allMediaItemsInCategory, {
                  id: mediaItem.id,
                }) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

                {!allUnImportedMediaItems.find(
                  (item: any) =>
                    item.snippet.resourceId.videoId ===
                    mediaItem.snippet.resourceId.videoId
                ) &&
                  allMediaItemsFromStore.find(
                    (storeItem: any) =>
                      storeItem.externalId ===
                      mediaItem.snippet.resourceId.videoId
                  ) &&
                  !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !initCategoryMediaItems?.some(
                    (initItem: any) =>
                      initItem.externalId ===
                      String(mediaItem.snippet.resourceId.videoId)
                  ) && (
                    <div
                      className={styles.importedInAnotherCategory}
                      draggable={false}
                    >
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                        className={styles.circle}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {initCategoryMediaItems?.some(
                  (initItem: any) =>
                    initItem.externalId ===
                    String(mediaItem.snippet.resourceId.videoId)
                ) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(
                        String(mediaItem.snippet.resourceId.videoId)
                      )}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                  </div>
                </>
              </li>
            ))} */}
      </ul>
    </>
  )
}

export default OnePlaylistMediaItems
