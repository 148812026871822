import { axiosInstance } from '.'

export const MediaSourceAPI = {
  getMediaSources: async (partnerId: number): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/mediasource`
    )
    return data
  },
}
