import React, { FC, useEffect, useState } from 'react'
import { Button, message, Switch } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import styles from './styles.module.scss'

interface IProps {
  youtubePlaylists: any
  showcasesData: any
  initCategoryMediaItems: any
  allMediaItemsInCategory: any
  setAllMediaItemsInCategory: any
  setEditCategoryDescription: any
  setIsChangeDescription: any
  setAllUnImportedMediaItems: any
  mediaPlaylists: any
  setIsChangeSync: any
  setMediaPlaylistData: any
  setIsOpenEditDescription: any
  setEditDescriptionMediaItems: any

  setOnePlaylistData: React.Dispatch<any>
  setIsOpenPlaylistMediaItems: React.Dispatch<boolean>
  setCurrentPlaylistDescription: React.Dispatch<string>
  setIsVideosTab: React.Dispatch<boolean>
  setIsOnePlaylist: React.Dispatch<boolean>
}

const Playlists: FC<IProps> = ({
  youtubePlaylists,
  showcasesData,
  allMediaItemsInCategory,
  setAllMediaItemsInCategory,
  setEditCategoryDescription,
  setIsChangeDescription,
  setAllUnImportedMediaItems,
  mediaPlaylists,
  setIsChangeSync,
  setMediaPlaylistData,
  setIsOpenEditDescription,
  setEditDescriptionMediaItems,

  setOnePlaylistData,
  setIsOpenPlaylistMediaItems,
  setCurrentPlaylistDescription,
  setIsVideosTab,
  setIsOnePlaylist,
}) => {
  const [isAutomaticallySyncPlaylist, setIsAutomaticallySyncPlaylist] =
    useState(false)
  const [isUseDescription, setIsUseDescription] = useState(false)

  const [youtubeShowcases, setYoutubeShowcases] = useState<any>([])
  const [
    firstShowcaseWithEqualDescription,
    setFirstShowcaseWithEqualDescription,
  ] = useState<any | undefined>(undefined)

  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const init = (): void => {
    const allDistributionMediaItems = storeCurrentDistributionData
      .map((item: any) => item.MediaItems)
      .flat()

    setFirstShowcaseWithEqualDescription(
      showcasesData.find((item: any) => item.checkDescription)
    )

    const filteredShowcases = showcasesData.map((showcaseData: any) => {
      const updateVideoList = showcaseData.videoList.filter((video: any) => {
        const youtubeMediaItemId = video.snippet.resourceId.videoId

        return !allDistributionMediaItems.some(
          (distributionMediaItem: any) =>
            distributionMediaItem.externalId === youtubeMediaItemId
        )
      })

      return {
        ...showcaseData,
        importedVideos: showcaseData.videoList.length - updateVideoList.length,
        unImportedVideoList: updateVideoList,
      }
    })

    setAllUnImportedMediaItems(
      _.uniqBy(
        filteredShowcases.map((item: any) => item.unImportedVideoList).flat(),
        'snippet.resourceId.videoId'
      )
    )

    setMediaPlaylistData(filteredShowcases)
    setYoutubeShowcases(filteredShowcases)
  }

  useEffect(() => {
    init()
  }, [])

  const onAddAllShowcaseMediaItems = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const currentShowcase = youtubeShowcases.find(
      (item: any) => item.playlistYoutubeId === showcaseId
    )

    if (currentShowcase.checkDescription) {
      setIsOpenEditDescription(true)
      setCurrentPlaylistDescription(currentShowcase.playlistDescription)
    } else {
      setCurrentPlaylistDescription('')
    }

    const uniqVideoList = currentShowcase.videoList.filter(
      (youtubeItem: any) => {
        const idMediaItemInYoutube = youtubeItem.snippet.resourceId.videoId

        return !allMediaItemsInCategory.find((item: any) => {
          const idMediaItemInCategory = item.snippet
            ? item.snippet.resourceId.videoId
            : item.externalId

          return idMediaItemInYoutube === idMediaItemInCategory
        })
      }
    )

    setEditDescriptionMediaItems(uniqVideoList)
    setAllMediaItemsInCategory([...uniqVideoList, ...allMediaItemsInCategory])
  }

  const onAddUnImportedShowcaseMediaItems = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const currentShowcase = youtubeShowcases.find(
      (item: any) => item.playlistYoutubeId === showcaseId
    )

    if (currentShowcase.checkDescription) {
      setIsOpenEditDescription(true)
      setCurrentPlaylistDescription(currentShowcase.playlistDescription)
    } else {
      setCurrentPlaylistDescription('')
    }

    const uniqVideoList = currentShowcase.unImportedVideoList.filter(
      (youtubeItem: any) => {
        const idMediaItemInYoutube = youtubeItem.snippet.resourceId.videoId

        return !allMediaItemsInCategory.find((item: any) => {
          const idMediaItemInCategory = item.snippet
            ? item.snippet.resourceId.videoId
            : item.externalId

          return idMediaItemInYoutube === idMediaItemInCategory
        })
      }
    )

    setEditDescriptionMediaItems(uniqVideoList)
    setAllMediaItemsInCategory([...uniqVideoList, ...allMediaItemsInCategory])
  }

  const onUseShowcaseDescription = (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId
    const cloneShowcases = _.cloneDeep(youtubeShowcases)

    const findShowcase = cloneShowcases.find(
      (item: any) => item.playlistYoutubeId === showcaseId
    )

    setIsChangeDescription(true)
    setFirstShowcaseWithEqualDescription(undefined)

    if (checked) {
      setEditCategoryDescription(findShowcase.playlistDescription)

      setYoutubeShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = true
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.checkDescription = true
            return cloneShowcase
          }

          return showcase
        })
      )
    } else {
      setEditCategoryDescription(findShowcase.categoryDescription)

      setYoutubeShowcases(
        cloneShowcases.map((showcase: any) => {
          const cloneShowcase = _.cloneDeep(showcase)
          cloneShowcase.checkDescription = false
          return cloneShowcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          const cloneShowcase = _.cloneDeep(showcase)
          cloneShowcase.checkDescription = false
          return cloneShowcase
        })
      )
    }

    // const updateShowcases = cloneShowcases.map((item: any) => {
    //   const showcase = _.cloneDeep(item)

    //   if (checked && showcase.playlistYoutubeId === showcaseId) {
    //     showcase.checkDescription = true
    //   } else {
    //     showcase.checkDescription = false
    //   }

    //   return showcase
    // })

    // setYoutubeShowcases(updateShowcases)
  }

  const onSyncPlaylist = (
    checked: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId
    const cloneShowcases = _.cloneDeep(youtubeShowcases)

    setIsChangeSync(true)
    if (checked) {
      setYoutubeShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = true
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = true
            return cloneShowcase
          }

          return showcase
        })
      )

      message.success('Playlist will be sync!', 3)
    } else {
      setYoutubeShowcases(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = false
            return cloneShowcase
          }

          return showcase
        })
      )

      setMediaPlaylistData(
        cloneShowcases.map((showcase: any) => {
          if (showcase.playlistYoutubeId === showcaseId) {
            const cloneShowcase = _.cloneDeep(showcase)
            cloneShowcase.isAsyncMediaPlaylist = false
            return cloneShowcase
          }

          return showcase
        })
      )

      message.error('Playlist will be not sync!', 3)
    }
  }

  const onShowPlaylistMediaItems = (e: any): void => {
    setIsOpenPlaylistMediaItems(true)
    setIsVideosTab(true)
    setIsOnePlaylist(true)

    const target = e.target as any
    const buttonEl = target.closest('[data-showcase-id]')
    const showcaseId = buttonEl?.dataset.showcaseId

    const findShowcase = youtubeShowcases.find(
      (item: any) => item.playlistYoutubeId === showcaseId
    )

    if (findShowcase) {
      setOnePlaylistData(findShowcase)
    }
  }

  return (
    <div className={styles.playlistWrapper}>
      {youtubeShowcases.map((showcase: any) => (
        <div
          className={styles.playlist}
          key={uuidv4()}
          data-showcase-id={showcase.playlistYoutubeId}
        >
          <img
            className={styles.img}
            src={showcase.showcasePicture}
            alt="playlist"
            role="presentation"
            onClick={onShowPlaylistMediaItems}
          />

          <div className={styles.description}>
            <div
              className={styles.imported}
            >{`${showcase.importedVideos} / ${showcase.totalVideos} imported`}</div>
            <div
              className={styles.title}
              role="presentation"
              onClick={onShowPlaylistMediaItems}
            >
              {showcase.playlistName}
            </div>
            <p className={styles.text}>{showcase.playlistDescription}</p>
          </div>

          <div className={styles.controls}>
            <div className={styles.syncPlaylist}>
              <span
                className={cn(styles.textSyncPlaylist, {
                  [styles.disabledText]: !showcase.isAsyncMediaPlaylist,
                })}
              >
                Automaticly sync this playlist to category
              </span>
              <Switch
                className={styles.switch}
                /* tslint:disable-next-line */
                onChange={onSyncPlaylist}
                defaultChecked={showcase.isAsyncMediaPlaylist}
              />
            </div>

            <div className={styles.useDescription}>
              <span
                className={cn(styles.textUseDescription, {
                  [styles.disabledText]: !showcase.checkDescription,
                })}
              >
                Use this Description for your Category
              </span>

              <span

              // onClick={
              //   showcase.playlistDescription === ''
              //     ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              //         message.warning(
              //           'This playlist not have description!',
              //           3
              //         )
              //     : undefined
              // }
              >
                <Switch
                  className={styles.switch}
                  defaultChecked={showcase.checkDescription}
                  onChange={onUseShowcaseDescription}
                  // disabled={showcase.playlistDescription === ''}
                />
              </span>
            </div>

            <div className={styles.buttons}>
              <Button
                className={cn(styles.addUnimportedBtn, {
                  [styles.disabledBtn]:
                    showcase.unImportedVideoList.length === 0,
                })}
                shape="round"
                onClick={onAddUnImportedShowcaseMediaItems}
                disabled={showcase.unImportedVideoList.length === 0}
              >
                Add Only unimported
              </Button>
              <Button
                className={cn(styles.addAllVideosBtn, {
                  [styles.disabledBtn]: showcase.videoList.length === 0,
                })}
                shape="round"
                onClick={onAddAllShowcaseMediaItems}
                disabled={showcase.videoList.length === 0}
              >
                Add all Videos
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Playlists
