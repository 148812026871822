import React, { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'

import styles from './styles.module.scss'

interface IProps {
  count: number
  background?: string
  withBorder: boolean
}

const EmptyCategoryList: FC<IProps> = ({ count, background, withBorder }) => (
  <>
    {Array(count)
      .fill(count)
      .map(() => (
        <div
          key={uuidv4()}
          className={styles.item}
          style={{
            backgroundColor: background || '#f6fafe',
            border: withBorder ? '2px dashed #d1dbe5' : 'none',
          }}
        />
      ))}
  </>
)

export default EmptyCategoryList
