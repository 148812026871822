import React, { FC, useEffect, useState } from 'react'
import { Button, Tabs, Spin, message } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useLocation } from 'react-router-dom'

import history from 'utils/history'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'
import Preview from 'components/Preview'
import { getPartnerId } from 'utils/helper'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { useActions } from 'store/hooks/useActions'

import { IUser } from 'models/user'
import { PartnerAPI } from 'api/User/Partner'
import { IPartner } from 'models/partner'

import { DistributionAPI } from 'api/VideoCollection/Distribution'

import { IDistribution, IResponseDistributions } from 'models/distribution'

import { ReactComponent as PreviewIcon } from 'sources/images/tv-preview-icon.svg'

import TabAssets from './TabAssets'
import TabColors from './TabColors'

import styles from './styles.module.scss'

const Branding: FC = () => {
  const [uploadLogo, setUploadLogo] = useState<any>(null)
  const [uploadSplash, setUploadSplash] = useState<any>(null)

  const [uploadLogoFile, setUploadLogoFile] = useState<RcFile | null>(null)
  const [uploadSplashFile, setUploadSplashFile] = useState<RcFile | null>(null)

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>()

  const [isLoading, setIsLoading] = useState(false)

  const [isUploadingSplash, setIsUploadingSplash] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
  } = useActions()

  const [previewVisible, setPreviewVisible] = useState(false)

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const {
    storeCurrentPartnerId,
    storeCurrentPartnerData,
    storeAllPartnersData,
  } = useTypedSelector(state => state.partner)

  const path = useLocation().pathname

  const setDistributionAssets = (assets: any): void => {
    const logo = assets.find(
      (item: any) => item.tag === 'Common/app_logo_200x100'
    )
    const splash = assets.find(
      (item: any) => item.tag === 'Common/main_splash_1920x1080'
    )

    if (logo) {
      setUploadLogo(logo)
    }

    if (splash) {
      setUploadSplash(splash)
    }
  }

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

      setUploadLogo(null)
      setUploadSplash(null)

      if (partnerId) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          allDistributions[currentIndex].id,
          {}
        )

        saveCurrentDistributionIdAction(allDistributions[currentIndex].id)
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        setDistributionAssets(response.distributions[0].Images)

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const init = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          if (storeCurrentDistributionId) {
            // console.log(
            //   'init storeCurrentDistributionId',
            //   storeCurrentDistributionId
            // )
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {}
            )

            saveCurrentDistributionIdAction(storeCurrentDistributionId)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )

            setDistributionAssets(response.distributions[0].Images)
          } else {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributions[0].id,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(distributions[0].id)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )

            setDistributionAssets(response.distributions[0].Images)
          }

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        setUploadLogo(null)
        setUploadSplash(null)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )

          setDistributionAssets(response.distributions[0].Images)

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    try {
      const changePartner = allPartners[currentIndex]

      saveCurrentPartnerIdAction(changePartner.id)
      setCurrentPartner(changePartner)
      saveCurrentPartnerDataAction(changePartner)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }
  }, [storeCurrentPartnerId])

  useEffect(() => {
    init()
  }, [])

  const onBeforeUploadFile = async (
    file: RcFile,
    fileList: RcFile[],
    asset: string
  ): Promise<boolean> => {
    setIsUploadingSplash(true)
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (file && partnerId && storeCurrentDistributionId) {
      let response: any = null

      if (asset === 'Logo') {
        response = await DistributionAPI.addAssetImageToDistribution(
          partnerId,
          storeCurrentDistributionId,
          'Common/app_logo_200x100',
          'Common',
          file
        )
      }

      if (asset === 'Splash') {
        response = await DistributionAPI.addAssetImageToDistribution(
          partnerId,
          storeCurrentDistributionId,
          'Common/main_splash_1920x1080',
          'Common',
          file
        )
      }

      if (response.status === 'success') {
        if (asset === 'Logo') {
          setUploadLogoFile(file)
          setUploadLogo({
            url: response.image.url,
            tag: response.image.tag,
          })
        }

        if (asset === 'Splash') {
          setUploadSplashFile(file)
          setUploadSplash({
            url: response.image.url,
            tag: response.image.tag,
          })
        }

        message.success(`Asset was uploaded!`, 3)
        setIsUploadingSplash(false)
      }

      if (response.status === 'ERROR') {
        setIsUploadingSplash(false)
      }
    }
    return false
  }

  return (
    <div className={styles.mainWrapper}>
      <TopBar
        isLogged
        distributions={allDistributions}
        setAllDistributions={setAllDistributions}
        onChangeDistribution={onChangeDistribution}
        allPartners={allPartners}
        setAllPartners={setAllPartners}
        setIsSuperAdmin={setIsSuperAdmin}
        isSuperAdmin={isSuperAdmin}
        onChangePartner={onChangePartner}
      />

      <div className={styles.mainContent}>
        <SideBar />

        <div className={styles.section}>
          <div className={styles.pageTitleWrapper}>
            <h3 className={styles.pageTitle}>Branding</h3>

            <Button
              className={styles.titleBtn}
              onClick={() => setPreviewVisible(true)}
              icon={<PreviewIcon />}
              shape="round"
            >
              Preview
            </Button>

            {previewVisible && (
              <Preview
                previewVisible={previewVisible}
                setPreviewVisible={setPreviewVisible}
              />
            )}
          </div>

          <div className={styles.content}>
            {isLoading ? (
              <Spin size="large" className={styles.spinner} />
            ) : (
              <Tabs
                defaultActiveKey="1"
                className={styles.tabs}
                destroyInactiveTabPane
              >
                <Tabs.TabPane className={styles.tabAssets} tab="Assets" key="1">
                  <TabAssets
                    uploadLogo={uploadLogo}
                    uploadSplash={uploadSplash}
                    onBeforeUploadFile={onBeforeUploadFile}
                    uploadLogoFile={uploadLogoFile}
                    uploadSplashFile={uploadSplashFile}
                    isUploadingSplash={isUploadingSplash}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane
                  destroyInactiveTabPane
                  className={styles.tabColors}
                  tab="colors"
                  key="2"
                >
                  <TabColors currentDistribution={currentDistribution} />
                </Tabs.TabPane>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Branding
