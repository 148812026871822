export enum PathActionsTypes {
  SAVE_PATH = 'SAVE_PATH',
}

interface SavePathAction {
  type: PathActionsTypes.SAVE_PATH
  payload: string
}

export type IPathAction = SavePathAction

export interface IPathState {
  currentPath: string
}
