import { Dispatch } from 'redux'

import { AuthAPI } from 'api/User/Auth'

import { ILoginForm } from 'models/loginForm'
import { IUser } from 'models/user'

import {
  UserActionsTypes,
  IUserAction,
  IUserInfo,
  IPartnerInfo,
} from 'store/types/user'

export const authStartAction = (): IUserAction => ({
  type: UserActionsTypes.START_LOGIN,
})

// async action
export const authUserAction =
  (loginFormData: ILoginForm) =>
  async (dispatch: Dispatch<IUserAction>): Promise<void> => {
    try {
      const response: any = await AuthAPI.authUser(loginFormData)

      if (response && response.status === 'success') {
        dispatch({ type: UserActionsTypes.SAVE_USER, payload: response })
        localStorage.setItem('user', JSON.stringify(response))
      }
    } catch (error: any) {
      dispatch({ type: UserActionsTypes.ERROR_USER, payload: error })
    }
  }

export const saveUserAction = (user: IUser): IUserAction => ({
  type: UserActionsTypes.SAVE_USER,
  payload: user,
})

export const removeUserAction =
  () =>
  async (dispatch: Dispatch<IUserAction>): Promise<void> => {
    try {
      dispatch({ type: UserActionsTypes.REMOVE_USER })
      localStorage.clear()
    } catch (error: any) {
      console.log(error)
    }
  }

export const saveUserInfoAction = (userInfo: IUserInfo): IUserAction => ({
  type: UserActionsTypes.SAVE_USER_INFO,
  payload: userInfo,
})

export const removeUserInfoAction =
  () =>
  async (dispatch: Dispatch<IUserAction>): Promise<void> => {
    try {
      dispatch({ type: UserActionsTypes.REMOVE_USER_INFO })
    } catch (error: any) {
      console.log(error)
    }
  }

export const savePartnerInfoAction = (
  partnerInfo: IPartnerInfo
): IUserAction => ({
  type: UserActionsTypes.SAVE_PARTNER_INFO,
  payload: partnerInfo,
})

export const removePartnerInfoAction =
  () =>
  async (dispatch: Dispatch<IUserAction>): Promise<void> => {
    try {
      dispatch({ type: UserActionsTypes.REMOVE_PARTNER_INFO })
    } catch (error: any) {
      console.log(error)
    }
  }
