import React, { FC, useContext, useEffect } from 'react'
import { message, Progress } from 'antd'

import { useActions } from 'store/hooks/useActions'

import { countPercent } from 'utils/helper'
import { useTypedSelector } from 'store/hooks/useTypedSelector'

import styles from './styles.module.scss'

interface IProps {
  currentUploadFiles: number
  totalUploadFiles: number
  setIsActiveProgressBar: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentUploadFiles: React.Dispatch<React.SetStateAction<number>>
  setTotalUploadFiles: React.Dispatch<React.SetStateAction<number>>
  isActiveProgressBar: boolean
  setAbortControllers: React.Dispatch<
    React.SetStateAction<[] | AbortController[]>
  >
  abortControllers: [] | AbortController[]
}

const ProgressBar: FC<IProps> = ({
  currentUploadFiles,
  totalUploadFiles,
  setIsActiveProgressBar,
  setCurrentUploadFiles,
  setTotalUploadFiles,
  isActiveProgressBar,
  abortControllers,
  setAbortControllers,
}) => {
  const handleBeforeUnload = (): string => 'You have upload files!'
  const { currentUploadSize, totalUploadSize } = useTypedSelector(
    state => state.progressBar
  )

  const {
    clearProgressBarUploadTotalSizeAction,
    clearProgressBarCurrentUploadSizeAction,
  } = useActions()

  useEffect(() => {
    setIsActiveProgressBar(true)
    window.onbeforeunload = handleBeforeUnload

    return () => {
      clearProgressBarUploadTotalSizeAction(0)
      clearProgressBarCurrentUploadSizeAction(0)

      setCurrentUploadFiles(0)
      setTotalUploadFiles(0)

      window.onbeforeunload = null

      setIsActiveProgressBar(false)
    }
  }, [])

  useEffect(() => {
    if (currentUploadSize === totalUploadSize && totalUploadSize !== 0) {
      setIsActiveProgressBar(false)
      setAbortControllers([])
    }
  }, [currentUploadSize])

  return (
    <div className={styles.progressBarWrapper}>
      <div className={styles.progressBar}>
        <span
          className={styles.text}
        >{`Uploading ${currentUploadFiles}/${totalUploadFiles} files`}</span>
        <Progress
          percent={countPercent(
            currentUploadSize,
            totalUploadSize,
            totalUploadFiles === currentUploadFiles
          )}
        />
      </div>
    </div>
  )
}

export default ProgressBar
