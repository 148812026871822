import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, message, Spin } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { PartnerAPI } from 'api/User/Partner'
import TopBar from 'components/TopBar'

import { ReactComponent as UserIcon } from 'sources/images/register/user.svg'
import { ReactComponent as PasswordIcon } from 'sources/images/register/password.svg'
import { ReactComponent as TVPreviewIcon } from 'sources/images/register/tv-preview-register.svg'
import { ReactComponent as EyeIcon } from 'sources/images/register/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'sources/images/register/eye-slash.svg'

import styles from './styles.module.scss'

const Register: FC = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [appName, setAppName] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const onSubmitForm = async (formData: React.FormEvent): Promise<void> => {
    if (password !== confirmPassword) {
      message.warning('Passwords are not equal!', 3)
    } else {
      const response: any = await PartnerAPI.registerNewPartner({
        password,
        email,
        appName,
      })

      if (response.status === 'success') {
        message.success('New partner was register!', 3)
        navigate('/login', { replace: true })
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [])

  return isLoading ? (
    <Spin size="large" className={styles.spinner} />
  ) : (
    <div className={styles.loginWrapper}>
      <TopBar
        isLogged={false}
        distributions={[]}
        onChangeDistribution={(value: number) => {
          console.log(value)
        }}
      />

      <div className={styles.content}>
        <div className={styles.formWrapper}>
          <h2 className={styles.title}>Create an account</h2>
          <Form
            className={styles.form}
            form={form}
            onFinish={onSubmitForm}
            layout="vertical"
          >
            <Form.Item
              label="Mail"
              name="Mail"
              className={cn(styles.formItem, styles.userName)}
            >
              <Input
                className={styles.formInput}
                prefix={<UserIcon />}
                placeholder="Mail"
                autoComplete="false"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              className={styles.formItem}
              name="Password"
              label="Password"
              rules={[
                { min: 8, message: 'Password must be minimum 8 symbols' },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g,
                  message:
                    'Password must be includes upper and lower letter and number',
                },
              ]}
            >
              <Input.Password
                className={styles.formInput}
                prefix={<PasswordIcon />}
                placeholder="Password"
                visibilityToggle
                autoComplete="false"
                iconRender={visible =>
                  visible ? <EyeIcon /> : <EyeSlashIcon />
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              name="Confirm password"
              label="Confirm password"
              className={cn(styles.formItem, styles.confirmPassword)}
              rules={[
                { min: 8, message: 'Password must be minimum 8 symbols' },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g,
                  message:
                    'Password must be includes upper and lower letter and number',
                },
              ]}
            >
              <Input.Password
                className={styles.formInput}
                prefix={<PasswordIcon />}
                placeholder="Confirm password"
                autoComplete="false"
                visibilityToggle
                iconRender={visible =>
                  visible ? <EyeIcon /> : <EyeSlashIcon />
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              className={cn(styles.formItem, styles.channelName)}
              name="Display app name"
              label="Display app name"
              rules={[{ required: true }]}
            >
              <Input
                className={styles.formInput}
                prefix={<TVPreviewIcon />}
                placeholder="My channel name"
                autoComplete="false"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAppName(e.target.value)
                }
              />
            </Form.Item>

            <Button
              className={cn(styles.submitBtn, {
                [styles.active]: !!email && !!password && !!confirmPassword,
              })}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Create your account
            </Button>

            <span className={styles.formText}>
              Already have an account?{' '}
              <Link className={styles.registerLink} to="/login">
                Login
              </Link>
            </span>
          </Form>
        </div>
        <div className={styles.picture} />
      </div>
    </div>
  )
}

export default Register
