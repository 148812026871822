import axios from 'axios'
import { message } from 'antd'

import { config } from 'config'
import history from 'utils/history'

import { removeUserAction } from 'store/actions/user'

export const axiosInstance = axios.create({
  baseURL: `${config.HOST}:${config.USER_SERVICE_PORT}`,
  validateStatus: status => {
    if (status === 401) {
      removeUserAction()
      history.push('/login')
      return false
    }

    return true
  },
})

axiosInstance.interceptors.response.use(
  response => {
    if (response.data.status !== 'ERROR') {
      const redirectPath = localStorage.getItem('redirectPath')

      if (redirectPath && redirectPath !== 'undefined') {
        history.push(redirectPath)
      } else {
        setTimeout(() => {
          history.push('/content')
        }, 500)
      }
    } else {
      message.error(response.data.message, 3)
      return new Promise(() => false)
    }

    return response
  },
  error => Promise.reject(error)
)
