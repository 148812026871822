import { IUser, UserType } from 'models/user'

export enum UserActionsTypes {
  START_LOGIN = 'START_LOGIN',
  SUCCESS_LOGIN = 'SUCCESS_LOGIN',
  SAVE_USER = 'SAVE_USER',
  SAVE_USER_INFO = 'SAVE_USER_INFO',
  REMOVE_USER_INFO = 'REMOVE_USER_INFO',
  SAVE_PARTNER_INFO = 'SAVE_PARTNER_INFO',
  REMOVE_PARTNER_INFO = 'REMOVE_PARTNER_INFO',
  REMOVE_USER = 'REMOVE_USER',
  ERROR_USER = 'ERROR_USER',
}

interface StartLoginAction {
  type: UserActionsTypes.START_LOGIN
}

interface SuccessLoginAction {
  type: UserActionsTypes.SUCCESS_LOGIN
}

interface SaveUserAction {
  type: UserActionsTypes.SAVE_USER
  payload: IUser
}

interface SaveUserInfoAction {
  type: UserActionsTypes.SAVE_USER_INFO
  payload: IUserInfo
}

interface RemoveUserInfoAction {
  type: UserActionsTypes.REMOVE_USER_INFO
}

interface SavePartnerInfoAction {
  type: UserActionsTypes.SAVE_PARTNER_INFO
  payload: IPartnerInfo
}

interface RemovePartnerInfoAction {
  type: UserActionsTypes.REMOVE_PARTNER_INFO
}

interface RemoveUserAction {
  type: UserActionsTypes.REMOVE_USER
}

interface ErrorUserAction {
  type: UserActionsTypes.ERROR_USER
  payload: any
}

export type IUserAction =
  | StartLoginAction
  | SuccessLoginAction
  | SaveUserAction
  | ErrorUserAction
  | RemoveUserAction
  | SaveUserInfoAction
  | RemoveUserInfoAction
  | SavePartnerInfoAction
  | RemovePartnerInfoAction

export interface IUserState {
  user: IUser | null
  userInfo: IUserInfo | null
  partnerInfo: IPartnerInfo | null
  loading: boolean
  error: any
}

export interface IUserInfo {
  id: number
  login: string
  name: string
  email: string
  type: UserType
  createdAt: string
  updatedAt: string
  PartnerId: number
}

export interface IPartnerInfo {
  id: number
  name: string
  description: string
  isConfirmed: boolean
  branding: string
  paymentMethod: string
  type: string
  storageType: string
  vastUrl: null
  vastInterval: null
  subscription: string
  createdAt: string
  updatedAt: string
}
