export enum ColorActionsTypes {
  CHANGE_BACKGROUND = ' CHANGE_BACKGROUND',
  CHANGE_SELECTED_VIDEO = 'CHANGE_SELECTED_VIDEO',
  CHANGE_TEXT = 'CHANGE_TEXT',
  POPULAR_COLORS = 'POPULAR_COLORS',
}

interface SetBackgroundColorAction {
  type: ColorActionsTypes.CHANGE_BACKGROUND
  payload: string
}

interface SetSelectedVideoColorAction {
  type: ColorActionsTypes.CHANGE_SELECTED_VIDEO
  payload: string
}

interface SetTextColorAction {
  type: ColorActionsTypes.CHANGE_TEXT
  payload: string
}

interface SetPopularColorsAction {
  type: ColorActionsTypes.POPULAR_COLORS
  payload: string[]
}

export type IColorAction =
  | SetBackgroundColorAction
  | SetSelectedVideoColorAction
  | SetTextColorAction
  | SetPopularColorsAction

export interface IColorState {
  background: string
  selectedVideo: string
  text: string
  popularColors: string[]
}
