import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Pagination, Spin } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { useTypedSelector } from 'store/hooks/useTypedSelector'

import circleStatusSrc from 'sources/images/content/circle-status.png'
import doneSrc from 'sources/images/content/done.png'

import styles from './styles.module.scss'

interface IProps {
  allMediaItemsInCategory: any
  addedMediaItems: []
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  onSelectAllUploadMediaItem: (e: CheckboxChangeEvent) => void
  selectedUploadMediaItemsId: string[]
  uploadYoutubeMediaItems: any
  isFiltered: boolean
  filteredMediaItems: any
  initCategoryMediaItems: any
  allUnImportedMediaItems: any
  uniqYoutubeChannelVideosWithoutCategory: any
  renderMediaItems: any
  scrollHandler: any
  fetching: boolean

  draggingUploadMediaItemRef: any
  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
}

const Videos: FC<IProps> = ({
  allMediaItemsInCategory,
  addedMediaItems,
  onSelectUploadMediaItem,
  onSelectAllUploadMediaItem,
  selectedUploadMediaItemsId,
  uploadYoutubeMediaItems,
  isFiltered,
  filteredMediaItems,
  initCategoryMediaItems,
  allUnImportedMediaItems,
  uniqYoutubeChannelVideosWithoutCategory,
  renderMediaItems,
  scrollHandler,
  fetching,

  draggingUploadMediaItemRef,
  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
}) => {
  const { storeCurrentDistributionData } = useTypedSelector(
    state => state.distribution
  )

  const allMediaItemsFromStore = storeCurrentDistributionData
    .map((item: any) => item.MediaItems)
    .flat()

  const importedInThisCategoryMediaItems = uploadYoutubeMediaItems.filter(
    (uploadItem: any) =>
      allMediaItemsInCategory.find(
        (categoryItem: any) =>
          uploadItem.snippet.resourceId.videoId === categoryItem.externalId
      )
  )

  // const [total, setTotal] = useState(0)
  // const [page, setPage] = useState(1)
  // const [mediaItemPerPage, setMediaItemPerPage] = useState(15)

  // const indexOfLastPage = page * mediaItemPerPage
  // const indexOfFirstPage = indexOfLastPage - mediaItemPerPage
  // const currentMediaItems = isFiltered
  //   ? filteredMediaItems.slice(indexOfFirstPage, indexOfLastPage)
  //   : uploadYoutubeMediaItems.slice(indexOfFirstPage, indexOfLastPage)

  // const init = (): void => {
  //   setTotal(
  //     isFiltered ? filteredMediaItems.length : uploadYoutubeMediaItems.length
  //   )
  //   setPage(1)
  // }

  // useEffect(() => {
  //   init()
  // }, [filteredMediaItems])

  return (
    <>
      <ul className={styles.videosWrapper}>
        {renderMediaItems.map((mediaItem: any) => (
          <li
            key={uuidv4()}
            data-upload-item={mediaItem.snippet.resourceId.videoId}
            id={mediaItem.snippet.resourceId.videoId}
            draggable
            onDragStart={handleDragStartUploadMediaItems}
            onDragEnter={handleDragEnterUploadMediaItems}
            onDragEnd={handleDragEndUploadMediaItems}
            onDrop={handleDragDropUploadMediaItems}
            className={cn(styles.uploadMediaItem, {
              [styles.hasInCategory]:
                allMediaItemsInCategory.find(
                  (item: any) =>
                    item.snippet &&
                    item.snippet.resourceId.videoId ===
                      mediaItem.snippet.resourceId.videoId
                ) ||
                allMediaItemsInCategory.find(
                  (item: any) =>
                    item.externalId === mediaItem.snippet.resourceId.videoId
                ),
              [styles.disabled]: _.find(addedMediaItems, {
                videoId: mediaItem.snippet.resourceId.videoId,
              }),
              [styles.selectedUploadMediaItem]:
                selectedUploadMediaItemsId.includes(
                  String(mediaItem.snippet.resourceId.videoId)
                ),
            })}
          >
            <img
              className={styles.mediaItemImg}
              src={
                mediaItem.snippet.thumbnails.high &&
                mediaItem.snippet.thumbnails.high.url
              }
              alt="video"
            />
            {_.find(allMediaItemsInCategory, {
              id: mediaItem.id,
            }) && (
              <div className={styles.overlay} draggable={false}>
                <img src={circleStatusSrc} alt="status" draggable={false} />
                <img
                  src={doneSrc}
                  alt="done"
                  className={styles.doneImg}
                  draggable={false}
                />
              </div>
            )}

            {!allUnImportedMediaItems.find(
              (item: any) =>
                item.snippet.resourceId.videoId ===
                mediaItem.snippet.resourceId.videoId
            ) &&
              allMediaItemsFromStore.find(
                (storeItem: any) =>
                  storeItem.externalId === mediaItem.snippet.resourceId.videoId
              ) &&
              !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
              !initCategoryMediaItems?.some(
                (initItem: any) =>
                  initItem.externalId ===
                  String(mediaItem.snippet.resourceId.videoId)
              ) && (
                <div
                  className={styles.importedInAnotherCategory}
                  draggable={false}
                >
                  <img
                    src={circleStatusSrc}
                    alt="status"
                    draggable={false}
                    className={styles.circle}
                  />
                  <img
                    src={doneSrc}
                    alt="done"
                    className={styles.doneImg}
                    draggable={false}
                  />
                </div>
              )}

            {initCategoryMediaItems?.some(
              (initItem: any) =>
                initItem.externalId ===
                String(mediaItem.snippet.resourceId.videoId)
            ) && (
              <div className={styles.overlay} draggable={false}>
                <img src={circleStatusSrc} alt="status" draggable={false} />
                <img
                  src={doneSrc}
                  alt="done"
                  className={styles.doneImg}
                  draggable={false}
                />
              </div>
            )}
            <>
              <>
                <div className={styles.mediaItemControls}>
                  <Checkbox
                    className={styles.checkboxMediaItem}
                    onChange={onSelectUploadMediaItem}
                    checked={selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    )}
                  />
                </div>

                <div className={styles.mediaItemTitleWrapper}>
                  <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                </div>
              </>
            </>
          </li>
        ))}

        {/* {fetching && <Spin size="large" />} */}

        {/* {isFiltered
          ? filteredMediaItems.map((mediaItem: any) => (
              <li
                key={uuidv4()}
                data-upload-item={mediaItem.snippet.resourceId.videoId}
                id={mediaItem.snippet.resourceId.videoId}
                draggable={false}
                className={cn(styles.uploadMediaItem, {
                  [styles.disabled]: _.find(addedMediaItems, {
                    videoId: mediaItem.snippet.resourceId.videoId,
                  }),
                  [styles.selectedUploadMediaItem]:
                    selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    ),
                })}
              >
                <img
                  className={styles.mediaItemImg}
                  src={
                    mediaItem.snippet.thumbnails.high &&
                    mediaItem.snippet.thumbnails.high.url
                  }
                  alt="video"
                />
                {_.find(allMediaItemsInCategory, {
                  id: mediaItem.id,
                }) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

                {!allUnImportedMediaItems.find(
                  (item: any) =>
                    item.snippet.resourceId.videoId ===
                    mediaItem.snippet.resourceId.videoId
                ) &&
                  allMediaItemsFromStore.find(
                    (storeItem: any) =>
                      storeItem.externalId ===
                      mediaItem.snippet.resourceId.videoId
                  ) &&
                  !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !initCategoryMediaItems?.some(
                    (initItem: any) =>
                      initItem.externalId ===
                      String(mediaItem.snippet.resourceId.videoId)
                  ) && (
                    <div
                      className={styles.importedInAnotherCategory}
                      draggable={false}
                    >
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                        className={styles.circle}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {initCategoryMediaItems?.some(
                  (initItem: any) =>
                    initItem.externalId ===
                    String(mediaItem.snippet.resourceId.videoId)
                ) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(
                        String(mediaItem.snippet.resourceId.videoId)
                      )}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                  </div>
                </>
              </li>
            ))
          : uploadYoutubeMediaItems.map((mediaItem: any) => (
              <li
                key={uuidv4()}
                data-upload-item={mediaItem.snippet.resourceId.videoId}
                id={mediaItem.snippet.resourceId.videoId}
                draggable={false}
                className={cn(styles.uploadMediaItem, {
                  [styles.disabled]: _.find(addedMediaItems, {
                    videoId: mediaItem.snippet.resourceId.videoId,
                  }),
                  [styles.selectedUploadMediaItem]:
                    selectedUploadMediaItemsId.includes(
                      String(mediaItem.snippet.resourceId.videoId)
                    ),
                })}
              >
                <img
                  className={styles.mediaItemImg}
                  src={
                    mediaItem.snippet.thumbnails.high &&
                    mediaItem.snippet.thumbnails.high.url
                  }
                  alt="video"
                />
                {_.find(allMediaItemsInCategory, {
                  id: mediaItem.id,
                }) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

                {!allUnImportedMediaItems.find(
                  (item: any) =>
                    item.snippet.resourceId.videoId ===
                    mediaItem.snippet.resourceId.videoId
                ) &&
                  allMediaItemsFromStore.find(
                    (storeItem: any) =>
                      storeItem.externalId ===
                      mediaItem.snippet.resourceId.videoId
                  ) &&
                  !_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !initCategoryMediaItems?.some(
                    (initItem: any) =>
                      initItem.externalId ===
                      String(mediaItem.snippet.resourceId.videoId)
                  ) && (
                    <div
                      className={styles.importedInAnotherCategory}
                      draggable={false}
                    >
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                        className={styles.circle}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {initCategoryMediaItems?.some(
                  (initItem: any) =>
                    initItem.externalId ===
                    String(mediaItem.snippet.resourceId.videoId)
                ) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}
                <>
                  <div className={styles.mediaItemControls}>
                    <Checkbox
                      className={styles.checkboxMediaItem}
                      onChange={onSelectUploadMediaItem}
                      checked={selectedUploadMediaItemsId.includes(
                        String(mediaItem.snippet.resourceId.videoId)
                      )}
                    />
                  </div>

                  <div className={styles.mediaItemTitleWrapper}>
                    <h4 className={styles.title}>{mediaItem.snippet.title}</h4>
                  </div>
                </>
              </li>
            ))} */}
      </ul>
      {/* <Pagination
        onChange={value => setPage(value)}
        pageSize={mediaItemPerPage}
        total={total}
        current={page}
        className={styles.pagination}
        showSizeChanger={false}
        showQuickJumper={false}
      /> */}
    </>
  )
}

export default Videos
