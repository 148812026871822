import { ILoginForm } from 'models/loginForm'
import { IUser } from 'models/user'
import { axiosInstance } from '.'

export const AuthAPI = {
  authUser: async (bodyData: ILoginForm): Promise<IUser> => {
    const { data } = await axiosInstance.post<IUser>('/login', bodyData)
    return data
  },
}
