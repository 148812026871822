import React, { FC, useEffect, useState } from 'react'
import { Button, Collapse, message } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import cn from 'classnames'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { useActions } from 'store/hooks/useActions'

import { getPartnerId } from 'utils/helper'

import ColorPicker from 'components/ColorPicker'
import { IDistribution } from 'models/distribution'
import { DistributionAPI } from 'api/VideoCollection/Distribution'

import viewBgSrc from 'sources/images/branding/branding-view-bg.png'
import { ReactComponent as DownArrowIcon } from 'sources/images/branding/down-arrow.svg'

import styles from './styles.module.scss'

interface IProps {
  currentDistribution: IDistribution | null | undefined
}

const TabColors: FC<IProps> = ({ currentDistribution }) => {
  const { background, selectedVideo, text, popularColors } = useTypedSelector(
    state => state.color
  )
  const { setBackgroundColor, setSelectedVideoColor, setTextColor } =
    useActions()

  const [defaultColors, setDefaultColors] = useState({
    activeColor: '',
    backgroundColor: '',
    textColor: '',
  })

  const { storeCurrentPartnerId } = useTypedSelector(state => state.partner)

  const init = (): void => {
    if (currentDistribution) {
      const { branding } = currentDistribution
      const {
        Active_Color: activeColor,
        Background_Color: backgroundColor,
        Text_Color: textColor,
      } = JSON.parse(branding)

      setBackgroundColor(backgroundColor)
      setSelectedVideoColor(activeColor)
      setTextColor(textColor)

      setDefaultColors({ activeColor, backgroundColor, textColor })
    }
  }

  const onSaveColors = async (): Promise<void> => {
    const updateDistribution = _.cloneDeep(currentDistribution)
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (updateDistribution && partnerId) {
      const distributionId = updateDistribution.id
      const branding = JSON.parse(updateDistribution.branding)

      branding.Active_Color = selectedVideo
      branding.Background_Color = background
      branding.Text_Color = text

      updateDistribution.branding = JSON.stringify(branding)

      await DistributionAPI.updateDistribution(
        partnerId,
        distributionId,
        updateDistribution
      )

      message.success('Colors is saved!', 3)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onResetColors = async (): Promise<void> => {
    const { activeColor, backgroundColor, textColor } = defaultColors

    if (
      activeColor === selectedVideo &&
      backgroundColor === background &&
      textColor === text
    ) {
      return
    }

    setBackgroundColor(backgroundColor)
    setSelectedVideoColor(activeColor)
    setTextColor(textColor)
  }

  const onBackgroundColorPicker = (selectedColor: string): void => {
    setBackgroundColor(selectedColor)
  }

  const onSelectedVideoColorPicker = (selectedColor: string): void => {
    setSelectedVideoColor(selectedColor)
  }

  const onTextColorPicker = (selectedColor: string): void => {
    setTextColor(selectedColor)
  }

  return (
    <>
      <div className={styles.systemColorsWrapper}>
        <div className={styles.selectColors}>
          <h5 className={styles.colorsTitle}>System colors</h5>

          <Collapse className={styles.collapseColors} defaultActiveKey={['1']}>
            <Collapse.Panel
              className={styles.collapseColorsPanel}
              showArrow={false}
              header={
                <div className={styles.colorItem}>
                  <Button
                    className={styles.colorBtn}
                    style={{ backgroundColor: background }}
                    icon={<DownArrowIcon />}
                  />
                  <div className={styles.colorInfo}>
                    <h5 className={styles.colorTitle}>Background color</h5>
                    <span className={styles.colorValue}>{background}</span>
                  </div>
                </div>
              }
              key="1"
            >
              <div className={styles.colorPickerWrapper}>
                <span className={styles.selectedColor}>{background}</span>

                <ColorPicker
                  popularColors={popularColors}
                  id={uuidv4()}
                  colorPickHandler={onBackgroundColorPicker}
                />
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              className={styles.collapseColorsPanel}
              showArrow={false}
              header={
                <div className={styles.colorItem}>
                  <Button
                    className={cn(styles.colorBtn, styles.colorBtnSelected1)}
                    style={{ backgroundColor: selectedVideo }}
                    icon={<DownArrowIcon />}
                  />
                  <div className={styles.colorInfo}>
                    <h5 className={styles.colorTitle}>Video selected color</h5>
                    <span className={styles.colorValue}>{selectedVideo}</span>
                  </div>
                </div>
              }
              key="2"
            >
              <div className={styles.colorPickerWrapper}>
                <span className={styles.selectedColor}>{selectedVideo}</span>

                <ColorPicker
                  popularColors={popularColors}
                  id={uuidv4()}
                  colorPickHandler={onSelectedVideoColorPicker}
                />
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              className={styles.collapseColorsPanel}
              showArrow={false}
              header={
                <div className={styles.colorItem}>
                  <Button
                    className={cn(styles.colorBtn, styles.colorBtnSelected2)}
                    icon={<DownArrowIcon />}
                    style={{ backgroundColor: text }}
                  />
                  <div className={styles.colorInfo}>
                    <h5 className={styles.colorTitle}>Text color</h5>
                    <span className={styles.colorValue}>{text}</span>
                  </div>
                </div>
              }
              key="3"
            >
              <div className={styles.colorPickerWrapper}>
                <span className={styles.selectedColor}>{text}</span>

                <ColorPicker
                  popularColors={popularColors}
                  id={uuidv4()}
                  colorPickHandler={onTextColorPicker}
                />
              </div>
            </Collapse.Panel>
          </Collapse>

          <div className={styles.controlsBtnWrapper}>
            <Button
              shape="round"
              onClick={onSaveColors}
              className={styles.controlsBtn}
            >
              Save colors
            </Button>
            <Button
              shape="round"
              onClick={onResetColors}
              className={styles.controlsBtn}
            >
              Reset colors
            </Button>
          </div>
        </div>

        <div className={styles.viewSelectedColors}>
          <img src={viewBgSrc} alt="view" />
          <div
            className={styles.viewWrapper}
            style={{
              backgroundColor: background,
              color: text,
            }}
          >
            <div className={styles.description}>
              <span className={styles.descriptionTitle}>
                Title of your channel
              </span>
              <p className={styles.descriptionText}>
                text text text text text text text text text text text text text
                text text text text text text text text text text text text text
                text text text text text text text text text text text text text
                text text text text text
              </p>
            </div>

            <div className={styles.categoriesList}>
              <div className={styles.category}>
                <span className={styles.categoryTitle}>First Category</span>
                <ul className={styles.categoryItems}>
                  <li />
                  <li />
                  <li />
                  <li />
                  <li
                    className={styles.selectedItem}
                    style={{ outlineColor: selectedVideo }}
                  />
                  <li />
                </ul>
              </div>

              <div className={styles.category}>
                <span className={styles.categoryTitle}>Second Category</span>
                <ul className={styles.categoryItems}>
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TabColors
