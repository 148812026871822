import React, { createContext, FC, useEffect, useState } from 'react'
import { message } from 'antd'

import history from 'utils/history'

import { IUser, IUserContext } from 'models/user'
import { ILoginForm } from 'models/loginForm'
import { useActions } from 'store/hooks/useActions'
import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { UserAPI } from 'api/User/User'

const initContext: IUserContext = {
  isAuth: false,
  signIn: (data: any, cb: any) => undefined,
  signOut: (cb: any) => undefined,
}

export const AuthContext = createContext(initContext)

export const AuthProvider: FC = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false)
  const { authUserAction, authStartAction, saveUserAction } = useActions()

  useEffect(() => {
    const userFromLS = localStorage.getItem('user')
    const redirectPath = localStorage.getItem('redirectPath')

    if (userFromLS) {
      setIsAuth(true)
      saveUserAction(JSON.parse(userFromLS))

      if (redirectPath && redirectPath !== 'undefined') {
        history.push(redirectPath)
      } else {
        history.push('/content')
      }
    }
  }, [])

  const signIn = (formData: any, cb: any): void => {
    authStartAction()
    authUserAction(formData).then(() => {
      const userFromLS = localStorage.getItem('user')

      if (userFromLS) {
        setIsAuth(true)
        cb()
      } else {
        message.error('Server is not responding!', 3)
      }
    })
  }

  const signOut = (cb: any): void => {
    // setIsAuth(false)
    // localStorage.removeItem('redirectPath')
    cb(setIsAuth)
  }

  const value = { isAuth, signIn, signOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
