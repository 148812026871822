import {
  IDistribution,
  IDistributionQueryParams,
  IResponseDistributions,
} from 'models/distribution'
import {
  AndroidTags,
  AppleTags,
  CommonTags,
  PlatformTypes,
  RokuTags,
} from 'models/platform'
import { axiosInstance } from '.'

export const DistributionAPI = {
  getDistributionsByPartner: async (
    partnerId: number | string,
    queryParams: IDistributionQueryParams
  ): Promise<IResponseDistributions> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution`,
      {
        params: { ...queryParams },
      }
    )

    return data
  },

  getOneDistributionByPartner: async (
    partnerId: number | string,
    distributionId: number,
    queryParams: IDistributionQueryParams
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution/${distributionId}`,
      {
        params: { ...queryParams },
      }
    )

    return data
  },

  updateDistribution: async (
    partnerId: number,
    distributionId: number,
    updateDistribution: IDistribution
  ): Promise<void> => {
    const { data } = await axiosInstance.put(
      `/partner/${partnerId}/distribution/${distributionId}`,
      updateDistribution
    )

    return data
  },

  createDistribution: async (
    partnerId: number,
    name: string
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution`,
      {
        name,
      }
    )

    return data
  },

  createCategoryInDistribution: async (
    partnerId: number,
    distributionId: number
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/category`,
      {
        name: 'New category',
        description: '',
        type: 'regular',
        tags: '',
        schedule: 'none',
        published: true,
        orderNumber: 0,
        MediaCategoryId: 0,
      }
    )

    return data
  },

  addAssetImageToDistribution: async (
    partnerId: number,
    distributionId: number,
    tag: AppleTags | RokuTags | AndroidTags | CommonTags,
    platform: PlatformTypes,
    fileData: any
  ): Promise<void> => {
    const formData = new FormData()

    formData.append('type', '')
    formData.append('platform', platform)
    formData.append('tag', tag)
    formData.append('asset', fileData)

    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/assetimage`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  },
}
