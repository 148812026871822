import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Button, Checkbox, Input, Pagination, Tabs } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import Upload, { RcFile } from 'antd/lib/upload'
import cn from 'classnames'

import { getMediaItemImageUrl } from 'utils/helper'

import { ReactComponent as SearchIcon } from 'sources/images/appVideos/tab-search-icon.svg'
import { ReactComponent as DownArrowIcon } from 'sources/images/appVideos/tab-down-arrow.svg'

import circleStatusSrc from 'sources/images/content/circle-status.png'
import doneSrc from 'sources/images/content/done.png'

import defaultImgBgSrc from 'sources/images/libary/default-img-bg.png'

import styles from './styles.module.scss'

interface IProps {
  allMediaItemsInCategory: any
  uploadMediaItems: RcFile[] | []
  onSearchUploadMediaItems: (e: ChangeEvent<HTMLInputElement>) => void
  isFiltered: boolean
  filteredMediaItems: RcFile[] | []
  addedMediaItems: RcFile[] | []
  onBeforeUploadFile: (file: RcFile, fileList: RcFile[]) => boolean
  onSelectUploadMediaItem: (e: CheckboxChangeEvent) => void
  onSelectAllUploadMediaItem: (e: CheckboxChangeEvent) => void
  onDeleteUploadMediaItem: (id: string) => void
  selectedUploadMediaItemsId: string[]
  handleDragStartUploadMediaItems: (e: any) => void
  handleDragEnterUploadMediaItems: (e: any) => void
  handleDragEndUploadMediaItems: (e: any) => void
  handleDragDropUploadMediaItems: (e: any) => void
  initCategoryMediaItems: any
  renderMediaItems: any
  scrollHandler: any
  fetching: boolean
  draggingUploadMediaItemRef: any
  failedMediaItems: any
}

const UploadMediaItems: FC<IProps> = ({
  allMediaItemsInCategory,
  uploadMediaItems,
  addedMediaItems,
  onBeforeUploadFile,
  onSelectUploadMediaItem,
  onSelectAllUploadMediaItem,
  selectedUploadMediaItemsId,
  handleDragStartUploadMediaItems,
  handleDragEnterUploadMediaItems,
  handleDragEndUploadMediaItems,
  handleDragDropUploadMediaItems,
  onSearchUploadMediaItems,
  isFiltered,
  filteredMediaItems,
  initCategoryMediaItems,
  renderMediaItems,
  scrollHandler,
  fetching,
  draggingUploadMediaItemRef,

  failedMediaItems,
}) => {
  const [isVideosTab, setIsVideosTab] = useState(true)

  const operations = {
    right: (
      <div className={styles.tabOperations}>
        <Upload
          accept=".mp4"
          multiple
          showUploadList={false}
          method={undefined}
          beforeUpload={onBeforeUploadFile}
        >
          <Button className={styles.uploadBtn} shape="round">
            Upload videos
          </Button>
        </Upload>

        <Button className={styles.tabOperationsBtn} shape="round">
          <SearchIcon />{' '}
          <Input
            placeholder="Search"
            className={styles.searchField}
            onChange={onSearchUploadMediaItems}
          />
        </Button>
        <Button className={styles.tabOperationsBtn} shape="round">
          Recently added <DownArrowIcon />
        </Button>
        <Button className={styles.tabOperationsBtn} shape="round">
          <Checkbox
            className={styles.checkbox}
            onChange={onSelectAllUploadMediaItem}
          >
            Select all
          </Checkbox>
        </Button>
      </div>
    ),
  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        className={styles.tabs}
        onScroll={scrollHandler}
        tabBarExtraContent={isVideosTab ? operations : null}
        data-tab="videos"
      >
        <Tabs.TabPane
          className={styles.tabVideos}
          tab={`Videos (${uploadMediaItems.length})`}
          key="1"
        >
          <Upload.Dragger
            className={styles.uploadWrapper}
            accept=".mp4"
            multiple
            method={undefined}
            beforeUpload={onBeforeUploadFile}
            showUploadList={false}
            openFileDialogOnClick={false}
          >
            {renderMediaItems.map((mediaItem: any) => (
              <li
                key={uuidv4()}
                data-upload-item={mediaItem.uid || mediaItem.id}
                id={mediaItem.uid || mediaItem.id}
                draggable
                onDragStart={handleDragStartUploadMediaItems}
                onDragEnter={handleDragEnterUploadMediaItems}
                onDragEnd={handleDragEndUploadMediaItems}
                onDrop={handleDragDropUploadMediaItems}
                className={cn(styles.uploadMediaItem, {
                  // [styles.draggingMediaItem]:
                  //   draggingMediaItemsId &&
                  //   draggingMediaItemsId.find((currentId: string) =>
                  //     mediaItem.uid
                  //       ? currentId === String(mediaItem.uid)
                  //       : currentId === String(mediaItem.id)
                  //   ),
                  [styles.hasInCategory]:
                    _.find(allMediaItemsInCategory, {
                      uid: mediaItem.uid,
                    }) ||
                    _.find(allMediaItemsInCategory, { id: mediaItem.id }) ||
                    failedMediaItems.find(
                      (item: any) => item.id === mediaItem.id
                    ),
                  [styles.disabled]: _.find(addedMediaItems, {
                    uid: mediaItem.uid,
                  }),
                  [styles.selectedUploadMediaItem]:
                    selectedUploadMediaItemsId.includes(
                      String(mediaItem.uid)
                    ) ||
                    selectedUploadMediaItemsId.includes(String(mediaItem.id)),
                })}
              >
                {!mediaItem.id && (
                  <p className={styles.text}>{mediaItem.name}</p>
                )}

                {mediaItem.status === 'failed' && (
                  <div className={styles.failedOverlay}>
                    <span className={styles.failedIconWrapper}>
                      <CloseOutlined className={styles.failedIcon} />
                    </span>
                  </div>
                )}

                {/* <div className={styles.draggingMediaItem} /> */}

                {mediaItem.id && (
                  <img
                    className={cn(styles.mediaItemImg)}
                    src={
                      mediaItem.Images.length > 0
                        ? getMediaItemImageUrl(mediaItem.Images, 'Small', true)
                        : defaultImgBgSrc
                    }
                    alt="video"
                  />
                )}

                {_.find(allMediaItemsInCategory, {
                  uid: mediaItem.uid,
                }) && (
                  <div className={styles.overlay} draggable={false}>
                    <img src={circleStatusSrc} alt="status" draggable={false} />
                    <img
                      src={doneSrc}
                      alt="done"
                      className={styles.doneImg}
                      draggable={false}
                    />
                  </div>
                )}

                {_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !failedMediaItems.find(
                    (item: any) => item.id === mediaItem.id
                  ) && (
                    <div className={styles.overlay} draggable={false}>
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {!_.find(allMediaItemsInCategory, { id: mediaItem.id }) &&
                  !mediaItem.Categories &&
                  !mediaItem.uid &&
                  !failedMediaItems.find(
                    (item: any) => item.id === mediaItem.id
                  ) && (
                    <div
                      className={styles.importedInAnotherCategory}
                      draggable={false}
                    >
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                        className={styles.circle}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}

                {initCategoryMediaItems?.some(
                  (initItem: any) => initItem.id && initItem.id === mediaItem.id
                ) &&
                  !failedMediaItems.find(
                    (item: any) => item.id === mediaItem.id
                  ) && (
                    <div className={styles.overlay} draggable={false}>
                      <img
                        src={circleStatusSrc}
                        alt="status"
                        draggable={false}
                      />
                      <img
                        src={doneSrc}
                        alt="done"
                        className={styles.doneImg}
                        draggable={false}
                      />
                    </div>
                  )}
                <>
                  <>
                    <div className={styles.mediaItemControls}>
                      <Checkbox
                        className={styles.checkboxMediaItem}
                        onChange={onSelectUploadMediaItem}
                        checked={
                          selectedUploadMediaItemsId.includes(
                            String(mediaItem.uid)
                          ) ||
                          selectedUploadMediaItemsId.includes(
                            String(mediaItem.id)
                          )
                        }
                      />
                    </div>

                    <div className={styles.mediaItemTitleWrapper}>
                      <h4 className={styles.title}>{mediaItem.name}</h4>
                    </div>
                  </>
                </>
              </li>
            ))}
          </Upload.Dragger>
        </Tabs.TabPane>
      </Tabs>
      {/* <Pagination
        onChange={value => setPage(value)}
        pageSize={mediaItemPerPage}
        total={total}
        current={page}
        className={styles.pagination}
        showSizeChanger={false}
        showQuickJumper={false}
      /> */}
    </>
  )
}

export default UploadMediaItems
