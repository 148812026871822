import _, { update } from 'lodash'
import { axiosInstance } from '.'

export const MediaItemAPI = {
  setOrderNumberForMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    categoryId: number,
    orderNumber: number
  ): Promise<void> => {
    const { data } = await axiosInstance.put(
      `/partner/${partnerId}/distribution/${distributionId}/mediaItem/${mediaItemId}/category/${categoryId}/orderNumber/${orderNumber}`
    )

    return data
  },

  createMediaItemWithUpload: async (
    partnerId: number,
    distributionId: number,
    fileData: any,
    categoryId: number,
    changeProgressBarUploadTotalSizeAction: any,
    changeProgressBarUploadSizeAction: any,
    setCurrentUploadFiles: React.Dispatch<React.SetStateAction<number>>,
    controller: AbortController
  ): Promise<void> => {
    const formData = new FormData()

    let isFirstTick = true
    // let isDownloadedFile = false
    let loadedSize = 0

    formData.append('type', 'vod')
    formData.append('title', fileData.name)
    formData.append('name', fileData.name)
    formData.append('description', fileData.uid)
    formData.append('ageRating', '')
    formData.append('tags', '')
    formData.append('storageType', 'host')
    formData.append('published', 'true')
    formData.append('sourceUrl', '')
    formData.append('source', 'file')
    formData.append('video', fileData)

    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/upload?categoryIds=${categoryId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: controller.signal,
        onDownloadProgress(progressEvent) {
          setCurrentUploadFiles(prevCount => {
            const newCount = prevCount + 1
            return newCount
          })
          // if (!isDownloadedFile) {
          //   isDownloadedFile = true
          //   setCurrentUploadFiles(prevCount => {
          //     const newCount = prevCount + 1
          //     return newCount
          //   })
          // }
        },
        onUploadProgress(progressEvent) {
          changeProgressBarUploadSizeAction(loadedSize, progressEvent.loaded)

          loadedSize = progressEvent.loaded

          if (isFirstTick) {
            isFirstTick = false
            changeProgressBarUploadTotalSizeAction(progressEvent.total)
          }
        },
      }
    )

    return data
  },

  setCategoriesForMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    categoryIds: string
  ): Promise<void> => {
    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}/category/${categoryIds}`
    )

    return data
  },

  addCategoriesForMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    categoryIds: string
  ): Promise<void> => {
    const { data } = await axiosInstance.put(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}/category/${categoryIds}`
    )

    return data
  },

  deleteCategoryForMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    categoryIds: string
  ): Promise<void> => {
    await axiosInstance.delete(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}/category/${categoryIds}`
    )
  },

  getMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    queryParams: any
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}`,
      {
        params: { ...queryParams },
      }
    )
    return data
  },

  getAllMediaItemsForDistribution: async (
    partnerId: number | string,
    distributionId: number | string
  ): Promise<void> => {
    const { data } = await axiosInstance.get(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem`
    )

    return data
  },

  deleteMediaItemFromDistribution: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number
  ): Promise<void> => {
    const { data } = await axiosInstance.delete(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}`
    )

    return data
  },

  updateMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    updateMediaItem: any
  ): Promise<void> => {
    const { data } = await axiosInstance.put(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}`,
      updateMediaItem
    )

    return data
  },

  createThumbnailsForMediaItem: async (
    partnerId: number,
    distributionId: number,
    mediaItemId: number,
    fileData: any
  ) => {
    const formData = new FormData()

    formData.append('types', '')
    formData.append('preview', fileData)

    const { data } = await axiosInstance.post(
      `/partner/${partnerId}/distribution/${distributionId}/mediaitem/${mediaItemId}/thumbnails`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  },
}
