import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, message, Spin, Typography } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'

import history from 'utils/history'

import { ICategory } from 'models/category'
import { IMediaItem } from 'models/mediaItem'
import { DistributionAPI } from 'api/VideoCollection/Distribution'
import { IDistribution, IResponseDistributions } from 'models/distribution'
import { IPartner } from 'models/partner'

import SideBar from 'components/SideBar'
import TopBar from 'components/TopBar'
import Preview from 'components/Preview'

import { useTypedSelector } from 'store/hooks/useTypedSelector'
import { MediaItemAPI } from 'api/VideoCollection/MediaItem'
import { PartnerAPI } from 'api/User/Partner'

import { getPartnerId } from 'utils/helper'

import { UserAPI } from 'api/User/User'
import { IUser } from 'models/user'

import { ReactComponent as AddIcon } from 'sources/images/add-icon.svg'
import { ReactComponent as PreviewIcon } from 'sources/images/tv-preview-icon.svg'
import { ReactComponent as CloseSelectedMediaItemsIcon } from 'sources/images/content/close-selected-media-items.svg'

import { CategoryAPI } from 'api/VideoCollection/Category'
import { useActions } from 'store/hooks/useActions'

import WithoutCategories from './WithoutCategories'
import Category from './Category'

import styles from './styles.module.scss'

interface IProps {
  isDoneUploadComputerMediaItems: boolean
  isDoneUploadVimeoMediaItems: boolean
  isDoneUploadYoutubeMediaItems: boolean
  setIsDoneUploadComputerMediaItems: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setIsDoneUploadVimeoMediaItems: React.Dispatch<React.SetStateAction<boolean>>
  setsDoneUploadYoutubeMediaItems: React.Dispatch<React.SetStateAction<boolean>>
}

let timerId: NodeJS.Timeout | null = null
const longDelay = 300000
const shortDelay = 60000

const Content: FC<IProps> = ({
  isDoneUploadComputerMediaItems,
  isDoneUploadVimeoMediaItems,
  isDoneUploadYoutubeMediaItems,
  setIsDoneUploadComputerMediaItems,
  setIsDoneUploadVimeoMediaItems,
  setsDoneUploadYoutubeMediaItems,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [allCategories, setAllCategories] = useState<ICategory[] | []>([])
  const [newCategoryId, setNewCategoryId] = useState<number | null>(null)

  const [allDistributions, setAllDistributions] = useState<IDistribution[]>([])
  const [currentDistribution, setCurrentDistribution] =
    useState<IDistribution | null>()

  const [allMediaItems, setAllMediaItems] = useState<IMediaItem[] | []>([])

  const [selectedMediaItemsId, setSelectedMediaItemsId] = useState<any>([])
  const [isOpenMediaItemsControls, setIsOpenMediaItemsControls] =
    useState(false)

  const [isDraggingMediaItem, setIsDraggingMediaItem] = useState(false)

  const [isLongInterval, setIsLongInterval] = useState(false)

  const { storeCurrentDistributionId } = useTypedSelector(
    state => state.distribution
  )
  const {
    saveCurrentDistributionIdAction,
    saveCurrentDistributionDataAction,
    saveCurrentPartnerIdAction,
    saveCurrentPartnerDataAction,
    clearDistributionAction,
  } = useActions()

  const [previewVisible, setPreviewVisible] = useState(false)

  // super admin
  const [allPartners, setAllPartners] = useState<any>(null)
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  const [currentPartner, setCurrentPartner] = useState<IPartner | null>(null)
  const {
    storeCurrentPartnerId,
    storeCurrentPartnerData,
    storeAllPartnersData,
  } = useTypedSelector(state => state.partner)

  const draggingCategoryRef = useRef<HTMLElement | null>(null)
  const draggingStartedIdxRef = useRef<number | null>(null)

  const navigate = useNavigate()
  const path = useLocation().pathname

  const createInterval = (
    mediaItems: IMediaItem[],
    distributionId: number
  ): void => {
    const partnerId = storeCurrentPartnerId || getPartnerId()

    if (partnerId) {
      timerId = setInterval(
        async () => {
          const response: any =
            await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

          if (response.status === 'success') {
            const categories = response.distributions[0].Categories

            const totalMediaItems = categories
              .map(
                (currentCategory: any) =>
                  currentCategory.MediaItems && currentCategory.MediaItems
              )
              .flat()
              .filter((item: any) => item !== undefined)

            setAllMediaItems(totalMediaItems)

            const isLongIntervalChecked = totalMediaItems.every(
              (mediaItem: IMediaItem) => mediaItem.status === 'imported'
            )

            setIsLongInterval(isLongIntervalChecked)
            if (categories) {
              const cloneCategories = _.cloneDeep(allCategories)

              setAllCategories(cloneCategories)
              setAllCategories(prev => {
                const clone = _.cloneDeep(categories)

                return clone.sort(
                  (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
                )
              })
            } else {
              setAllCategories([])
            }
          }
        },
        !isLongInterval ? shortDelay : longDelay
      )
    }
  }

  const init = async (): Promise<void> => {
    try {
      setIsLoadingData(true)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      localStorage.setItem('redirectPath', path)

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          if (storeCurrentDistributionId) {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(storeCurrentDistributionId)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          } else {
            response = await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributions[0].id,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

            saveCurrentDistributionIdAction(distributions[0].id)
            saveCurrentDistributionDataAction(
              response.distributions[0].Categories.filter(
                (item: any) => item.name !== 'Orphan'
              )
            )
          }

          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])

            const categories = response.distributions[0].Categories
            const totalMediaItems = categories
              .map(
                (currentCategory: any) =>
                  currentCategory.MediaItems && currentCategory.MediaItems
              )
              .flat()
              .filter((item: any) => item !== undefined)

            setAllMediaItems(totalMediaItems)

            if (timerId) {
              clearInterval(timerId)
            }

            createInterval(allMediaItems, response.distributions[0].id)

            if (categories) {
              setAllCategories(
                categories.sort(
                  (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
                )
              )
            } else {
              setAllCategories([])
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    init()

    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [])

  const getSuperAdminInitDistribution = async (): Promise<void> => {
    try {
      setIsLoadingData(true)
      const partnerId = storeCurrentPartnerId

      if (partnerId) {
        const { distributions }: IResponseDistributions =
          await DistributionAPI.getDistributionsByPartner(partnerId, {})

        setAllDistributions(distributions)

        if (distributions.length > 0) {
          let response: any = null

          response = await DistributionAPI.getOneDistributionByPartner(
            partnerId,
            distributions[0].id,
            {
              withCategories: 'true',
              withMediaItems: 'true',
              withMediaItemImages: 'true',
            }
          )

          saveCurrentDistributionIdAction(distributions[0].id)
          saveCurrentDistributionDataAction(
            response.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )
          if (response.status === 'success') {
            setCurrentDistribution(response.distributions[0])

            const categories = response.distributions[0].Categories
            const totalMediaItems = categories
              .map(
                (currentCategory: any) =>
                  currentCategory.MediaItems && currentCategory.MediaItems
              )
              .flat()
              .filter((item: any) => item !== undefined)

            setAllMediaItems(totalMediaItems)

            if (timerId) {
              clearInterval(timerId)
            }

            createInterval(allMediaItems, response.distributions[0].id)

            if (categories) {
              setAllCategories(
                categories.sort(
                  (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
                )
              )
            } else {
              setAllCategories([])
            }
          }
        } else {
          saveCurrentDistributionIdAction(null)
          saveCurrentDistributionDataAction(null)
          setAllDistributions([])
          setAllCategories([])
          localStorage.setItem('currentDistribution', 'undefined')
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminInitDistribution()

      localStorage.setItem(
        'currentPartner',
        JSON.stringify(storeCurrentPartnerId)
      )
    }
  }, [storeCurrentPartnerId])

  useEffect(() => {
    if (timerId) {
      clearInterval(timerId)
    }

    if (storeCurrentDistributionId) {
      createInterval(allMediaItems, storeCurrentDistributionId)
    }
  }, [isLongInterval])

  useEffect(() => {
    if (currentDistribution) {
      const categories = currentDistribution.Categories

      if (categories) {
        const totalMediaItems = categories
          .map(
            (currentCategory: any) =>
              currentCategory.MediaItems && currentCategory.MediaItems
          )
          .flat()
          .filter((item: any) => item !== undefined)

        setAllMediaItems(totalMediaItems)

        if (timerId) {
          clearInterval(timerId)
        }

        createInterval(totalMediaItems, currentDistribution.id)

        setAllCategories(
          categories.sort(
            (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
          )
        )
      } else {
        setAllCategories([])
      }

      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    localStorage.setItem(
      'currentDistribution',
      JSON.stringify(currentDistribution?.id)
    )
  }, [currentDistribution])

  const updateDistributionDataAfterUpload = async (): Promise<void> => {
    const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

    if (partnerId && currentDistribution) {
      const distributionToUpdateUploadMediaItemsId = currentDistribution.id

      if (distributionToUpdateUploadMediaItemsId === currentDistribution.id) {
        const response: any = await DistributionAPI.getOneDistributionByPartner(
          partnerId,
          distributionToUpdateUploadMediaItemsId,
          {
            withCategories: 'true',
            withMediaItems: 'true',
            withMediaItemImages: 'true',
          }
        )

        saveCurrentDistributionIdAction(distributionToUpdateUploadMediaItemsId)
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )

        if (response.status === 'success') {
          setCurrentDistribution(response.distributions[0])

          const categories = response.distributions[0].Categories
          const totalMediaItems = categories
            .map(
              (currentCategory: any) =>
                currentCategory.MediaItems && currentCategory.MediaItems
            )
            .flat()
            .filter((item: any) => item !== undefined)

          setAllMediaItems(totalMediaItems)

          if (timerId) {
            clearInterval(timerId)
          }

          createInterval(allMediaItems, response.distributions[0].id)

          if (categories) {
            setAllCategories([])
            setAllCategories(
              categories.sort(
                (a: ICategory, b: ICategory) => a.orderNumber - b.orderNumber
              )
            )
          } else {
            setAllCategories([])
          }
        }
      }
    }
  }

  useEffect(() => {
    if (isDoneUploadComputerMediaItems) {
      updateDistributionDataAfterUpload()
      setIsDoneUploadComputerMediaItems(false)
    }

    if (isDoneUploadVimeoMediaItems) {
      updateDistributionDataAfterUpload()
      setIsDoneUploadVimeoMediaItems(false)
    }

    if (isDoneUploadYoutubeMediaItems) {
      updateDistributionDataAfterUpload()
      setsDoneUploadYoutubeMediaItems(false)
    }
  }, [
    isDoneUploadComputerMediaItems,
    isDoneUploadVimeoMediaItems,
    isDoneUploadYoutubeMediaItems,
  ])

  const onChangePartner = async (currentIndex: number): Promise<void> => {
    const changePartner = allPartners[currentIndex]
    const userFromLS = localStorage.getItem('user')

    if (timerId) {
      clearInterval(timerId)
    }

    if (changePartner && userFromLS) {
      const accountFromLS: IUser = JSON.parse(userFromLS)

      const response: any = await PartnerAPI.getOnePartnerInfo(
        changePartner.id,
        accountFromLS.token
      )

      saveCurrentPartnerIdAction(allPartners[currentIndex].id)

      if (response.status === 'success') {
        setCurrentPartner(response.partners[0])
        saveCurrentPartnerDataAction(response.partners[0])
      }
    }
  }

  const onChangeDistribution = async (currentIndex: number): Promise<void> => {
    const partnerId = isSuperAdmin ? storeCurrentPartnerId : getPartnerId()

    if (timerId) {
      clearInterval(timerId)
    }

    if (partnerId) {
      const response: any = await DistributionAPI.getOneDistributionByPartner(
        partnerId,
        allDistributions[currentIndex].id,
        {
          withCategories: 'true',
          withMediaItems: 'true',
          withMediaItemImages: 'true',
        }
      )

      saveCurrentDistributionIdAction(allDistributions[currentIndex].id)

      if (response.status === 'success') {
        setCurrentDistribution(response.distributions[0])
        saveCurrentDistributionDataAction(
          response.distributions[0].Categories.filter(
            (item: any) => item.name !== 'Orphan'
          )
        )
      }
    }
  }

  const onChangeCategories = (updateCategories: ICategory[]): void => {
    try {
      let cloneCategories = _.cloneDeep(allCategories)

      cloneCategories = _.differenceBy(allCategories, updateCategories, 'id')
      cloneCategories = cloneCategories
        .concat(updateCategories)
        .sort(
          (category1: ICategory, category2: ICategory) =>
            category1.orderNumber - category2.orderNumber
        )

      setAllCategories(cloneCategories)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDragStartCategory = (e: any): void => {
    const target = e.target as HTMLElement
    const draggingCategory = target.closest(
      '[data-category-order]'
    ) as HTMLElement
    draggingStartedIdxRef.current = allCategories?.findIndex(
      ({ id }: ICategory) => Number(draggingCategory.id) === id
    )

    draggingCategoryRef.current = draggingCategory
  }

  const handleDragEnterCategory = (e: any): void => {
    if (isDraggingMediaItem) {
      return
    }

    const target = e.target as HTMLElement
    const underCategory = target.closest('[data-category-order]') as HTMLElement

    if (draggingCategoryRef.current?.id !== underCategory.id) {
      const draggingIdx = allCategories?.findIndex(
        ({ id }: ICategory) => Number(draggingCategoryRef.current?.id) === id
      )
      const underIdx = allCategories?.findIndex(
        ({ id }: ICategory) => Number(underCategory.id) === id
      )

      if (draggingIdx === -1 || underIdx === -1) {
        return
      }

      const cloneCategories = _.cloneDeep(allCategories)

      if (
        cloneCategories &&
        draggingIdx !== undefined &&
        underIdx !== undefined
      ) {
        const tmp = cloneCategories[draggingIdx]
        cloneCategories[draggingIdx] = cloneCategories[underIdx]
        cloneCategories[underIdx] = tmp

        setAllCategories(cloneCategories)
      }
    }
  }

  const handleDragLeaveCategory = (e: React.MouseEvent): void => {
    // e.target.classList.remove('over')
  }

  const handleDragEndCategory = (e: React.MouseEvent): void => {
    // const target = e.target as HTMLElement
  }

  const handleDragOverCategory = (e: React.MouseEvent): void => {
    e.preventDefault()
  }

  const handleDragDropCategory = async (e: any): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()

    try {
      const target = e.target as HTMLElement
      const underCategory = target.closest(
        '[data-category-order]'
      ) as HTMLElement
      const underIdx = allCategories?.findIndex(
        ({ id }: ICategory) => Number(underCategory.id) === id
      )
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (draggingStartedIdxRef.current !== underIdx) {
        const category = allCategories.find(
          ({ id }) => Number(draggingCategoryRef.current?.id) === id
        )

        if (category && partnerId) {
          const distributionId = Number(category?.DistributionId)
          const categoryId = Number(draggingCategoryRef.current?.id)
          const newNumberOrder = allCategories.findIndex(
            ({ id }) => Number(underCategory.id) === id
          )

          category.orderNumber = newNumberOrder + 1

          await CategoryAPI.updateCategory(
            partnerId,
            distributionId,
            categoryId,
            {
              ...category,
            }
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onAddNewVideos = (id: string, distributionId: number): void => {
    if (distributionId === undefined && currentDistribution) {
      const currentDistributionId = currentDistribution.id
      navigate(`${currentDistributionId}/add-videos/${id}/computer`)
    } else {
      navigate(`${distributionId}/add-videos/${id}/computer`)
    }
  }

  const onCloseControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(false)
  }

  const onOpenControlsSelectedMediaItems = (): void => {
    setIsOpenMediaItemsControls(true)
  }

  const changeSelectedMediaItemsId = (
    newId: string,
    newCategoryIdItem: number,
    isRemoved: boolean
  ): void => {
    if (isRemoved) {
      const filteredIds = selectedMediaItemsId.filter(
        (item: any) => item.mediaItemId !== newId
      )
      setSelectedMediaItemsId(filteredIds)
    } else {
      setSelectedMediaItemsId([
        ...selectedMediaItemsId,
        { mediaItemId: newId, categoryId: newCategoryIdItem },
      ])
    }
  }

  const onCreateNewCategory = async (isBottomBtn: boolean): Promise<void> => {
    try {
      const cloneAllCategories = _.cloneDeep(allCategories)
      const cloneCurrentDistribution = _.cloneDeep(currentDistribution)
      const distributionId = currentDistribution && currentDistribution.id
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && distributionId && cloneCurrentDistribution) {
        const response: any =
          await DistributionAPI.createCategoryInDistribution(
            partnerId,
            distributionId
          )

        const newCategory = response.category

        newCategory.orderNumber = 1

        await CategoryAPI.updateCategory(
          partnerId,
          distributionId,
          newCategory.id,
          {
            ...newCategory,
          }
        )

        let responseDistribution: any = null

        if (storeCurrentDistributionId) {
          responseDistribution =
            await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

          saveCurrentDistributionDataAction(
            responseDistribution.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )
        } else {
          responseDistribution =
            await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

          saveCurrentDistributionDataAction(
            responseDistribution.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )
        }

        if (isBottomBtn) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }

        const updateCAllCategories = [response.category, ...cloneAllCategories]

        cloneCurrentDistribution.Categories = updateCAllCategories

        setAllCategories(updateCAllCategories)
        setNewCategoryId(response.category.id)
        message.success('Category was created!', 3)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDeleteCategory = async (
    categoryId: number,
    distributionId: number
  ): Promise<void> => {
    try {
      const cloneAllCategories = _.cloneDeep(allCategories)
      const cloneCurrentDistribution = _.cloneDeep(currentDistribution)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && cloneCurrentDistribution) {
        await CategoryAPI.deleteCategory(partnerId, distributionId, categoryId)

        let responseDistribution: any = null

        if (storeCurrentDistributionId) {
          responseDistribution =
            await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              storeCurrentDistributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

          saveCurrentDistributionDataAction(
            responseDistribution.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )
        } else {
          responseDistribution =
            await DistributionAPI.getOneDistributionByPartner(
              partnerId,
              distributionId,
              {
                withCategories: 'true',
                withMediaItems: 'true',
                withMediaItemImages: 'true',
              }
            )

          saveCurrentDistributionDataAction(
            responseDistribution.distributions[0].Categories.filter(
              (item: any) => item.name !== 'Orphan'
            )
          )
        }

        const updateAllCategories = cloneAllCategories.filter(
          ({ id }) => id !== categoryId
        )

        cloneCurrentDistribution.Categories = updateAllCategories

        setAllCategories(updateAllCategories)
        message.success('Category was deleted!', 3)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDropdownMenuChangePublishedMediaItem = async (
    categoryId: number
  ): Promise<void> => {
    try {
      const cloneAllCategories = _.cloneDeep(allCategories)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      const findCategory = cloneAllCategories.find(
        (item: any) => item.id === categoryId
      )

      if (findCategory && partnerId && storeCurrentDistributionId) {
        if (!findCategory.published) {
          findCategory.published = true

          const updateAllCategories = _.cloneDeep(allCategories).map(
            (category: any) => {
              if (categoryId === category.id) {
                const cloneCategory = _.cloneDeep(category)
                cloneCategory.published = true
                return cloneCategory
              }

              return category
            }
          )

          const categoryResponse: any = await CategoryAPI.updateCategory(
            partnerId,
            storeCurrentDistributionId,
            findCategory.id,
            findCategory
          )

          setAllCategories(updateAllCategories)
          message.success('Category was published!', 3)
        } else {
          findCategory.published = false

          const updateAllCategories = _.cloneDeep(allCategories).map(
            (category: any) => {
              if (categoryId === category.id) {
                const cloneCategory = _.cloneDeep(category)
                cloneCategory.published = false
                return cloneCategory
              }

              return category
            }
          )

          const categoryResponse: any = await CategoryAPI.updateCategory(
            partnerId,
            storeCurrentDistributionId,
            findCategory.id,
            findCategory
          )

          setAllCategories(updateAllCategories)
          message.success('Category was unpublished!', 3)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePublishedCategory = async (
    checked: boolean,
    e: MouseEvent
  ): Promise<void> => {
    try {
      const target = e.target as any
      const buttonEl = target.closest('[data-category-id]')
      const categoryId = buttonEl?.dataset.categoryId
      const cloneAllCategories = _.cloneDeep(allCategories)
      const partnerId = storeCurrentPartnerId || getPartnerId()

      const findCategory = cloneAllCategories.find(
        (item: any) => String(item.id) === categoryId
      )

      if (checked && partnerId && findCategory && storeCurrentDistributionId) {
        findCategory.published = true

        const updateAllCategories = _.cloneDeep(allCategories).map(
          (category: any) => {
            if (categoryId === String(category.id)) {
              const cloneCategory = _.cloneDeep(category)
              cloneCategory.published = true
              return cloneCategory
            }

            return category
          }
        )

        const categoryResponse: any = await CategoryAPI.updateCategory(
          partnerId,
          storeCurrentDistributionId,
          findCategory.id,
          findCategory
        )

        setAllCategories(updateAllCategories)
        message.success('Category was published!', 3)
      }

      if (!checked && partnerId && findCategory && storeCurrentDistributionId) {
        findCategory.published = false

        const updateAllCategories = _.cloneDeep(allCategories).map(
          (category: any) => {
            if (categoryId === String(category.id)) {
              const cloneCategory = _.cloneDeep(category)
              cloneCategory.published = false
              return cloneCategory
            }

            return category
          }
        )

        const categoryResponse: any = await CategoryAPI.updateCategory(
          partnerId,
          storeCurrentDistributionId,
          findCategory.id,
          findCategory
        )

        setAllCategories(updateAllCategories)
        message.success('Category was unpublished!', 3)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onRemoveSelectedMediaItemsAction = async (): Promise<void> => {
    try {
      if (selectedMediaItemsId.length === 0) {
        return
      }

      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id

        const promiseArr = selectedMediaItemsId.map((item: any) =>
          MediaItemAPI.deleteCategoryForMediaItem(
            partnerId,
            distributionId,
            item.mediaItemId,
            String(item.categoryId)
          )
        )

        const responses = await Promise.allSettled(promiseArr)
        const cloneAllCategories = _.cloneDeep(allCategories)

        const filteredAllCategories = selectedMediaItemsId.map((item: any) => {
          const findCategory = cloneAllCategories.find(
            (category: ICategory) => item.categoryId === category.id
          )

          if (findCategory && findCategory.MediaItems) {
            findCategory.MediaItems = findCategory.MediaItems.filter(
              (mediaItem: IMediaItem) =>
                item.mediaItemId !== String(mediaItem.id)
            )
          }

          return findCategory
        })

        const updateAllCategories = cloneAllCategories.map(
          (category: ICategory) => {
            const findFilteredCategory = filteredAllCategories.find(
              (filteredCategory: ICategory) =>
                filteredCategory.id === category.id
            )

            if (findFilteredCategory) {
              return findFilteredCategory
            }

            return category
          }
        )

        setAllCategories([])
        setAllCategories(() => updateAllCategories)
        setAllMediaItems((prevMediaItems: any) =>
          prevMediaItems.filter(
            (mediaItem: IMediaItem) =>
              !selectedMediaItemsId.find(
                (item: any) =>
                  String(mediaItem.id) === item.mediaItemId &&
                  mediaItem.CategoryMediaItem.CategoryId === item.categoryId
              )
          )
        )

        message.success('Videos was removed!', 3)
        setSelectedMediaItemsId([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onUnpublishedSelectedMediaItemsAction = async (): Promise<void> => {
    try {
      if (selectedMediaItemsId.length === 0) {
        return
      }

      const partnerId = storeCurrentPartnerId || getPartnerId()

      if (partnerId && currentDistribution) {
        const distributionId = currentDistribution.id

        const filteredMediaItems = allMediaItems.filter(
          (mediaItem: IMediaItem) =>
            selectedMediaItemsId.find(
              (item: any) =>
                String(mediaItem.id) === item.mediaItemId &&
                mediaItem.CategoryMediaItem.CategoryId === item.categoryId
            )
        )

        const updateMediaItems = filteredMediaItems.map(
          (mediaItem: IMediaItem) => {
            const cloneMediaItem = _.cloneDeep(mediaItem)
            cloneMediaItem.published = false
            return cloneMediaItem
          }
        )

        const promiseArr = updateMediaItems.map((mediaItem: any) =>
          MediaItemAPI.updateMediaItem(
            partnerId,
            distributionId,
            mediaItem.id,
            mediaItem
          )
        )

        const responses = await Promise.allSettled(promiseArr)
        const cloneAllCategories = _.cloneDeep(allCategories)

        const filteredAllCategories = selectedMediaItemsId.map((item: any) => {
          const findCategory = cloneAllCategories.find(
            (category: ICategory) => item.categoryId === category.id
          )

          if (findCategory && findCategory.MediaItems) {
            findCategory.MediaItems = findCategory.MediaItems.map(
              (mediaItem: IMediaItem) => {
                if (item.mediaItemId === String(mediaItem.id)) {
                  const cloneMediaItem = _.cloneDeep(mediaItem)
                  cloneMediaItem.published = false
                  return cloneMediaItem
                }
                return mediaItem
              }
            )
          }

          return findCategory
        })

        const prepareAllCategories = cloneAllCategories.map(
          (category: ICategory) => {
            const findFilteredCategory = filteredAllCategories.find(
              (filteredCategory: ICategory) =>
                filteredCategory.id === category.id
            )

            if (findFilteredCategory) {
              return findFilteredCategory
            }

            return category
          }
        )

        const updateAllCategories = prepareAllCategories.map(
          (categoryItem: ICategory) => {
            const cloneCategory = _.cloneDeep(categoryItem)
            cloneCategory.MediaItems = categoryItem.MediaItems?.map(
              (mediaItem: IMediaItem) => {
                if (
                  selectedMediaItemsId.find(
                    (item: any) => item.mediaItemId === String(mediaItem.id)
                  )
                ) {
                  const cloneMediaItem = _.cloneDeep(mediaItem)
                  cloneMediaItem.published = false
                  return cloneMediaItem
                }

                return mediaItem
              }
            )
            return cloneCategory
          }
        )

        setAllCategories([])
        setAllCategories(() => updateAllCategories)
        setAllMediaItems((prevMediaItems: any) =>
          prevMediaItems.filter(
            (mediaItem: IMediaItem) =>
              !selectedMediaItemsId.find(
                (item: any) =>
                  String(mediaItem.id) === item.mediaItemId &&
                  mediaItem.CategoryMediaItem.CategoryId === item.categoryId
              )
          )
        )

        message.success('Videos was unpublished!', 3)
        setSelectedMediaItemsId([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={styles.mainWrapper}>
        <TopBar
          isLogged
          distributions={allDistributions}
          setAllDistributions={setAllDistributions}
          onChangeDistribution={onChangeDistribution}
          allPartners={allPartners}
          setAllPartners={setAllPartners}
          setIsSuperAdmin={setIsSuperAdmin}
          isSuperAdmin={isSuperAdmin}
          onChangePartner={onChangePartner}
        />

        <div className={styles.mainContent}>
          <SideBar />

          <div className={styles.section}>
            <div className={styles.pageTitleWrapper}>
              <h3 className={styles.pageTitle}>Content</h3>
              <Button
                className={styles.titleBtn}
                shape="round"
                onClick={() => setPreviewVisible(true)}
                icon={<PreviewIcon />}
              >
                Preview
              </Button>
              <Button
                className={styles.titleBtn}
                shape="round"
                icon={<AddIcon />}
                onClick={() => onCreateNewCategory(false)}
              >
                Create New Category
              </Button>

              {previewVisible && (
                <Preview
                  previewVisible={previewVisible}
                  setPreviewVisible={setPreviewVisible}
                />
              )}
            </div>

            {isLoadingData ? (
              <Spin className={styles.spinner} size="large" />
            ) : (
              <>
                {!allCategories || allCategories.length === 1 ? (
                  <WithoutCategories
                    onCreateNewCategory={onCreateNewCategory}
                  />
                ) : (
                  <div className={styles.content}>
                    {allCategories
                      .filter((item: any) => item.name !== 'Orphan')
                      .map((category: ICategory, idx) => (
                        <Category
                          key={category.id}
                          category={category}
                          isDraggingMediaItem={isDraggingMediaItem}
                          setIsDraggingMediaItem={setIsDraggingMediaItem}
                          isLastCategory={allCategories.length - 1 === idx}
                          newCategoryId={newCategoryId}
                          onAddNewVideos={onAddNewVideos}
                          onChangeCategories={onChangeCategories}
                          openMediaItemsSelectedControls={
                            onOpenControlsSelectedMediaItems
                          }
                          onDeleteCategory={onDeleteCategory}
                          selectedMediaItemsId={selectedMediaItemsId}
                          changeSelectedMediaItemsId={
                            changeSelectedMediaItemsId
                          }
                          handleDragStartCategory={handleDragStartCategory}
                          handleDragEnterCategory={handleDragEnterCategory}
                          handleDragLeaveCategory={handleDragLeaveCategory}
                          handleDragEndCategory={handleDragEndCategory}
                          handleDragOverCategory={handleDragOverCategory}
                          handleDragDropCategory={handleDragDropCategory}
                          onChangePublishedCategory={onChangePublishedCategory}
                          currentDistribution={{
                            name: currentDistribution?.name,
                            id: currentDistribution?.id,
                          }}
                          onDropdownMenuChangePublishedMediaItem={
                            onDropdownMenuChangePublishedMediaItem
                          }
                        />
                      ))}

                    <div className={styles.createNewCategory}>
                      <Button
                        className={styles.createNewCategoryBtn}
                        icon={<AddIcon />}
                        shape="circle"
                        onClick={() => onCreateNewCategory(true)}
                      >
                        Create new category
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isOpenMediaItemsControls && (
        <div className={styles.bottomSelectedControls}>
          <Button
            className={styles.selectedCloseBtn}
            icon={<CloseSelectedMediaItemsIcon />}
            onClick={onCloseControlsSelectedMediaItems}
          />
          <span className={styles.selectedCount}>
            {selectedMediaItemsId.length} Selected
          </span>
          <Button
            shape="round"
            className={styles.selectedBtn}
            onClick={onRemoveSelectedMediaItemsAction}
          >
            Remove selected
          </Button>
          <Button
            shape="round"
            className={styles.selectedBtn}
            onClick={onUnpublishedSelectedMediaItemsAction}
          >
            Unpublish selected
          </Button>
          <Button shape="round" className={styles.selectedBtn}>
            Actions
          </Button>
        </div>
      )}
    </>
  )
}

export default Content
