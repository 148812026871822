import React, { FC, useEffect, useState } from 'react'
import { Button, Checkbox, Drawer, Select, Slider, Switch } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import _ from 'lodash'

import { ReactComponent as CloseFiltersIcon } from 'sources/images/libary/close-filters.svg'
import { ReactComponent as ApplyFiltersIcon } from 'sources/images/libary/apply-filters.svg'
import { ReactComponent as EyeIcon } from 'sources/images/libary/eye.svg'
import { ReactComponent as YoutubeIcon } from 'sources/images/libary/youtube.svg'
import { ReactComponent as VimeoIcon } from 'sources/images/libary/vimeo.svg'
import { ReactComponent as MyFilesIcon } from 'sources/images/libary/myfiles.svg'
import { ReactComponent as LinksIcon } from 'sources/images/libary/links.svg'
import { ReactComponent as SelectedIcon } from 'sources/images/libary/selected-category.svg'

import styles from './styles.module.scss'

interface IProps {
  visibleFilters: boolean
  allCategories: string[]
  onCloseFilters: () => void
  onSetFilters: (filters: any) => void
  min: number
  max: number
  allFilters: any
  categoryFromContent: string | undefined
}

const Filters: FC<IProps> = ({
  visibleFilters,
  allCategories,
  onCloseFilters,
  onSetFilters,
  min,
  max,
  allFilters,
  categoryFromContent,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [selectedPublished, setSelectedPublished] = useState<string[]>([])
  const [selectedSources, setSelectedSources] = useState<string[]>([])
  const [selectedRange, setSelectedRange] = useState<[number, number]>([
    min,
    max,
  ])

  const init = (): void => {
    if (categoryFromContent) {
      setSelectedCategories([categoryFromContent])
    }
  }

  useEffect(() => {
    init()
  }, [allCategories])

  useEffect(() => {
    if (_.isEmpty(allFilters)) {
      setSelectedCategories([])
      setSelectedStatuses([])
      setSelectedPublished([])
      setSelectedSources([])
      setSelectedRange([min, max])
    }
  }, [allFilters])

  const onChangeCategory = (names: string[]): void => {
    setSelectedCategories([...names])
  }

  const onToggleStatus = (checked: boolean, status: string): void => {
    if (!checked && selectedStatuses.includes(status)) {
      setSelectedStatuses(
        selectedStatuses.filter((statusName: string) => statusName !== status)
      )
    } else {
      setSelectedStatuses([status, ...selectedStatuses])
    }
  }

  const onTogglePublished = (checked: boolean, value: string): void => {
    if (!checked && selectedPublished.includes(value)) {
      setSelectedPublished(
        selectedPublished.filter(
          (publishedName: string) => publishedName !== value
        )
      )
    } else {
      setSelectedPublished([value, ...selectedPublished])
    }
  }

  const onChangeSource = (e: CheckboxChangeEvent, source: string): void => {
    if (e.target.checked && !selectedSources.includes(source)) {
      setSelectedSources([source, ...selectedSources])
    } else {
      setSelectedSources(
        selectedSources.filter((sourceName: string) => sourceName !== source)
      )
    }
  }

  const onChangeViews = (range: [number, number]): void => {
    setSelectedRange(range)
  }

  return (
    <Drawer
      title="Filter"
      width={276}
      className={styles.filters}
      onClose={onCloseFilters}
      visible={visibleFilters}
      closable={false}
      extra={
        <Button
          className={styles.closeFiltersBtn}
          onClick={onCloseFilters}
          icon={<CloseFiltersIcon />}
        />
      }
    >
      <div className={styles.selectFilterCategories}>
        <h5 className={styles.filtersTitle}>Categories</h5>

        <Select
          className={styles.categories}
          placeholder="Select category"
          mode="multiple"
          value={selectedCategories}
          menuItemSelectedIcon={
            <SelectedIcon className={styles.selectedCategoryImg} />
          }
          onChange={onChangeCategory}
        >
          {allCategories.map(name => (
            <Select.Option
              key={name}
              className={styles.categoryItem}
              value={name}
            >
              {name}
              <div className={styles.selectedCategoryImg} />
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={styles.filtersWrapper}>
        <h5 className={styles.filtersTitle}>Status</h5>

        <div className={styles.filterItem}>
          <span className={styles.statusText}>Imported</span>
          <Switch
            className={styles.filterSwitch}
            checked={selectedStatuses.includes('imported')}
            onChange={(checked: boolean) => onToggleStatus(checked, 'imported')}
          />
        </div>

        <div className={styles.filterItem}>
          <span className={styles.statusText}>in progress</span>
          <Switch
            className={styles.filterSwitch}
            checked={selectedStatuses.includes('in progress')}
            onChange={(checked: boolean) =>
              onToggleStatus(checked, 'in progress')
            }
          />
        </div>

        <div className={styles.filterItem}>
          <span className={styles.statusText}>Error</span>
          <Switch
            className={styles.filterSwitch}
            checked={selectedStatuses.includes('failed')}
            onChange={(checked: boolean) => onToggleStatus(checked, 'failed')}
          />
        </div>
      </div>

      <div className={styles.filtersWrapper}>
        <h5 className={styles.filtersTitle}>Published</h5>

        <div className={styles.filterItem}>
          <span className={styles.statusText}>Published</span>
          <Switch
            className={styles.filterSwitch}
            checked={selectedPublished.includes('published')}
            onChange={(checked: boolean) =>
              onTogglePublished(checked, 'published')
            }
          />
        </div>

        <div className={styles.filterItem}>
          <span className={styles.statusText}>Unpublished</span>
          <Switch
            className={styles.filterSwitch}
            checked={selectedPublished.includes('unpublished')}
            onChange={(checked: boolean) =>
              onTogglePublished(checked, 'unpublished')
            }
          />
        </div>
      </div>

      <div className={styles.sourceFilters}>
        <h5 className={styles.filtersTitle}>Source</h5>

        <Checkbox
          className={styles.checkboxFilterSource}
          checked={selectedSources.includes('youtube')}
          onChange={e => onChangeSource(e, 'youtube')}
        >
          <YoutubeIcon /> youtube
        </Checkbox>
        <Checkbox
          className={styles.checkboxFilterSource}
          checked={selectedSources.includes('vimeo')}
          onChange={e => onChangeSource(e, 'vimeo')}
        >
          <VimeoIcon /> vimeo
        </Checkbox>
        <Checkbox
          className={styles.checkboxFilterSource}
          checked={selectedSources.includes('mp4')}
          onChange={e => onChangeSource(e, 'mp4')}
        >
          <MyFilesIcon />
          My files
        </Checkbox>
        <Checkbox
          className={styles.checkboxFilterSource}
          checked={selectedSources.includes('links')}
          onChange={e => onChangeSource(e, 'links')}
        >
          <LinksIcon className={styles.sourceLinkIcon} />
          Links
        </Checkbox>
      </div>

      <div className={styles.viewsFilters}>
        <div className={styles.viewsTitleWrapper}>
          <h5 className={styles.filtersTitle}>Views</h5>
          <span className={styles.viewsValues}>
            {`${min} - ${max}`} <EyeIcon className={styles.eyeIcon} />
          </span>
        </div>

        <Slider
          className={styles.viewsSlider}
          range
          min={min}
          max={max}
          onChange={onChangeViews}
          defaultValue={[min, max]}
        />
      </div>

      <Button
        icon={<ApplyFiltersIcon />}
        shape="round"
        type="primary"
        disabled={
          selectedCategories.length === 0 &&
          selectedStatuses.length === 0 &&
          selectedPublished.length === 0 &&
          selectedSources.length === 0 &&
          selectedRange[0] === min &&
          selectedRange[1] === max
        }
        onClick={() =>
          onSetFilters({
            categories: selectedCategories,
            statuses: selectedStatuses,
            published: selectedPublished,
            sources: selectedSources,
            range: selectedRange,
          })
        }
        className={styles.applyFiltersBtn}
      >
        Aplly Filters
      </Button>
    </Drawer>
  )
}

export default Filters
